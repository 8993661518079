import { format } from "date-fns";
import { RefreshCw, ListFilter, ChevronDown, Info } from "lucide-react";
import React, { useEffect, useMemo, useState, useContext } from "react";
import { Dropdown, Accordion, AccordionContext } from "react-bootstrap";
import { fetchWithAuth } from "../../../../../api-client/APIClient";
import {
  CateogryScopeMap,
  CommonModelToggle,
  CommonModelSyncStatus,
  CommonModelSyncStatusType,
  PauseReason,
  LinkedAccount,
  CommonModelScopeMap,
  AuthType,
  LinkedAccountCondition,
} from "../../../../../models/Entities";
import EmptyStateWrapper from "../../../../shared/EmptyStateWrapper";

import MergeTable from "../../../../shared/MergeTable";
import type { Props as SyncHistoryProps } from "./SyncHistory";
import {
  getLinkedAccountCommonModelTogglesMap,
  createScopeMap,
} from "../../../../../api-client/APIClient";
import { showErrorToast, showSuccessToast } from "../../../../shared/Toasts";
import ScopesTable from "../data/ScopesTable";
import ScopesDropdown from "../data/ScopesDropdown";
import { Schemas } from "../../../configuration/common-models/CommonModelToggleTypes";
import { ScopeStatuses } from "../../../../../constants";
import { ScopesFieldToggle } from "../../../../shared/MergeToggles";
import TableSkeletonLoader from "../../../shared/TableSkeletonLoader";
import { Badge, Checkbox, Tooltip, Text, Card } from "@merge-api/merge-javascript-shared";
import { SortedModelsMap } from "../../../configuration/common-models/CommonModelUtils";
import { EnabledAction } from "../../../../../models/CommonModel";
import RotatingChevronRight from "../../../../shared/RotatingChevronRight";
import { BaseColor } from "@merge-api/merge-javascript-shared/dist/designSystem/types";

const getSyncStatusBadge = (
  syncStatus: CommonModelSyncStatusType,
  scopesText: string,
  pauseReason?: PauseReason | null,
  syncReason?: string | null,
) => {
  let color;
  let icon;
  let text;
  let title;

  switch (syncStatus) {
    case CommonModelSyncStatusType.SYNCING:
      color = "blue";
      icon = <RefreshCw className="rotating m-0 p-0" size={10} />;
      text = "Syncing";
      title = syncReason;
      break;

    case CommonModelSyncStatusType.DONE:
      color = "teal";
      text = "Done";
      break;

    case CommonModelSyncStatusType.PARTIALLY_SYNCED:
      color = "yellow";
      text = "Partially synced";
      title = "One or more fields for this model failed to sync.";
      break;

    case CommonModelSyncStatusType.FAILED:
      color = "red";
      text = "Failed";
      break;

    case CommonModelSyncStatusType.DISABLED:
      if (scopesText === ScopeStatuses.WRITE) {
        return (
          <Tooltip placement="top" title="Write-only models do not sync data">
            <Badge color="gray">Disabled</Badge>
          </Tooltip>
        );
      } else {
        color = "gray";
        text = "Disabled";
        break;
      }

    case CommonModelSyncStatusType.PAUSED:
      if (pauseReason && pauseReason === PauseReason.PRICING_PLAN_LIMIT) {
        color = "gray";
        text = "Paused";
        title = "Please upgrade your plan to continue syncing data from this account.";
        break;
      } else {
        color = "gray";
        text = "Paused";
        title =
          "Syncing for this account has been paused due to inactivity or unsuccessful sync attempts. To resume automated syncing, ensure that the account’s credentials are valid and press 'Resync all'";
        break;
      }
    default:
      color = "teal";
      text = "Done";
      break;
  }
  return (
    <Badge
      color={color as BaseColor}
      className="flex flex-row items-center justify-center gap-x-1.5 whitespace-nowrap"
    >
      {icon}
      <span>{text}</span>
      {title && (
        <Tooltip title={title} className="flex items-center">
          <Info size={12} className="text-gray-60" />
        </Tooltip>
      )}
    </Badge>
  );
};

const getScopesText = (scopes: string[]) => {
  if (!scopes || scopes.length == 0) {
    return ScopeStatuses.DISABLED;
  } else if (scopes.includes("ENABLED_ACTION_WRITE") && scopes.includes("ENABLED_ACTION_READ")) {
    return ScopeStatuses.READ_WRITE;
  } else if (scopes.includes("ENABLED_ACTION_WRITE")) {
    return ScopeStatuses.WRITE;
  } else {
    return ScopeStatuses.READ;
  }
};

const SyncHistoryExpandableRow = ({
  syncStatus,
  linkedAccount,
  scopes,
  numberOfAppliedFilters,
}: {
  syncStatus: CommonModelSyncStatus;
  linkedAccount: LinkedAccount | null;
  scopes: CommonModelScopeMap;
  isOverviewPage: boolean;
  numberOfAppliedFilters: number | undefined;
}) => {
  return (
    <>
      <tr key={syncStatus.model_id}>
        <td>
          <div className="flex flex-row items-center gap-x-1 whitespace-nowrap">
            <Text variant="h6">{syncStatus.model_name}</Text>
            {numberOfAppliedFilters !== undefined && numberOfAppliedFilters > 0 && (
              <Tooltip
                title={
                  numberOfAppliedFilters === 1
                    ? "Selective Sync filter has been applied to this model"
                    : "Selective Sync filters have been applied to this model"
                }
              >
                <ListFilter className="text-gray-60" size="16" />
              </Tooltip>
            )}
          </div>
        </td>
        <td>
          <Text className="text-gray-60">{getScopesText(scopes.actions)}</Text>
        </td>
        <td>
          {getSyncStatusBadge(
            syncStatus.status,
            getScopesText(scopes.actions),
            linkedAccount?.pause_reason,
            syncStatus.sync_reason,
          )}
        </td>
        <td>
          {syncStatus.last_sync_start ? (
            <Text>{format(new Date(syncStatus.last_sync_start), "MMM dd, hh:mm a")}</Text>
          ) : (
            <Text className="text-gray-60">—</Text>
          )}
        </td>
        <td>
          {!(linkedAccount?.is_test_account ?? false) && syncStatus.next_sync_start ? (
            <Text>{format(new Date(syncStatus.next_sync_start), "MMM dd, hh:mm a")}</Text>
          ) : (
            <Text className="text-gray-60">—</Text>
          )}
        </td>
        <td></td>
      </tr>
    </>
  );
};

// TODO @vnarayanam ~ remove this deprecated code when scopes refactor is fully launched ~ https://app.asana.com/0/1205900822182079/1206311452057006
const SyncHistoryExpandableRowWithFieldScopes = ({
  syncStatus,
  linkedAccount,
  scopes,
  commonModels,
  commonModelName,
  updateEnabledStatus,
  numberOfAppliedFilters,
}: {
  syncStatus: CommonModelSyncStatus;
  linkedAccount: LinkedAccount | null;
  scopes: CommonModelScopeMap;
  commonModels: SortedModelsMap;
  commonModelName: string;
  numberOfAppliedFilters: number | undefined;
  updateEnabledStatus: (
    modelName: string,
    fieldName: string | undefined,
    enabledActions: string[],
    shouldResetOverrideActions: boolean,
    shouldResetFieldOverride: boolean,
    onFetch: () => void,
  ) => void;
}) => {
  const currentEventKey = useContext(AccordionContext);
  const isTestAccount = !!linkedAccount?.is_test_account;
  const isSftpAccount = linkedAccount?.auth_type === AuthType.SFTP;
  const isQBDAccount = linkedAccount?.auth_type === AuthType.WEB_CONNECTOR;

  const changeState = (
    modelName: string,
    fieldName: string | null,
    enabledActions: string[],
    shouldResetOverrideActions: boolean,
    shouldResetFieldOverride: boolean,
  ) => {
    const onFetch = () => {
      showSuccessToast("Successfully updated scope");
    };
    const relatingField = (fieldName && commonModels[modelName].fields[fieldName]) || undefined;
    const relatedModelName = relatingField?.related_to;
    const relatedModel = !relatedModelName ? undefined : commonModels[relatedModelName];
    const isRelatedToSubobject = relatedModel && !relatedModel.has_endpoints;
    const shouldToggleRelatedModelInsteadOfField =
      relatedModelName && isRelatedToSubobject && relatedModel.name !== modelName;
    const transmittedFieldName = shouldToggleRelatedModelInsteadOfField
      ? undefined
      : fieldName ?? undefined;
    const modelToUpdate = shouldToggleRelatedModelInsteadOfField ? relatedModelName : modelName;
    updateEnabledStatus(
      modelToUpdate,
      transmittedFieldName,
      enabledActions,
      shouldResetOverrideActions,
      shouldResetFieldOverride,
      onFetch,
    );
  };

  const clickHandler = (scopeKey: string, enabledActions: string[]) => {
    const shouldResetOverrideActions = scopeKey == ScopeStatuses.RESET;
    changeState(commonModelName, null, enabledActions, shouldResetOverrideActions, false);
  };

  const isLinkedActionOverridesPresent = scopes.linkedAccountActionOverrides?.length > 0 || false;
  const scopeDropDownMenuOptions = (
    <>
      <div className="border-b-[1px] border-gray-10">
        <Dropdown.Item
          className="text-[14px] font-normal text-black hover:bg-gray-0"
          eventKey={ScopeStatuses.READ}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Checkbox
            label={ScopeStatuses.READ}
            checked={scopes.actions.includes(EnabledAction.READ)}
            onChange={() => {
              const actionsToEnabled = scopes.actions.includes(EnabledAction.WRITE)
                ? [EnabledAction.WRITE]
                : [];
              const wasPrevChecked = scopes.actions.includes(EnabledAction.READ);
              if (!wasPrevChecked) {
                // if not prev checked then enabling
                actionsToEnabled.push(EnabledAction.READ);
              }
              clickHandler(ScopeStatuses.READ, actionsToEnabled);
            }}
          />
        </Dropdown.Item>
        {scopes.capabilities.includes(EnabledAction.WRITE) && (
          <>
            <Dropdown.Item
              className="text-[14px] text-black font-normal hover:bg-gray-0"
              eventKey={ScopeStatuses.WRITE}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                label={ScopeStatuses.WRITE}
                checked={scopes.actions.includes(EnabledAction.WRITE)}
                onChange={() => {
                  const actionsToEnabled = scopes.actions.includes(EnabledAction.READ)
                    ? [EnabledAction.READ]
                    : [];
                  const wasPrevChecked = scopes.actions.includes(EnabledAction.WRITE);
                  if (!wasPrevChecked) {
                    actionsToEnabled.push(EnabledAction.WRITE);
                  }
                  clickHandler(ScopeStatuses.WRITE, actionsToEnabled);
                }}
              />
            </Dropdown.Item>
          </>
        )}
      </div>
      <Dropdown.Item
        className={`mt-2 text-[14px]  font-normal hover:bg-gray-0 ${
          !isLinkedActionOverridesPresent ? " text-gray-60" : "text-black"
        }`}
        active={!isLinkedActionOverridesPresent}
        disabled={!isLinkedActionOverridesPresent}
        eventKey="Reset"
        onClick={(e: any) => {
          e.stopPropagation();
          clickHandler(ScopeStatuses.RESET, []);
        }}
      >
        Reset to org default
      </Dropdown.Item>
    </>
  );

  const isDisabled = !scopes.actions || scopes.actions.length == 0;
  const isOpen = currentEventKey == syncStatus.model_id;

  return (
    <>
      <ScopesFieldToggle isClickable={!isDisabled} eventKey={syncStatus.model_id}>
        <td>
          <div className="flex flex-row items-center gap-x-1 whitespace-nowrap">
            <Text variant="h6">{syncStatus.model_name}</Text>

            {numberOfAppliedFilters !== undefined && numberOfAppliedFilters > 0 && (
              <Tooltip
                title={
                  numberOfAppliedFilters === 1
                    ? "Selective Sync filter has been applied to this model"
                    : "Selective Sync filters have been applied to this model"
                }
              >
                <ListFilter className="text-gray-60" size="12" />
              </Tooltip>
            )}
          </div>
        </td>
        <td>
          <div className="flex align-items-center">
            <ScopesDropdown menuOptions={scopeDropDownMenuOptions}>
              <div className="flex align-items-center">
                <div
                  className={`text-[14px] font-medium mr-[4px] ${isDisabled ? "text-gray-60" : ""}`}
                >
                  {getScopesText(scopes.actions)}
                </div>
                <ChevronDown size={12} />
              </div>
            </ScopesDropdown>
            {isLinkedActionOverridesPresent && (
              <Tooltip placement="top" title="Override of your organization's default setting">
                <div className="text-[14px] font-inter italic text-yellow-70 ml-1">Overridden</div>
              </Tooltip>
            )}
          </div>
        </td>
        <td>
          {getSyncStatusBadge(
            syncStatus.status,
            getScopesText(scopes.actions),
            linkedAccount?.pause_reason,
            syncStatus.sync_reason,
          )}
        </td>
        <td>
          {syncStatus.last_sync_start ? (
            <Text>{format(new Date(syncStatus.last_sync_start), "MMM dd, hh:mm a")}</Text>
          ) : (
            <Text className="text-gray-60">—</Text>
          )}
        </td>
        {!isTestAccount && !isSftpAccount && !isQBDAccount && (
          <td>
            {!(linkedAccount?.is_test_account ?? false) && syncStatus.next_sync_start ? (
              <Text>{format(new Date(syncStatus.next_sync_start), "MMM dd, hh:mm a")}</Text>
            ) : (
              <Text className="text-gray-60">—</Text>
            )}
          </td>
        )}
        <td className="flex justify-content-end pr-6">
          <RotatingChevronRight
            isRotatedDown={isOpen && !isDisabled}
            className="text-gray-60 text-base"
          />
        </td>
      </ScopesFieldToggle>
      {/* dont render scopes table if model is disabled */}
      {!isDisabled && (
        <td colSpan={6} className="border-top-0 pt-0 pb-0 hover:bg-white">
          <Accordion.Collapse eventKey={syncStatus.model_id}>
            <ScopesTable
              key={commonModelName}
              linkedAccountScopes={scopes}
              commonModelName={commonModelName}
              changeState={changeState}
              hideScopesToggle={isSftpAccount}
            />
          </Accordion.Collapse>
        </td>
      )}
    </>
  );
};

type Props = Pick<SyncHistoryProps, "linkedAccount"> & {
  /**
   * Data for the table - array of sync statuses, either fetched and
   * empty or full, or unfetched and represented by `null`.
   */
  syncStatuses: CommonModelSyncStatus[] | null;
  resyncButton?: any;
  isOverviewPage?: boolean;
  includeLinkedAccountScopes?: boolean;
  schemas?: Schemas | null;
  commonModels?: SortedModelsMap | null;
  remoteLinkedAccountConditions?: LinkedAccountCondition[];
};

/**
 * Shows a table of the syncs that have happened in the past - automatically polls for new
 * data once every three seconds to keep it up to date.
 */
const SyncHistoryTable = ({
  linkedAccount,
  syncStatuses,
  resyncButton,
  isOverviewPage = false,
  includeLinkedAccountScopes = false,
  commonModels = null,
  remoteLinkedAccountConditions,
}: Props) => {
  const isTestAccount = !!linkedAccount?.is_test_account;
  const isFirstSyncComplete = !!linkedAccount?.completed_at;
  const isSftpAccount = linkedAccount?.auth_type === AuthType.SFTP;
  const isQBDAccount = linkedAccount?.auth_type === AuthType.WEB_CONNECTOR;
  const alphabetizedSyncStatuses = useMemo(() => {
    syncStatuses?.sort((a, b) => a.model_name.localeCompare(b.model_name));
    return syncStatuses;
  }, [syncStatuses]);

  const [linkedAccountLevelCommonModelScopes, setLinkedAccountLevelCommonModelScopes] = useState<
    CateogryScopeMap | undefined
  >(undefined);

  useEffect(() => {
    if (!linkedAccount) {
      return;
    }
    getLinkedAccountCommonModelTogglesMap(linkedAccount, setLinkedAccountLevelCommonModelScopes);
  }, [linkedAccount]);

  const updateEnabledStatus = (
    modelName: string,
    fieldName: string | undefined,
    enabledActions: string[],
    shouldResetOverrideActions: boolean,
    shouldResetFieldOverride: boolean,
    onFetch: () => void,
  ) => {
    if (!linkedAccount) {
      return;
    }
    const updatedScope = {
      enabled_actions: enabledActions,
      ...(fieldName && { field: fieldName }),
      name: modelName,
      reset_linked_account_action_override: shouldResetOverrideActions,
      reset_linked_account_field_override: shouldResetFieldOverride,
    };
    fetchWithAuth({
      path: `integrations/common-model-toggles/${linkedAccount.category}?linked_account_id=${linkedAccount.id}`,
      method: "PATCH",
      body: updatedScope,
      onResponse: (data: CommonModelToggle[]) => {
        setLinkedAccountLevelCommonModelScopes(createScopeMap(data));
        onFetch();
      },
      onError: () => {
        showErrorToast("Unable to update Scope");
      },
    });
  };

  const syncStatusRows = includeLinkedAccountScopes
    ? linkedAccountLevelCommonModelScopes &&
      commonModels &&
      alphabetizedSyncStatuses
        ?.filter((syncStatus) =>
          Object.keys(linkedAccountLevelCommonModelScopes).includes(syncStatus.model_name),
        )
        .map((syncStatus) => {
          const numberOfAppliedFilters = remoteLinkedAccountConditions?.filter(
            (condition) => condition.common_model === syncStatus.model_name,
          ).length;

          return (
            <SyncHistoryExpandableRowWithFieldScopes // toggles enabled
              key={syncStatus.model_id}
              syncStatus={syncStatus}
              linkedAccount={linkedAccount}
              scopes={linkedAccountLevelCommonModelScopes![syncStatus.model_name] || []}
              commonModels={commonModels}
              commonModelName={syncStatus.model_name}
              updateEnabledStatus={updateEnabledStatus}
              numberOfAppliedFilters={numberOfAppliedFilters}
            />
          );
        })
    : linkedAccountLevelCommonModelScopes &&
      alphabetizedSyncStatuses?.map((syncStatus) => {
        const numberOfAppliedFilters = remoteLinkedAccountConditions?.filter(
          (condition) => condition.common_model === syncStatus.model_name,
        ).length;
        return (
          <SyncHistoryExpandableRow // sync history page no toggles enabled
            key={syncStatus.model_id} // Added key for consistency
            syncStatus={syncStatus}
            linkedAccount={linkedAccount}
            scopes={linkedAccountLevelCommonModelScopes[syncStatus.model_name] || []}
            isOverviewPage={isOverviewPage}
            numberOfAppliedFilters={numberOfAppliedFilters}
          />
        );
      });

  const header = (
    <>
      <th scope="col">Model</th>
      <th scope="col">Scope</th>
      <th scope="col">Status</th>
      <th scope="col" colSpan={isTestAccount ? 2 : 1}>
        {isSftpAccount || isQBDAccount ? "Last updated" : "Last sync start"}
      </th>
      {!isTestAccount && !isSftpAccount && !isQBDAccount && (
        <th scope="col">
          Next sync{" "}
          <Tooltip title="This is an approximation. Many factors affect the next start time.">
            <Info size={12} />
          </Tooltip>
        </th>
      )}
    </>
  );

  const content =
    linkedAccount && !isFirstSyncComplete ? (
      <tr>
        <td colSpan={12} className="p-0">
          <EmptyStateWrapper isTable title="Account not yet linked" />
        </td>
      </tr>
    ) : linkedAccount?.pause_reason === PauseReason.PLAN_LIMIT_REACHED ? (
      <tr>
        <td colSpan={12} className="p-0">
          <EmptyStateWrapper isTable title="No data synced" />
        </td>
      </tr>
    ) : syncStatuses === null || syncStatusRows === undefined ? (
      <TableSkeletonLoader cols={5} />
    ) : (
      (!!syncStatuses.length && syncStatusRows) || (
        <tr>
          <td colSpan={12} className="p-0">
            <EmptyStateWrapper isTable title="No sync events" />
          </td>
        </tr>
      )
    );

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between mb-5">
        <div className="flex items-center flex-grow-1">
          <Text variant="h3">{isSftpAccount ? "SFTP data" : "Data sync"}</Text>
        </div>
        <div>{resyncButton}</div>
      </div>
      <Card className="flex flex-col mb-9">
        <Accordion>
          <MergeTable header={header} content={content} hasMarginBottom={false} noBoxShadow />
        </Accordion>
      </Card>
    </div>
  );
};

export default SyncHistoryTable;
