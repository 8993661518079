import React from "react";
import clsx from "clsx";
import { EndpointUsageDataResponse } from "../../../types";
import EndpointUsageTableCell from "./EndpointUsageTableCell";
import EndpointUsageTableRowHeader from "./EndpointUsageTableRowHeader";
import { APICategory } from "@merge-api/merge-javascript-shared";

type EndpointUsageTableRowProps = {
  index: number;
  endpointUsageData: EndpointUsageDataResponse;
  endpoint: string;
  selectedCategory: APICategory;
};

const EndpointUsageTableRow = ({
  index,
  endpointUsageData,
  endpoint,
  selectedCategory,
}: EndpointUsageTableRowProps) => {
  return (
    <tr
      key={index}
      className={clsx(
        index < endpointUsageData.endpoints.length - 1 &&
          "border-b-[1px] border-solid border-gray-10",
      )}
    >
      {/* row headers */}
      <EndpointUsageTableRowHeader endpoint={endpoint} selectedCategory={selectedCategory} />

      {/* row cells */}
      {endpointUsageData.integrations.map((integration, colIndex) => (
        <EndpointUsageTableCell
          key={colIndex}
          endpointReadinessRating={
            endpointUsageData.usage_data[endpoint][integration.name].endpoint_readiness_rating
          }
        />
      ))}
    </tr>
  );
};

export default EndpointUsageTableRow;
