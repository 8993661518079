import React, { useEffect, useState } from "react";
import MergeTable from "../../../../../shared/MergeTable";
import FieldMappingByIntegrationRow from "./FieldMappingByIntegrationRow";
import { showSuccessToast, showErrorToast } from "../../../../../shared/Toasts";
import { deleteLinkedAccountFieldMapping } from "../../../../../../api-client/APIClient";
import { getAvailableIntegrationsForFieldMapping } from "../../utils/FieldMappingUtils";
import {
  AvailableFieldMappingIntegration,
  IntegrationWideFieldMappingInstanceInfo,
} from "../../../../../../models/Entities";
import { CONFIGURATION_COMMON_MODELS_PATH } from "../../../../../../router/RouterUtils";
import { Card, Link, Text, TextFieldVariant, Typeahead } from "@merge-api/merge-javascript-shared";

type FieldMappingByIntegrationTableProps = {
  fieldMappingTargetId: string;
  availableIntegrations: AvailableFieldMappingIntegration[];
  fieldMappingTargetCommonModelName: string;
  fieldMappingTargetCategory: string;
  fieldMappingTargetFieldKey: string;
  refreshFieldMappings: () => void;
  isRemoteDataEnabled?: boolean;
};

const FieldMappingByIntegrationTable = ({
  fieldMappingTargetId,
  availableIntegrations,
  fieldMappingTargetCommonModelName,
  fieldMappingTargetCategory,
  fieldMappingTargetFieldKey,
  refreshFieldMappings,
  isRemoteDataEnabled = true,
}: FieldMappingByIntegrationTableProps) => {
  const [selectedSearchOption, setSelectedSearchOption] = useState<string>("");
  const [updatedAvailableIntegrations, setUpdatedAvailableIntegrations] =
    useState<AvailableFieldMappingIntegration[]>(availableIntegrations);
  useEffect(() => setUpdatedAvailableIntegrations(availableIntegrations), [availableIntegrations]);

  const updateAvailableIntegrations = (
    integrationID: string,
    fieldMappingInstanceId: string,
    originField: string,
    remoteEndpointPath: string,
    remoteEndpointMethod: string,
    originType: string,
    isLinkedAccountOverrideEnabled: boolean,
    traversalPath: Array<string>,
  ) => {
    const currAvailableIntegrations = updatedAvailableIntegrations;
    const currIndex =
      currAvailableIntegrations.findIndex(
        (element) => element.integration_info.id === integrationID,
      ) ?? -1;
    if (currIndex >= 0 && currAvailableIntegrations.length) {
      const currIntegration = currAvailableIntegrations[currIndex];
      const newFieldMappingInfo: IntegrationWideFieldMappingInstanceInfo = {
        id: fieldMappingInstanceId,
        origin_field: originField,
        display_name: originField,
        field_description: undefined,
        remote_endpoint_path: remoteEndpointPath,
        enable_linked_account_level_overrides: isLinkedAccountOverrideEnabled,
        field_traversal_path: traversalPath,
        remote_endpoint_method: remoteEndpointMethod,
        origin_type: originType,
      };
      currIntegration.field_mapping_info = newFieldMappingInfo;
      currAvailableIntegrations[currIndex] = currIntegration;
      setUpdatedAvailableIntegrations([...currAvailableIntegrations]);
    }
  };

  const tableHeaders = (
    <>
      <th scope="col" className="pl-6">
        Integration
      </th>
      <th scope="col">Remote Field</th>
      <th scope="col">Endpoint</th>
      <th scope="col" className="pr-6"></th>
    </>
  );

  const handleFieldMappingDelete = (avaialbleIntegration: AvailableFieldMappingIntegration) => {
    const { field_mapping_info } = avaialbleIntegration;
    deleteLinkedAccountFieldMapping(field_mapping_info!.id, () => {
      getAvailableIntegrationsForFieldMapping({
        fieldMappingTargetId,
        onResponse: () => {
          refreshFieldMappings();
        },
        onError: () => {
          showErrorToast("Unable to delete Field Mapping");
        },
      });
      showSuccessToast("Successfully deleted Field Mapping");
    });
  };

  const filteredAvailableIntegrations =
    selectedSearchOption == ""
      ? updatedAvailableIntegrations
      : updatedAvailableIntegrations.filter(
          (availableIntegration) =>
            availableIntegration.integration_info.name.includes(selectedSearchOption) ||
            (availableIntegration?.field_mapping_info?.display_name ?? "").includes(
              selectedSearchOption,
            ) ||
            (availableIntegration?.field_mapping_info?.remote_endpoint_path ?? "")?.includes(
              selectedSearchOption,
            ),
        );

  const options: string[] = [];
  filteredAvailableIntegrations.forEach(
    (availableIntegration: AvailableFieldMappingIntegration) => {
      options.push(availableIntegration?.integration_info.name);
      if (availableIntegration?.field_mapping_info?.display_name != null) {
        options.push(availableIntegration?.field_mapping_info?.display_name);
      }
      if (availableIntegration?.field_mapping_info?.remote_endpoint_path != null) {
        options.push(availableIntegration?.field_mapping_info?.remote_endpoint_path);
      }
    },
  );
  options.sort();

  const rowData = (
    <>
      {filteredAvailableIntegrations.map(
        (availableIntegration: AvailableFieldMappingIntegration) => {
          return (
            <FieldMappingByIntegrationRow
              fieldMappingTargetID={fieldMappingTargetId}
              fieldMappingTargetCommonModelName={fieldMappingTargetCommonModelName}
              fieldMappingInstanceId={availableIntegration?.field_mapping_info?.id}
              fieldMappingTargetCategory={fieldMappingTargetCategory}
              fieldMappingTargetFieldKey={fieldMappingTargetFieldKey}
              integrationID={availableIntegration.integration_info.id}
              name={
                availableIntegration.integration_info.abbreviated_name != null &&
                availableIntegration.integration_info.abbreviated_name.trim() !== ""
                  ? availableIntegration.integration_info.abbreviated_name
                  : availableIntegration.integration_info.name
              }
              squareImage={availableIntegration.integration_info.square_image}
              originField={availableIntegration.field_mapping_info?.display_name}
              remoteEndpointPath={availableIntegration?.field_mapping_info?.remote_endpoint_path}
              remoteEndpointMethod={
                availableIntegration?.field_mapping_info?.remote_endpoint_method
              }
              originType={availableIntegration?.field_mapping_info?.origin_type}
              isLinkedAccountOverrideEnabled={
                availableIntegration?.field_mapping_info?.enable_linked_account_level_overrides
              }
              handleDelete={() => {
                handleFieldMappingDelete(availableIntegration);
              }}
              updateAvailableIntegrations={updateAvailableIntegrations}
              isRemoteDataEnabled={isRemoteDataEnabled}
            />
          );
        },
      )}
    </>
  );

  return (
    <Card className="flex flex-column pt-5 gap-y-5" size="lg">
      <div className="gap-y-2 px-6">
        <Text variant="h5" className="mb-2">
          Field Mappings by integration
        </Text>
        {isRemoteDataEnabled ? (
          <>
            Create Field Mappings that apply across all Linked Accounts of an integration for this
            field. Any changes to data made here will be updated in each Linked Account’s next sync.
          </>
        ) : (
          <>
            <Link
              href={`${CONFIGURATION_COMMON_MODELS_PATH}/${fieldMappingTargetCategory}`}
              as="span"
            >
              Enable Remote Data
            </Link>
            {"  "}
            <span>
              to create new Field Mappings across all Linked Accounts of an integration for this
              target field
            </span>
          </>
        )}
      </div>

      <div className="px-6">
        <Typeahead
          showSearchIcon
          borderVariant={TextFieldVariant.Bordered}
          value={selectedSearchOption === "" ? null : selectedSearchOption}
          options={options}
          onChange={(_, selected: any) => {
            setSelectedSearchOption(selected ?? "");
          }}
          placeholder="Search integrations, mappings, or endpoints..."
        />
      </div>
      {filteredAvailableIntegrations && (
        <MergeTable
          header={tableHeaders}
          content={rowData}
          hasMarginBottom={false}
          isOverviewPage
        />
      )}
    </Card>
  );
};
export default FieldMappingByIntegrationTable;
