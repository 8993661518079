import React from "react";
import clsx from "clsx";
import { LinkedAccount, LinkedAccountStatus } from "../../../../../../models/Entities";
import { Card, Text } from "@merge-api/merge-javascript-shared";
import IDsCardSkeleton from "./components/IDsCardSkeleton";
import LinkedAccountID from "./components/LinkedAccountID";
import APITesterButton from "./components/APITesterButton";
import DottedOutlineTextCard from "../../../../../shared/DottedOutlineTextCard";
import AddProductionLinkedAccountButton from "../../../AddProductionLinkedAccountButton";

type OverviewIDsCardProps = {
  linkedAccount: LinkedAccount | null;
  integrationIssueComponent?: React.ReactNode;
  className?: string;
};

const OverviewIDsCard = ({
  linkedAccount,
  integrationIssueComponent,
  className,
}: OverviewIDsCardProps) => {
  // skeleton
  if (!linkedAccount) {
    return <IDsCardSkeleton className={className} />;
  }

  return (
    <div className={clsx(className, "flex flex-col w-full gap-y-5")}>
      {!linkedAccount.is_test_account && (
        <AddProductionLinkedAccountButton linkedAccount={linkedAccount} />
      )}
      {!(linkedAccount.status == LinkedAccountStatus.RELINK_NEEDED) && (
        <APITesterButton linkedAccount={linkedAccount} />
      )}

      {/* integration issue */}
      {integrationIssueComponent}
      <Card className="overflow-hidden">
        {/* header */}
        <div className="px-4 py-3 border-b-solid border-b-gray-10 border-b-[1px]">
          <Text variant="h5">Account details</Text>
        </div>
        <div className="flex flex-col gap-3 p-4">
          {/* Linked Account Id */}
          <LinkedAccountID
            linkedAccountId={linkedAccount.id}
            hasIssue={!!integrationIssueComponent}
          />

          {/* Sync Connection Id (used for data warehouse) */}
          {linkedAccount.sync_connection_id && (
            <div className="flex flex-col gap-2">
              <Text variant="h6" className="mb-2">
                Connection ID
              </Text>
              <DottedOutlineTextCard text={linkedAccount.sync_connection_id} />
            </div>
          )}

          {/* Account Token */}

          <div className="flex flex-col gap-2">
            <Text variant="h6">Account token</Text>
            {linkedAccount.account_token != null && linkedAccount.account_token != "" ? (
              <DottedOutlineTextCard text={linkedAccount.account_token} isSecret isSecretHidden />
            ) : null}
          </div>
        </div>
      </Card>
    </div>
  );
};
export default OverviewIDsCard;
