import React from "react";
import { useHistory } from "react-router-dom";
import { ChevronRight } from "lucide-react";
import { navigateToFailedLinkedAccountLogsPage } from "../../../../../router/RouterUtils";
import { LinkedAccount, Log } from "../../../../../models/Entities";
import { Badge, Button, ButtonVariant, Accordion, Text } from "@merge-api/merge-javascript-shared";
import LogRow from "../../../shared/LogRow";

type IntegrationIssueLogsRowProps = {
  logs: Log[];
  linkedAccount: LinkedAccount;
};
const IntegrationIssueLogsRow = ({ logs, linkedAccount }: IntegrationIssueLogsRowProps) => {
  const history = useHistory();

  return (
    <Accordion
      className="mt-5 border-[1px]"
      titleClassName="flex flex-row items-center py-3 px-4"
      chevronColor="black"
      title={
        <div className="flex flex-row items-center gap-x-2">
          <Text variant="h6">Logs</Text>
          <Badge color="gray">{logs.length}</Badge>
        </div>
      }
      variant="outline"
    >
      <div className="flex flex-col">
        {logs.slice(0, 3).map((log: Log) => {
          return <LogRow log={log} linkedAccount={linkedAccount} />;
        })}
        <div
          className="flex justify-content-center align-items-center py-3 px-4 border-t-[1px] border-gray-10 hover:cursor-pointer"
          onClick={() => {
            navigateToFailedLinkedAccountLogsPage(history, linkedAccount.id);
          }}
        >
          <Button variant={ButtonVariant.TextBlue} size="sm" rightIcon={<ChevronRight size={12} />}>
            View more logs
          </Button>
        </div>
      </div>
    </Accordion>
  );
};
export default IntegrationIssueLogsRow;
