import React from "react";
import { Text, Card, ButtonVariant, Button } from "@merge-api/merge-javascript-shared";
import IconForFieldType from "../../../../../shared/IconForFieldType";

interface FieldMappingCardProps {
  displayName?: string;
  description?: string | React.ReactNode;
  badge: React.ReactNode;
  onClick?: () => void;
  type?: string | null;
}

export const FieldMappingCard = ({
  displayName,
  description,
  badge,
  onClick,
  type,
}: FieldMappingCardProps) => {
  return (
    <Card variant="outline" className="flex flex-row p-4 items-start gap-2 truncate">
      <div className="flex flex-col flex-grow w-full gap-y-2 h-full whitespace-nowrap">
        <div className="flex flex-col gap-2">
          <div className="truncate">{badge}</div>
          <div className="flex items-end">
            <Text variant="lg-mono" className="text-wrap break-all gap-x-[6px]">
              <>
                {displayName}
                {type && (
                  <IconForFieldType size={16} type={type} className="text-gray-60 pb-[2px]" />
                )}
              </>
            </Text>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row justify-between h-full whitespace-normal gap-y-2">
          {typeof description === "string" ? (
            <Text variant="sm" className="text-gray-60 whitespace-normal">
              {description}
            </Text>
          ) : (
            description
          )}
          {onClick && (
            <div className="flex h-full items-end justify-end">
              <Button variant={ButtonVariant.TextBlue} size="sm" onClick={onClick}>
                View
              </Button>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default FieldMappingCard;
