import React from "react";
import EmptyStateWrapper from "../shared/EmptyStateWrapper";
import { Card } from "@merge-api/merge-javascript-shared";

type NoPermissionsBlockProps = {
  restrictedPageName: string;
};

const NoPermissionsBlock = ({ restrictedPageName }: NoPermissionsBlockProps) => {
  return (
    <Card className="p-4">
      <EmptyStateWrapper
        title={`You do not have permission to access ${restrictedPageName}`}
        subtitle={`Please contact your organization's administrator to be added as an Admin or Developer to
        view ${restrictedPageName}`}
      />
    </Card>
  );
};

export default NoPermissionsBlock;
