import React, { useState, useContext } from "react";
import useAppContext from "../../../../../../context/useAppContext";
import FieldMappingModalTitle from "../../../../../linked-accounts/detail-page/field-mappings/modal/FieldMappingModalTitle";
import CancelAndNextFooter from "../../../../../../common/CancelAndNextFooter";
import { Typeahead, TextField, Alert, Select } from "@merge-api/merge-javascript-shared";
import { showSuccessToast, showErrorToast } from "../../../../../../shared/Toasts";
import { addNewFieldMappingTargetForOrg } from "../../../utils/FieldMappingUtils";
import { FieldMappingTarget } from "../../../../../../../models/Entities";
import OrgMappingCreationContext from "./context/OrgMappingCreationModalContext";
import { CONFIGURATION_COMMON_MODELS_PATH } from "../../../../../../../router/RouterUtils";
import { AlertTriangle } from "lucide-react";

const MODAL_FORM_SCREEN_HOSTS = {
  NO_COMMON_MODEL_SELECTED: "h-[313px]",
  COMMON_MODEL_WITH_REMOTE_DATA: "h-[487px]",
  COMMON_MODEL_NO_REMOTE_DATA: "h-[407px]",
};
const FieldMappingTargetOrgForm = () => {
  const { commonModels, category, onModalClose, setOrganizationMappingInfo } =
    useContext(OrgMappingCreationContext);

  const { user } = useAppContext();
  const [selectedCommonModel, setSelectedCommonModel] = useState<{
    label: string;
    key: string;
    hasRemoteData: boolean;
  } | null>(null);
  const [fieldTargetName, setFieldTargetName] = useState<string | null>(null);
  const [fieldDescriptionName, setFieldDescriptionName] = useState<string | null>(null);
  const [modalHeight, setModalHeight] = useState(MODAL_FORM_SCREEN_HOSTS.NO_COMMON_MODEL_SELECTED);

  const onSave = () => {
    if (fieldTargetName && selectedCommonModel) {
      const postBody = {
        common_model_id: `${category}.${selectedCommonModel.label}`,
        field_key: fieldTargetName,
        field_description: fieldDescriptionName || "",
      };
      const onSuccess = (data: FieldMappingTarget) => {
        setOrganizationMappingInfo((prevMappingInfo) => {
          const updatedMappingInfoForModel = prevMappingInfo;
          updatedMappingInfoForModel![selectedCommonModel.label!]["field_mapping_targets"].push(
            data,
          );
          const updatedOrgState = { ...prevMappingInfo, ...updatedMappingInfoForModel };
          return updatedOrgState;
        });
        showSuccessToast("Created org wide target!");
        onModalClose();
        document.getElementById(`${selectedCommonModel?.label}-mapping-info`)?.scrollIntoView({
          behavior: "smooth",
        });
      };
      const onFailure = () => {
        onModalClose();
        showErrorToast("Unable to create org-wide override");
      };

      addNewFieldMappingTargetForOrg({
        body: postBody,
        category,
        onSuccess,
        onError: onFailure,
      });
    }
  };
  return (
    <div className={`${modalHeight} transition-all duration-400 ease-in`}>
      <div className="pt-[18px]">
        <FieldMappingModalTitle
          title={`Add ${user.organization.name}-wide target field`}
          onClose={onModalClose}
        />
      </div>
      <div className="p-6">
        <div>
          Add a new Common Model field, also known as a <strong>target field</strong>, to map
          third-party data to.
        </div>
        <div className="mt-4 text-base font-semibold leading-6">Select Common Model</div>
        <div className="mt-2">
          <Select
            placeholder="Select Common Model..."
            options={commonModels!}
            renderOption={(option: any) => {
              return option.label;
            }}
            onChange={(_: any, selectedOption: any) => {
              if (selectedOption == null) {
                setSelectedCommonModel(selectedOption);
                setTimeout(() => {
                  setModalHeight(MODAL_FORM_SCREEN_HOSTS.NO_COMMON_MODEL_SELECTED);
                }, 400);
              } else {
                selectedOption.hasRemoteData
                  ? setModalHeight(MODAL_FORM_SCREEN_HOSTS.COMMON_MODEL_WITH_REMOTE_DATA)
                  : setModalHeight(MODAL_FORM_SCREEN_HOSTS.COMMON_MODEL_NO_REMOTE_DATA);

                // if switching from model with remote enabled to not skip timeout
                if (
                  selectedCommonModel &&
                  selectedCommonModel.hasRemoteData &&
                  !selectedOption.hasRemoteData
                ) {
                  setSelectedCommonModel(selectedOption);
                } else {
                  setTimeout(() => {
                    setSelectedCommonModel(selectedOption);
                  }, 400);
                }
              }
            }}
          />
        </div>
        <div
          className={`transition duration-400 ease
          ${selectedCommonModel ? "" : " opacity-0"}`}
        >
          {" "}
          {!selectedCommonModel ? (
            <></>
          ) : !selectedCommonModel.hasRemoteData ? (
            <div className="mt-4">
              <Alert color="yellow" icon={<AlertTriangle size={16} />}>
                <div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${CONFIGURATION_COMMON_MODELS_PATH}/${category}`}
                  >
                    Enable Remote Data
                  </a>
                  {"  "}
                  to create a new Common Model field for this Model
                </div>
              </Alert>
            </div>
          ) : (
            <>
              <TextField
                label="Target field name"
                className="mt-4"
                onChange={(e) => {
                  setFieldTargetName(e.target.value);
                }}
              />
              <TextField
                label={
                  <div className="flex items-center">
                    <div className="text-base font-semibold">Target field description</div>
                    <div className="ml-auto text-base text-gray-60">Optional</div>
                  </div>
                }
                className="mt-4"
                onChange={(e) => {
                  setFieldDescriptionName(e.target.value);
                }}
              />
            </>
          )}
        </div>
        <div className="mt-6">
          <CancelAndNextFooter
            buttonText="Save"
            onCancel={() => {
              onModalClose();
            }}
            onNext={() => {
              onSave();
            }}
            isNextDisabled={
              selectedCommonModel == null || fieldTargetName == null || fieldTargetName == ""
            }
          />
        </div>
      </div>
    </div>
  );
};

export default FieldMappingTargetOrgForm;
