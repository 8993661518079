import React from "react";
import clsx from "clsx";
import { Card, Text } from "@merge-api/merge-javascript-shared";
import { ResponseCodeBadge } from "../../../../shared/MergeBadges";
import { ResponseBodySection, ResponseHeadersSection } from "./components";
import { ApiTesterRequestState } from "../hooks/useApiTesterRequestState";

type ApiTesterResponseCardProps = {
  className?: string;
} & Pick<ApiTesterRequestState, "responseBody" | "responseCode" | "responseHeaders" | "isLoading">;

const ApiTesterResponseCard = ({
  className,
  responseBody,
  responseCode,
  responseHeaders,
  isLoading,
}: ApiTesterResponseCardProps) => {
  const hasMadeInitialRequest = !!(responseBody || responseCode || responseHeaders);

  return (
    <Card className={clsx("bg-white", className)}>
      {/* Header */}
      <div className="flex flex-row justify-between px-6 py-5 border-b-solid border-b-gray-10 border-b-[1px]">
        <Text variant="h3">Response</Text>
        <div>{responseCode && <ResponseCodeBadge responseCode={responseCode} size="lg" />}</div>
      </div>

      {/* Response Headers */}
      <ResponseHeadersSection responseHeaders={responseHeaders} />

      {/* Response Body */}
      <ResponseBodySection
        className="px-6 py-5 border-t-gray-10 border-t-[1px] h-full"
        responseBody={responseBody}
        hasMadeInitialRequest={hasMadeInitialRequest}
        isLoading={isLoading}
        responseCode={responseCode}
      />
    </Card>
  );
};

export default React.memo(ApiTesterResponseCard);
