import React, { useState, useContext } from "react";
import FieldMappingModalTitle from "../../../../../linked-accounts/detail-page/field-mappings/modal/FieldMappingModalTitle";
import OrgMappingCreationContext from "./context/OrgMappingCreationModalContext";
import CancelAndNextFooter from "../../../../../../common/CancelAndNextFooter";
import { Select, Alert } from "@merge-api/merge-javascript-shared";
import CommonModelFieldDropdown from "../../../../../../shared/CommonModelFieldDropdown";
import {
  CommonModelFieldDefinition,
  OverriddenCommonModelTarget,
} from "../../../../../../../models/Entities";
import { showErrorToast, showSuccessToast } from "../../../../../../shared/Toasts";
import { createOrgWideCommonModelOverrideTarget } from "../../../utils/FieldMappingUtils";
import { AlertTriangle } from "lucide-react";
import { CONFIGURATION_COMMON_MODELS_PATH } from "../../../../../../../router/RouterUtils";

const CommonModelOverrideOrgForm = () => {
  const { commonModels, onModalClose, availableOverrides, setOrganizationMappingInfo, category } =
    useContext(OrgMappingCreationContext);
  const [selectedCommonModel, setSelectedCommonModel] = useState<{
    label: string;
    key: string;
    hasRemoteData: boolean;
  } | null>(null);
  const [selectedFieldOverride, setSelectedFieldOverride] =
    useState<CommonModelFieldDefinition | null>(null);

  const onSave = () => {
    if (!selectedCommonModel || !selectedFieldOverride) {
      return;
    }
    const onSuccess = (data: OverriddenCommonModelTarget) => {
      setOrganizationMappingInfo((prevMappingInfo) => {
        const updatedMappingInfoForModel = prevMappingInfo;
        updatedMappingInfoForModel![selectedCommonModel.label!][
          "override_common_model_targets"
        ].push(data);
        return { ...prevMappingInfo, ...updatedMappingInfoForModel };
      });
      showSuccessToast("Created org wide override!");
      onModalClose();
      document.getElementById(`${selectedCommonModel?.label}-mapping-info`)?.scrollIntoView({
        behavior: "smooth",
      });
    };
    const onFailure = () => {
      onModalClose();
      showErrorToast("Unable to create org-wide override");
    };
    const overridePayload = {
      common_model_id: `${selectedCommonModel.key}`,
      overridden_field: selectedFieldOverride!.field_name,
    };

    createOrgWideCommonModelOverrideTarget(overridePayload, onSuccess, onFailure);
  };

  return (
    <div>
      <div className="pt-[18px]">
        <FieldMappingModalTitle title="Add Common Model override field" onClose={onModalClose} />
      </div>
      <div className="p-6">
        <div>
          Select an existing Common Model field to override and map new third-party data to.
        </div>
        <div className="mt-4 text-base font-semibold leading-6">Select Common Model</div>
        <div className="mt-2">
          <Select
            placeholder="Select Common Model..."
            options={commonModels!}
            renderValue={(option: any) => {
              return option.label;
            }}
            renderOption={(option: any) => {
              return option.label;
            }}
            onChange={(_: any, selectedOption) => {
              setSelectedCommonModel(selectedOption);
            }}
          />
        </div>
        <div className={`transition duration-400 ease ${selectedCommonModel ? "" : " opacity-0"}`}>
          {!selectedCommonModel ? (
            <></>
          ) : !selectedCommonModel.hasRemoteData ? (
            <div className="mt-4">
              <Alert color="yellow" icon={<AlertTriangle size={16} className="text-yellow-50" />}>
                <div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${CONFIGURATION_COMMON_MODELS_PATH}/${category}`}
                  >
                    Enable Remote Data
                  </a>
                  {"  "}
                  to create a new Common Model field for this Model
                </div>
              </Alert>
            </div>
          ) : (
            <>
              <div className="mt-4 text-base font-semibold leading-6">Select field</div>
              <div className="mt-2">
                <Select
                  placeholder="Select field..."
                  disabled={selectedCommonModel == null}
                  options={
                    selectedCommonModel ? availableOverrides![selectedCommonModel.label] : []
                  }
                  getOptionLabel={(option: any) => {
                    return option.field_name;
                  }}
                  renderValue={(value: any) => {
                    return value.field_name;
                  }}
                  renderOption={(option) => {
                    return (
                      <CommonModelFieldDropdown
                        fieldDescription={option.field_description}
                        fieldType={option.field_format || option.field_type}
                        fieldName={option.field_name}
                      />
                    );
                  }}
                  onChange={(_: any, selectedOption) => {
                    setSelectedFieldOverride(selectedOption);
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className="mt-6">
          <CancelAndNextFooter
            buttonText="Save"
            onCancel={() => {
              onModalClose();
            }}
            onNext={() => {
              onSave();
            }}
            isNextDisabled={selectedCommonModel == null || selectedFieldOverride == null}
          />
        </div>
      </div>
    </div>
  );
};
export default CommonModelOverrideOrgForm;
