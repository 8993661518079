import React, { useEffect, useState } from "react";
import { fetchWithAuth } from "../../../../../api-client/APIClient";
import { CustomIntegration } from "../../../../../models/Entities";
import { showSuccessToast, showErrorToast } from "../../../../shared/Toasts";
import { Link } from "react-router-dom";
import { Col, Row, ListGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import EmptyStateWrapper from "../../../../shared/EmptyStateWrapper";
import { SectionHeaderWrapper, TitleHeading } from "../../../../shared/MergeLayouts";
import {
  CONFIGURATION_INTEGRATIONS_CUSTOM_INTEGRATIONS_ADD_PATH,
  navigateToEditIndividualCustomIntegrationPage,
} from "../../../../../router/RouterUtils";
import DeprecatedH4 from "../../../../../deprecated/DeprecatedH4";
import {
  Card,
  Button,
  ButtonVariant,
  Toggle,
  Text,
  Menu,
  MenuItem,
  displayNameForAPICategory,
  AdminAPICategory,
} from "@merge-api/merge-javascript-shared";
import { MoreVertical } from "lucide-react";

function ConfigurationCustomIntegrations() {
  const history = useHistory();
  const [customIntegrations, setCustomIntegrations] = useState<CustomIntegration[] | null>();

  useEffect(() => {
    fetchCustomIntegrations();
  }, []);

  function fetchCustomIntegrations() {
    fetchWithAuth({
      path: "/integrations/custom",
      method: "GET",
      onResponse: (data) => {
        // TODO: Paginate
        setCustomIntegrations(data.results);
      },
    });
  }

  function changeCustomIntegration(customIntegration: CustomIntegration) {
    const active = !customIntegration.active;

    fetchWithAuth({
      path: `/integrations/custom/${customIntegration.id}`,
      method: "PATCH",
      body: { active: active },
      onResponse: () => {
        fetchCustomIntegrations();
        showSuccessToast(
          `Successfully ${active ? "activated" : "disabled"} the ${
            customIntegration.name
          } custom integration!`,
        );
      },
      onError: () => {
        showErrorToast(
          `Failed to ${active ? "activate" : "disable"} the ${
            customIntegration.name
          } custom integration.`,
        );
      },
    });
  }

  function deleteCustomIntegration(customIntegration: CustomIntegration) {
    fetchWithAuth({
      path: `/integrations/custom/${customIntegration.id}`,
      method: "DELETE",
      onResponse: () => {
        fetchCustomIntegrations();
        showSuccessToast(`Successfully deleted the ${customIntegration.name} custom integration!`);
      },
      onError: () => {
        showErrorToast(`Failed to delete the ${customIntegration.name} custom integration.`);
      },
    });
  }

  return (
    <SectionHeaderWrapper
      title="Custom integrations"
      subtitle={`Add "integrations" to your organization's linking experience with custom steps. These
              custom integrations will appear similar to other integrations, and will be specific to
              your organization's linking experience.`}
      titleHeading={TitleHeading.H2}
      button={
        <Link to={CONFIGURATION_INTEGRATIONS_CUSTOM_INTEGRATIONS_ADD_PATH}>
          <Button variant={ButtonVariant.TertiaryWhite} size="sm">
            Add integration
          </Button>
        </Link>
      }
    >
      <Card className="mt-6 p-6">
        <ListGroup variant="flush" className="my-n3">
          {customIntegrations ? (
            customIntegrations.length > 0 ? (
              customIntegrations.map((customIntegration: CustomIntegration) => (
                <ListGroup.Item key={customIntegration.id}>
                  <Row className="align-items-center">
                    <Col>
                      <DeprecatedH4 className="font-weight-base mb-1">
                        {customIntegration.name}
                      </DeprecatedH4>
                      <Text variant="sm" className="text-gray-60 mb-0">
                        {displayNameForAPICategory(customIntegration.category as AdminAPICategory)}
                      </Text>
                    </Col>
                    <Col className="col-auto">
                      <Toggle
                        checked={customIntegration.active}
                        onChange={() => changeCustomIntegration(customIntegration)}
                      />
                    </Col>
                    <Col className="col-auto">
                      <Menu
                        ButtonProps={{
                          children: <MoreVertical size={16} />,
                          variant: ButtonVariant.IconShadowHidden,
                        }}
                        menuPlacement="bottom-end"
                      >
                        <MenuItem
                          onClick={() =>
                            navigateToEditIndividualCustomIntegrationPage(
                              history,
                              customIntegration.id,
                            )
                          }
                        >
                          Edit integration
                        </MenuItem>
                        <MenuItem onClick={() => deleteCustomIntegration(customIntegration)}>
                          Delete integration
                        </MenuItem>
                      </Menu>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))
            ) : (
              <EmptyStateWrapper title="No custom integrations" />
            )
          ) : (
            <EmptyStateWrapper isSpinner />
          )}
        </ListGroup>
      </Card>
    </SectionHeaderWrapper>
  );
}

export default ConfigurationCustomIntegrations;
