import React from "react";
import { Text } from "@merge-api/merge-javascript-shared";

type UserCellProps = {
  name?: string;
  email?: string;
};

const UserCell = ({ name, email }: UserCellProps) => {
  if (!name) {
    return <td>-</td>;
  }

  return (
    <td>
      <div className="flex flex-col">
        <Text variant="h6" className="flex-wrap">
          {name}
        </Text>
        <Text variant="sm" className="text-gray-60 flex-wrap">
          {email}
        </Text>
      </div>
    </td>
  );
};

export default UserCell;
