import { Dispatch, SetStateAction } from "react";
import { fetchLinkedAccountFieldMappingInstances } from "../../../../../../api-client/APIClient";
import {
  CommonModelFieldDefinition,
  FieldMappingCreationAndEditDict,
  FieldMappingModalStep,
  FieldMappingOptions,
  FieldMappingPathways,
  FieldMappingTargetsAndFieldMappingInstances,
  LinkedAccount,
  ModelAndFieldOverrideDict,
} from "../../../../../../models/Entities";
import { showErrorToast, showSuccessToast } from "../../../../../shared/Toasts";
import { createOverriddenCommonModelInstance } from "../../../../configuration/field-mappings/utils/FieldMappingUtils";
import FieldMappingOptionSelection from "./steps/FieldMappingOptionSelection";
import React from "react";
import AdvancedFieldMappingSelection from "./steps/advanced-field-mapping/AdvancedFieldMappingSelection";
import CommonModelOverrideMappingCreation from "./steps/CommonModelOverrideMappingCreation";
import LinkedAccountSpecificFieldMappingCreation from "./steps/LinkedAccountSpecificFieldMappingCreation";
import RemoteFieldSelection from "./steps/RemoteFieldSelection";

/**
 * Generates a flattened array of field mapping options with additional metadata for creation and editing
 *
 * @param fieldMappingOptions - A nested object containing field mapping configurations, organized by common model keys,
 *                              API endpoint IDs, and traversal paths.
 * @returns An array of FieldMappingCreationAndEditDict objects, each representing a field mapping option with metadata.
 */
export const createFieldMappingOptions = (fieldMappingOptions: FieldMappingOptions) => {
  const options: FieldMappingCreationAndEditDict[] = [];

  // Iterate through each common model key in the fieldMappingOptions object.
  Object.keys(fieldMappingOptions).forEach((commonModelKey) => {
    // For each common model key, iterate through its associated API endpoint IDs, sorted alphabetically.
    Object.keys(fieldMappingOptions[commonModelKey])
      .sort()
      .forEach((apiEndpointId) => {
        // For each API endpoint ID, iterate through its associated traversal paths.
        Object.keys(fieldMappingOptions[commonModelKey][apiEndpointId]).forEach((traversalPath) => {
          // Push a new option into the options array with metadata:
          // - Spread the current field mapping's properties.
          // - Generate a unique key using display_name, api_endpoint_id, and a random number.
          // - Include the commonModelName for context.
          options.push({
            ...fieldMappingOptions[commonModelKey][apiEndpointId][traversalPath],
            key: `${
              fieldMappingOptions[commonModelKey][apiEndpointId][traversalPath].display_name
            }-${
              fieldMappingOptions[commonModelKey][apiEndpointId][traversalPath].api_endpoint_id
            }-${Math.floor(Math.random() * 10000)}`,
            commonModelName: commonModelKey,
          });
        });
      });
  });

  return options;
};

export const getModalContent = (modalStep: FieldMappingModalStep) => {
  switch (modalStep) {
    case FieldMappingModalStep.FIELD_MAPPING_OPTION_SELECTION:
      return <FieldMappingOptionSelection />;
    case FieldMappingModalStep.REMOTE_FIELD_SELECTION:
      return <RemoteFieldSelection />;
    case FieldMappingModalStep.COMMON_MODEL_SELECTION_AND_LINKED_ACCOUNT_MAPPING_CREATION:
      return <LinkedAccountSpecificFieldMappingCreation />;
    case FieldMappingModalStep.COMMON_MODEL_OVERRIDE_SELECTION:
      return <CommonModelOverrideMappingCreation />;
    case FieldMappingModalStep.ADVANCED_FIELD_MAPPING_SELECTION:
      return <AdvancedFieldMappingSelection />;
    default:
      return <FieldMappingOptionSelection />;
  }
};

export const isAdvancedMappingAvailableForRemoteField = (
  remoteField: FieldMappingCreationAndEditDict,
) => {
  return (
    (remoteField.type.includes("list") && remoteField.value?.length !== 0) ||
    remoteField.type == "dict"
  );
};

export const getFieldMappingModalTitle = (
  selectedFieldMappingPathway: FieldMappingPathways,
  linkedAccount?: LinkedAccount | null,
) => {
  switch (selectedFieldMappingPathway) {
    case FieldMappingPathways.LINKED_ACCOUNT_SPECIFIC_MAPPING:
      return `Map a new ${linkedAccount?.end_user.organization_name}-specific target field`;
    case FieldMappingPathways.COMMON_MODEL_OVERRIDE:
      return "Override an existing Merge field";
  }
};

const getAvailableFieldsToModelMap = (availableFieldsAndOverrides: ModelAndFieldOverrideDict) => {
  return Object.values(availableFieldsAndOverrides)?.reduce(
    (
      acc: {
        [common_model_id: string]: CommonModelFieldDefinition[];
      },
      modelOverrideInfo,
    ) => {
      acc[modelOverrideInfo.common_model_name] =
        modelOverrideInfo["allowed_overridden_fields_configs"];
      return acc;
    },
    {},
  );
};

export const getOverrideOptions = (
  commonModelSelected: string | undefined,
  availableFieldsAndOverrides: ModelAndFieldOverrideDict,
) => {
  if (!commonModelSelected) {
    return [];
  }
  return getAvailableFieldsToModelMap(availableFieldsAndOverrides!)[commonModelSelected];
};

export const createCommonModelOverride = (
  selectedRemoteField: FieldMappingCreationAndEditDict | null,
  linkedAccount: LinkedAccount | null,
  commonModelSelected: string | undefined,
  jmesPath: string | null,
  fieldToOverride: {
    name: string;
    type: string;
    description: string;
    format: string | null;
  } | null,
  setIsCreating: (value: boolean) => void,
  setFieldMappingTargetAndInstances: Dispatch<
    SetStateAction<FieldMappingTargetsAndFieldMappingInstances>
  >,
  onModalClose: () => void,
) => {
  setIsCreating(true);
  if (selectedRemoteField) {
    const data = {
      linked_account_id: linkedAccount?.id,
      common_model_id: `${linkedAccount?.category}.${commonModelSelected}`,
      overridden_field: fieldToOverride?.name,
      field_traversal_path: selectedRemoteField.traversal_path.split("."),
      api_endpoint_id: selectedRemoteField?.api_endpoint_id,
      display_name: selectedRemoteField?.display_name,
      origin_type: selectedRemoteField.type,
      ...(jmesPath !== null ? { jmes_path: jmesPath } : {}),
    };

    const onSuccess = () => {
      fetchLinkedAccountFieldMappingInstances(
        linkedAccount!.id,
        setFieldMappingTargetAndInstances,
        () => {
          onModalClose();
          setIsCreating(false);
          showSuccessToast("Successfully created a linked account mapping!");
          document.getElementById(`${commonModelSelected}-mapping-info`)?.scrollIntoView({
            behavior: "smooth",
          });
        },
      );
    };

    createOverriddenCommonModelInstance(data, onSuccess, () => {
      showErrorToast("Unable to create Common Model override");
      onModalClose();
    });
  }
};

const workdayPendingEmployeesPathPattern = /^\/service\/[^/]+\/Human_Resources\/v38\.2$/;
const workdayActiveEmployeesPathPattern = /^\/service\/[^/]+\/Human_Resources$/;

export const renderWorkdayEmployeesEndpointStatus = (apiEndpointPath: string) => {
  if (workdayPendingEmployeesPathPattern.test(apiEndpointPath)) {
    return <span className="text-sm">(pending)</span>;
  }
  if (workdayActiveEmployeesPathPattern.test(apiEndpointPath)) {
    return <span className="text-sm">(active)</span>;
  }
  return null;
};
