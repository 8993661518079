import React from "react";
import clsx from "clsx";
import { Card, Text } from "@merge-api/merge-javascript-shared";
import { ApiTesterAccountSelector, EndpointPathSelector, ParamsHeadersAndBody } from "./components";
import { ApiTesterRequestState } from "../hooks/useApiTesterRequestState";
import { APITesterLinkedAccount } from "../../../../../models/Entities";

type ApiTesterRequestCardProps = {
  className?: string;
  isLoading: boolean;
  onSendClick: () => void;
  onSearchChange: (search: string) => void;
  linkedAccounts?: APITesterLinkedAccount[];
  linkedAccount?: APITesterLinkedAccount;
  setLinkedAccount: React.Dispatch<React.SetStateAction<APITesterLinkedAccount | undefined>>;
} & ApiTesterRequestState;

const ApiTesterRequestCard = ({
  className,
  onSendClick,
  isLoading,
  linkedAccount,
  onSearchChange,
  setLinkedAccount,
  linkedAccounts,
  method,
  setMethod,
  path,
  setPath,
  params,
  setParams,
  headers,
  setHeaders,
  body,
  setBody,
  mockSandboxCategory,
  setMockSandboxCategory,
  category,
  mockSandboxIntegrations,
  mockSandboxIntegration,
  setMockSandboxIntegration,
}: ApiTesterRequestCardProps) => {
  return (
    <Card className={clsx("bg-white", className)}>
      {/* header */}
      <div className="py-5 px-6 border-b-solid border-b-gray-10 border-b-[1px]">
        <Text variant="h3">Request</Text>
      </div>

      {/* account selector */}
      <ApiTesterAccountSelector
        className="border-b-solid border-b-gray-10 border-b-[1px]"
        linkedAccount={linkedAccount}
        setLinkedAccount={setLinkedAccount}
        linkedAccounts={linkedAccounts}
        mockSandboxCategory={mockSandboxCategory}
        setMockSandboxCategory={setMockSandboxCategory}
        mockSandboxIntegrations={mockSandboxIntegrations}
        mockSandboxIntegration={mockSandboxIntegration}
        setMockSandboxIntegration={setMockSandboxIntegration}
        onSearchChange={onSearchChange}
      />

      {/* endpoint path */}
      <EndpointPathSelector
        className="border-b-solid border-b-gray-10 border-b-[1px]"
        linkedAccount={linkedAccount}
        onSendClick={onSendClick}
        method={method}
        setMethod={setMethod}
        path={path}
        setPath={setPath}
        isLoading={isLoading}
        category={category}
      />

      {/* params headers and body */}
      <ParamsHeadersAndBody
        method={method}
        params={params}
        setParams={setParams}
        headers={headers}
        setHeaders={setHeaders}
        body={body}
        setBody={setBody}
      />
    </Card>
  );
};

export default ApiTesterRequestCard;
