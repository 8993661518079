import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { formatBytes } from "../../services";
import { showErrorToast } from "./Toasts";
import { MoreVertical } from "lucide-react";
import { ButtonVariant, Menu, MenuItem, Text } from "@merge-api/merge-javascript-shared";

type Props = { upload: (files: File[]) => void };

function Dropzone({ upload }: Props) {
  const [files, setFiles] = useState<Array<File & { preview: string }>>([]);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length === 1) {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          ),
        );
        upload(acceptedFiles);
      } else {
        showErrorToast("Please only upload 1 image file.");
      }
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  const fileRows = files.map((file: File & { preview: string }) => (
    <li key={file.name} className="list-group-item dz-processing dz-image-preview">
      <div className="row align-items-center">
        <div className="col-auto">
          <div className="avatar">
            <img className="avatar-img deprecated-rounded" src={file.preview} alt="" />
          </div>
        </div>
        <div className="col ml-n3">
          <Text variant="h6" className="mb-1">
            {file.name}
          </Text>
          <Text variant="sm" className="text-gray-60">
            {formatBytes(file.size)}
          </Text>
        </div>
        <div className="col-auto">
          <Menu
            ButtonProps={{
              children: <MoreVertical size={16} />,
              variant: ButtonVariant.IconShadowHidden,
              className: "bg-transparent",
            }}
            menuPlacement="bottom-end"
          >
            <MenuItem onClick={() => setFiles([])}>Remove</MenuItem>
          </Menu>
        </div>
      </div>
    </li>
  ));

  return (
    <>
      <div
        {...getRootProps({
          className: "dropzone dropzone-multiple dz-clickable flex flex-col ",
        })}
      >
        <div className="py-10 px-4 flex flex-col items-center bg-white border-[1px] border-dashed border-gray-10 hover:cursor-pointer rounded-md hover:bg-gray-0 transition-colors duration-150 ease-in-out">
          <input {...getInputProps()} />
          <p className="mb-0 text-gray-60">Drag and drop, or click to browse files</p>
        </div>
      </div>
      <ul className="dz-preview dz-preview-multiple list-group list-group-lg list-group-flush">
        {fileRows}
      </ul>
    </>
  );
}

export default Dropzone;
