import React from "react";
import {
  isWebConnectorLogEntry,
  isWebhookLogEntry,
  LogType,
} from "../../IntegrationsManagementEntities";
import MergeMark from "../../../../assets/img/mark-borderless.png";
import { Text, Tooltip } from "@merge-api/merge-javascript-shared";

/**
 * Helper component for arrow with a tooltip
 */
export enum ArrowDirection {
  RIGHT = "RIGHT",
  LEFT = "LEFT",
}

interface ArrowProps {
  tooltip: string;
  direction?: ArrowDirection;
}

export const Arrow = ({ tooltip, direction = ArrowDirection.RIGHT }: ArrowProps) => {
  const isLeftArrow = direction === ArrowDirection.LEFT;
  return (
    <Tooltip title={tooltip} className="flex justify-center">
      <svg
        className={`w-4 h-4 fill-gray-60 ${isLeftArrow ? "rotate-180" : ""}`}
        viewBox="0 0 16 16"
      >
        <rect x="4" y="7" width="5" height="2" />
        <path d="M12 8L9 5V11L12 8Z" />
      </svg>
    </Tooltip>
  );
};

interface CircleBadgeProps {
  badgeName: string;
  imageUrl?: string;
  size: number;
}
/**
 * Helper component to create a circular badge with a given name/imageUrl if exists
 */
const CircleBadge = ({ badgeName, imageUrl, size }: CircleBadgeProps) => (
  <Tooltip title={badgeName}>
    <div
      className={`flex items-center justify-center bg-white w-[${size}px] h-[${size}px] overflow-hidden rounded-full`}
    >
      {imageUrl ? (
        <img src={imageUrl} width={size} height={size} className="rounded-full" />
      ) : (
        <Text variant="h6">{badgeName[0].toUpperCase()}</Text>
      )}
    </div>
  </Tooltip>
);

interface Props {
  log: LogType;
  size?: number;
}

/**
 * Shows a cell with a directional visual with logos/text for where the log
 * came from. Has a standard 5 cells, but there's different content based on
 * if the direction is inbound/outbound.
 */
const DirectionCell = ({ log, size = 20 }: Props) => {
  const isInbound = log.direction === "INBOUND";

  const integrationName = log.linked_account.integration?.name || "None";
  const IntegrationCircleBadge = () => {
    return (
      <CircleBadge
        badgeName={integrationName}
        imageUrl={log.linked_account.integration?.square_image}
        size={size}
      />
    );
  };

  const orgName = log.linked_account.organization.name || "None";
  const OrganizationCircleBadge = () => {
    return <CircleBadge badgeName={orgName} size={size} />;
  };

  const MergeCircleBadge = () => {
    return <CircleBadge badgeName="Merge" imageUrl={MergeMark} size={size} />;
  };

  return (
    <div className="grid grid-cols-3 flex-row items-center">
      {isWebhookLogEntry(log) || isWebConnectorLogEntry(log) ? (
        isInbound ? (
          <>
            <IntegrationCircleBadge />
            <Arrow tooltip={`${integrationName} to Merge`} />
            <MergeCircleBadge />
          </>
        ) : (
          <>
            <OrganizationCircleBadge />
            <Arrow tooltip={`Merge to ${orgName}`} direction={ArrowDirection.LEFT} />
            <MergeCircleBadge />
          </>
        )
      ) : isInbound ? (
        <>
          <OrganizationCircleBadge />
          <Arrow tooltip={`${orgName} to Merge`} />
          <MergeCircleBadge />
        </>
      ) : (
        <>
          <MergeCircleBadge />
          <Arrow tooltip={`Merge to ${integrationName}`} />
          <IntegrationCircleBadge />
        </>
      )}
    </div>
  );
};

export default DirectionCell;
