import React, { useContext, useEffect, useState } from "react";
import { AlertTriangle, ArrowLeft } from "lucide-react";
import CancelAndNextFooter from "../../../../../../common/CancelAndNextFooter";
import { Alert, Link, TextField } from "@merge-api/merge-javascript-shared";
import FieldMappingsGridContainer from "../../components/FieldMappingsGridContainer";
import FieldMappingModalTitle from "../../modal/FieldMappingModalTitle";
import FieldMappingModalContext from "../context/FieldMappingModalContext";
import { doesModelHaveRemoteDataEnabled } from "../../modal/field-mappings/CreateFieldMappingModelSelection";
import { CONFIGURATION_COMMON_MODELS_PATH } from "../../../../../../../router/RouterUtils";
import { FieldMappingSource } from "../../../../../../../constants";
import {
  createLinkedAccountFieldMapping,
  fetchLinkedAccountFieldMappingInstances,
} from "../../../../../../../api-client/APIClient";
import LinkedAccountFieldMappingsContext from "../../context/LinkedAccountFieldMappingsContext";
import { showSuccessToast } from "../../../../../../shared/Toasts";
import RemoteFieldCard from "../components/RemoteFieldCard";
import FieldMappingsMergeToIntegrationHeader from "../../components/FieldMappingsMergeToIntegrationHeader";
import FieldMappingTargetBadge from "../../../../../../shared/FieldMappingTargetBadge";

const LinkedAccountSpecificFieldMappingCreation = () => {
  const {
    linkedAccount,
    onModalClose,
    selectedRemoteField,
    jmesPath,
    fieldMappingInstances,
    commonModelsToMappingConfig,
  } = useContext(FieldMappingModalContext);
  const { setFieldMappingTargetAndInstances } = useContext(LinkedAccountFieldMappingsContext);

  const [fieldMappingTargetName, setFieldMappingTargetName] = useState<string | null>(null);
  const [fieldMappingTargetDescription, setFieldMappingTargetDescription] = useState<string | null>(
    null,
  );
  const [isCreating, setIsCreating] = useState(false);
  const [
    shouldShowEditingExistingFieldWarningBanner,
    setShouldShowEditingExistingFieldWarningBanner,
  ] = useState<boolean>(false);

  const createFieldMapping = (selectedRemoteField: any) => {
    setIsCreating(true);
    const body = {
      linked_account_id: linkedAccount!.id,
      common_model_id: `${linkedAccount!.category}.${selectedRemoteField!.commonModelName}`,
      field_key: fieldMappingTargetName!,
      field_traversal_path: selectedRemoteField.traversal_path.split("."),
      create_for_organization: false,
      configured_by: FieldMappingSource.ORGANIZATION,
      ...(fieldMappingTargetDescription
        ? {
            field_description: fieldMappingTargetDescription,
          }
        : {}),
      api_endpoint_id: selectedRemoteField.api_endpoint_id,
      display_name: selectedRemoteField.display_name,
      origin_type: selectedRemoteField.type,
      ...(jmesPath !== null ? { jmes_path: jmesPath } : {}),
    };
    createLinkedAccountFieldMapping(body, () => {
      fetchLinkedAccountFieldMappingInstances(
        linkedAccount!.id,
        setFieldMappingTargetAndInstances,
        () => {
          onModalClose();
          setIsCreating(false);
          showSuccessToast("Successfully created a linked account mapping!");
          document
            .getElementById(`${selectedRemoteField!.commonModelName}-mapping-info`)
            ?.scrollIntoView({
              behavior: "smooth",
            });
        },
      );
    });
  };

  /* this useEffect is for determining if a user is trying to edit a preexisting mapping
   * this means that the field name typed in and the remote field match an existing mapping
   */
  useEffect(() => {
    setShouldShowEditingExistingFieldWarningBanner(
      fieldMappingInstances.some(
        (fieldMappingInstance) =>
          fieldMappingInstance.common_model_name === selectedRemoteField?.commonModelName &&
          fieldMappingInstance.field_key === fieldMappingTargetName,
      ),
    );
  }, [fieldMappingTargetName, selectedRemoteField]);

  return (
    <>
      <FieldMappingModalTitle
        title={`Map a new ${linkedAccount?.end_user.organization_name}-specific target field`}
        onClose={onModalClose}
      />
      <div className="px-6 mt-5">
        <div className="text-base">
          <p className="mb-2">
            Select a third-party Remote Field to create a Field Mapping for this new{" "}
            {linkedAccount?.end_user.organization_name}-specific target field.
          </p>
          <p className="mb-0">
            This change will be reflected after the next sync which will be a full resync of data
            for {linkedAccount?.end_user.organization_name}.
          </p>
        </div>
        <div className="flex flex-col mt-6 gap-y-4">
          <FieldMappingsMergeToIntegrationHeader
            integrationName={linkedAccount?.integration.name!}
            integrationSquareImage={linkedAccount?.integration.square_image}
            isFMv3DashboardEnabled
            commonModel={selectedRemoteField?.commonModelName}
          />

          <FieldMappingsGridContainer>
            <>
              <div className="flex-row flex-shrink-0">
                {!doesModelHaveRemoteDataEnabled(
                  selectedRemoteField!.commonModelName,
                  commonModelsToMappingConfig,
                ) ? (
                  <Alert color="yellow" icon={<AlertTriangle size={16} />}>
                    <span>
                      <Link
                        target="_blank"
                        href={`${CONFIGURATION_COMMON_MODELS_PATH}/${linkedAccount!.category}#${
                          selectedRemoteField!.commonModelName
                        }`}
                      >
                        Enable Remote Data
                      </Link>
                      {"  "} to create a new target field for this model
                    </span>
                  </Alert>
                ) : (
                  <>
                    <TextField
                      placeholder="Name"
                      value={fieldMappingTargetName!}
                      onChange={(e) => {
                        setFieldMappingTargetName(e.target.value);
                      }}
                    />
                    <TextField
                      className="mt-4"
                      placeholder="Description (optional)"
                      value={fieldMappingTargetDescription ? fieldMappingTargetDescription : ""}
                      onChange={(e) => {
                        setFieldMappingTargetDescription(e.target.value);
                      }}
                    />
                    {shouldShowEditingExistingFieldWarningBanner && (
                      <div className="w-full mt-4 h-[40px]">
                        <Alert showWarningIcon className="h-[40px]">
                          <span className="text-sm">You are editing an existing target field</span>
                        </Alert>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="flex p-4 pt-3 flex-shrink-0">
                <ArrowLeft size={20} />
              </div>
              <RemoteFieldCard
                displayName={selectedRemoteField?.display_name || ""}
                apiEndpointPath={selectedRemoteField?.api_endpoint_path}
                type={selectedRemoteField?.type}
                jmesPath={jmesPath}
                badge={
                  <FieldMappingTargetBadge
                    type="linked-account-specific-target-field"
                    linkedAccount={linkedAccount!}
                  />
                }
              />
            </>
          </FieldMappingsGridContainer>
        </div>
      </div>
      <div className="pr-6 !mt-6">
        <CancelAndNextFooter
          buttonText="Save mapping"
          onCancel={onModalClose}
          onNext={() => {
            createFieldMapping(selectedRemoteField);
          }}
          isNextDisabled={fieldMappingTargetName == null || fieldMappingTargetName == undefined}
          isLoading={isCreating}
        />
      </div>
    </>
  );
};
export default LinkedAccountSpecificFieldMappingCreation;
