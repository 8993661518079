import React from "react";
import { Text } from "@merge-api/merge-javascript-shared";

type IPAddressCellProps = {
  ipAddress: string;
};

const IPAddressCell = ({ ipAddress }: IPAddressCellProps) => {
  return (
    <td>
      <Text className="text-gray-60">{ipAddress}</Text>
    </td>
  );
};

export default IPAddressCell;
