import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { ChevronRight } from "lucide-react";
import IntegrationIssueMetadataCard from "./IntegrationIssueMetadataCard";
import { navigateToIndividualIssuePage } from "../../../../../router/RouterUtils";
import useCategoryComponentSchema from "../../../../../hooks/useCategoryComponentSchema";
import { getDescription } from "../../../issues/detail-page/IntegrationManagementIndividualIssueDescription";
import {
  IntegrationIssue,
  IntegrationIssueMetadata,
  IntegrationIssueActionLogEntryAction,
  CommonModelInformation,
} from "../../../IntegrationsManagementEntities";
import { spectrum } from "../../../../../styles/theme";
import { Button, ButtonVariant, Card, Text } from "@merge-api/merge-javascript-shared";

type IntegrationIssueDetailedErrorContainerProps = {
  issueMetadata: IntegrationIssueMetadata[] | null;
  integrationIssue: IntegrationIssue;
  showPreview: boolean;
  isMissingPermissionIssue?: boolean;
};

type OtherIssueContainerProps = {
  isOverviewPage: boolean;
};
const OtherIssueContainer = styled.div<OtherIssueContainerProps>`
  padding: 8px 12px;
  border-radius: ${(props) => (props.isOverviewPage ? "" : "6px")};
  font-size: ${(props) => (props.isOverviewPage ? "12px" : "16px")};
  &:hover {
    background: ${(props) => (props.isOverviewPage ? spectrum.gray0 : "")};
    cursor: ${(props) => (props.isOverviewPage ? "pointer" : "")};
  }
`;

const filterIssueMetadata = (issueMetadata: IntegrationIssueMetadata[] | null) => {
  if (!issueMetadata) {
    return [];
  }
  const filteredMetadata = issueMetadata.filter((issueMetadata) => {
    return (
      issueMetadata.action === IntegrationIssueActionLogEntryAction.INFO &&
      issueMetadata?.is_resolved != true
    );
  });
  return filteredMetadata;
};

const groupAndSortIssueMetadata = (issueMetadata: IntegrationIssueMetadata[]) => {
  // group all endpoints by error message
  const issueErrorMessageMap = new Map();
  issueMetadata.map((issueMetadataEntry) => {
    const errorMessage = issueMetadataEntry.user_facing_error_detail || "";
    if (issueErrorMessageMap.has(errorMessage)) {
      const existingErrorInfo = issueErrorMessageMap.get(errorMessage);

      issueMetadataEntry.common_model_information.forEach((newCommonModelInfo) => {
        const newCommonModelName = newCommonModelInfo.common_model_name;
        // go thru each commonm model in the failed endpoint and merge the common model missing list with the other endpoints with same error message
        const foundCommonModelIndex = existingErrorInfo.common_model_information.findIndex(
          (commonModelInfo: CommonModelInformation) => {
            return commonModelInfo.common_model_name == newCommonModelName;
          },
        );
        if (foundCommonModelIndex > -1) {
          const newMissingFieldList = newCommonModelInfo.missing_fields;
          const existingMissingFieldList =
            existingErrorInfo.common_model_information[foundCommonModelIndex]["missing_fields"];

          // combine and de dupe
          existingErrorInfo.common_model_information[foundCommonModelIndex]["missing_fields"] = [
            ...new Set([...newMissingFieldList, ...existingMissingFieldList]),
          ];
        } else {
          existingErrorInfo.common_model_information.push(newCommonModelInfo);
        }
      });
    } else {
      issueErrorMessageMap.set(errorMessage, issueMetadataEntry);
    }
  });

  return Array.from(issueErrorMessageMap.values()).sort(
    (metadata1: IntegrationIssueMetadata, metadata2: IntegrationIssueMetadata) => {
      return (
        metadata2?.common_model_information?.length - metadata1?.common_model_information?.length
      );
    },
  );
};
const IntegrationIssueDetailedErrorContainer = ({
  issueMetadata,
  integrationIssue,
  showPreview,
  isMissingPermissionIssue,
}: IntegrationIssueDetailedErrorContainerProps) => {
  const history = useHistory();
  const { schemas } = useCategoryComponentSchema(integrationIssue.linked_account.category);

  const filteredMetadata = filterIssueMetadata(issueMetadata);
  return (
    <Card variant="outline" className={`border-[1px] ${showPreview ? "mt-2" : ""}`}>
      {isMissingPermissionIssue && filteredMetadata.length > 0 ? (
        <div>
          {showPreview
            ? groupAndSortIssueMetadata(filteredMetadata)
                .slice(0, 2)
                .map(
                  (
                    metadata: IntegrationIssueMetadata,
                    index: number,
                    array: IntegrationIssueMetadata[],
                  ) => {
                    return (
                      <IntegrationIssueMetadataCard
                        showPreview={showPreview}
                        issueMetadata={metadata}
                        categorySchema={schemas}
                        isFirstIndex={index == 0}
                        isLastIndex={index == array.length - 1}
                        onClick={() => {
                          navigateToIndividualIssuePage(
                            history,
                            integrationIssue.id,
                            "linked_account_page",
                          );
                        }}
                      />
                    );
                  },
                )
            : groupAndSortIssueMetadata(filteredMetadata).map(
                (
                  metadata: IntegrationIssueMetadata,
                  index: number,
                  array: IntegrationIssueMetadata[],
                ) => {
                  return (
                    <IntegrationIssueMetadataCard
                      showPreview={showPreview}
                      issueMetadata={metadata}
                      categorySchema={schemas}
                      isFirstIndex={index == 0}
                      isLastIndex={index == array.length - 1}
                    />
                  );
                },
              )}
        </div>
      ) : (
        <OtherIssueContainer
          isOverviewPage={showPreview}
          onClick={() => {
            if (showPreview) {
              navigateToIndividualIssuePage(history, integrationIssue.id, "linked_account_page");
            }
          }}
        >
          <Text>{getDescription(integrationIssue)}</Text>
        </OtherIssueContainer>
      )}
      {showPreview && isMissingPermissionIssue && issueMetadata && issueMetadata.length > 2 && (
        <div
          className="flex justify-content-center align-items-center py-3 px-4 border-t-[1px] border-gray-10 hover:cursor-pointer"
          onClick={() => {
            navigateToIndividualIssuePage(history, integrationIssue.id, "linked_account_page");
          }}
        >
          <Button variant={ButtonVariant.TextBlue} size="sm" rightIcon={<ChevronRight size={12} />}>
            View more remediations
          </Button>
        </div>
      )}
    </Card>
  );
};

export default IntegrationIssueDetailedErrorContainer;
