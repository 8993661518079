import React, { SetStateAction, Dispatch } from "react";
import { Button, ButtonVariant, TextField } from "@merge-api/merge-javascript-shared";
import AnimatedChevron from "../../../../../../configuration/common-models/AnimatedChevron";
import AdvancedFieldMappingSelector from "./AdvancedFieldMappingSelector";
import useAdvancedFieldMappingForm from "./hooks/useAdvancedFieldMappingsForm";

type AdvancedFieldMappingFormProps = {
  jsonData: string;
  onJmesPathChange: (jmesPath: string | null) => void;
  setIsJmesPathValid: Dispatch<SetStateAction<boolean>>;
};
const AdvancedFieldMappingForm = ({
  jsonData,
  setIsJmesPathValid,
  onJmesPathChange,
}: AdvancedFieldMappingFormProps) => {
  const {
    showAdvancedMappingTool,
    setShowAdvancedMappingTool,
    jmesPathResult,
    jmesPath,
    inputError,
    handleJmesPathInput,
  } = useAdvancedFieldMappingForm({
    setIsJmesPathValid,
    onJmesPathChange,
    jsonData,
  });
  const showResult = showAdvancedMappingTool || jmesPath !== null;
  return (
    <div className={`${showResult ? "bg-blue-0 rounded-md" : ""} `}>
      <div
        className={`${
          showAdvancedMappingTool ? "border-b border-gray-10" : ""
        }  flex items-center justify-between`}
      >
        <div className="px-[14px] truncate">
          {showResult && (
            <div>
              <span className="font-semibold mr-1">Result:</span>
              {jmesPathResult}
            </div>
          )}
        </div>
        <Button
          size="md"
          variant={ButtonVariant.SecondaryBlue}
          onClick={() => {
            if (!showAdvancedMappingTool) {
              setTimeout(() => {
                setShowAdvancedMappingTool(!showAdvancedMappingTool);
              }, 400);
            } else {
              setShowAdvancedMappingTool(!showAdvancedMappingTool);
            }
          }}
          className="ml-auto"
          rightIcon={<AnimatedChevron $isUp={showAdvancedMappingTool} />}
        >
          Advanced mapping
        </Button>
      </div>
      <div className="px-[14px]">
        <div
          className={`${
            showAdvancedMappingTool ? "opacity-100 mt-3.5 max-h-[260px]" : "h-0 opacity-0"
          } transition-all duration-100`}
        >
          {showAdvancedMappingTool && (
            <AdvancedFieldMappingSelector
              jsonString={JSON.stringify(jsonData)}
              jsonFinderClassName="h-[260px]"
              handleJmesPath={(jmesPath: any) => {
                handleJmesPathInput(jmesPath);
              }}
            />
          )}
        </div>
        <div className={`${showAdvancedMappingTool ? "opacity-100 mt-3" : "opacity-0 h-0"}`}>
          Select the field inside the list object you want to map, or specify a JSON query below
          using{" "}
          <a href="https://jmespath.org/" target="_blank" rel="noreferrer">
            JMESPath
          </a>
        </div>
        <div className={`${showAdvancedMappingTool ? "opacity-100 mt-2 pb-3.5" : "opacity-0 h-0"}`}>
          <TextField
            placeholder="Example query: array[?type=='value'].result"
            value={jmesPath!}
            error={inputError}
            onChange={(e) => {
              handleJmesPathInput(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AdvancedFieldMappingForm;
