import React from "react";
import {
  IntegrationIssueMetadata,
  CommonModelInformation,
} from "../../../IntegrationsManagementEntities";
import CommonModelMissingFieldCell from "./CommonModelMissingFieldCell";
import { Schemas } from "../../../configuration/common-models/CommonModelToggleTypes";
import { getCommonModelName } from "../../../utils/IntegrationsManagementUtils";
import { Text } from "@merge-api/merge-javascript-shared";

type IntegrationIssueMetadataCardProps = {
  issueMetadata: IntegrationIssueMetadata;
  categorySchema: Schemas;
  isFirstIndex: boolean;
  isLastIndex: boolean;
  onClick?: () => void;
  showPreview: boolean;
};

const IntegrationIssueMetadataCard = ({
  issueMetadata,
  categorySchema,
  isFirstIndex,
  isLastIndex,
  onClick,
  showPreview,
}: IntegrationIssueMetadataCardProps) => {
  const commonModelsWithIssues = issueMetadata?.common_model_information || [];
  return (
    commonModelsWithIssues && (
      <div
        className={`flex flex-col gap-y-2 p-3 ${
          showPreview ? "hover:bg-gray-0 hover:cursor-pointer" : "pt-2"
        } ${isLastIndex ? "" : "border-b-[1px] border-gray-10"} ${
          isFirstIndex ? "hover:rounded-t-lg" : ""
        }`}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        {issueMetadata.user_facing_error_detail && (
          <Text>{issueMetadata.user_facing_error_detail}</Text>
        )}
        {commonModelsWithIssues.length > 0 && (
          <>
            <Text variant="sm" className="text-gray-60">
              Impacted Merge Common Models and fields
            </Text>
            <div className="flex flex-wrap items-center	gap-2">
              {commonModelsWithIssues.map((commonModel: CommonModelInformation) => {
                const commonModelName = getCommonModelName(commonModel.common_model_name);
                return (
                  <CommonModelMissingFieldCell
                    commonModel={commonModelName}
                    missingFields={commonModel.missing_fields}
                    modelSchema={categorySchema[commonModelName]}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
    )
  );
};
export default IntegrationIssueMetadataCard;
