import React from "react";
import styled from "styled-components";
import LiveWaitingIndicator from "../LiveWaitingIndicator";
import LeftSideBar from "../helpers/LeftSideBar";
import { LeftSideBarCol } from "../helpers/LeftSideBarSpacing";
import TileStep from "../helpers/TileStep";
import UnifiedAPIExplorer from "./UnifiedAPIExplorer";
import useDashboardOnboardingStatus from "../../utils/useDashboardOnboardingStatus";
import { Text } from "@merge-api/merge-javascript-shared";

const StyledP = styled.p`
  margin-bottom: 9px;
`;

const RelativeContainer = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  bottom: 9px;
`;

const AbsoluteDiv = styled.div`
  position: absolute;
  top: 35px;
`;

const StyledDownloadImage = styled.img`
  position: absolute;
`;

const DownloadImage = require("../../src/DownloadImage.png");

const APIRequestSection = () => {
  const dashboardOnboardingStatus = useDashboardOnboardingStatus();

  return (
    <div className="flex flex-col mx-auto w-11/12">
      <Text variant="h3" className="mb-6">
        Make API request
      </Text>
      <div className="flex flex-row">
        <LeftSideBar stepNumber="1" isNormalStep={false} />
        <TileStep
          img={
            <RelativeContainer>
              <StyledDownloadImage src={DownloadImage} height={80} />
              <AbsoluteDiv>
                {dashboardOnboardingStatus?.has_pulled_common_model_data ? (
                  <LiveWaitingIndicator color="green" />
                ) : (
                  <LiveWaitingIndicator color="red" />
                )}
              </AbsoluteDiv>
            </RelativeContainer>
          }
          title="Pull data from Unified API"
          subtitle={
            <>
              <StyledP>
                Use the interface below to learn how to authenticate API requests to the Unified
                API.
              </StyledP>
              Pull any synced data via GET request to the Unified API to finish this step!
            </>
          }
          stepNumber={1}
        />
      </div>
      <div className="flex flex-row">
        <LeftSideBarCol className="col-2 align-items-center" />
        <UnifiedAPIExplorer />
      </div>
    </div>
  );
};

export default APIRequestSection;
