import React from "react";
import { Badge } from "@merge-api/merge-javascript-shared";
import { IntegrationIssue, IntegrationIssueStatus } from "../pages/IntegrationsManagementEntities";
import { snakeCaseToSentenceCase } from "../../services";
import { isIssueMuted } from "../pages/utils/IntegrationsManagementUtils";

type Props = {
  issue: IntegrationIssue;
  size?: "sm" | "md" | "lg" | undefined;
};

const IssueStatusBadge = ({ issue, size = "md" }: Props) => {
  const status = isIssueMuted(issue) ? "Muted" : issue.status;

  switch (status) {
    case IntegrationIssueStatus.RESOLVED:
      return (
        <Badge color="teal" size={size}>
          {snakeCaseToSentenceCase(status)}
        </Badge>
      );
    case IntegrationIssueStatus.ONGOING:
      return (
        <Badge color="red" size={size}>
          {snakeCaseToSentenceCase(status)}
        </Badge>
      );
    default:
      return <Badge size={size}>{snakeCaseToSentenceCase(issue.status)}</Badge>;
  }
};

export default IssueStatusBadge;
