import React, { useEffect, useState } from "react";
import useAppContext from "../../../context/useAppContext";
import { APIRequestLogEntry, IntegrationIssue } from "../../IntegrationsManagementEntities";
import { fetchIntegrationIssueLogs } from "../../utils/IntegrationsManagementAPIClient";
import { IntegrationIssueLogs } from "../../IntegrationsManagementEntities";
import ContentLoader from "react-content-loader";
import { isBillingPlanFreeLaunch } from "../../settings/billing/BillingUtils";
import LogRow from "../../shared/LogRow";
import EmptyStateWrapper from "../../../shared/EmptyStateWrapper";
import { Card, Text } from "@merge-api/merge-javascript-shared";

const LoadingLogsComponent = () => {
  const skeletonRows = Array.from({ length: 8 }).map((_, i) => {
    return (
      <div className="flex flex-row items-center px-4 py-3 border-t-[1px] border-gray-10">
        <ContentLoader
          speed={2}
          width="100%"
          height={32}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          radius={16}
        >
          <rect x="0" y="0" rx="16" ry="16" width="100%" height="28" radius="8" />
        </ContentLoader>
      </div>
    );
  });

  return <>{skeletonRows}</>;
};

type IndividualIssueActivityProps = {
  integrationIssue: IntegrationIssue;
};

const IndividualIssueActivity = ({ integrationIssue }: IndividualIssueActivityProps) => {
  const { user, isUserPrivileged } = useAppContext();
  const [issueLogs, setIssueLogs] = useState<undefined | APIRequestLogEntry[]>(undefined);

  useEffect(() => {
    fetchIntegrationIssueLogs({
      integrationIssueID: integrationIssue.id,
      onSuccess: (result: IntegrationIssueLogs) => {
        setIssueLogs(result.recent_logs);
      },
    });
  }, []);

  return (
    <Card className="flex flex-col my-6">
      <div className="px-4 py-3">
        <Text variant="h5">Logs</Text>
      </div>
      <div className="flex flex-col">
        {issueLogs ? (
          issueLogs.length > 0 ? (
            issueLogs.map((issueLog) => {
              return (
                <LogRow
                  log={issueLog}
                  linkedAccount={integrationIssue.linked_account}
                  issueID={integrationIssue.id}
                />
              );
            })
          ) : (
            <div className="border-t-[1px] border-gray-10">
              <EmptyStateWrapper
                title={
                  isUserPrivileged
                    ? "No recent activity"
                    : "You must be an admin or developer to view activity"
                }
                subtitle={
                  isUserPrivileged
                    ? `Logs are retained for ${
                        isBillingPlanFreeLaunch(
                          user.organization.organization_billing_plan?.billing_plan,
                        )
                          ? 3
                          : 7
                      } days`
                    : undefined
                }
              />
            </div>
          )
        ) : (
          <LoadingLogsComponent />
        )}
      </div>
    </Card>
  );
};
export default IndividualIssueActivity;
