import React from "react";
import { formatDate } from "../../../../../../../models/Helpers";
import { Text } from "@merge-api/merge-javascript-shared";

type DateCellProps = {
  date: Date;
};

const DateCell = ({ date }: DateCellProps) => {
  return (
    <td>
      <Text>{formatDate(date)}</Text>
    </td>
  );
};

export default DateCell;
