import React, { useState } from "react";
import {
  Button,
  ButtonVariant,
  Card,
  Text,
  TextField,
  TextFieldVariant,
} from "@merge-api/merge-javascript-shared";
import useAppContext from "../../../../context/useAppContext";
import { fetchWithAuth } from "../../../../../api-client/APIClient";
import { HTTPMethod } from "@merge-api/merge-javascript-shared";
import { showErrorToast, showSuccessToast } from "../../../../shared/Toasts";

const PersonalInformationCard = () => {
  // STATE
  const { setUser, user } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState<string>(user.name);
  const [error, setError] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  // HANDLERS
  const handleName = (newName: string) => {
    if (newName.length < 1 || newName.length > 100) {
      setError(true);
    } else {
      setError(false);
    }
    setName(newName);
    setHasChanges(true);
  };

  const onSubmit = (name: string) => {
    setIsLoading(true);
    fetchWithAuth({
      path: "/users/me",
      method: HTTPMethod.PATCH,
      body: { name: name },
      onResponse: (data) => {
        showSuccessToast("Successfully updated your profile!");
        setUser(data.user);
        setIsLoading(false);
        setHasChanges(false);
      },
      onError: () => {
        showErrorToast("Failed to update your profile.");
        setIsLoading(false);
      },
    });
  };

  return (
    <Card variant="shadow">
      <div className="w-full flex flex-col">
        {/* header */}
        <div className="px-6 py-5 border-b-solid border-b-gray-10 border-b-[1px]">
          <Text variant="h5">Personal information</Text>
        </div>

        {/* body */}
        <div className="flex flex-col px-6 py-5 justify-between gap-y-5">
          {/* name */}
          <div className="flex flex-col gap-2">
            <Text variant="h6">Full name</Text>
            <div className="flex flex-row gap-3 justify-between">
              <TextField
                variant={TextFieldVariant.Shadowed}
                defaultValue={name}
                onChange={(e) => handleName(e.target.value)}
                error={error}
                errorText="Please enter a name between 1 - 100 characters"
                className="w-full"
              />
              <Button
                variant={ButtonVariant.PrimaryBlue}
                disabled={!hasChanges}
                loading={isLoading}
                onClick={() => onSubmit(name)}
              >
                Save
              </Button>
            </div>
          </div>

          {/* email */}
          <div className="flex flex-col gap-2 mb-2">
            <Text variant="h6">Email address</Text>
            <Text className="bg-gray-10 text-black rounded-md h-10 items-center flex pl-4 pr-4">
              {user.email}
            </Text>
          </div>
        </div>
      </div>
    </Card>
  );
};
export default PersonalInformationCard;
