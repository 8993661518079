import { APICategory, Toggle, Tooltip } from "@merge-api/merge-javascript-shared";
import React, { useEffect, useState } from "react";
import { EnabledAction, ScopeOption } from "../../../../../models/CommonModel";
import { ModelWithSortedFields } from "../../../configuration/common-models/CommonModelUtils";
import { getSanitizedHTML } from "../../../../../models/Helpers";
import { replaceBackticksWithCode } from "../../../../../services";
import clsx from "clsx";
import { Result } from "../../../../../api-client/APIClient";
import ScopeConfirmationDialog from "../../../configuration/common-models/ScopeConfirmationDialog";
import useProductRestrictions from "../../../../shared/hooks/useProductRestrictions";

interface ScopeRowProps {
  model: ModelWithSortedFields;
  selectedCategory: APICategory;
  updateEnabledStatus: (
    modelName: string,
    fieldName: string | null,
    enabledActions: Array<EnabledAction>,
    optionalActionsToEnable: Array<EnabledAction>,
    optionalActionsToDisable: Array<EnabledAction>,
    isFieldConfigurable?: boolean,
  ) => Promise<Result<void>>;
  isDefaultScopesOffFlag?: boolean;
  dependentModelScopesFlag?: boolean;
}

const ScopeRow = ({
  model,
  selectedCategory,
  updateEnabledStatus,
  isDefaultScopesOffFlag,
  dependentModelScopesFlag,
}: ScopeRowProps) => {
  const { productRestrictions } = useProductRestrictions();

  const [isModelEnabled, setIsModelEnabled] = useState<boolean>(
    model.enabled_model_actions.length > 0,
  );
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentOption, setCurrentOption] = useState<ScopeOption>(
    model.enabled_model_actions.length > 0 ? ScopeOption.DISABLED : ScopeOption.ENABLED,
  );
  const [currentCapabilities, setCurrentCapabilities] = useState(model.enabled_model_actions);
  const canToggleFields = !!productRestrictions?.are_toggles_enabled;

  useEffect(() => {
    setIsModelEnabled(model.enabled_model_actions.length > 0);
  }, [selectedCategory, model]);

  const handleChange = (newIsEnabled: boolean) => {
    const enabledActionsArray = [];
    if (!isModelEnabled) {
      if (model.model_capabilities.length === 2) {
        enabledActionsArray.push(EnabledAction.WRITE);
      }
      enabledActionsArray.push(EnabledAction.READ);
    }

    const action = isModelEnabled ? ScopeOption.DISABLED : ScopeOption.ENABLED;

    handleOptionSelect(action, model.model_capabilities);

    setIsModelEnabled(!(enabledActionsArray.length === 0));
  };

  const handleOptionSelect = (optionId: ScopeOption, capability: EnabledAction[]) => {
    if (!isDefaultScopesOffFlag && !canToggleFields) {
      return;
    }

    // Store option and open modal
    setCurrentOption(optionId);
    setCurrentCapabilities(capability);
    setModalOpen(true);
  };

  const confirmAction = async () => {
    if (!isDefaultScopesOffFlag && !canToggleFields) {
      return;
    }

    const isOptional = currentOption == ScopeOption.OPTIONAL;

    const isEnabling = currentOption == ScopeOption.ENABLED || isOptional;
    const actionsToEnable = isEnabling ? currentCapabilities : [];

    // Checking if something is optional
    const optionalActionsToEnable = isOptional ? currentCapabilities : [];
    // Checking if something is enabled but not optional
    const optionalActionsToDisable =
      !isEnabling || (isEnabling && !isOptional) ? currentCapabilities : [];

    await updateEnabledStatus(
      model.name,
      null,
      actionsToEnable as Array<EnabledAction>,
      optionalActionsToEnable,
      optionalActionsToDisable,
      isOptional,
    );

    // Close the modal
    setModalOpen(false);
  };

  const onCancelAction = () => {
    setModalOpen(false);
    setIsModelEnabled(model.enabled_model_actions.length > 0);
  };

  return (
    <div className="relative py-1" key={model.name}>
      {model && (
        <div className="flex items-center py-4 px-6 rounded-md border-[1px] border-gray-10">
          <Tooltip
            tooltipClassName="max-w-l text-center"
            title={
              model?.model_description && (
                <div
                  className="[&>code]:p-0 [&>code]:border-none [&>code]:text-white [&>code]:font-bold [&>code]:bg-transparent"
                  dangerouslySetInnerHTML={{
                    __html: getSanitizedHTML(
                      replaceBackticksWithCode(model.model_description) as string,
                    ),
                  }}
                />
              )
            }
            enterDelay={100}
          >
            <div className="text-base text-left text-black truncate min-w-0 font-semibold">
              {model.name}
            </div>
          </Tooltip>
          <div className="ml-auto">
            <Toggle
              name={model.name + "-common-model-toggle"}
              checked={isModelEnabled}
              onChange={handleChange}
              className="ml-2 text-base"
              label={isModelEnabled ? "Enabled" : "Disabled"}
            />
          </div>
        </div>
      )}
      <ScopeConfirmationDialog
        isOpen={isModalOpen}
        onClose={() => onCancelAction()}
        onConfirm={confirmAction}
        capabilities={currentCapabilities}
        modelName={model.name}
        optionId={currentOption}
        category={selectedCategory}
        dependentModelScopesFlag={dependentModelScopesFlag}
        showCapabilities={false}
      />
    </div>
  );
};

export default ScopeRow;
