import React from "react";
import { Toggle, Text } from "@merge-api/merge-javascript-shared";

type IssueFilterProps = {
  label: string;
  onClick: () => void;
  isSelected: boolean;
};
const IssueFilter = ({ label, onClick, isSelected }: IssueFilterProps) => {
  return (
    <div className="flex flex-row items-center whitespace-nowrap justify-between rounded-lg bg-gray-0 px-3 gap-x-1 w-full h-[42px]">
      <Text className="!font-semibold text-gray-60">{label}</Text>
      <Toggle
        checked={isSelected}
        onChange={() => {
          onClick();
        }}
      />
    </div>
  );
};
export default IssueFilter;
