import React from "react";

type Props = {
  noMargin?: boolean;
};

export const Divider = ({ noMargin }: Props) => {
  return (
    <hr className={`border-[1px] border-gray-10 overflow-hidden ${noMargin ? "m-0" : "my-3"}`} />
  );
};

export default Divider;
