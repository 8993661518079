import React, { useContext, useEffect, useState } from "react";
import CancelAndNextFooter from "../../../../../../common/CancelAndNextFooter";
import { Button, ButtonVariant, Typeahead } from "@merge-api/merge-javascript-shared";
import {
  FieldMappingCreationAndEditDict,
  FieldMappingModalStep,
  FieldMappingPathways,
} from "../../../../../../../models/Entities";
import FieldMappingDropdownChild from "../../../../../../shared/FieldMappingDropdownChild";
import FieldMappingModalContext from "../context/FieldMappingModalContext";
import { ArrowRight } from "lucide-react";
import FieldMappingModalTitle from "../../modal/FieldMappingModalTitle";
import {
  createFieldMappingOptions,
  getFieldMappingModalTitle,
  isAdvancedMappingAvailableForRemoteField,
} from "../helpers";

const RemoteFieldSelection = () => {
  const {
    linkedAccount,
    onModalClose,
    setModalStep,
    linkedAccountFieldMappingOptions,
    selectedRemoteField,
    setSelectedRemoteField,
    selectedFieldMappingPathway,
    jmesPath,
    setJmesPath,
  } = useContext(FieldMappingModalContext);

  const [showAdvancedMappingOption, setShowAdvancedMappingOption] = useState(false);
  const availableFieldMappings = linkedAccountFieldMappingOptions?.remote_field_options;

  // calculate state if remote field already selected when component loads
  useEffect(() => {
    setShowAdvancedMappingOption(
      (selectedRemoteField?.type.includes("list") && selectedRemoteField.value?.length !== 0) ||
        selectedRemoteField?.type == "dict",
    );
  }, []);

  const getAndSetNextModalStep = (selectedFieldMappingPathway: FieldMappingPathways) => {
    switch (selectedFieldMappingPathway) {
      case FieldMappingPathways.LINKED_ACCOUNT_SPECIFIC_MAPPING:
        setModalStep(
          FieldMappingModalStep.COMMON_MODEL_SELECTION_AND_LINKED_ACCOUNT_MAPPING_CREATION,
        );
        break;
      case FieldMappingPathways.COMMON_MODEL_OVERRIDE:
        setModalStep(FieldMappingModalStep.COMMON_MODEL_OVERRIDE_SELECTION);
        break;
    }
  };

  return (
    <>
      <FieldMappingModalTitle
        title={getFieldMappingModalTitle(selectedFieldMappingPathway!, linkedAccount)}
        onClose={onModalClose}
      />
      <div className="px-6 mt-5">
        <div className="mb-4">Select the third-party Remote Field you want to map</div>
        <Typeahead
          value={selectedRemoteField}
          options={availableFieldMappings ? createFieldMappingOptions(availableFieldMappings!) : []}
          onChange={(_, remoteField: any) => {
            if (remoteField) {
              setSelectedRemoteField!(remoteField);
              // reset jmes path on any remote field selection switch
              setShowAdvancedMappingOption(false);
              if (isAdvancedMappingAvailableForRemoteField(remoteField)) {
                setTimeout(() => {
                  setShowAdvancedMappingOption(true);
                }, 400);
              }
            } else {
              setSelectedRemoteField!(null);
              // if advanced mapping tool is opened and closed and another remote field is selceted
              setJmesPath(null);
            }
          }}
          placeholder={
            availableFieldMappings == undefined
              ? "Loading Remote Fields..."
              : "Search Remote Fields..."
          }
          getOptionLabel={(option: any) => {
            return option?.display_name ?? "";
          }}
          renderOption={(fieldMapping: FieldMappingCreationAndEditDict) => {
            return <FieldMappingDropdownChild option={fieldMapping} />;
          }}
          loadingText="Loading fields..."
          loading={availableFieldMappings == undefined}
        />
        <div
          className={`${
            showAdvancedMappingOption && selectedRemoteField ? `mt-4` : "h-0 opacity-0"
          } transition-all ease-in-out duration-400`}
        >
          {showAdvancedMappingOption && selectedRemoteField && (
            <div className={`${jmesPath ? "bg-blue-0 rounded-md" : ""} `}>
              <div className="flex items-center justify-between">
                <div className="px-[14px] w-[500px]">
                  {jmesPath && <div className="truncate">{jmesPath}</div>}
                </div>
                <div className="h-[40px] bg-gray-0 rounded-md items-center pt-2">
                  <Button
                    variant={ButtonVariant.TextBlue}
                    rightIcon={<ArrowRight size={12} />}
                    onClick={() => {
                      setModalStep(FieldMappingModalStep.ADVANCED_FIELD_MAPPING_SELECTION);
                    }}
                  >
                    <div className="mx-1">
                      {jmesPath ? "Edit advanced mapping" : "Add advanced mapping"}
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="mt-6">
          <CancelAndNextFooter
            isNextDisabled={selectedRemoteField == null}
            onCancel={onModalClose}
            onNext={() => {
              getAndSetNextModalStep(selectedFieldMappingPathway!);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default RemoteFieldSelection;
