import React from "react";
import SkeletonLoader from "../../../../../shared/SkeletonLoader";
import FieldMappingUnmappedTargetsForCommonModel from "./FieldMappingUnmappedTargetsForCommonModel";
import {
  FieldMappingCommonModelConfiguration,
  LinkedAccount,
} from "../../../../../../models/Entities";

import useFieldmappingTargetsPage from "./hooks/useFieldMappingTargetsPage";
import { SectionHeaderWrapper } from "../../../../../shared/MergeLayouts";
import { Button, ButtonVariant, Link, Typeahead } from "@merge-api/merge-javascript-shared";
import { ArrowLeft } from "lucide-react";
import { navigateToLinkedAccountFieldMappings } from "../../../../../../router/RouterUtils";
import { useHistory } from "react-router-dom";

type Props = {
  linkedAccount: LinkedAccount;
};
const FieldMappingUnmappedTargetsPage = ({ linkedAccount }: Props) => {
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const preSelectedCommonModel = params.get("commonModelName") ?? null;

  const {
    fieldMappingInformation,
    fieldMappingConfigurations,
    availableFieldMappings,
    selectedCommonModels,
    setSelectedCommonModels,
  } = useFieldmappingTargetsPage({
    linkedAccount: linkedAccount,
    preSelectedCommonModel: preSelectedCommonModel,
  });

  const filterFieldMappingConfigs = () => {
    if (selectedCommonModels.length == 0) {
      return fieldMappingConfigurations;
    }

    return fieldMappingConfigurations!.filter((config: FieldMappingCommonModelConfiguration) => {
      return selectedCommonModels.includes(config.common_model_name);
    });
  };

  return (
    <>
      <div className="flex">
        <Button
          variant={ButtonVariant.TextBlue}
          leftIcon={<ArrowLeft size={12} />}
          onClick={() => navigateToLinkedAccountFieldMappings(history, linkedAccount.id)}
        >
          Field Mapping
        </Button>
      </div>
      <SectionHeaderWrapper
        className="mt-2"
        title={`Unmapped ${linkedAccount.organization.name}-wide target fields`}
        subtitle={
          <>
            Connect predefined target fields to new {linkedAccount.integration.name} Remote Fields.
            Any changes made here are automatically saved and will be updated in the next sync. Go
            to{" "}
            <Link href="/configuration/field-mappings/target-fields" target="_blank">
              Advanced Configuration
            </Link>{" "}
            to add more organization-wide target fields or set default integration-wide mappings.{" "}
            <Link
              href="https://docs.merge.dev/supplemental-data/field-mappings/overview/"
              target="_blank"
            >
              Learn more
            </Link>
            .
          </>
        }
      >
        <div className="my-6">
          {linkedAccount &&
          fieldMappingInformation &&
          availableFieldMappings &&
          fieldMappingConfigurations ? (
            <>
              <Typeahead
                disableCloseOnSelect
                multiple
                showPlusFormat
                onChange={(event: any, selected: string[] | null) => {
                  setSelectedCommonModels(selected ?? []);
                }}
                options={fieldMappingConfigurations.map(
                  (config: FieldMappingCommonModelConfiguration) => {
                    return config.common_model_name;
                  },
                )}
                tagsLimit={3}
              />
              <div className="flex flex-col gap-6 mt-6">
                {filterFieldMappingConfigs()!.map(
                  (configuration: FieldMappingCommonModelConfiguration) => {
                    return (
                      <FieldMappingUnmappedTargetsForCommonModel
                        commonModel={configuration.common_model_name}
                        fieldMappingTargets={fieldMappingInformation.field_mapping_targets.filter(
                          (target: { common_model_name: string }) => {
                            return target.common_model_name == configuration.common_model_name;
                          },
                        )}
                        isRemoteDataEnabled={configuration.has_remote_data_enabled}
                        fieldMappingOptions={availableFieldMappings}
                        linkedAccount={linkedAccount}
                      />
                    );
                  },
                )}
              </div>
            </>
          ) : (
            <div className="flex flex-col gap-6">
              <SkeletonLoader height={40} fullWidth borderRadius={4} />
              <SkeletonLoader height={300} fullWidth borderRadius={4} />
              <SkeletonLoader height={300} fullWidth borderRadius={4} />
              <SkeletonLoader height={300} fullWidth borderRadius={4} />
            </div>
          )}
        </div>
      </SectionHeaderWrapper>
    </>
  );
};

export default FieldMappingUnmappedTargetsPage;
