import React from "react";
import { ButtonVariant, Dialog } from "@merge-api/merge-javascript-shared";

type Props = {
  open: boolean;
  onHide: () => void;
};

export default function FreeToLaunchConfirmModal({ open, onHide }: Props) {
  return (
    <Dialog
      title="Successfully upgraded"
      open={open}
      onClose={onHide}
      variant="sm"
      primaryButtonText="Done"
      primaryButtonVariant={ButtonVariant.PrimaryBlue}
      onPrimaryButtonClick={onHide}
      hideSecondaryButton
    >
      <div className="mb-2">
        You can now add up to 10 Linked Accounts. Any Linked Accounts that were connected beyond the
        Free plan limit of 3 will start syncing automatically.
      </div>
    </Dialog>
  );
}
