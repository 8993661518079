import React from "react";
import { useLoadUsers } from "./hooks/useLoadUsers";
import { Typeahead } from "@merge-api/merge-javascript-shared";

type UserPickerProps = {
  userId: number | null;
  onUserIdChange: (userId: number | null) => void;
};

/**
 * Dropdown picker for selecting a user.
 */
const UserPicker = ({ userId, onUserIdChange }: UserPickerProps) => {
  const { users, isLoading, onUserFilterChange } = useLoadUsers();

  /**
   * These functions are required b/c we compare user.name to user.id. This therefore assumes
   * every user has a unique name. This is due to a limitation with our version of
   * TypeaheadDropdown. If we update it (which is a breaking change) or use a different dropdown
   * lib we could pass in user object so that we maintain reference of user.id to user.name
   */
  const getUserIdFromName = (name: string): number | null => {
    const user = users.find((user) => user.name === name);
    if (!user) return null;
    return user.id;
  };
  const getNameFromUserId = (inputUserId: number | null): string | null => {
    const user = users.find((user) => user.id === inputUserId);
    if (!user) return null;
    return user.name;
  };

  return (
    <Typeahead
      placeholder="Search users..."
      options={users.map(({ name }) => name)}
      value={getNameFromUserId(userId)}
      onChange={(_: any, name: string | null) => onUserIdChange(getUserIdFromName(name ?? ""))}
      onInputChange={(_, value, reason) => reason !== "reset" && onUserFilterChange(value)}
      loading={isLoading}
    />
  );
};

export default UserPicker;
