import React, { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import styled from "styled-components";
import { Info } from "lucide-react";
import MissingFieldsList from "./MissingFieldsList";
import { Schema } from "../../../configuration/common-models/CommonModelToggleTypes";
import { Text } from "@merge-api/merge-javascript-shared";

type CommonModelFieldMissingFieldCellProps = {
  commonModel: string;
  missingFields: string[];
  modelSchema: Schema;
};

const CommonModelCell = styled.div`
  padding: 0px 6px;
  border-radius: 4px;
  border: 0.5px solid var(--lm-gray-10, #dce2ea);
  font-size: 10px;
  line-height: 20px;
`;

const StyledInfo = styled(Info)`
  cursor: pointer;
`;

const StyledPopover = styled(Popover)`
  max-width: 240px;
  padding-right: 0px;
  padding-left: 0px;
  border: 0.5px solid var(--lm-gray-10, #dce2ea);
`;

const CommonModelMissingFieldCell = ({
  commonModel,
  missingFields,
  modelSchema,
}: CommonModelFieldMissingFieldCellProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  return (
    <div
      onMouseLeave={() => {
        setIsPopoverOpen(false);
      }}
    >
      <CommonModelCell className="flex flex-row items-center">
        <Text variant="title-sm">{commonModel}</Text>
        <OverlayTrigger
          placement="right"
          overlay={
            <StyledPopover id={`missing-fields-list-${commonModel}`}>
              <MissingFieldsList missingFields={missingFields} modelSchema={modelSchema} />
            </StyledPopover>
          }
          show={isPopoverOpen}
        >
          <StyledInfo
            size={10}
            className="ml-1.5"
            onMouseEnter={() => {
              setIsPopoverOpen(true);
            }}
          />
        </OverlayTrigger>
      </CommonModelCell>
    </div>
  );
};
export default CommonModelMissingFieldCell;
