import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { fetchWithAuth } from "../../../../../api-client/APIClient";
import { WebhookReceiver } from "../../../../../models/Entities";
import { CONFIGURATION_WEBHOOKS_RECEIVERS_PATH } from "../../../../../router/RouterUtils";
import { palette } from "../../../../../styles/theme";
import MultiSwitch, { MultiSwitchOption } from "../../../../shared/MultiSwitch";
import { showErrorToast, showSuccessToast } from "../../../../shared/Toasts";
import type { Props as WebhookReceiverRowProps } from "./WebhookReceiverRow";
import {
  Alert,
  Button,
  ButtonVariant,
  Link,
  Text,
  TextField,
  TextFieldVariant,
} from "@merge-api/merge-javascript-shared";
import DottedOutlineTextCard from "../../../../shared/DottedOutlineTextCard";
import { Info } from "lucide-react";

type Props = WebhookReceiverRowProps & {
  /**
   * If true, we should focus the input ref when we show this component
   */
  isInitiallyFocused: boolean;
  width: number;
};

const IS_LISTENING_OPTION: MultiSwitchOption = {
  id: "enabled",
  text: "Enabled",
  backgroundColor: palette.white,
  borderColor: palette.border,
  selectedColor: "#11ba90",
  disableTooltip: "Add signature key to enable listener",
};

const NOT_LISTENING_OPTION: MultiSwitchOption = {
  id: "disabled",
  text: "Disabled",
  backgroundColor: palette.white,
  borderColor: palette.border,
  selectedColor: palette.slate,
};

const HANDSHAKE_OPTION: MultiSwitchOption = {
  id: "handshake",
  text: "Handshake",
  backgroundColor: palette.white,
  borderColor: palette.border,
  selectedColor: palette.slate,
};

const ListenerSwitch = styled(MultiSwitch)`
  & .btn {
    font-size: 12px;
    padding: 6px 12px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
  }
`;

interface ListenerSwitchWrapperProps {
  integrationUsesWebhookHandshake: boolean;
  currentIsActive: boolean;
  currentIsAwaitingHandshake: boolean;
  currentKeyValue?: string;
  listeningOption: MultiSwitchOption;
  onSelectOption: (option: MultiSwitchOption) => void;
}

const ListenerSwitchWrapper = ({
  integrationUsesWebhookHandshake,
  currentIsActive,
  currentIsAwaitingHandshake,
  currentKeyValue,
  listeningOption,
  onSelectOption,
}: ListenerSwitchWrapperProps) => {
  const listenerOptions = [NOT_LISTENING_OPTION];

  if (integrationUsesWebhookHandshake && !currentIsActive && !currentKeyValue) {
    listenerOptions.push(HANDSHAKE_OPTION);
  } else {
    listenerOptions.push(listeningOption);
  }

  const getSelectedId = (currentIsAwaitingHandshake: boolean, currentIsActive: boolean) => {
    let selectedId = NOT_LISTENING_OPTION.id;
    if (currentIsAwaitingHandshake) {
      selectedId = HANDSHAKE_OPTION.id;
    } else if (currentIsActive) {
      selectedId = IS_LISTENING_OPTION.id;
    }
    return selectedId;
  };

  return (
    <ListenerSwitch
      selectedID={getSelectedId(currentIsAwaitingHandshake, currentIsActive)}
      onSelectOption={onSelectOption}
      options={listenerOptions}
    />
  );
};

/**
 * Shows the expanded contents of a single row for the WebhookEventsTable,
 * where each row shows one receiver itself. Shows signature key details.
 */
const WebhookReceiverDetails = React.forwardRef<Pick<HTMLInputElement, "focus">, Props>(
  ({
    receiver: { key, event, is_active, is_awaiting_handshake, webhook_listener_url },
    integrationName,
    linkedAccountId,
    integrationUsesWebhookHandshake,
    integrationUsesAppLevelSecretKey,
    isUsingOrgLevelSignatureKey,
    isUsingAutoWebhookSetup,
  }) => {
    const [currentKeyValue, setCurrentKeyValue] = useState(key);
    const [currentIsActive, setCurrentIsActive] = useState(is_active);
    const [currentIsAwaitingHandshake, setCurrentIsAwaitingHandshake] =
      useState(is_awaiting_handshake);
    const [originalValue, setOriginalValue] = useState(key);
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    // Calls the API with the saved changes
    const saveChanges = (
      keyValue: string | undefined,
      isActiveValue: boolean,
      isHandshake: boolean,
      onSuccess?: () => void,
    ) => {
      setIsSaving(true);
      fetchWithAuth({
        path:
          isHandshake && integrationUsesWebhookHandshake
            ? `/integrations/webhook-receivers/${linkedAccountId}?is_handshake=${isHandshake}`
            : `/integrations/webhook-receivers/${linkedAccountId}`,
        method: "POST",
        body: {
          event,
          key: keyValue,
          is_active: isActiveValue,
        },
        onResponse: (receiver: WebhookReceiver) => {
          setIsSaving(false);
          setIsEditing(false);
          onSuccess?.();
        },
        onError: () => {
          setIsSaving(false);
          showErrorToast("Couldn't update the receiver, please try again");
        },
      });
    };

    const editButton = !isEditing && (
      <Button size="sm" variant={ButtonVariant.TertiaryWhite} onClick={() => setIsEditing(true)}>
        Edit
      </Button>
    );

    const saveChangesButton = (
      <Button
        size="sm"
        disabled={isSaving}
        onClick={() =>
          saveChanges(currentKeyValue, currentIsActive, currentIsAwaitingHandshake, () => {
            setOriginalValue(currentKeyValue);
            showSuccessToast(`Updated ${event}'s key`);
          })
        }
      >
        Save changes
      </Button>
    );

    const cancelButton = isEditing && (
      <Button
        variant={ButtonVariant.TertiaryWhite}
        size="sm"
        onClick={() => {
          setIsEditing(false);
          setCurrentKeyValue(originalValue);
        }}
      >
        Cancel
      </Button>
    );

    const handleSelectOption = (option: MultiSwitchOption) => {
      let isNewlyAwaitingHandshake = false;
      let isNewlyActive = false;
      if (option.id === HANDSHAKE_OPTION.id) {
        isNewlyAwaitingHandshake = true;
      } else if (option.id === IS_LISTENING_OPTION.id) {
        isNewlyActive = true;
      }

      // We use the original value to not wipe out any local changes
      saveChanges(currentKeyValue, isNewlyActive, isNewlyAwaitingHandshake, () => {
        setCurrentIsAwaitingHandshake(isNewlyAwaitingHandshake);
        setCurrentIsActive(isNewlyActive);
        isNewlyAwaitingHandshake
          ? showSuccessToast(`${event} webhook is listening for handshake request`)
          : showSuccessToast(`Turned ${event} webhook ${isNewlyActive ? "on" : "off"}`);
      });
    };

    // The multiswitch option for listening is disabled if there's no key value
    const listeningOption = useMemo(
      () => ({
        ...IS_LISTENING_OPTION,
        disable:
          (currentKeyValue?.length ?? 0) === 0 &&
          !isUsingAutoWebhookSetup &&
          !integrationUsesAppLevelSecretKey,
      }),
      [currentKeyValue],
    );

    return (
      <div className="flex flex-col gap-y-8 my-4">
        {!isUsingOrgLevelSignatureKey && (
          <>
            <Alert color="gray" icon={<Info size={16} />}>
              {isUsingAutoWebhookSetup || integrationUsesAppLevelSecretKey ? (
                <Text>
                  Merge automatically created this webhook in {integrationName}. Manage this
                  behavior in
                  {"  "}
                  <Link href={CONFIGURATION_WEBHOOKS_RECEIVERS_PATH} className="ml-1">
                    Configuration
                  </Link>
                  .
                </Text>
              ) : (
                <Text>{`Get a signature key from ${integrationName} to enable Merge to receive webhooks`}</Text>
              )}
            </Alert>
            {!isUsingAutoWebhookSetup && !integrationUsesAppLevelSecretKey && (
              <div className="flex flex-col gap-y-2">
                <div className="flex flex-row items-center">
                  <Text variant="h6">Signature key</Text>
                  <Text className="ml-1 text-gray-60">(required)</Text>
                </div>

                <div className="flex gap-2 flex-col">
                  <TextField
                    placeholder={`Enter signature key from ${integrationName}`}
                    variant={TextFieldVariant.BorderedCode}
                    disabled={!isEditing}
                    defaultValue={currentKeyValue ?? ""}
                    onChange={(e) => setCurrentKeyValue(e.currentTarget.value)}
                  />
                  <div className="flex flex-row gap-2">
                    {editButton}
                    {cancelButton}
                    {saveChangesButton}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        <div className="flex flex-col gap-y-2">
          <Text variant="h6">Third-party webhook</Text>
          <div>
            <ListenerSwitchWrapper
              integrationUsesWebhookHandshake={integrationUsesWebhookHandshake}
              currentIsActive={currentIsActive}
              currentIsAwaitingHandshake={currentIsAwaitingHandshake}
              currentKeyValue={currentKeyValue}
              listeningOption={listeningOption}
              onSelectOption={handleSelectOption}
            />
          </div>
        </div>
        {webhook_listener_url && (
          <div className="flex flex-col gap-y-2">
            <Text variant="h6">Merge receiver URL</Text>
            <Text className="text-gray-60">
              Send {integrationName} webhooks to this Merge endpoint to update your user's data in
              Merge
            </Text>
            <DottedOutlineTextCard text={webhook_listener_url} />
          </div>
        )}
      </div>
    );
  },
);

WebhookReceiverDetails.displayName = "WebhookReceiverDetails";
export default WebhookReceiverDetails;
