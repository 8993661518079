import jmespath from "jmespath";
import { useContext, useEffect, useState, useCallback } from "react";
import FieldMappingModalContext from "../../../context/FieldMappingModalContext";

type UseAdvancedFieldMappingsSelectionParams = {
  onJmesPathChange: (jmesPath: string | null) => void;
  jsonData: string;
};

const useAdvancedFieldMappingSelection = ({
  onJmesPathChange,
  jsonData,
}: UseAdvancedFieldMappingsSelectionParams) => {
  const [inputError, setInputError] = useState(false);
  const [jmesPathResult, setJmesPathResult] = useState<string | null>(null);
  const [isJmesPathValid, setIsJmesPathValid] = useState(false);

  const { jmesPath } = useContext(FieldMappingModalContext);

  const evaluateJmesPath = useCallback(
    (path: string): { result: string | null; isValid: boolean } => {
      try {
        const result = JSON.stringify(jmespath.search(jsonData, path));
        return { result, isValid: true };
      } catch {
        return { result: null, isValid: false };
      }
    },
    [jsonData],
  );

  // Sync context `jmesPath` with local state
  useEffect(() => {
    if (jmesPath) {
      const { result, isValid } = evaluateJmesPath(jmesPath);
      setInputError(!isValid);
      setIsJmesPathValid(isValid);
      setJmesPathResult(result);
    }
  }, [jmesPath, evaluateJmesPath]);

  // Handle JMESPath input changes
  const handleJmesPathInput = useCallback(
    (input: string) => {
      if (input.trim() === "") {
        // Reset state for empty input
        setInputError(false);
        setIsJmesPathValid(false);
        setJmesPathResult(null);
        onJmesPathChange(null);
        return;
      }

      const { result, isValid } = evaluateJmesPath(input);

      // Update state based on evaluation
      setInputError(!isValid);
      setIsJmesPathValid(isValid);
      setJmesPathResult(result);

      // Notify parent of JMESPath change
      onJmesPathChange(input);
    },
    [evaluateJmesPath, onJmesPathChange],
  );

  return {
    inputError,
    handleJmesPathInput,
    jmesPathResult,
    isJmesPathValid,
  };
};

export default useAdvancedFieldMappingSelection;
