import React from "react";
import { Badge, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { FieldMappingCreationAndEditDict } from "../../models/Entities";
import IconForFieldType from "./IconForFieldType";
import { renderWorkdayEmployeesEndpointStatus } from "../pages/linked-accounts/detail-page/field-mappings/modal-fmv3/helpers";

type Props = {
  option: FieldMappingCreationAndEditDict;
  isIntegrationWide?: boolean;
};

const FieldMappingDropdownChild = ({ option, isIntegrationWide = false }: Props) => {
  let example = option.value ?? null;

  if (example !== null) {
    if (typeof example === "object") {
      example = JSON.stringify(example);
    } else {
      example = String(example);
    }
  }

  return (
    <div className="flex flex-row w-full items-start break-words whitespace-normal break-all gap-x-2 pr-6 text-gray-60">
      <div className="flex">
        <IconForFieldType size={16} type={option.format ?? option.type} className="text-black" />
      </div>
      <div className="flex flex-col w-full max-w-full mt-0.5">
        <div className="flex flex-row items-start gap-x-2">
          <Text variant="md-mono" className="text-wrap text-black">
            {option.display_name}
          </Text>
          {!isIntegrationWide &&
          option.percentage_availability &&
          option.percentage_availability > 0 ? (
            <Badge
              color={option.percentage_availability >= 50 ? "teal" : "yellow"}
              size="sm"
              className="whitespace-nowrap mt-0.5"
            >
              {option.percentage_availability}% coverage
            </Badge>
          ) : (
            <></>
          )}
        </div>

        {option.commonModelName && (
          <div className="flex flex-row w-100 gap-x-3 items-center whitespace-nowrap">
            <Text variant="sm">Model:</Text>
            <Text variant="sm" className="truncate">
              {option.commonModelName}
            </Text>
          </div>
        )}
        <div className="flex flex-row w-100 gap-x-3 items-center truncate rtl">
          <Text variant="sm" className="text-nowrap">
            Endpoint:
          </Text>
          <Tooltip
            title={option.api_endpoint_path || "/"}
            className="flex flex-grow truncate gap-x-2"
          >
            <>
              <Text variant="title-sm" className="text-blue-40">
                GET
              </Text>
              <span dir="rtl" className="text-sm truncate rtl">
                {renderWorkdayEmployeesEndpointStatus(option.api_endpoint_path)}
                {"  "}
                <bdi>{option.api_endpoint_path ?? "/"}</bdi>
              </span>
            </>
          </Tooltip>
        </div>
        {!isIntegrationWide && example && (
          <div className="flex flex-row w-100 gap-x-3 items-center truncate">
            <Text variant="sm">Example:</Text>
            <Text variant="sm" className="truncate">
              {example}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default FieldMappingDropdownChild;
