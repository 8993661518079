import React from "react";
import { Modal, Card, TextField, Text } from "@merge-api/merge-javascript-shared";
import AdvancedFieldMappingSelector from "./advanced-field-mappings/AdvancedFieldMappingSelector";
import FieldMappingModalTitle from "../FieldMappingModalTitle";
import CancelAndNextFooter from "../../../../../../common/CancelAndNextFooter";
import useEditJmesPathModal from "./hooks/useEditJmesPathModal";
import clsx from "clsx";

type EditJmesPathModalProps = {
  jsonData: string;
  remoteFieldDisplayName: string;
  onModalClose: () => void;
  onUpdate: (jmesPath: string) => void;
  currentJmesPath: string | null;
  organizationTargetField?: string;
};
const EditJmesPathModal = ({
  jsonData,
  currentJmesPath,
  remoteFieldDisplayName,
  organizationTargetField,
  onModalClose,
  onUpdate,
}: EditJmesPathModalProps) => {
  const { jmesPath, jmesPathResult, inputError, handleJmesPathInput } = useEditJmesPathModal({
    currentJmesPath,
    jsonData,
  });
  return (
    <Modal open onClose={() => {}}>
      <Card className="pt-[18px] pb-6 w-[800px]">
        <FieldMappingModalTitle
          title={`Advanced mapping - ${remoteFieldDisplayName}`}
          onClose={() => {
            onModalClose();
          }}
        />
        <div className="px-6 pt-5 pb-0">
          <div>
            <AdvancedFieldMappingSelector
              jsonString={JSON.stringify(jsonData)}
              handleJmesPath={(jmesPath: any) => {
                handleJmesPathInput(jmesPath);
              }}
            />
          </div>
          <div className="mt-3">
            {" "}
            Select the field inside the list object you want to map, or specify a JSON query below
            using{" "}
            <a href="https://jmespath.org/" target="_blank" rel="noreferrer">
              JMESPath
            </a>
          </div>
          <div className="mt-2">
            <TextField
              placeholder="Example query: array[?type=='value'].result"
              value={jmesPath!}
              error={inputError}
              onChange={(e) => {
                handleJmesPathInput(e.target.value);
              }}
            />
          </div>
          <div className="mt-3 bg-gray-0 py-2.5 px-3 truncate rounded-md">
            <span className="font-semibold">Result: </span>
            <span>{jmesPathResult}</span>
          </div>
        </div>
        <div className={clsx("", organizationTargetField && "flex items-center justify-between")}>
          {organizationTargetField && (
            <Text variant="md" className="text-left ml-6 pt-6">
              {organizationTargetField}
            </Text>
          )}
          <CancelAndNextFooter
            className="mt-6 px-6"
            onCancel={() => {
              onModalClose();
            }}
            isNextDisabled={jmesPath == null || inputError}
            buttonText="Save"
            onNext={() => {
              onUpdate(jmesPath!);
            }}
          />
        </div>
      </Card>
    </Modal>
  );
};
export default EditJmesPathModal;
