import React from "react";
import { ChevronRight } from "lucide-react";
import clsx from "clsx";

type SelectionRowProps = {
  icon: React.ReactElement;
  title: string;
  subtitle: React.ReactNode | string;
  onClick?: () => void;
  disable?: boolean;
};

const SelectionRow = ({ icon, title, subtitle, onClick, disable = false }: SelectionRowProps) => {
  return (
    <button
      onClick={() => {
        if (onClick && !disable) {
          onClick();
        }
      }}
      className={clsx(
        "flex items-center border rounded-lg pt-3 pb-3 pl-5 bg-none border-gray-10 transition-colors",
        disable
          ? "text-gray-60 cursor-default"
          : "hover:bg-gray-0 hover:cursor-pointer focus:outline-none hover:outline-none",
      )}
    >
      <div>{icon}</div>
      <div className="flex-1 ml-5 hover:cursor-pointer">
        <div className="text-base text-left font-semibold">{title}</div>
        <div className="text-sm text-left">{subtitle}</div>
      </div>
      {!disable && <ChevronRight className="mr-5" size={16} color="gray" />}
    </button>
  );
};

export default SelectionRow;
