import React from "react";
import clsx from "clsx";
import { Card, Text } from "@merge-api/merge-javascript-shared";
import { Key } from "lucide-react";
import SkeletonLoader from "../../../../../../shared/SkeletonLoader";

type IDsCardSkeletonProps = {
  className?: string;
};

const IDsCardSkeleton = ({ className }: IDsCardSkeletonProps) => {
  return (
    <Card className={clsx(className, "overflow-hidden")}>
      <div className="flex flex-col w-full gap-2 bg-gray-0 p-2">
        <Text variant="title-sm">Linked Account ID</Text>
        <div className="rounded border-gray-10 bg-gray-10 p-1">
          <SkeletonLoader fullWidth height={12} borderRadius={2} className="my-0.5" />
        </div>
        <Text variant="title-sm" className="flex items-center">
          <Key size={12} className="mr-1.5" />
          Account token
        </Text>
        <div className="rounded border-gray-10 bg-gray-10 p-1">
          <SkeletonLoader fullWidth height={12} borderRadius={2} className="my-0.5" />
        </div>
      </div>
    </Card>
  );
};

export default IDsCardSkeleton;
