import React from "react";
import { X } from "lucide-react";
import { Text, Button, ButtonVariant } from "@merge-api/merge-javascript-shared";

type FieldMappingModalProps = {
  title: string;
  onClose: () => void;
};

const FieldMappingModalTitle = ({ title, onClose }: FieldMappingModalProps) => {
  return (
    <div className="px-6 border-b border-gray-10 pb-[18px] flex items-center justify-between">
      <Text variant="h5" className="flex-1 truncate">
        {title}
      </Text>
      <Button
        size="sm"
        variant={ButtonVariant.IconShadowHidden}
        className="ml-auto"
        onClick={() => {
          onClose();
        }}
      >
        <X size={16} />
      </Button>
    </div>
  );
};

export default FieldMappingModalTitle;
