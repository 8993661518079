import React from "react";
import map from "lodash/map";
import { AuditLogEventType } from "../../../../../models/Entities";
import { snakeCaseToSentenceCase, sentenceCaseToSnakeCase } from "../../../../../utils/casing";
import { Typeahead } from "@merge-api/merge-javascript-shared";

type EventTypePickerProps = {
  eventType: AuditLogEventType | null;
  onEventTypeChange: (eventType: AuditLogEventType | null) => void;
};

const EventTypePicker = ({ eventType, onEventTypeChange }: EventTypePickerProps) => {
  return (
    <Typeahead
      placeholder="Search events..."
      options={map(AuditLogEventType, snakeCaseToSentenceCase).sort()}
      value={eventType}
      getOptionLabel={(event) => snakeCaseToSentenceCase(event)}
      onChange={(_: any, event: string | null) =>
        onEventTypeChange(sentenceCaseToSnakeCase(event ?? "") as AuditLogEventType)
      }
    />
  );
};

export default EventTypePicker;
