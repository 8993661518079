import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Users, VolumeX, CheckCircle2, PauseCircle, AlertCircle, XCircle } from "lucide-react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { fetchWithAuth } from "../../../api-client/APIClient";
import { LinkedAccount, LinkedAccountMetrics } from "../../../models/Entities";
import { navigateToLinkedAccountsTablePage, useQuery } from "../../../router/RouterUtils";
import IntegrationsManagementFilter from "../shared/IntegrationsManagementFilter";
import PaginationFooter from "../../shared/PaginationFooter";
import { showErrorToast, showSuccessToast } from "../../shared/Toasts";
import LinkedAccountDeletionModal from "./shared/LinkedAccountDeletionModal";
import MaybeDisable from "../../shared/MaybeDisable";
import { useMemo } from "react";
import { LinkedAccountStatuses } from "../../../constants";
import { InputCol } from "../shared/IntegrationsManagementFilter";
import LinkedAccountTable from "./LinkedAccountTable";
import {
  Button,
  Alert,
  Badge,
  Text,
  ButtonVariant,
  Card,
} from "@merge-api/merge-javascript-shared";
import { useLazyRequest } from "../../shared/hooks/useLazyRequest";

type Props = {
  isTestAccounts: boolean;
  linkedAccounts: LinkedAccount[] | undefined;
  hasNext: boolean;
  hasPrevious: boolean;
  onNext: () => void;
  onPrevious: () => void;
  onRemove: (index: number) => void;
  onRefetch: (cursor?: any) => void;
  hasInitialized: boolean;
  isLoading: boolean;
  onLinkedAccountsParamsPathChange: (paramsPath: string) => void;
};

const LinkedAccountMetricsRow = styled(Row)`
  margin-bottom: 24px;
}
`;

const LinkedAccountsPageData = (props: Props) => {
  const history = useHistory();
  const [linkedAccountMetrics, setLinkedAccountMetrics] = useState<LinkedAccountMetrics>();
  const [selectedStatus, setSelectedStatus] = useState(LinkedAccountStatuses.ALL);
  const [paramsPath, setParamsPath] = useState<string | undefined>(undefined);
  const [linkedAccountsBeingResynced, setLinkedAccountsBeingResynced] = useState<
    Set<LinkedAccount>
  >(new Set());
  const [linkedAccountToDelete, setLinkedAccountToDelete] = useState<LinkedAccount | undefined>();
  const isLinkedAccountDeletionModalOpen = linkedAccountToDelete != undefined;
  const query = useQuery();
  const [fetchDetailedCounts, _] = useLazyRequest<LinkedAccountMetrics>({});

  const getLinkedAccountParams = () => {
    return selectedStatus !== "ALL" ? `status=${selectedStatus}` : "";
  };

  const completeProductionDuplicatesOf = useMemo<string | null>(
    () => query.get("complete_production_duplicates_of"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const showDuplicatesOnly = !!completeProductionDuplicatesOf;

  const testAccountPath = props.isTestAccounts ? "&is_test_account=true" : "";

  const getLinkedAccountParamsPath = () => {
    let result = getLinkedAccountParams();

    if (paramsPath) {
      if (result) {
        result += `&${paramsPath}`;
      } else {
        result = paramsPath;
      }
    }

    if (testAccountPath) {
      if (result) {
        result += `&${testAccountPath}`;
      } else {
        result = testAccountPath;
      }
    }

    return result;
  };

  const updateDetailedCounts = async () => {
    const { data } = await fetchDetailedCounts({
      path: `/integrations/linked-accounts/detailed-counts?${getLinkedAccountParamsPath()}`,
    });
    setLinkedAccountMetrics(data);
  };

  useEffect(() => {
    if (paramsPath == undefined) {
      return;
    }
    updateDetailedCounts();
  }, [paramsPath]);

  useEffect(() => {
    const status = query.get("status");
    if (status) {
      setSelectedStatus(status);
    }
  }, [location.search]);

  useEffect(() => {
    const newParamsPath = getLinkedAccountParamsPath();
    history.replace({
      pathname: location.pathname,
      search: newParamsPath,
    });

    props.onLinkedAccountsParamsPathChange(getLinkedAccountParamsPath());
  }, [paramsPath, selectedStatus, location]);

  // refetch if demo state changes
  useEffect(() => {
    props.hasInitialized && props.onRefetch();
  }, [props.onRefetch, props.hasInitialized]);

  const forceResync = (linkedAccount: LinkedAccount) => {
    setLinkedAccountsBeingResynced(new Set(linkedAccountsBeingResynced).add(linkedAccount));
    fetchWithAuth({
      path: `/integrations/linked-accounts/${linkedAccount.id}/force-resync`,
      method: "POST",
      onResponse: (_) => {
        const newSet = new Set(linkedAccountsBeingResynced);
        newSet.delete(linkedAccount);
        setLinkedAccountsBeingResynced(newSet);
        showSuccessToast(
          `Resync queued for ${linkedAccount.end_user.organization_name} integration with ${linkedAccount.integration.name}.`,
        );
      },
      onError: () => {
        const newSet = new Set(linkedAccountsBeingResynced);
        newSet.delete(linkedAccount);
        setLinkedAccountsBeingResynced(newSet);
        showErrorToast("Failed to force resync. Please try again.");
      },
    });
  };

  const deleteLinkedAccount = () => {
    if (linkedAccountToDelete && props.linkedAccounts) {
      fetchWithAuth({
        path: `/integrations/linked-accounts/${linkedAccountToDelete.id}`,
        method: "DELETE",
        onResponse: () => {
          const newSet = new Set(linkedAccountsBeingResynced);
          newSet.delete(linkedAccountToDelete);
          setLinkedAccountsBeingResynced(newSet);
          showSuccessToast("Deleted linked account!");
          setLinkedAccountToDelete(undefined);
          const indexToRemove = props.linkedAccounts?.findIndex(
            (linkedAccount) => linkedAccount.id == linkedAccountToDelete.id,
          );
          if (indexToRemove || indexToRemove === 0) {
            props.onRemove(indexToRemove);
          }
        },
        onError: () => {
          showErrorToast("Failed to delete linked account. Please try again.");
        },
      });
    }
  };

  const onIgnoreDuplicatesClick = () => {
    fetchWithAuth({
      path: `/integrations/linked-accounts/${completeProductionDuplicatesOf}/ignore-duplicates`,
      method: "POST",
      onResponse: () => {
        showSuccessToast("Successfully ignored accounts with these credentials!");
        navigateToLinkedAccountsTablePage(history);
      },
      onError: () => {
        showErrorToast("Failed to ignore accounts with these credentials.");
      },
    });
  };

  const handleStatusSelect = (status: string) => {
    setSelectedStatus(selectedStatus === status ? LinkedAccountStatuses.ALL : status);
  };

  const hasPagination = props.hasNext || props.hasPrevious;
  return (
    <>
      {linkedAccountToDelete && (
        <LinkedAccountDeletionModal
          isModalOpen={isLinkedAccountDeletionModalOpen}
          deleteLinkedAccount={deleteLinkedAccount}
          closeModal={() => setLinkedAccountToDelete(undefined)}
          linkedAccount={linkedAccountToDelete}
        />
      )}
      {showDuplicatesOnly && (
        <Alert className="mt-2 mb-6" icon={<Users size={16} />}>
          <div className="flex flex-row w-full items-center justify-between gap-x-1">
            <div className="flex flex-col">
              <Text variant="h6">
                These Linked Accounts are configured using the same credentials
              </Text>
              <Text>
                If this is intentional, press &quot;Ignore&quot; to dismiss warnings for these
                accounts. Otherwise, delete unwanted Linked Accounts to avoid unnecessary syncing.
              </Text>
            </div>
            <Button
              size="sm"
              leftIcon={<VolumeX size={12} />}
              onClick={onIgnoreDuplicatesClick}
              variant={ButtonVariant.TertiaryWhite}
            >
              Ignore
            </Button>
          </div>
        </Alert>
      )}
      <MaybeDisable disable={showDuplicatesOnly}>
        <IntegrationsManagementFilter
          isLinkedAccounts
          setParamsPath={setParamsPath}
          isTestAccounts={props.isTestAccounts}
        />
      </MaybeDisable>
      <LinkedAccountMetricsRow className="no-gutters" style={{ minWidth: "100%" }}>
        <InputCol lg="3" md="6" sm="12">
          <Card
            size="lg"
            className={`overflow-hidden cursor-pointer h-[124px] ${
              selectedStatus === LinkedAccountStatuses.COMPLETE ? "border border-blue-40" : ""
            }`}
            onClick={() => {
              handleStatusSelect(LinkedAccountStatuses.COMPLETE);
            }}
          >
            <Badge size="lg" color="teal" className="w-full p-0">
              <div className="d-flex align-items-center px-4 py-1.5 w-100">
                <div className="d-flex align-items-center gap-1.5 mr-auto ">
                  <CheckCircle2 size={16} />
                  <Text className="font-semibold">Linked</Text>
                </div>
                <button
                  className="group"
                  onClick={() => {
                    handleStatusSelect(LinkedAccountStatuses.COMPLETE);
                  }}
                >
                  <Text
                    variant="title-sm"
                    className="font-semibold text-blue-40 group-hover:text-blue-60"
                  >
                    {selectedStatus === LinkedAccountStatuses.COMPLETE ? "Remove filter" : "Filter"}
                  </Text>
                </button>
              </div>
            </Badge>
            <div className="p-5">
              <div className="flex justify-between items-center">
                <Text variant="h2">
                  {linkedAccountMetrics
                    ? linkedAccountMetrics.completed_linked_accounts_count +
                      (linkedAccountMetrics.rippling_oauth_accounts_count ?? 0) -
                      (showDuplicatesOnly ? linkedAccountMetrics.idle_accounts_count : 0)
                    : ""}
                </Text>
              </div>
            </div>
          </Card>
        </InputCol>
        <InputCol lg="3" md="6" sm="12">
          <Card
            size="lg"
            className={`overflow-hidden cursor-pointer min-h-[124px] ${
              selectedStatus === LinkedAccountStatuses.IDLE ? "border border-blue-40" : ""
            }`}
            onClick={() => {
              handleStatusSelect(LinkedAccountStatuses.IDLE);
            }}
          >
            <Badge size="lg" color="gray" className="w-full p-0">
              <div className="flex align-items-center px-4 py-1.5 w-100">
                <div className="d-flex align-items-center gap-1.5 mr-auto">
                  <PauseCircle size={16} />
                  <Text className="font-semibold">Idle</Text>
                </div>

                <button
                  className="group"
                  onClick={() => handleStatusSelect(LinkedAccountStatuses.IDLE)}
                >
                  <Text
                    variant="title-sm"
                    className="font-semibold text-blue-40 group-hover:text-blue-60"
                  >
                    {selectedStatus === LinkedAccountStatuses.IDLE ? "Remove filter" : "Filter"}
                  </Text>
                </button>
              </div>
            </Badge>
            <div className="p-5">
              <div className="flex justify-between items-center">
                <Text variant="h2">
                  {linkedAccountMetrics ? linkedAccountMetrics.idle_accounts_count : ""}
                </Text>
              </div>
            </div>
          </Card>
        </InputCol>
        <InputCol lg="3" md="6" sm="12">
          <Card
            size="lg"
            className={`overflow-hidden cursor-pointer min-h-[124px] ${
              selectedStatus === LinkedAccountStatuses.INCOMPLETE ? "border border-blue-40" : ""
            }`}
            onClick={() => {
              handleStatusSelect(LinkedAccountStatuses.INCOMPLETE);
            }}
          >
            <Badge size="lg" color="yellow" className="w-full p-0">
              <div className="d-flex align-items-center px-4 py-1.5 w-100">
                <div className="d-flex align-items-center gap-1.5 mr-auto">
                  <AlertCircle size={16} />
                  <Text className="font-semibold">Incomplete</Text>
                </div>
                <button
                  className="group"
                  onClick={() => {
                    handleStatusSelect(LinkedAccountStatuses.INCOMPLETE);
                  }}
                >
                  <Text
                    variant="title-sm"
                    className="font-semibold text-blue-40 group-hover:text-blue-60"
                  >
                    {selectedStatus === LinkedAccountStatuses.INCOMPLETE
                      ? "Remove filter"
                      : "Filter"}
                  </Text>
                </button>
              </div>
            </Badge>
            <div className="p-5">
              <div className="flex justify-between items-center">
                <Text variant="h2">
                  {linkedAccountMetrics
                    ? linkedAccountMetrics.incomplete_linked_accounts_count
                    : ""}
                </Text>
              </div>
            </div>
          </Card>
        </InputCol>
        <InputCol lg="3" md="6" sm="12">
          <Card
            size="lg"
            className={`overflow-hidden cursor-pointer min-h-[124px] ${
              selectedStatus === LinkedAccountStatuses.RELINK_NEEDED
                ? "border border-[#075FF7]"
                : ""
            }`}
            onClick={() => {
              handleStatusSelect(LinkedAccountStatuses.RELINK_NEEDED);
            }}
          >
            <Badge size="lg" color="red" className="w-full p-0">
              <div className="d-flex justify-content-between align-items-center px-4 py-1.5 w-100">
                <div className="d-flex align-items-center gap-1.5 mr-auto">
                  <XCircle size={16} />
                  <Text className="font-semibold">Relink</Text>
                </div>
                <button
                  className="group"
                  onClick={() => {
                    handleStatusSelect(LinkedAccountStatuses.RELINK_NEEDED);
                  }}
                >
                  <Text
                    variant="title-sm"
                    className="font-semibold text-blue-40 group-hover:text-blue-60"
                  >
                    {selectedStatus === LinkedAccountStatuses.RELINK_NEEDED
                      ? "Remove filter"
                      : "Filter"}
                  </Text>
                </button>
              </div>
            </Badge>
            <div className="p-5">
              <div className="flex justify-between items-center">
                <Text variant="h2">
                  {linkedAccountMetrics ? linkedAccountMetrics.relink_needed_accounts_count : ""}
                </Text>
              </div>
            </div>
          </Card>
        </InputCol>
      </LinkedAccountMetricsRow>
      <Row>
        <Col md={12}>
          <LinkedAccountTable
            linkedAccounts={props.linkedAccounts}
            isLoading={props.isLoading}
            forceResync={forceResync}
            isTestAccounts={props.isTestAccounts}
            setLinkedAccountToDelete={setLinkedAccountToDelete}
          />
        </Col>
      </Row>

      {hasPagination && (
        <PaginationFooter
          hasPrevious={props.hasPrevious}
          hasNext={props.hasNext}
          onPreviousClick={props.onPrevious}
          onNextClick={props.onNext}
        />
      )}
    </>
  );
};

export default LinkedAccountsPageData;
