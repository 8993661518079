import React from "react";
import { useHistory } from "react-router-dom";
import { displayNameForAPICategory } from "../../../../../models/Helpers";
import { APICategory } from "../../../../../models/Entities";
import { Box, Globe, MoreHorizontal } from "lucide-react";
import DeleteFieldMappingModal from "../../../linked-accounts/detail-page/field-mappings/DeleteFieldMappingModal";
import { navigateToConfigurationFieldMappingTargets } from "../../../../../router/RouterUtils";
import { useState } from "react";
import useAppContext from "../../../../context/useAppContext";
import {
  Badge,
  ButtonVariant,
  Card,
  Dialog,
  Menu,
  MenuItem,
  Text,
} from "@merge-api/merge-javascript-shared";
import { showErrorToast, showSuccessToast } from "../../../../shared/Toasts";
import { deleteCommonModelOverrideTarget } from "../utils/FieldMappingUtils";

type FieldMappingHeaderProps = {
  targetId: string;
  commonModel: String;
  title: String;
  category: APICategory;
  description: String;
  isOverride?: boolean;
};
const FieldMappingHeader = ({
  targetId,
  commonModel,
  title,
  category,
  description,
  isOverride = false,
}: FieldMappingHeaderProps) => {
  const [showDeleteFieldMappingModal, setShowDeleteFieldMappingModal] = useState(false);
  const [showDeleteOverrideModal, setShowDeleteOverrideModal] = useState(false);

  const history = useHistory();
  const { user } = useAppContext();

  return (
    <>
      <DeleteFieldMappingModal
        fieldMappingTargetId={targetId}
        isFieldMappingTargetDelete
        handlePostDelete={() => {
          navigateToConfigurationFieldMappingTargets(history);
        }}
        showModal={showDeleteFieldMappingModal}
        onHide={() => setShowDeleteFieldMappingModal(false)}
        headerText="Delete target field"
        text="This will delete all Field Mappings associated with this target field."
      />

      <Dialog
        open={showDeleteOverrideModal}
        onClose={() => {
          setShowDeleteOverrideModal(false);
        }}
        onPrimaryButtonClick={() => {
          deleteCommonModelOverrideTarget(
            targetId,
            () => {
              showSuccessToast("Successfully deleted override!");
              setShowDeleteOverrideModal(false);
              navigateToConfigurationFieldMappingTargets(history);
            },
            () => {
              showErrorToast("Unable to delete override!");
            },
          );
        }}
        primaryButtonText="Delete override"
        onSecondaryButtonClick={() => {
          setShowDeleteOverrideModal(false);
        }}
        secondaryButtonText="Cancel"
        title="Delete override"
        variant="sm"
      >
        This will delete all Field Mappings associated with this override.
        <br />
        <br />
        Do you wish to continue?
      </Dialog>

      <div>
        <div className={`h-2 rounded-t-lg ${isOverride ? "bg-blue-20" : "bg-orange-20"}`}></div>
        <Card size="lg" className="flex flex-row px-6 pt-3 pb-5">
          <div className="flex flex-col flex-grow-1 gap-y-2">
            <Text variant="lg-mono" className="text-xl font-semibold">
              {title}
            </Text>
            <div className="flex flex-row items-center gap-2">
              {isOverride ? (
                <Badge color="blue" className="flex items-center">
                  <Box className="mr-1" size={12} />
                  <div className="font-normal">Common Model override</div>
                </Badge>
              ) : (
                <Badge color="orange" className="flex items-center">
                  <Globe className="mr-1" size={12} />
                  <div className="font-normal">{user.organization.name}-wide target field</div>
                </Badge>
              )}
              <Badge>{commonModel}</Badge>
              <Badge color="blue">{displayNameForAPICategory(category)}</Badge>
            </div>
            <Text>{description}</Text>
          </div>
          <div className="flex flex-col justify-content-center">
            <Menu
              ButtonProps={{
                children: <MoreHorizontal size={12} />,
                size: "sm",
                variant: ButtonVariant.IconOnly,
              }}
              menuPlacement="bottom-end"
            >
              <MenuItem
                onClick={() => {
                  isOverride
                    ? setShowDeleteOverrideModal(true)
                    : setShowDeleteFieldMappingModal(true);
                }}
              >
                Delete {isOverride ? "target field" : "override"}
              </MenuItem>
            </Menu>
          </div>
        </Card>
      </div>
    </>
  );
};

export default FieldMappingHeader;
