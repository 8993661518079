import React, { useState } from "react";
import { Dialog, Checkbox } from "@merge-api/merge-javascript-shared";

type Props = {
  isModalOpen: boolean;
  closeModal: () => void;
  convertToProduction: () => void;
};

const LinkedAccountTestAccountConversionModal = ({
  isModalOpen,
  closeModal,
  convertToProduction,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [
    isLinkedAccountConversionConfirmationChecked,
    setisLinkedAccountConversionConfirmationChecked,
  ] = useState(false);

  const onClickPrimary = () => {
    setIsLoading(true);
    convertToProduction();
  };

  const onClickSecondary = () => {
    setIsLoading(false);
    setisLinkedAccountConversionConfirmationChecked(false);
    closeModal();
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={onClickSecondary}
      onPrimaryButtonClick={onClickPrimary}
      onSecondaryButtonClick={onClickSecondary}
      primaryButtonDisabled={!isLinkedAccountConversionConfirmationChecked}
      primaryButtonText="Convert Linked Account"
      primaryButtonLoading={isLoading}
      secondaryButtonText="Cancel"
      title="Are you sure you want to convert this?"
      variant="md"
    >
      <>
        This is an irreversible action. Once the account is converted to a production Linked
        Account, a new account token will be generated.
        <br />
        <br />
        Only <b>Admin</b> users will be able to access the token from the dashboard.
        <div className="my-6">
          <Checkbox
            name="linked-account-deletion-checkbox"
            checked={isLinkedAccountConversionConfirmationChecked}
            onChange={setisLinkedAccountConversionConfirmationChecked}
            label="I understand that this is an irreversible action"
            labelFontWeight="semibold"
            variant="secondary"
          />
        </div>
      </>
    </Dialog>
  );
};

export default LinkedAccountTestAccountConversionModal;
