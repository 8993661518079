import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Route, useHistory, useParams } from "react-router-dom";
import {
  ISSUES_PATH,
  navigateToIssuesPage,
  navigateToLinkedAccountIssuesPage,
} from "../../../../router/RouterUtils";
import PortalPageHeaderWrapper from "../../../portal/PortalPageHeaderWrapper";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";
import {
  IntegrationIssue,
  IntegrationIssueActionLogEntryAction,
  IntegrationIssueActor,
  IntegrationIssueStatus,
} from "../../IntegrationsManagementEntities";

import {
  fetchIntegrationIssue,
  updateIntegrationIssueStatus,
  fetchLogEntryByID,
  fetchLogEntryResponseBody,
} from "../../utils/IntegrationsManagementAPIClient";
import { getIssueCreatedAt, isIssueMuted } from "../../utils/IntegrationsManagementUtils";
import { IntegrationIssueCommentModal } from "./IntegrationIssueCommentModal";
import IndividualIssueActivity from "./IndividualIssueActivity";
import IndividualIssueMuteButton from "./IntegrationsManagementIndividualIssueMuteButton";
import { DATE_FORMAT, IssueDescription } from "../../../../constants";
import { ArrowLeft, Check, MessageSquare, VolumeX } from "lucide-react";
import IntegrationIssueDetailedErrorContainer from "../../linked-accounts/detail-page/overview/IntegrationIssueDetailedErrorContainer";
import ImpactedModelsRow from "../../linked-accounts/detail-page/overview/ImpactedModelsRow";
import { getImpactedModels } from "../../utils/IntegrationsManagementUtils";
import { Button, Badge, ButtonVariant, Text, Card } from "@merge-api/merge-javascript-shared";
import IDsCard from "../../linked-accounts/detail-page/overview/IDsCard";
import { LogSidePanel } from "../../logs/side-panel/shared-components/LogSidePanel";
import IssueStatusBadge from "../../../shared/IssueStatusBadge";

export const getResolvedBy = (integrationIssue: IntegrationIssue) => {
  if (integrationIssue?.metadata) {
    for (let i = integrationIssue.metadata.length - 1; i >= 0; i--) {
      const action = integrationIssue.metadata[i];
      if (action.action == IntegrationIssueActionLogEntryAction.RESOLVE) {
        switch (action.actor_type) {
          case IntegrationIssueActor.AUTO_ACTOR:
            return "Auto-resolved";
          case IntegrationIssueActor.USER_ACTOR:
            return `Resolved by ${action.user_name || action.user_email}`;
          default:
            return "Resolved";
        }
      }
    }
  }
  return "Resolved";
};
const IntegrationsManagementIndividualIssuePage = () => {
  const [integrationIssue, setIntegrationIssue] = useState<undefined | IntegrationIssue>();
  const [isCommentModalShown, setIsCommentModalShown] = useState<boolean>(false);
  const history = useHistory<any>();
  const [resolveLoading, setResolveLoading] = useState<boolean>(false);
  const isFromLinkedAccountPage = history?.location?.state?.from == "linked_account_page";
  const { integrationIssueID } = useParams<{
    integrationIssueID: string;
  }>();
  const isResolved = integrationIssue?.status === IntegrationIssueStatus.RESOLVED;
  const isMuted = integrationIssue ? isIssueMuted(integrationIssue) : false;
  const isMissingPermissionIssue =
    integrationIssue?.error_description == IssueDescription.MISSING_PERMISSION;
  const isWarningIssue =
    integrationIssue?.error_description == IssueDescription.MISSING_PERMISSION ||
    integrationIssue?.error_description == IssueDescription.SFTP_MISSING_REQUIRED_FIELDS ||
    integrationIssue?.error_description == IssueDescription.SFTP_INCORRECTLY_FORMATTED_ROW;

  const impactedModels = integrationIssue?.metadata
    ? getImpactedModels(integrationIssue.metadata)
    : {};
  const hasImpactedModels = Object.keys(impactedModels).length > 0;
  useEffect(() => {
    fetchIntegrationIssue({
      integrationIssueID,
      onSuccess: (result: IntegrationIssue) => {
        setIntegrationIssue(result);
      },
    });
  }, [integrationIssueID]);

  const updateStatus = (status: IntegrationIssueStatus) => {
    setResolveLoading(true);
    updateIntegrationIssueStatus({
      integrationIssueID,
      status,
      onSuccess: (data: IntegrationIssue) => {
        setIntegrationIssue(data);
        setResolveLoading(false);
        showSuccessToast("Issue marked as " + status.toLowerCase() + ".");
      },
      onError: () => {
        showErrorToast("Issue resolution failing.");
      },
    });
  };

  function clickResolveButton() {
    if (isResolved) {
      updateStatus(IntegrationIssueStatus.ONGOING);
    } else {
      updateStatus(IntegrationIssueStatus.RESOLVED);
    }
  }

  if (!integrationIssue) return <></>;

  const issueCreatedDate = getIssueCreatedAt(integrationIssue);

  const dateCreatedString = issueCreatedDate
    ? new Date(issueCreatedDate).toLocaleDateString(undefined, DATE_FORMAT)
    : "-";

  const issueComponent = (
    <>
      <Button
        variant={ButtonVariant.TertiaryWhite}
        onClick={() => setIsCommentModalShown(true)}
        fullWidth
        className="gap-x-2"
      >
        <MessageSquare size={12} />
        {integrationIssue.comment_count} comments
      </Button>
      <Card className="flex flex-col px-4 py-3 gap-y-1">
        <Text variant="h5">Issue created</Text>
        <Text>{dateCreatedString}</Text>
      </Card>
    </>
  );

  return (
    <PortalPageHeaderWrapper
      isLinkedAccountPortalView
      preTitleContent={
        <Button
          variant={ButtonVariant.TextBlue}
          onClick={
            isFromLinkedAccountPage
              ? () => navigateToLinkedAccountIssuesPage(history, integrationIssue.linked_account)
              : () => navigateToIssuesPage(history)
          }
          leftIcon={<ArrowLeft size={14} />}
        >
          {isFromLinkedAccountPage ? "Back to Linked Account" : "Back to Issues"}
        </Button>
      }
      title={
        <div className="flex flex-row items-center gap-x-3">
          <span>{integrationIssue.error_description}</span>
          {isMuted && <VolumeX size={30} />}
          <IssueStatusBadge issue={integrationIssue} size="lg" />
        </div>
      }
      isMaxWidthEnabled
      rightElement={
        <div className="flex flex-row items-center justify-end gap-3">
          <IndividualIssueMuteButton
            integrationIssue={integrationIssue}
            onIssueUpdate={setIntegrationIssue}
          />
          {isResolved != undefined && (
            <Button
              className="float-right"
              variant={ButtonVariant.TertiaryWhite}
              size="sm"
              onClick={clickResolveButton}
              loading={resolveLoading}
              leftIcon={resolveLoading ? undefined : <Check size="12" />}
            >
              {resolveLoading ? (
                <>{isResolved ? "Reopening" : "Resolving"}</>
              ) : (
                <>{isResolved ? "Reopen" : "Resolve"}</>
              )}
            </Button>
          )}
        </div>
      }
    >
      <>
        <Row>
          <Col xs="12" md="9" lg="9" xl="9">
            <>
              <div
                className={`h-[6px] rounded-t-lg ${isWarningIssue ? "bg-yellow-70" : "bg-red-60"}`}
              />
              <Card
                className={`rounded-t-none ${
                  isMissingPermissionIssue ? "pt-3 pl-4 pb-4 pr-4" : "pt-2 pl-4 pb-4 pr-4 "
                }`}
              >
                {isMissingPermissionIssue && integrationIssue.metadata && hasImpactedModels && (
                  <ImpactedModelsRow impactedModels={impactedModels} isOverviewPage={false} />
                )}
                <Text
                  variant="h6"
                  className={`mb-2 ${isMissingPermissionIssue && hasImpactedModels ? "mt-4" : ""}`}
                >
                  Recommended remediation to share with your customer
                </Text>
                <IntegrationIssueDetailedErrorContainer
                  integrationIssue={integrationIssue}
                  showPreview={false}
                  issueMetadata={integrationIssue.metadata}
                  isMissingPermissionIssue={
                    integrationIssue.error_description == "Missing permission"
                  }
                />
              </Card>
              <div className="mt-6">
                <IndividualIssueActivity integrationIssue={integrationIssue} />
              </div>
            </>
          </Col>
          <Col xs="12" md="3" lg="3" xl="3">
            <IDsCard
              linkedAccount={integrationIssue.linked_account}
              integrationIssueComponent={issueComponent}
            />
          </Col>
        </Row>
        <IntegrationIssueCommentModal
          integrationIssue={integrationIssue}
          isShown={isCommentModalShown}
          setIsShown={setIsCommentModalShown}
        />
        <Route
          exact
          path={`${ISSUES_PATH}/:integrationIssueID/log-entry/:logID`}
          render={() => (
            <LogSidePanel
              title="API request log"
              fetchLog={fetchLogEntryByID}
              fetchResponseBody={fetchLogEntryResponseBody}
            />
          )}
        />
      </>
    </PortalPageHeaderWrapper>
  );
};

export default IntegrationsManagementIndividualIssuePage;
