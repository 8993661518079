import React, { useEffect, useState } from "react";
import { stringRemoveSpaces } from "../../../../../../../../services";
import { X } from "lucide-react";
import {
  APICategory,
  Badge,
  Button,
  ButtonVariant,
  Text,
  Typeahead,
} from "@merge-api/merge-javascript-shared";
import {
  lucideIconForAPICategory,
  displayNameForAPICategory,
} from "../../../../../../../../models/Helpers";
import ChangedDataCommonModelCard from "./ChangedDataCommonModelCard";
import { CategoryToModelsToFieldsMap } from "../../../hooks/useWebhookOptions";
import { conditionallyPluralize } from "../../../../../../../../utils/general";
import { uniq } from "lodash";

interface Props {
  category: APICategory;
  commonModelOptions: string[];
  modelsToFieldsEnabledMap?: CategoryToModelsToFieldsMap;
  selectedCommonModels: string[];
  selectedCommonModelEvents: string[];
  selectedCommonModelsForCategory: string[];
  onRemoveCategory?: () => void;
  onRemoveCommonModel: (commonModel: string) => void;
  onCheckboxChange: (isChecked: boolean, value: string) => void;
  onSelectedCommonModelsChange: (selectedCommonModels: string[]) => void;
  onSelectedCommonModelEventsChange: (selectedCommonModelEvents: string[]) => void;
  selectedChangedDataCommonModelsToFields?: Record<string, string[]>;
  setSelectedChangedDataCommonModelsToFields: (
    selectedChangedDataCommonModelsToFields: Record<string, string[]>,
  ) => void;
  deletedWebhookEnabled: boolean;
}
const ChangedDataCategoryCard = ({
  category,
  commonModelOptions,
  modelsToFieldsEnabledMap,
  selectedCommonModels, // selected common models across all categories
  selectedCommonModelEvents,
  selectedCommonModelsForCategory,
  onRemoveCategory,
  onRemoveCommonModel,
  onCheckboxChange,
  onSelectedCommonModelsChange,
  onSelectedCommonModelEventsChange,
  selectedChangedDataCommonModelsToFields,
  setSelectedChangedDataCommonModelsToFields,
  deletedWebhookEnabled,
}: Props) => {
  // state
  const [selectedCommonModelsCount, setSelectedCommonModelsCount] = useState<number>(
    selectedCommonModelsForCategory.length,
  );

  // use effect that updates the common model counts for each category
  useEffect(() => {
    setSelectedCommonModelsCount(selectedCommonModelsForCategory.length);
  }, [selectedCommonModelsForCategory]);

  /**
   * Update the arrays of selectedCommonModels and selectedCommonModelEvents.
   * `commonModels` is the new list of selectedCommonModels (just their names) for the category.
   * Removes the old common models that were selected for the category and then adds all the selected
   * common models in.
   */
  const updateSelectedCommonModels = (_: any, commonModels: string[] | null) => {
    if (commonModels) {
      const newlyAddedCommonModels = commonModels.filter((m) => !selectedCommonModels.includes(m));
      const deletedCommonModels = selectedCommonModelsForCategory.filter(
        (m) => !commonModels.includes(m),
      );

      // filter out deleted common models
      const updatedCommonModels = selectedCommonModels.filter(
        (m) => !deletedCommonModels.includes(m),
      );

      // Compose array of events to add for each newly added Common Model.
      const addedEvents: string[] = [];
      const defaultCommonModelEvents = [".added", ".changed", ".removed"];
      newlyAddedCommonModels.forEach((commonModel) =>
        addedEvents.push(
          ...defaultCommonModelEvents.map(
            (defaultEvent) => `${stringRemoveSpaces(commonModel)}${defaultEvent}`,
          ),
        ),
      );

      // Remove events for deleted common models
      const updatedSelectedEvents = selectedCommonModelEvents.filter(
        (e) => !deletedCommonModels.map((m) => stringRemoveSpaces(m)).includes(e.split(".")[0]),
      );

      // For newly added Common Models, add the default event types for that model and add to list of all common models (for all categories).
      updatedCommonModels.push(...newlyAddedCommonModels);
      updatedSelectedEvents.push(...addedEvents);

      onSelectedCommonModelsChange(updatedCommonModels);
      onSelectedCommonModelEventsChange(uniq(updatedSelectedEvents));
      setSelectedCommonModelsCount(selectedCommonModelsForCategory.length);
    }
  };

  return (
    <div className="border border-gray-10 rounded-lg w-full bg-white mb-2">
      <div className="border-b border-gray-10 px-4 py-3 flex lg:flex-row cursor-default flex-col lg:items-center items-start justify-between">
        <div className="flex flex-row justify-center items-center">
          {lucideIconForAPICategory(category as APICategory, "md", "mr-2")}
          <Text variant="h6">{displayNameForAPICategory(category as APICategory)}</Text>
        </div>
        <div className="flex flex-row justify-center items-center lg:mt-0 mt-3">
          <Badge size="md" color="blue">
            {conditionallyPluralize("model", selectedCommonModelsCount)}
          </Badge>
          <Button
            size="sm"
            className="ml-3"
            variant={ButtonVariant.IconShadowHidden}
            onClick={onRemoveCategory}
          >
            <X size={16} />
          </Button>
        </div>
      </div>
      <div className="px-4 py-3">
        <Text variant="h6" className="mb-2">
          Select common models
        </Text>
        <Typeahead
          multiple
          showSearchIcon
          placeholder="Search..."
          options={commonModelOptions}
          onChange={updateSelectedCommonModels}
          value={selectedCommonModelsForCategory}
          disableClearable
        />
        {selectedCommonModelsForCategory.map((commonModel) => {
          const selectedFields = selectedChangedDataCommonModelsToFields?.[commonModel] || [];
          const enabledFieldsForModel =
            category && modelsToFieldsEnabledMap?.[category]
              ? modelsToFieldsEnabledMap?.[category]?.[commonModel]
              : [];
          return (
            <ChangedDataCommonModelCard
              commonModel={commonModel}
              deletedWebhookEnabled={deletedWebhookEnabled}
              selectedFields={selectedFields}
              enabledFieldsForModel={enabledFieldsForModel}
              selectedCommonModelEvents={selectedCommonModelEvents}
              onRemoveCommonModel={onRemoveCommonModel}
              onCheckboxChange={onCheckboxChange}
              setSelectedChangedDataCommonModelsToFields={
                setSelectedChangedDataCommonModelsToFields
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default ChangedDataCategoryCard;
