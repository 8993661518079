import React from "react";
import clsx from "clsx";
import EndpointReadinessRatingIcon from "../../EndpointReadinessRatingIcon/EndpointReadinessRatingIcon";
import { EndpointReadinessRating } from "../../../types";
import { CELL_WIDTH } from "../EndpointUsageTable";

type EndpointUsageTableCellProps = {
  endpointReadinessRating: EndpointReadinessRating;
};

const EndpointUsageTableCell = ({ endpointReadinessRating }: EndpointUsageTableCellProps) => {
  return (
    <td className={clsx(CELL_WIDTH, "py-3 px-4 border-r-[1px] border-solid border-gray-10")}>
      <div className="flex items-center justify-center">
        <EndpointReadinessRatingIcon endpointReadinessRating={endpointReadinessRating} />
      </div>
    </td>
  );
};

export default EndpointUsageTableCell;
