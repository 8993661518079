import React from "react";
import { Button, ButtonVariant, Card, Text, Toggle } from "@merge-api/merge-javascript-shared";
import { ExternalLink } from "lucide-react";

export interface SubLeftNavigationBarCardProps {
  cardTitle: string;
  cardDetails: JSX.Element | string;
  isToggleChecked?: boolean;
  onToggleClick: (enabled: boolean) => void;
}

const SubLeftNavigationBarCard: React.FC<SubLeftNavigationBarCardProps> = ({
  cardTitle,
  cardDetails,
  isToggleChecked,
  onToggleClick,
}: SubLeftNavigationBarCardProps) => {
  return (
    <Card className="flex flex-col px-4 py-3">
      <div className="flex flex-row w-full justify-between items-center whitespace-nowrap mb-1">
        <Text variant="h6" className="text-wrap">
          {cardTitle}
        </Text>
        <Toggle checked={isToggleChecked} onChange={() => onToggleClick(!isToggleChecked)} />
      </div>
      <Button
        variant={ButtonVariant.TextBlue}
        href="https://help.merge.dev/en/articles/8358419-what-is-redact-unmapped-data"
        rightIcon={<ExternalLink size={12} />}
        size="sm"
      >
        Learn more
      </Button>
      <hr className="border-gray-10 my-3 w-full" />
      {cardDetails}
    </Card>
  );
};

export default SubLeftNavigationBarCard;
