import React from "react";
import PersonalInformationCard from "./cards/PersonalInformationCard";
import AvatarCard from "./cards/AvatarCard";
import SecurityCard from "./cards/SecurityCard";
import useAppContext from "../../../context/useAppContext";

function Profile() {
  const { user } = useAppContext();

  return (
    <div className="grid grid-cols-4 gap-6 w-full mb-9">
      <div className="flex flex-col col-span-4 xl:col-span-1 gap-6">
        <AvatarCard />
      </div>
      <div className="flex flex-col col-span-4 xl:col-span-3 gap-6">
        <PersonalInformationCard />
        <SecurityCard />
      </div>
    </div>
  );
}

export default Profile;
