import React, { useState, useEffect } from "react";
import useAppContext from "../../../../../context/useAppContext";
import { DOCS_FIELD_MAPPINGS_TARGET_FIELDS_PATH } from "../../../../../../router/RouterUtils";
import ConfigureOrgMappingModal from "./modal/ConfigureOrgMappingModal";
import { getOrganizationMappingInfo } from "../../../../../../api-client/APIClient";
import { showErrorToast } from "../../../../../shared/Toasts";
import CommonModelMappingInformationCard from "./CommonModelMappingInformationCard";
import {
  Select,
  displayNameForAPICategory,
  Menu,
  MenuItem,
  Typeahead,
  Link,
} from "@merge-api/merge-javascript-shared";
import INTEGRATION_CATEGORY_LIST from "../../../../../../models/Helpers";
import EmptyStateWrapper from "../../../../../shared/EmptyStateWrapper";
import { getAllOverrideModelAndFieldOptionsForCategory } from "../../utils/FieldMappingUtils";
import OrgMappingCreationContext from "./modal/context/OrgMappingCreationModalContext";
import {
  APICategory,
  CommonModelFieldDefinition,
  OrganizationFieldMappingInfo,
  OverrideModelAndFieldOptions,
  OverrideModelAndFieldOptionsValue,
} from "../../../../../../models/Entities";

import { Plus } from "lucide-react";
import { SectionHeaderWrapper } from "../../../../../shared/MergeLayouts";

const CategoryCommonModelsMappingsOverview = () => {
  const [organizationMappingInfo, setOrganizationMappingInfo] =
    useState<OrganizationFieldMappingInfo | null>(null);
  const [categoryCommonModels, setCategoryCommonModels] = useState<
    | {
        label: string;
        key: string;
        hasRemoteData: boolean;
        modelConfigurationId: string | null;
      }[]
    | null
  >(null);
  const [category, setCategory] = useState<APICategory>(INTEGRATION_CATEGORY_LIST[0]);
  const [availableOverrides, setAvailableOverrides] = useState<{
    [common_model_id: string]: CommonModelFieldDefinition[];
  } | null>(null);
  const [openCreationForm, setOpenCreationForm] = useState(false);
  const [showOverrideForm, setShowOverrideForm] = useState(false);
  const [selectedSearchOption, setSelectedSearchOption] = useState<Array<string>>([]);
  const { user } = useAppContext();
  let hasFieldMappings = false;
  const commonModelCardsToShow = organizationMappingInfo
    ? Object.keys(organizationMappingInfo).filter((mappingInfo) => {
        if (selectedSearchOption.length == 0) {
          return mappingInfo;
        }
        return selectedSearchOption.includes(mappingInfo);
      })
    : [];

  commonModelCardsToShow.forEach((commonModel) => {
    const modelMappingInfo = organizationMappingInfo![commonModel];
    if (
      modelMappingInfo.override_common_model_targets.length > 0 ||
      modelMappingInfo.field_mapping_targets.length > 0
    ) {
      hasFieldMappings = true;
    }
  });
  useEffect(() => {
    if (category) {
      const onSuccess = (data: OrganizationFieldMappingInfo) => {
        setOrganizationMappingInfo(data);
        setCategoryCommonModels(
          Object.keys(data)
            .sort()
            .map((commonModelName) => {
              return {
                label: commonModelName,
                key: data[commonModelName]["common_model_id"],
                hasRemoteData: data[commonModelName]["has_remote_data_enabled"],
                modelConfigurationId: data[commonModelName]["model_configuration_id"],
              };
            }),
        );
      };

      const onFailure = () => {
        showErrorToast("Unable to fetch Field Mappings for organization");
      };

      getOrganizationMappingInfo(category, onSuccess, onFailure);
    }
  }, [category]);

  useEffect(() => {
    if (category) {
      const onSuccess = (data: OverrideModelAndFieldOptions) => {
        const modelToOverrideMap = Object.values(data).reduce(
          (
            modelToOverrideMap: {
              [id: string]: CommonModelFieldDefinition[];
            },
            overrideModelInfo: OverrideModelAndFieldOptionsValue,
          ) => {
            const commonModelName = overrideModelInfo.common_model_name;
            modelToOverrideMap[commonModelName] =
              overrideModelInfo.allowed_overridden_fields_configs;

            return modelToOverrideMap;
          },
          {},
        );
        setAvailableOverrides(modelToOverrideMap);
      };
      const onFailure = () => {
        showErrorToast("Unable to fetch Field Mappings for organizaiton");
      };

      getAllOverrideModelAndFieldOptionsForCategory(category, onSuccess, onFailure);
    }
  }, [category]);

  return (
    <>
      {openCreationForm &&
        categoryCommonModels &&
        category &&
        organizationMappingInfo &&
        availableOverrides && (
          <OrgMappingCreationContext.Provider
            value={{
              commonModels: categoryCommonModels,
              availableOverrides: availableOverrides,
              onModalClose: () => {
                setOpenCreationForm(false);
                setShowOverrideForm(false);
              },
              setOrganizationMappingInfo: setOrganizationMappingInfo,
              organizatioMappingInfo: organizationMappingInfo,
              category: category,
            }}
          >
            <ConfigureOrgMappingModal showOverrideForm={showOverrideForm} />
          </OrgMappingCreationContext.Provider>
        )}

      <SectionHeaderWrapper
        title="Configure organization-wide Field Mappings"
        subtitle={
          <>
            Add <strong>new fields</strong> to your Common Models or select{" "}
            <strong>existing fields</strong> to create organization-wide Field Mappings. Select a
            field to add Field Mappings by integration.{" "}
            <Link href={DOCS_FIELD_MAPPINGS_TARGET_FIELDS_PATH} target="_blank">
              Learn more
            </Link>
          </>
        }
      >
        <div className="mt-8">
          {organizationMappingInfo ? (
            <>
              <div className="flex gap-4">
                <div className="w-[178px]">
                  <Select
                    className="bg-white"
                    options={INTEGRATION_CATEGORY_LIST}
                    value={category}
                    clearable={false}
                    getOptionLabel={(option) => {
                      return displayNameForAPICategory(option as APICategory);
                    }}
                    onChange={(_: any, category: APICategory | null) => {
                      if (category) {
                        setCategory(category);
                        setSelectedSearchOption([]);
                      }
                    }}
                  />
                </div>
                <div className="flex-1">
                  <Typeahead
                    showSearchIcon
                    disableCloseOnSelect
                    multiple
                    showPlusFormat
                    onChange={(event: any, selected: string[] | null) => {
                      setSelectedSearchOption(selected ? selected : []);
                    }}
                    options={Object.keys(organizationMappingInfo)}
                    tagsLimit={3}
                  />
                </div>
                <div
                  className={`${
                    availableOverrides == null ? "opacity-35 pointer-events-none" : ""
                  }`}
                >
                  <Menu
                    menuPlacement="bottom-end"
                    ButtonProps={{
                      children: "Field",
                      leftIcon: <Plus size={16} />,
                    }}
                  >
                    <>
                      <MenuItem
                        onClick={() => {
                          setOpenCreationForm(true);
                        }}
                      >
                        Add {user.organization.name}-wide target field
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setOpenCreationForm(true);
                          setShowOverrideForm(true);
                        }}
                      >
                        Add Common Model override field
                      </MenuItem>
                    </>
                  </Menu>
                </div>
              </div>
              <div className="flex flex-column gap-6 my-6">
                {!hasFieldMappings ? (
                  <div className="w-100 flex items-center justify-center text-gray-60">
                    <div className="py-9">
                      <p className="text-xl font-semibold mb-0 ml-auto text-center">No fields</p>
                      <p className="text-sm mt-1 font-medium">Click +Field to add one</p>
                    </div>
                  </div>
                ) : (
                  commonModelCardsToShow.sort().map((commonModelId: string) => {
                    const mappingInfo = organizationMappingInfo[commonModelId];
                    if (
                      mappingInfo.field_mapping_targets.length == 0 &&
                      mappingInfo.override_common_model_targets.length == 0
                    ) {
                      return;
                    }
                    return (
                      <CommonModelMappingInformationCard
                        commonModelName={commonModelId}
                        category={category}
                        mappingInfo={organizationMappingInfo[commonModelId]}
                      />
                    );
                  })
                )}
              </div>
            </>
          ) : (
            <EmptyStateWrapper isSpinner />
          )}
        </div>
      </SectionHeaderWrapper>
    </>
  );
};
export default CategoryCommonModelsMappingsOverview;
