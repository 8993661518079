import React from "react";
import { Switch, Route, Redirect, useParams } from "react-router-dom";
import {
  getLinkedAccountDetailOverviewPathForId,
  getLinkedAccountDetailWebhooksPathForId,
  getLinkedAccountDetailAPIRequestLogsSettingsPathForId,
  getLinkedAccountDetailLogsSettingsPathForId,
  getLinkedAccountDetailWebhookLogsSettingsPathForId,
  getLinkedAccountDetailSettingsPathForId,
  getLinkedAccountDetailFieldMappingsPathForId,
  getLinkedAccountIssuesPathForId,
  getLinkedAccountSelectiveSyncPathForId,
  getLinkedAccountUnmappedTargetsPath,
} from "../../../../router/RouterUtils";
import LinkedAccountLogsTab from "./logs-page/LinkedAccountLogsTab";
import SettingsPage from "./settings/SettingsPage";
import FieldMappingsPage from "./field-mappings/FieldMappingsPage";
import IssuesPage from "./issues/IssuesPage";
import OverviewPage from "./overview/OverviewPage";
import WebhooksPage from "./webhooks/WebhooksPage";
import { LinkedAccount } from "../../../../models/Entities";
import { IntegrationIssue } from "../../IntegrationsManagementEntities";
import LinkedAccountSelectiveSyncPage from "./selective-sync/LinkedAccountSelectiveSyncPage";
import FieldMappingUnmappedTargetsPage from "./field-mappings/unmapped-targets/FieldMappingUnmappedTargetsPage";

type LinkedAccountDetailPageRouterProps = {
  linkedAccount: LinkedAccount | null;
  shouldShowWebhooksTab: boolean;
  shouldShowSelectiveSyncTab: boolean;
  shouldShowFieldMappingsTab: boolean;
  updateLinkedAccount: () => void;
  ongoingIntegrationIssue: IntegrationIssue | null;
  refetchIntegrationIssues: () => void;
  isDataWarehouseAccount: boolean;
};

const LinkedAccountDetailPageRouter = ({
  linkedAccount,
  shouldShowWebhooksTab,
  shouldShowSelectiveSyncTab,
  shouldShowFieldMappingsTab,
  updateLinkedAccount,
  ongoingIntegrationIssue,
  refetchIntegrationIssues,
  isDataWarehouseAccount,
}: LinkedAccountDetailPageRouterProps) => {
  // hooks
  const { id: linkedAccountId } = useParams<Pick<LinkedAccount, "id">>();

  // render
  if (!linkedAccount) return null;

  return (
    <Switch>
      {/* Overview */}
      <Route exact path={getLinkedAccountDetailOverviewPathForId(linkedAccountId)}>
        <OverviewPage
          linkedAccount={linkedAccount}
          ongoingIntegrationIssue={ongoingIntegrationIssue}
          shouldShowWebhooksTab={shouldShowWebhooksTab}
          shouldShowSelectiveSyncTab={shouldShowSelectiveSyncTab}
          shouldShowFieldMappingsTab={shouldShowFieldMappingsTab}
          refetchIntegrationIssues={refetchIntegrationIssues}
          isDataWarehouseAccount={isDataWarehouseAccount}
        />
      </Route>

      {/* Webhooks */}
      <Route exact path={getLinkedAccountDetailWebhooksPathForId(linkedAccountId)}>
        {shouldShowWebhooksTab && <WebhooksPage linkedAccount={linkedAccount} />}
      </Route>

      {/* Logs */}
      <Route
        path={[
          getLinkedAccountDetailAPIRequestLogsSettingsPathForId(linkedAccountId),
          getLinkedAccountDetailLogsSettingsPathForId(linkedAccountId),
          getLinkedAccountDetailWebhookLogsSettingsPathForId(linkedAccountId),
        ]}
      >
        <LinkedAccountLogsTab linkedAccount={linkedAccount} />
      </Route>

      {/* Settings */}
      <Route exact path={getLinkedAccountDetailSettingsPathForId(linkedAccountId)}>
        <SettingsPage linkedAccount={linkedAccount} updateLinkedAccount={updateLinkedAccount} />
      </Route>

      {/* Field Mappings */}
      <Route exact path={getLinkedAccountDetailFieldMappingsPathForId(linkedAccountId)}>
        {shouldShowFieldMappingsTab && <FieldMappingsPage linkedAccount={linkedAccount} />}
      </Route>

      <Route exact path={getLinkedAccountUnmappedTargetsPath(linkedAccountId)}>
        {shouldShowFieldMappingsTab && (
          <FieldMappingUnmappedTargetsPage linkedAccount={linkedAccount} />
        )}
      </Route>

      {/* Issues */}
      <Route exact path={getLinkedAccountIssuesPathForId(linkedAccountId)}>
        <IssuesPage linkedAccount={linkedAccount} />
      </Route>

      {/* Selective Sync */}
      <Route exact path={getLinkedAccountSelectiveSyncPathForId(linkedAccountId)}>
        {shouldShowSelectiveSyncTab && (
          <LinkedAccountSelectiveSyncPage linkedAccount={linkedAccount} />
        )}
      </Route>

      <Redirect to={getLinkedAccountDetailOverviewPathForId(linkedAccountId)} />
    </Switch>
  );
};

export default LinkedAccountDetailPageRouter;
