import React, { useContext } from "react";
import { ArrowLeft } from "lucide-react";
import FieldMappingModalContext from "../../context/FieldMappingModalContext";
import FieldMappingModalTitle from "../../../modal/FieldMappingModalTitle";
import AdvancedFieldMappingSelector from "../../../modal/field-mappings/advanced-field-mappings/AdvancedFieldMappingSelector";
import { TextField } from "@merge-api/merge-javascript-shared";
import CancelAndNextFooter from "../../../../../../../common/CancelAndNextFooter";
import useAdvancedFieldMappingSelection from "./hooks/useAdvancedFieldMappingSelection";
import clsx from "clsx";
import { FieldMappingModalStep } from "../../../../../../../../models/Entities";

const AdvancedFieldMappingSelection = () => {
  const { linkedAccount, onModalClose, setModalStep, selectedRemoteField, jmesPath, setJmesPath } =
    useContext(FieldMappingModalContext);

  const jsonData = selectedRemoteField!.value;
  const { inputError, handleJmesPathInput, jmesPathResult, isJmesPathValid } =
    useAdvancedFieldMappingSelection({
      onJmesPathChange: setJmesPath,
      jsonData,
    });

  return (
    <>
      <FieldMappingModalTitle
        title={`Map a new ${linkedAccount?.end_user.organization_name}-specific target field`}
        onClose={onModalClose}
      />
      <div className="mx-6 mt-4">
        <div className="flex-col w-full">
          <button
            onClick={() => {
              setModalStep(FieldMappingModalStep.REMOTE_FIELD_SELECTION);
            }}
          >
            {" "}
            <div className="items-center">
              <ArrowLeft size={16} className="text-blue-40 font-extrabold" />
              <span className="ml-1 text-blue-40 font-extrabold">Back</span>
            </div>
          </button>
          <div className="truncate text-black font-extrabold">{`Advanced mapping - ${
            selectedRemoteField!.display_name
          }`}</div>
          <div className="mt-3">
            <AdvancedFieldMappingSelector
              jsonString={JSON.stringify(jsonData)}
              handleJmesPath={(jmesPath: any) => {
                handleJmesPathInput(jmesPath);
              }}
            />
          </div>
          <div className="mt-3">
            {" "}
            Select the field inside the list object you want to map, or specify a JSON query below
            using{" "}
            <a href="https://jmespath.org/" target="_blank" rel="noreferrer">
              JMESPath
            </a>
          </div>
          <div className="mt-2">
            <TextField
              placeholder="Example query: array[?type=='value'].result"
              value={!jmesPath ? "" : jmesPath}
              error={inputError}
              onChange={(e) => {
                handleJmesPathInput(e.target.value);
              }}
            />
          </div>
          <div
            className={clsx(
              "mt-2",
              jmesPathResult ? "px-2 bg-gray-0 h-[40px] flex items-center" : "h-[0px]",
            )}
          >
            {jmesPathResult && (
              <div className="flex items-center">
                <span className="font-semibold mr-1">Result:</span>
                <span className="truncate">{jmesPathResult}</span>
              </div>
            )}
          </div>

          <div className="mt-6">
            <CancelAndNextFooter
              isNextDisabled={inputError && !isJmesPathValid}
              onCancel={onModalClose}
              onNext={() => {
                setModalStep(FieldMappingModalStep.REMOTE_FIELD_SELECTION);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdvancedFieldMappingSelection;
