import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { updateOrganizationIntegrationSettings } from "../../../../api-client/organization/OrganizationIntegrationSettingsAPIClient";
import {
  OrganizationConfigStatusField,
  OrganizationIntegrationSettings,
  PartnershipType,
  SyncFrequencyPlan,
} from "../../../../models/Entities";
import find from "lodash/find";
import { snakeCaseToSpacedCapitalized } from "../../../../services";
import IntegrationSettingsConfigParametersSection from "./IntegrationSettingsConfigParametersSection";
import PartnershipCredentialModal from "./settings/partnership-modal/PartnershipCredentialModal";
import IntegrationSettingsRowOverview from "./IntegrationSettingsRowOverview";
import IntegrationSettingsSectionContainer from "./IntegrationSettingsSectionContainer";
import IntegrationSyncFrequencySection from "./IntegrationSyncFrequencySection";
import useAppContext from "../../../context/useAppContext";
import {
  Alert,
  ButtonVariant,
  Dialog,
  TextField,
  TextFieldVariant,
  Text,
} from "@merge-api/merge-javascript-shared";
import { getSanitizedHTML } from "../../../../models/Helpers";

interface Props {
  organizationIntegrationSettings: OrganizationIntegrationSettings;
  onIntegrationToggle: (data: OrganizationIntegrationSettings) => void;
  initiallyExpanded: boolean;
}

const IntegrationSettingsRow = ({
  organizationIntegrationSettings,
  onIntegrationToggle,
  initiallyExpanded,
}: Props) => {
  const { user } = useAppContext();
  const [showPartnershipcCredentialModal, setShowPartnershipCredentialModal] = useState(false);
  const [orgIntSettings, setOrgIntSettings] = useState(organizationIntegrationSettings);
  const integrationSettings = useMemo(() => orgIntSettings, [orgIntSettings]);
  const isPartnershipModalEnabled = user.is_partnership_credential_modal_enabled || false;

  const configStatusFields = integrationSettings.organization_config_status;
  const integration = useMemo(
    () => integrationSettings.integration,
    [integrationSettings.integration],
  );

  const syncPlanMap = user.organization.sync_frequency_plans;
  const syncPlanType =
    syncPlanMap?.[integrationSettings.category as string] ||
    syncPlanMap?.[SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_DEFAULT];
  const isSyncFrequencyBillingPlan = (Object as any)
    .values(SyncFrequencyPlan)
    .includes(syncPlanType);

  const MergeBaseURL = process.env.REACT_APP_BASE_API_URL || "https://api.merge.dev";

  const [fieldBeingModified, setFieldBeingModified] =
    useState<OrganizationConfigStatusField | null>(null);
  const [fieldBeingModifiedValue, setFieldBeingModifiedValue] = useState<string | Blob | null>("");
  const [isSavingFieldBeingModified, setIsSavingFieldBeingModified] = useState(false);
  const humanReadableFieldBeingModifiedName = useMemo(
    () => snakeCaseToSpacedCapitalized(fieldBeingModified?.field_name ?? ""),
    [fieldBeingModified],
  );

  const isConfigIncomplete = find(
    configStatusFields,
    (configField: OrganizationConfigStatusField) => {
      return configField.required && !configField.completed;
    },
  );

  // If the accordion is open
  const [isOpen, setIsOpen] = useState(initiallyExpanded);

  const handleFileUpload = useCallback(
    (value: FileList | null) => {
      const certificateFile = value ? value[0] : null;
      if (certificateFile) {
        const blob = new Blob([certificateFile], {
          type: certificateFile.type,
        });
        setFieldBeingModifiedValue(blob);
      }
    },
    [setFieldBeingModifiedValue],
  );

  const handleFieldBeingModifiedSave = useCallback(
    (event: any) => {
      event.preventDefault();
      if (!fieldBeingModified) {
        return;
      }
      let body: {};
      const fieldData: { [field_name: string]: string | Blob | null } = {};
      fieldData[fieldBeingModified.field_name] = fieldBeingModifiedValue;
      if (fieldBeingModified.field_type == "file") {
        body = fieldData;
      } else {
        body = { organization_config_fields: JSON.stringify(fieldData) };
      }

      setIsSavingFieldBeingModified(true);
      updateOrganizationIntegrationSettings({
        settingsId: integrationSettings.id,
        body: body,
        onUpdate: (data: OrganizationIntegrationSettings) => {
          onIntegrationToggle(data);
          setIsSavingFieldBeingModified(false);
          setFieldBeingModified(null);
        },
      });
    },
    [fieldBeingModified, fieldBeingModifiedValue],
  );

  const getCustomerNotesByPartnership = (notes: string, partnership: PartnershipType) => {
    const partnership_placeholder = "{" + partnership + "}";
    return notes.includes(partnership_placeholder)
      ? notes.substring(
          notes.indexOf(partnership_placeholder) + partnership_placeholder.length,
          notes.lastIndexOf(partnership_placeholder),
        )
      : notes;
  };

  let cleanHTMLString = "";
  if (integration.notes_for_customers) {
    cleanHTMLString = getSanitizedHTML(
      getCustomerNotesByPartnership(
        integration.notes_for_customers,
        integrationSettings.partnership_type,
      ),
    );
  }

  const ADP_WORKFORCE_NOW = "198fe785-0a6c-4098-b546-be0a83dd2455";
  const ADP_RUN = "7941cce2-1db2-4281-8473-c6c4741f0609";
  const ADP_NEXT_GEN = "d2ec4855-7613-41cf-a449-c05344961bc8";

  const ADP_PRODUCTS = [ADP_WORKFORCE_NOW, ADP_RUN, ADP_NEXT_GEN];

  const renderADPComponent = (integrationId: string, integrationSettingsId: string) => {
    if (!integrationId || !integrationSettingsId) return null;

    switch (integrationId) {
      case ADP_WORKFORCE_NOW:
      case ADP_RUN:
      case ADP_NEXT_GEN:
        return (
          <div>
            <Text variant="h6" className="mb-2">
              Subscription create notification URL:
            </Text>
            <Text className="text-gray-60">
              {`${MergeBaseURL}/api/integrations/organization-webhook-listeners/${integrationSettingsId}`}
            </Text>
          </div>
        );
      default:
        return null;
    }
  };

  // Section with configuration notes for the integration
  const configurationNotesSection = integration.notes_for_customers && (
    <IntegrationSettingsSectionContainer id="notes" isPartOfMainCard>
      <div className="flex flex-col px-5 gap-y-4">
        <div>
          <Text variant="h6" className="mb-2">
            Configuration notes
          </Text>
          <span
            className="text-gray-60"
            dangerouslySetInnerHTML={{
              __html: cleanHTMLString,
            }}
          />
        </div>
        {Object.values(ADP_PRODUCTS).includes(integration.id) &&
          renderADPComponent(integration.id, integrationSettings.id)}
        {integration.name === "Rippling" && (
          <div className="mt-3">
            <span className="font-semibold text-base">Webhook URL:</span>
            <br />
            <span className="text-sm select-all text-[#82848c]">
              {`${MergeBaseURL}/api/integrations/organization-webhook-listener/eogU1dU3JZqCo4hohmIC4lb9resKplMPods3xA1qxRczXwCdhSJeng/${organizationIntegrationSettings.organization.id}`}
            </span>
          </div>
        )}
      </div>
    </IntegrationSettingsSectionContainer>
  );

  // If there's a field being edited, this component shows up with ability to edit it
  const editModal = (
    <Dialog
      open={!!fieldBeingModified}
      onClose={() => setFieldBeingModified(null)}
      onPrimaryButtonClick={(e) => handleFieldBeingModifiedSave(e)}
      primaryButtonLoading={isSavingFieldBeingModified}
      primaryButtonVariant={ButtonVariant.PrimaryBlue}
      primaryButtonText="Save"
      onSecondaryButtonClick={() => setFieldBeingModified(null)}
      secondaryButtonText="Cancel"
      title={`Set ${integration.name} ${humanReadableFieldBeingModifiedName}`}
      variant="md"
    >
      <p>{fieldBeingModified?.description}</p>
      {fieldBeingModified?.field_type === "string" && (
        <TextField
          onChange={(e) => setFieldBeingModifiedValue(e.currentTarget.value)}
          placeholder={`Enter your ${integration.name} ${humanReadableFieldBeingModifiedName}`}
          variant={TextFieldVariant.Bordered}
        />
      )}
      {fieldBeingModified?.field_type === "file" && (
        <input
          type="file"
          placeholder={`Upload ${integration.name} ${humanReadableFieldBeingModifiedName}`}
          accept={
            fieldBeingModified?.field_name.toLowerCase() == "SSL certificate file".toLowerCase()
              ? ".pem"
              : ".key,.txt"
          } // TODO: refactor so the files to accept just comes from the backend
          onChange={(e) => handleFileUpload(e.currentTarget.files)}
        />
      )}
    </Dialog>
  );

  /*
   *
   * IF YOU EVER ADD ANY ADDITIONAL CONTENT TO THE INNER CONTENTS OF THE ACCORDION TOGGLE, PLEASE ADD IT TO THE BELOW
   * THIS USED TO NEVER BE THE CASE, BUT WE HAVE INTEGRATIONS THAT DO NOT HAVE CONTENT NOW POST SYNC-FREQUENCY CHANG
   *
   * WE DO NOT RENDER THE CHEVRON IN THE <INTEGRATIONSETTINGSROWOVERVIEW/> COMPONENT IF THERE ISN'T CONTENT
   *
   */
  const doesNotHaveContent =
    !organizationIntegrationSettings.is_beta &&
    !configurationNotesSection &&
    isSyncFrequencyBillingPlan;

  const hasMergeCreds = organizationIntegrationSettings.has_merge_creds;
  const usingPartnerCreds =
    orgIntSettings.partnership_type == PartnershipType.ORGANIZATION_PARTNERSHIP;
  return (
    <>
      {editModal}
      {showPartnershipcCredentialModal && (
        <PartnershipCredentialModal
          hasMergeCreds={hasMergeCreds}
          setOrganizationIntegrationSettings={setOrgIntSettings}
          configStatusFields={configStatusFields}
          organizationIntegrationSettingsID={integrationSettings.id}
          onModalClose={() => {
            setShowPartnershipCredentialModal(false);
          }}
          isAlreadyCompleted={!isConfigIncomplete}
          partnershipType={orgIntSettings.partnership_type}
        />
      )}
      <Accordion key={integration.name} defaultActiveKey={initiallyExpanded ? "0" : undefined}>
        <Card style={{ marginBottom: "20px" }}>
          <Accordion.Toggle
            onClick={() => setIsOpen(!isOpen)}
            as={Card.Body}
            className={classNames(configStatusFields.length > 0 && "clickable")}
            eventKey="0"
            style={{ padding: "16px" }}
          >
            <IntegrationSettingsRowOverview
              settings={integrationSettings}
              onIntegrationToggle={onIntegrationToggle}
              isOpen={isOpen}
              isBeta={organizationIntegrationSettings.is_beta}
              isToggleable={organizationIntegrationSettings.is_toggleable}
              doesNotHaveContent={doesNotHaveContent}
            />
          </Accordion.Toggle>

          {/* Beta Alert */}
          {organizationIntegrationSettings.is_beta && (
            <IntegrationSettingsSectionContainer id="Beta Aside" isPartOfMainCard>
              <Alert color="blue" showWarningIcon className="mx-3">
                <p className="mb-0 text-sm">
                  Beta indicates a newer integration that we&apos;ve tested extensively, but has yet
                  to be widely adopted. Due to this, edge cases can appear, please let us know if
                  you hit any! Once we have sufficient usage, the beta flag will be removed. To
                  request access, please reach out to support@merge.dev
                </p>
              </Alert>
            </IntegrationSettingsSectionContainer>
          )}

          {configurationNotesSection}
          {!isSyncFrequencyBillingPlan && (
            <IntegrationSyncFrequencySection
              settings={integrationSettings}
              onIntegrationToggle={onIntegrationToggle}
            />
          )}
          {organizationIntegrationSettings.is_enabled &&
            (isPartnershipModalEnabled ? (
              integrationSettings.partnership_available && (
                <Accordion.Collapse eventKey="0" key="accordion-section-container">
                  <>
                    <div className="pl-6 px-5 pb-5 border-t border-gray-10 pt-4">
                      {!isConfigIncomplete && usingPartnerCreds ? (
                        <span className="mr-2">Currently using my credentials</span>
                      ) : (
                        hasMergeCreds && (
                          <span className="mr-2">Currently using Merge credentials</span>
                        )
                      )}
                      <button
                        onClick={() => {
                          setShowPartnershipCredentialModal(true);
                        }}
                        className="p-0 border-none bg-transparent text-blue-40 hover:text-blue-60 font-semibold hover:cursor-pointer"
                      >
                        {isConfigIncomplete && !hasMergeCreds
                          ? "Set up organization-level credentials"
                          : "Edit"}
                      </button>
                    </div>
                  </>
                </Accordion.Collapse>
              )
            ) : (
              <IntegrationSettingsConfigParametersSection
                integrationName={integration.name}
                organizationIntegrationSettingsId={integrationSettings.id}
                isCurrentlyPartnered={
                  integrationSettings.partnership_type === PartnershipType.ORGANIZATION_PARTNERSHIP
                }
                isPartnershipCheckboxVisible={integrationSettings.partnership_available}
                modifyField={setFieldBeingModified}
                configStatusFields={configStatusFields}
                onIntegrationToggle={onIntegrationToggle}
              />
            ))}
        </Card>
      </Accordion>
    </>
  );
};

export default IntegrationSettingsRow;
