import styled from "styled-components";
import { palette } from "../../styles/theme/colors";
import DeprecatedH2 from "../../deprecated/DeprecatedH2";

type TextSize = string;

export enum TextType {
  BLACK,
  GRAPHITE,
  BLUE,
  BLUE2,
  SUCCESS,
  WARNING,
  DANGER,
  WHITE,
  MUTED,
  MUTED2,
}

type Props = {
  color?: string;
  fontWeight?: string;
  fontSize?: TextSize;
  lineHeight?: string;
  type?: TextType;
  isBold?: boolean;
  isOverflow?: boolean;
};

const getFontColor = (type: TextType | undefined) => {
  switch (type) {
    case TextType.SUCCESS:
      return "#1A9B87";
    case TextType.WHITE:
      return "#FFFFFF";
    case TextType.MUTED:
      return "#8492A6";
    case TextType.MUTED2:
      return "#C5C7CD";
    case TextType.WARNING:
      return "#D9A800";
    case TextType.DANGER:
      return "#E62837";
    case TextType.BLUE:
      return "#2c7be5";
    case TextType.BLUE2:
      return palette.blue;
    case TextType.BLACK:
    default:
      return "#080808";
  }
};

const MergeText = styled.span<Props>`
  font-weight: ${(props) => (props.isBold ? "600" : props.fontWeight ?? "normal")};
  font-size: ${(props) => props.fontSize ?? "12px"};
  line-height: ${(props) => props.lineHeight ?? "20px"};
  color: ${(props) => props.color ?? getFontColor(props.type)};
  margin-left: 0px;
`;

export default MergeText;
