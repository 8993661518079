import React from "react";
import { LOG_DIRECTIONS } from "../../../../../../constants";
import { CodeBlockFileDownloadInfo } from "../../../../../shared/MergeCodeBlock";
import {
  isScraperLogEntry,
  isWebhookLogEntry,
  LogType,
  ScraperLogEntry,
} from "../../../../IntegrationsManagementEntities";
import { ScraperLogSidePanelResponseSection } from "../../ScraperLogEntrySidePanel";
import { CollapsableSection } from "./LogSidePanelBody";
import { LogSidePanelBodySection } from "./LogSidePanelBodySection";
import { LogSidePanelBodySectionHeader } from "./LogSidePanelBodySectionHeader";
import { Card } from "@merge-api/merge-javascript-shared";
import Divider from "../../../../../shared/Divider";
import MergeMarkAvatar from "./MergeMarkAvatar";

type Props = {
  logEntry: LogType;
  organizationBodyImage: JSX.Element;
  integrationImage: JSX.Element;
  hasDynamicResponseBody: boolean;
  responseBody: string | undefined;
  downloadInfo?: CodeBlockFileDownloadInfo;
  isRedacted?: boolean;
};

export const LogSidePanelResponseSection = ({
  logEntry,
  organizationBodyImage,
  integrationImage,
  hasDynamicResponseBody,
  responseBody,
  downloadInfo,
  isRedacted,
}: Props) => {
  const isOutbound = logEntry.direction === LOG_DIRECTIONS.LOG_OUTBOUND;
  const isWebhookLog = isWebhookLogEntry(logEntry);
  const isScraperLog = isScraperLogEntry(logEntry);

  let responseImage = <MergeMarkAvatar />;
  if (isOutbound) {
    responseImage = isWebhookLog ? organizationBodyImage : integrationImage;
  }

  const shouldShowResponseHeaders =
    !isScraperLog && logEntry.response_headers && Object.keys(logEntry.response_headers).length > 0;

  return (
    <Card size="lg" className="flex flex-col w-full h-fit">
      <LogSidePanelBodySectionHeader
        title="Response"
        subtitle={logEntry.url}
        image={responseImage}
        response_status={logEntry.response_code}
      />

      {isScraperLog ? (
        <>
          <CollapsableSection title="Response parameters" numberOfElements={undefined}>
            <ScraperLogSidePanelResponseSection logEntry={logEntry as ScraperLogEntry} />
          </CollapsableSection>
          <Divider noMargin />
          <LogSidePanelBodySection
            title="Body"
            data={responseBody}
            isBody
            isRequest={false}
            downloadInfo={downloadInfo}
            isRoundedBottom
          />
        </>
      ) : (
        <>
          {shouldShowResponseHeaders && (
            <LogSidePanelBodySection
              title="Headers"
              data={logEntry.response_headers}
              isRequest={false}
              isCollapsedByDefault
              isRoundedBottom={!(logEntry.response_body || hasDynamicResponseBody)}
            />
          )}
          {shouldShowResponseHeaders && (logEntry.response_body || hasDynamicResponseBody) && (
            <Divider noMargin />
          )}
          {(logEntry.response_body || hasDynamicResponseBody) && (
            <LogSidePanelBodySection
              title="Body"
              data={responseBody}
              isRedacted={isRedacted}
              isBody
              isRequest={false}
              downloadInfo={downloadInfo}
              isRoundedBottom
            />
          )}
        </>
      )}
    </Card>
  );
};
