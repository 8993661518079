import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col, Accordion } from "react-bootstrap";
import classNames from "classnames";
import ClickableContainer from "../../../../shared/ClickableContainer";
import { XSIconContainer } from "../../../configuration/common-models/FieldToggle";
import CenteredVerticalLineCol from "../../../configuration/common-models/CenteredVerticalLineCol";
import { CommonModelFieldLevelMapping } from "../../../../../models/Entities";
import { mapTypeToFeatherIconClassName } from "../../../configuration/common-models/FieldToggle";
import LinkedAccountSettingToggle, {
  LinkedAccountSettingToggleDropdownOption,
} from "../overview/LinkedAccountSettingToggle";
import AnimatedChevron from "../../../configuration/common-models/AnimatedChevron";
import { firstLetterUpperCase } from "../../../../../services";

const StyledCenteredVerticalLineCol = styled(CenteredVerticalLineCol)`
  width: 24px;
`;

const StyledXSIconContainer = styled(XSIconContainer)`
  margin-left: 2px;
`;

type ScopesFieldRowProps = {
  fieldName: string;
  fieldDescription: string | null;
  fieldType: string | undefined;
  linkedAccountCommonModelActions: string[];
  linkedAccountCommonModelFieldScope: CommonModelFieldLevelMapping;
  commonModelName: string;
  linkedAccountFieldOverrides: string[];
  isLastRow: boolean;
  changeState: (
    commonModelName: string,
    fieldName: string,
    linkedAccountCommonModelActions: string[],
    shouldResetOverrideActions: boolean,
    shouldResetFieldOverride: boolean,
  ) => void;
  hideScopesToggle?: boolean;
};

const ScopesFieldRow = ({
  fieldName,
  fieldDescription,
  fieldType,
  linkedAccountCommonModelActions,
  linkedAccountCommonModelFieldScope,
  commonModelName,
  linkedAccountFieldOverrides,
  isLastRow,
  changeState,
  hideScopesToggle = false,
}: ScopesFieldRowProps) => {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  const updateFieldLevelScope = (scopeKey: LinkedAccountSettingToggleDropdownOption) => {
    switch (scopeKey) {
      case LinkedAccountSettingToggleDropdownOption.ENABLE:
        changeState(commonModelName, fieldName, linkedAccountCommonModelActions, false, false);
        break;
      case LinkedAccountSettingToggleDropdownOption.RESET:
        changeState(commonModelName, fieldName, [], false, true);
        break;
      default:
        changeState(commonModelName, fieldName, [], false, false);
        break;
    }
  };

  return (
    <Row className="flex-nowrap m-0">
      <StyledCenteredVerticalLineCol
        className="col-auto px-0 ml-4"
        $verticalLineConfig={isLastRow ? "top-half" : "all"}
      >
        <Row className="mx-0 h-100">
          <Col className="px-0 my-auto">
            <StyledXSIconContainer $iconColumnWidthInPixels={24}>
              <i className={classNames("fe", mapTypeToFeatherIconClassName(fieldType))} />
            </StyledXSIconContainer>
          </Col>
        </Row>
      </StyledCenteredVerticalLineCol>
      <Col className="pr-0 mr-3 ml-4">
        <Row className="items-center flex-nowrap border-t-[1px] border-gray-10">
          <div className="flex-none pt-2 pb-2 pr-6 pl-0 my-auto min-w-fit xl:min-w-[400px] xl:w-1/4 w-1/2">
            {fieldDescription ? (
              <>
                <ClickableContainer
                  onClick={() => {
                    setIsDescriptionExpanded(!isDescriptionExpanded);
                  }}
                >
                  <div className="flex items-center">
                    <span className="text-[12px] font-normal font-mono">{fieldName}</span>
                    <AnimatedChevron className="text-[10px] ml-1" $isUp={isDescriptionExpanded} />
                    <span className="text-gray-60 ml-2 text-[10px] font-medium">
                      {firstLetterUpperCase(fieldType || "")}
                    </span>
                  </div>
                </ClickableContainer>
                <Accordion activeKey={isDescriptionExpanded ? "0" : undefined}>
                  <Accordion.Collapse eventKey="0">
                    <div className="text-[12px] text-gray-60 mt-1 font-inter text-wrap">
                      {fieldDescription}
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              </>
            ) : (
              <div className="flex items-center">
                <span className="text-[12px] font-mono font-normal">{fieldName}</span>
              </div>
            )}
          </div>
          <div className="flex-none xl:w-1/4 w-1/2">
            {!hideScopesToggle && (
              <LinkedAccountSettingToggle
                onDropdownSelected={updateFieldLevelScope}
                isSettingEnabled={linkedAccountCommonModelFieldScope.isEnabled}
                isDefaultOverridden={linkedAccountFieldOverrides.includes(fieldName)}
                includeToggleIcon
              />
            )}
          </div>
        </Row>
      </Col>
    </Row>
  );
};
export default ScopesFieldRow;
