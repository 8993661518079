import React from "react";
import { Text } from "@merge-api/merge-javascript-shared";
import { ChevronRight } from "lucide-react";

type MappingOverviewRowProps = {
  rowHeader: React.ReactNode;
  fieldName: string;
  description: string;
  numberOfMappings: number;
  typeOfMapping: string;
  onRowClick: () => void;
};
const MappingOverviewRow = ({
  rowHeader,
  fieldName,
  description,
  numberOfMappings,
  typeOfMapping,
  onRowClick,
}: MappingOverviewRowProps) => {
  return (
    <button className="block w-100 p-0" onClick={onRowClick}>
      <div className="flex gap-5 items-center hover:cursor-pointer hover:bg-gray-0  border-t border-gray-10 py-5 px-6">
        <div className="flex flex-grow flex-col gap-2">
          <div className="flex justify-start">{rowHeader}</div>
          <div className="flex flex-col text-left">
            <Text variant="lg-mono">{fieldName}</Text>
            <Text variant="sm" className="text-gray-60">
              {description}
            </Text>
          </div>
        </div>
        <div className="flex justify-end items-center whitespace-nowrap gap-x-6">
          <Text variant="pre-title" className={`${numberOfMappings == 0 ? "text-gray-60" : " "}`}>
            {numberOfMappings} {typeOfMapping == "field-mappings" ? "mapping" : "override"}
            {numberOfMappings == 1 ? "" : "s"}
          </Text>
          <ChevronRight size={16} className="text-gray-60" />
        </div>
      </div>
    </button>
  );
};
export default MappingOverviewRow;
