import React, { useState, useCallback } from "react";
import { Card, Text, Button, ButtonVariant, Badge } from "@merge-api/merge-javascript-shared";
import useAppContext from "../../../../context/useAppContext";
import { showErrorToast, showSuccessToast } from "../../../../shared/Toasts";
import { fetchWithAuth, fetchCurrentUser } from "../../../../../api-client/APIClient";
import ChangePasswordModal from "../modals/ChangePasswordModal";
import TwoFAModal from "../modals/TwoFAModal";

const SecurityCard = () => {
  // CONTEXT
  const { user, setUser } = useAppContext();

  // STATE
  const [configURL2FA, setConfigURL2FA] = useState<null | string>(null);

  const [isModifying2FA, setIsModifying2FA] = useState<boolean>(false);

  const [showChangePasswordModal, setShowChangePasswordModal] = useState<boolean>(false);
  const [showTwoFAModal, setShowTwoFAModal] = useState<boolean>(false);

  // HANDLERS
  const onClickEnable2FA = useCallback(() => {
    fetchWithAuth({
      path: "/users/totp/create",
      method: "POST",
      onResponse: (data) => {
        setConfigURL2FA(data.config_url);
      },
      onError: () => {
        showErrorToast("Oops, something went wrong! Please try again.");
      },
    });
    setShowTwoFAModal(true);
  }, []);

  const onClickModify2FA = useCallback(() => {
    setIsModifying2FA(true);
    fetchWithAuth({
      path: "/users/totp/modify",
      method: "POST",
      onResponse: (data) => {
        setConfigURL2FA(data.config_url);
      },
      onError: () => {
        showErrorToast("Oops, something went wrong! Please try again.");
      },
    });
    setShowTwoFAModal(true);
  }, []);

  const onClickDisable2FA = useCallback(() => {
    fetchWithAuth({
      path: "/users/totp/disable",
      method: "POST",
      onResponse: () => {
        fetchCurrentUser((response) => {
          setUser(response);
          showSuccessToast("Two-factor authentication disabled successfully!");
        });
      },
      onError: () => {
        showErrorToast("Failed to disable two-factor authentication. Please try again.");
      },
    });
  }, [user]);

  return (
    <>
      <Card variant="shadow">
        <div className="w-full flex flex-col">
          {/* header */}
          <div className="px-6 py-5 border-b-solid border-b-gray-10 border-b-[1px]">
            <Text variant="h5">Security</Text>
          </div>

          {/* body */}
          <div className="flex flex-col px-6 py-5 justify-between gap-y-5">
            {/* password */}
            <div className="flex flex-row justify-between items-center">
              <Text variant="h6">Password</Text>
              <div className="flex flex-row gap-3">
                <Button
                  variant={ButtonVariant.SecondaryBlue}
                  size="sm"
                  onClick={() => setShowChangePasswordModal(true)}
                >
                  Change password
                </Button>
                <ChangePasswordModal
                  isShown={showChangePasswordModal}
                  onHide={() => setShowChangePasswordModal(false)}
                  user={user}
                />
              </div>
            </div>

            {/* 2fa */}
            <div className="flex flex-row gap-12 items-center">
              <div className="flex flex-col">
                <div className="flex flex-row items-center gap-2">
                  <Text variant="h6">Two-factor authentication</Text>
                  {user.is_two_factor_enabled && <Badge color="teal">Enabled</Badge>}
                </div>
                <Text>
                  Merge supports 2FA with an authenticator app. We recommend using Google
                  Authenticator, which is available on iOS and Android
                </Text>
              </div>
              {user.is_two_factor_enabled && !user.organization.is_mfa_required ? (
                <Button variant={ButtonVariant.DangerFilled} size="sm" onClick={onClickDisable2FA}>
                  Disable 2FA
                </Button>
              ) : user.is_two_factor_enabled && user.organization.is_mfa_required ? (
                <Button size="sm" variant={ButtonVariant.SecondaryBlue} onClick={onClickModify2FA}>
                  Change 2FA device
                </Button>
              ) : configURL2FA ? (
                <Button
                  variant={ButtonVariant.TertiaryWhite}
                  size="sm"
                  onClick={() => setConfigURL2FA(null)}
                >
                  Cancel
                </Button>
              ) : (
                <Button size="sm" onClick={onClickEnable2FA}>
                  Set up
                </Button>
              )}
              <TwoFAModal
                isShown={showTwoFAModal}
                onHide={() => {
                  setShowTwoFAModal(false);
                  setConfigURL2FA(null);
                }}
                isModifying2FA={isModifying2FA}
                configURL2FA={configURL2FA}
                setConfigURL2FA={setConfigURL2FA}
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default SecurityCard;
