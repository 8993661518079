import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { fetchWithAuth } from "../../../api-client/APIClient";
import { useLocation, useHistory } from "react-router-dom";
import { ISSUE_STATUSES } from "../../../constants";
import { convertStringToDate } from "../../../services";
import { useQuery } from "../../../router/RouterUtils";
import MergeDatePicker from "../../shared/MergeDatePicker";
import queryString from "query-string";
import styled from "styled-components";
import { Typeahead } from "@merge-api/merge-javascript-shared";
import IssueFilter from "../linked-accounts/detail-page/issues/IssuesFilter";

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
`;

const InputRow = styled(Row)`
  row-gap: 12px;

  @media (min-width: 1200px) {
    row-gap: 16px;
  }
`;

export const InputColDeprecated = styled(Col)`
  padding-right: 0;

  @media (min-width: 768px) {
    &:not(:nth-child(even)) {
      padding-right: 12px;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  @media (min-width: 992px) {
    &:not(:last-child) {
      padding-right: 12px;
    }
  }

  @media (min-width: 1200px) {
    &:not(:last-child) {
      padding-right: 16px;
    }
  }
`;

export const InputCol = styled(Col)`
  padding-right: 0;

  @media (min-width: 768px) {
    &:not(:nth-child(even)) {
      padding-right: 12px;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  @media (min-width: 992px) {
    &:not(:last-child) {
      padding-right: 12px;
    }
  }

  @media (min-width: 1200px) {
    &:not(:last-child) {
      padding-right: 16px;
    }
  }

  // New styles for small and medium screens in free-gated version
  @media (max-width: 991px) {
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }
  }

  // Additional styling for medium screens
  @media (min-width: 768px) and (max-width: 991px) {
    &:nth-child(2) {
      margin-top: 0;
    }
  }
`;

interface Props {
  isLinkedAccounts?: boolean;
  isFieldMappingsLinkedAccounts?: boolean;
  isIntegrationLogs?: boolean;
  isIntegrationIssues?: boolean;
  isTestAccounts?: boolean;
  setIsTestAccounts?: (isTestAccounts: boolean) => void;
  setParamsPath: (data: string) => void;
}

const IntegrationsManagementFilter = ({
  isLinkedAccounts,
  isFieldMappingsLinkedAccounts,
  isIntegrationIssues,
  setParamsPath,
  isTestAccounts,
  setIsTestAccounts,
}: Props) => {
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();
  const statusQuery = query.get("status");
  const showResolvedIssuesDefault =
    statusQuery?.toUpperCase() === ISSUE_STATUSES.ISSUE_STATUS_RESOLVED.toUpperCase(); // Don't show resolved issues by default
  const integrationQuery =
    query.get("integration__name") ?? query.get("linked_account__integration__name");
  const endUserOrganizationQuery =
    query.get("end_user__organization_name") ??
    query.get("linked_account__end_user__organization_name");
  const startDateQuery = convertStringToDate(query.get("start_date"));
  const endDateQuery = convertStringToDate(query.get("end_date"));
  const [allIntegrations, setAllIntegrations] = useState<Array<string>>([]);
  const [allEndUserOrganizations, setAllEndUserOrganizations] = useState<Array<string>>([]);

  const [selectedIntegration, setSelectedIntegration] = useState<string | null>(integrationQuery);
  const [selectedEndUserOrganization, setSelectedEndUserOrganization] = useState<string | null>(
    endUserOrganizationQuery,
  );

  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(startDateQuery);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(endDateQuery);

  const [displayMutedIssues, setDisplayMutedIssues] = useState(false);
  const [displayResolvedIssues, setDisplayResolvedIssues] =
    useState<boolean>(showResolvedIssuesDefault);

  useEffect(() => {
    const paramsPath = getParamsPath();

    setParamsPath(paramsPath);

    history.replace({
      pathname: location.pathname,
      search: paramsPath,
    });
  }, [
    selectedIntegration,
    selectedEndUserOrganization,
    selectedStartDate,
    selectedEndDate,
    displayMutedIssues,
    displayResolvedIssues,
  ]);

  function getParamsPath() {
    if (isLinkedAccounts || isFieldMappingsLinkedAccounts) {
      const completeProductionDuplicatesOf = query.get("complete_production_duplicates_of");
      return queryString.stringify({
        ...(isIntegrationIssues && !displayResolvedIssues
          ? { status: ISSUE_STATUSES.ISSUE_STATUS_ONGOING.toUpperCase() }
          : {}),
        ...(selectedIntegration ? { integration__name: selectedIntegration } : {}),
        ...(selectedEndUserOrganization
          ? { end_user__organization_name: selectedEndUserOrganization }
          : {}),
        ...(selectedStartDate ? { start_date: new Date(selectedStartDate).toISOString() } : {}),
        ...(selectedEndDate ? { end_date: new Date(selectedEndDate).toISOString() } : {}),
        ...(completeProductionDuplicatesOf
          ? {
              complete_production_duplicates_of: completeProductionDuplicatesOf,
            }
          : {}),
      });
    } else {
      return queryString.stringify({
        ...(isIntegrationIssues &&
          !displayResolvedIssues && {
            status: ISSUE_STATUSES.ISSUE_STATUS_ONGOING.toUpperCase(),
          }),
        ...(selectedIntegration ? { linked_account__integration__name: selectedIntegration } : {}),
        ...(selectedEndUserOrganization
          ? {
              linked_account__end_user__organization_name: selectedEndUserOrganization,
            }
          : {}),
        ...(selectedStartDate ? { start_date: new Date(selectedStartDate).toISOString() } : {}),
        ...(selectedEndDate ? { end_date: new Date(selectedEndDate).toISOString() } : {}),
        ...(displayMutedIssues ? { include_muted: displayMutedIssues } : {}),
      });
    }
  }

  const orgFilterURIPath = `/integrations/linked-accounts/end-user-organization-names${
    isLinkedAccounts ? (isTestAccounts ? "?is_test_account=True" : "?is_test_account=False") : ""
  }`;

  useEffect(() => {
    fetchWithAuth({
      path: "/integrations/names",
      method: "GET",
      onResponse: (data) => {
        setAllIntegrations(data);
      },
    });
    fetchWithAuth({
      path: orgFilterURIPath,
      method: "GET",
      onResponse: (data) => {
        setAllEndUserOrganizations(data);
      },
    });
  }, [history]);

  return (
    <div
      className={`grid grid-cols-1 ${
        isIntegrationIssues ? "lg:grid-cols-5" : "lg:grid-cols-3"
      } gap-6 mb-9 lg:mb-6`}
    >
      <MergeDatePicker
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        onStartDateSet={setSelectedStartDate}
        onEndDateSet={setSelectedEndDate}
      />
      <Typeahead
        placeholder="Search integrations..."
        options={allIntegrations}
        value={selectedIntegration}
        onChange={(_, integration: string | string[] | null) => {
          setSelectedIntegration(integration as string | null);
        }}
      />
      <Typeahead
        placeholder="Search organizations..."
        options={allEndUserOrganizations}
        value={selectedEndUserOrganization}
        onChange={(_, organization: string | string[] | null) =>
          setSelectedEndUserOrganization(organization as string | null)
        }
      />
      {isIntegrationIssues && (
        <div className="col-span-1 lg:col-span-2 gap-6 flex flex-row">
          <IssueFilter
            label="Show muted"
            isSelected={displayMutedIssues}
            onClick={() => {
              setDisplayMutedIssues(!displayMutedIssues);
            }}
          />
          <IssueFilter
            label="Show resolved"
            isSelected={displayResolvedIssues}
            onClick={() => {
              setDisplayResolvedIssues(!displayResolvedIssues);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default IntegrationsManagementFilter;
