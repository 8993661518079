import React from "react";
import MergeTable from "../../../../../../shared/MergeTable";
import { PendingInvite } from "../../../../../../../models/Entities";
import { UserAvatar } from "../../../../../../shared/MergeAvatars";
import { Text, Menu, MenuItem, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { ReactComponent as FrownyFace } from "../../../../../../../../src/assets/svg/frowny-face.svg";
import useAppContext from "../../../../../../context/useAppContext";
import { fetchWithAuth } from "../../../../../../../api-client/APIClient";
import { showSuccessToast } from "../../../../../../shared/Toasts";
import { MoreVertical } from "lucide-react";

type PendingTableProps = {
  pendingInvites?: PendingInvite[];
  onRefetchPendingUsers: () => void;
};

const PendingInvitesTable = ({ pendingInvites = [], onRefetchPendingUsers }: PendingTableProps) => {
  // HOOKS
  const { isUserPrivileged: isMeUserPrivileged } = useAppContext();

  // HANDLERS
  const deletePendingInvites = (pendingInvite: PendingInvite) => {
    fetchWithAuth({
      path: "/organizations/users/invites/" + pendingInvite.id,
      method: "DELETE",
      onResponse: () => {
        showSuccessToast("Successfully deleted pending invite for " + pendingInvite.email + "!");
        onRefetchPendingUsers();
      },
    });
  };

  return (
    <>
      {pendingInvites.length > 0 ? (
        <MergeTable
          noBoxShadow
          hasMorePaddingOnFirstElement
          hover={false}
          header={
            <>
              <th scope="col">Email address</th>
            </>
          }
          content={pendingInvites.map((pendingInvite) => (
            <tr>
              <td>
                <div className="flex flex-row items-center">
                  <UserAvatar email={pendingInvite.email} />
                  <Text className="ml-4">{pendingInvite.email}</Text>
                </div>
              </td>
              <td className="w-24">
                {isMeUserPrivileged && (
                  <Menu
                    ButtonProps={{
                      children: <MoreVertical size={16} />,
                      variant: ButtonVariant.IconShadowHidden,
                      size: "md",
                    }}
                  >
                    <MenuItem
                      onClick={() => deletePendingInvites(pendingInvite)}
                      className="text-red-60"
                    >
                      Delete invite
                    </MenuItem>
                  </Menu>
                )}
              </td>
            </tr>
          ))}
        />
      ) : (
        <MergeTable
          noBoxShadow
          hover={false}
          content={
            <div className="flex flex-col items-center gap-4 py-8">
              <FrownyFace />
              <Text className="text-gray-60" variant="h4">
                No pending invites
              </Text>
            </div>
          }
        />
      )}
    </>
  );
};

export default PendingInvitesTable;
