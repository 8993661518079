import React, { useState, useEffect } from "react";
import { Card, Text, Chips, Alert, Link } from "@merge-api/merge-javascript-shared";
import { Elements } from "@stripe/react-stripe-js";
import { Info } from "lucide-react";
import stripePromise from "./components/StripePromise";
import { BILLING_PATH } from "../../../../router/RouterUtils";
import { paymentOptions } from "../../../../constants";
import { LAUNCH_GUARDRAILS_LINKED_ACCOUNT_LIMIT } from "./BillingModels";
import { PaymentMethodTypes } from "./BillingModels";
import { Stripe } from "@stripe/stripe-js";
import BankAccountSetupForm from "./BankAccountSetupForm";
import CreditCardSetupForm from "./CreditCardSetupForm";
import { useLocation } from "react-router-dom";
import useAppContext from "../../../context/useAppContext";

export interface LinkedAccountProdCount {
  production_account_count: number;
}

type navigateState = {
  fromUpgradeButton?: boolean;
};

const AddPaymentMethod: React.FC = () => {
  const location = useLocation<navigateState>();
  const isFromUpgradeButton = location.state;
  const { user } = useAppContext();
  const freeAccountGated = user?.is_free_account_gating_enabled ?? false;

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(
    PaymentMethodTypes.BANK_ACCOUNT,
  );

  const [stripe, setStripe] = useState<Stripe | null | undefined>(null);
  useEffect(() => {
    async function loadStripe() {
      const value = await stripePromise;
      setStripe(value);
    }
    loadStripe();
  }, []);

  const handleButtonClick = (value: string) => {
    setSelectedPaymentMethod(value);
  };

  const modifiedPaymentOptions = paymentOptions.map((option) => ({
    ...option,
    selected: selectedPaymentMethod === option.value,
    onClick: () => handleButtonClick(option.value),
  }));

  return (
    <div className="flex flex-col max-w-3xl mx-auto p-4 gap-y-6">
      <div className="flex flex-col space-y-2 mt-6 items-start">
        <Text variant="h6" className="font-semibold text-blue-40">
          <Link href={BILLING_PATH}>← Back to billing</Link>
        </Text>
      </div>
      <Text variant="h2" className="font-semibold">
        {isFromUpgradeButton.fromUpgradeButton ? "Upgrade to Launch" : "Add payment method"}
      </Text>

      {location?.state?.fromUpgradeButton && (
        <Card>
          <div className="flex flex-row border-b-[1px] border-gray-10 px-5 py-4">
            <Text variant="h5">Plan details</Text>
          </div>
          <div className="flex flex-col pt-4 pb-5 px-5 gap-y-4">
            <div className="flex flex-col">
              <Text variant="h3">$650 / month</Text>
              <Text className="text-gray-60">for 10 Production Linked Accounts</Text>
            </div>
            {user &&
              user?.organization?.static_production_linked_account_count &&
              user?.organization?.static_production_linked_account_count > 10 && (
                <>
                  <div className="flex flex-col">
                    <Text variant="h3">
                      + $
                      {(user?.organization?.static_production_linked_account_count -
                        LAUNCH_GUARDRAILS_LINKED_ACCOUNT_LIMIT) *
                        65}{" "}
                      / month
                    </Text>
                    <Text className="text-gray-60">
                      for{" "}
                      {user?.organization?.static_production_linked_account_count -
                        LAUNCH_GUARDRAILS_LINKED_ACCOUNT_LIMIT}{" "}
                      additional Production Linked Accounts
                    </Text>
                  </div>
                  <Alert color="blue" icon={<Info size={16} />}>
                    Interested in bulk discounts?<Link>Talk to our team</Link> for other options.
                  </Alert>
                </>
              )}
            <div className="flex flex-col gap-y-2">
              <div className="flex flex-row justify-between px-4 py-2 bg-gray-0 rounded-lg">
                <Text variant="h6">Current total of Production Linked Accounts</Text>
                <Text variant="md">
                  <Text className="text-blue-40">
                    {user?.organization?.static_production_linked_account_count}{" "}
                  </Text>
                  / 10
                </Text>
              </div>

              <Text variant="sm" className="text-gray-60">
                Upgrading to Launch will start syncing any Linked Accounts that were connected
                beyond the Free plan limit of 3. If you don't want these Linked Accounts synced, you
                can delete them in{" "}
                <Link href="/linked-accounts/accounts" target="_blank">
                  Linked Accounts
                </Link>
                .
              </Text>
            </div>
          </div>
        </Card>
      )}

      <Card>
        <div className="flex flex-row border-b-[1px] border-gray-10 px-5 py-4">
          <Text variant="h5">Payment</Text>
        </div>
        <div className="flex flex-col px-5 pt-4 pb-5 gap-y-4">
          <Text variant="h6">Select payment method</Text>
          <div className="flex flex-row">
            <Chips className="mb-2" options={modifiedPaymentOptions} />
          </div>
          <hr className="border-gray-10 border-[1px] w-full my-0" />

          {stripe ? (
            <Elements stripe={stripe}>
              {selectedPaymentMethod === PaymentMethodTypes.BANK_ACCOUNT && (
                <BankAccountSetupForm
                  isFromUpgrade={isFromUpgradeButton}
                  freeAccountGated={freeAccountGated}
                />
              )}
              {selectedPaymentMethod === PaymentMethodTypes.CREDIT_CARD && (
                <CreditCardSetupForm
                  isFromUpgrade={isFromUpgradeButton}
                  freeAccountGated={freeAccountGated}
                />
              )}
            </Elements>
          ) : (
            <Text>An unknown error occurred. Please try again later or contact us.</Text>
          )}
        </div>
      </Card>

      <Text variant="sm" className="text-gray-60">
        By clicking Confirm, you authorize Merge to debit the bank account specified above for any
        amount owed for charges arising from your use of Merge's services and/or purchase of
        products from Merge, pursuant to Merge's website and terms, until this authorization is
        revoked. You may amend or cancel this authorization at any time by providing notice to Merge
        with 30 (thirty) days notice.
      </Text>
    </div>
  );
};

export default AddPaymentMethod;
