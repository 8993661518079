import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Box, Globe, User } from "lucide-react";
import FieldMappingModalContext from "../context/FieldMappingModalContext";
import FieldMappingModalTitle from "../../modal/FieldMappingModalTitle";
import SelectionRow from "../../../../../../common/SelectionRow";
import {
  CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH,
  navigateToLinkedAccountUnmappedTargetsPage,
} from "../../../../../../../router/RouterUtils";
import { FieldMappingModalStep, FieldMappingPathways } from "../../../../../../../models/Entities";

const FieldMappingOptionSelection = () => {
  const {
    linkedAccount,
    setModalStep,
    onModalClose,
    fieldMappingTargets,
    fieldMappingInstances,
    setSelectedFieldMappingPathway,
  } = useContext(FieldMappingModalContext);
  const history = useHistory();
  if (!linkedAccount) {
    return <></>;
  }
  const doesHaveOrgTargetsDefined = fieldMappingTargets.length > 0;
  const doesOrgHaveInstances = fieldMappingInstances.length > 0;
  return (
    <>
      <FieldMappingModalTitle title="Add Mapping" onClose={onModalClose} />
      <div className="flex px-6 mt-6 flex-column gap-4">
        <SelectionRow
          key="remote-field-selection-la-mapping"
          icon={<User size={16} className="text-yellow-50" />}
          title="Map additional Linked Account-specific target field"
          subtitle={`Create a new target field for this Linked Account and connect it to a ${linkedAccount.integration.name} field`}
          onClick={() => {
            setModalStep(FieldMappingModalStep.REMOTE_FIELD_SELECTION);
            setSelectedFieldMappingPathway(FieldMappingPathways.LINKED_ACCOUNT_SPECIFIC_MAPPING);
          }}
        />
        <SelectionRow
          key="map-existing-target"
          disable={!doesHaveOrgTargetsDefined}
          icon={<Globe size={16} className="text-orange-60" />}
          title={`Map existing ${linkedAccount.organization.name} target fields`}
          subtitle={
            doesHaveOrgTargetsDefined ? (
              `Connect predefined target fields to ${linkedAccount.integration.name} fields`
            ) : doesOrgHaveInstances ? (
              <>
                All your organization-wide target fields have been mapped. See{" "}
                <a href={CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH}>Advanced Configuration</a>{" "}
                to add fields.
              </>
            ) : (
              <>
                No organization-wide target fields have been defined. See{" "}
                <a href={CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH}>Advanced Configuration</a>{" "}
                to add fields.
              </>
            )
          }
          onClick={() => {
            navigateToLinkedAccountUnmappedTargetsPage(history, linkedAccount.id);
            setModalStep(FieldMappingModalStep.MAP_EXISTING_TARGET);
          }}
        />
        <SelectionRow
          key="remote-field-selection-common-model-override-mapping"
          icon={<Box size={16} className="text-blue-50" />}
          title="Override Merge Common Model field"
          subtitle={`Connect an existing Merge Common Model field to a ${linkedAccount.integration.name} field`}
          onClick={() => {
            setModalStep(FieldMappingModalStep.REMOTE_FIELD_SELECTION);
            setSelectedFieldMappingPathway(FieldMappingPathways.COMMON_MODEL_OVERRIDE);
          }}
        />
      </div>
    </>
  );
};

export default FieldMappingOptionSelection;
