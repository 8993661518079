import React from "react";
import { Text } from "@merge-api/merge-javascript-shared";
import { Tooltip } from "@merge-api/merge-javascript-shared";
import { Info } from "lucide-react";

type LinkedAccountsCountsCardSubheaderProps = {
  text: string;
  tooltipText?: string;
};

const LinkedAccountsCountsCardSubheader: React.FC<LinkedAccountsCountsCardSubheaderProps> = ({
  text,
  tooltipText,
}) => {
  return (
    <div className="flex items-center gap-2">
      <Text variant="h6">{text}</Text>
      {tooltipText && (
        <Tooltip title={tooltipText}>
          <Info size={16} />
        </Tooltip>
      )}
    </div>
  );
};

export default LinkedAccountsCountsCardSubheader;
