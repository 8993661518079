import React, { useState, useEffect } from "react";
import { fetchWithAuth } from "../../../../api-client/APIClient";
import { useForm } from "react-hook-form";
import ContentLoader from "react-content-loader";
import { User, Comment } from "../../../../models/Entities";
import { Form, Col, Row, InputGroup } from "react-bootstrap";
import { fetchIntegrationIssueComments } from "../../../pages/utils/IntegrationsManagementAPIClient";
import { getCapitalizedTimeFromNow } from "../../../shared/utils/SharedComponentUtils";
import { UserAvatar, UserAvatarSize } from "../../../shared/MergeAvatars";
import { spectrum } from "../../../../styles/theme";
import { Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";

const commentStyle = {
  overflow: "hidden",
  overflowWrap: "break-word",
  height: "40px",
};

interface CommentsCardProps {
  integrationID?: string;
  integrationIssueID?: string;
}

export function IntegrationIssueComments({ integrationID, integrationIssueID }: CommentsCardProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, reset } = useForm();
  const [comments, setComments] = useState<undefined | Comment[]>();

  useEffect(() => {
    fetchComments();
  }, []);

  function fetchComments() {
    if (integrationID) {
      fetchWithAuth({
        path: `/integrations/${integrationID}/comments`,
        method: "GET",
        onResponse: (data) => {
          setComments(data.results);
        },
      });
    }
    if (integrationIssueID) {
      fetchIntegrationIssueComments({
        integrationIssueID,
        onSuccess: (data: { results: Comment[] }) => {
          setComments(data.results);
        },
      });
    }
  }

  const onSubmit = (data: { body: any }) => {
    setIsLoading(true);

    if (integrationID) {
      fetchWithAuth({
        path: `/integrations/${integrationID}/comments`,
        method: "POST",
        body: { body: data.body },
        onResponse: (data) => {
          comments && comments.unshift(data);
          reset();
          fetchComments();
          setIsLoading(false);
        },
      });
    }
    if (integrationIssueID) {
      fetchWithAuth({
        path: `/integrations/issues/${integrationIssueID}/comments`,
        method: "POST",
        body: { body: data.body },
        onResponse: (data) => {
          comments && comments.unshift(data);
          reset();
          fetchComments();
          setIsLoading(false);
        },
      });
    }
  };

  return (
    <div className="-m-6">
      <div className="card-header">
        <Row>
          <Form
            className="form-inline w-100 mt-2"
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
          >
            <Form.Group className="w-100">
              <Col>
                <InputGroup className="input-group input-group-flush">
                  <input
                    className="form-control form-control-flush w-100"
                    data-toggle="autosize"
                    placeholder="Leave a comment..."
                    name="body"
                    type="text"
                    style={commentStyle as any}
                    ref={register({
                      required: true,
                      minLength: 1,
                    })}
                  />
                </InputGroup>
              </Col>
              <Col className="col-auto align-self-end">
                <Button
                  size="sm"
                  variant={ButtonVariant.TertiaryWhite}
                  loading={isLoading}
                  className="mb-2"
                  type="submit"
                >
                  Submit
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Row>
      </div>
      <div className="card-body blue-modal-card">
        {comments ? (
          comments.map((comment) => <CommentRow comment={comment} key={comment.id} />)
        ) : (
          <>
            <ContentLoader
              speed={1.4}
              width={290}
              height={381}
              viewBox="0 0 290 381"
              backgroundColor={spectrum.gray0}
              foregroundColor={spectrum.gray10}
            >
              <rect x="81" y="37" rx="3" ry="3" width="162" height="23" />
              <rect x="81" y="15" rx="3" ry="3" width="162" height="15" />
              <circle cx="22" cy="28" r="21" />
              <rect x="81" y="124" rx="3" ry="3" width="162" height="23" />
              <rect x="81" y="102" rx="3" ry="3" width="162" height="15" />
              <circle cx="22" cy="115" r="21" />
              <rect x="81" y="211" rx="3" ry="3" width="162" height="23" />
              <rect x="81" y="189" rx="3" ry="3" width="162" height="15" />
              <circle cx="22" cy="202" r="21" />
              <rect x="81" y="298" rx="3" ry="3" width="162" height="23" />
              <rect x="81" y="276" rx="3" ry="3" width="162" height="15" />
              <circle cx="22" cy="289" r="21" />
              <rect x="81" y="385" rx="3" ry="3" width="157" height="23" />
              <rect x="81" y="363" rx="3" ry="3" width="162" height="15" />
              <circle cx="22" cy="376" r="21" />
            </ContentLoader>
          </>
        )}
      </div>
    </div>
  );
}

interface CommentRowProps {
  comment: {
    id: string;
    created_at: string;
    body: string;
    user: User;
  };
}

export function CommentRow(props: CommentRowProps) {
  return (
    <div className="flex flex-row gap-x-3 w-full mb-3" key={props.comment.id}>
      <div className="flex flex-shrink-0">
        <UserAvatar user={props.comment.user} size={UserAvatarSize.sm} />
      </div>
      <div className="inline-block py-4 px-5 bg-gray-0 rounded-lg">
        <div className="flex flex-row items-center justify-between gap-x-5 mb-1.5">
          <Text variant="h6">{props.comment.user.name}</Text>
          <Text variant="sm" className="text-gray-60">
            {getCapitalizedTimeFromNow(props.comment.created_at)}
          </Text>
        </div>
        <Text variant="sm" className="text-break">
          {props.comment.body}
        </Text>
      </div>
    </div>
  );
}
