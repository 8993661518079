import React from "react";
import { Rotate3d, BaggageClaim, ChevronRight } from "lucide-react";
import {
  DOCS_REMOTE_DATA_PATH,
  DOCS_PASSTHROUGH_REQUESTS_PATH,
} from "../../../../../router/RouterUtils";
import DeprecatedH4 from "../../../../../deprecated/DeprecatedH4";
import { Button, Card, Text } from "@merge-api/merge-javascript-shared";

const SupplementalData = () => {
  return (
    <Card className="p-6 mx-auto w-3/4">
      <Text variant="h3" className="mb-1">
        Supplemental data
      </Text>
      <Text as="p" className="mb-6 text-gray-60">
        Interact with original data from integrated third-party platforms
      </Text>
      <Text as="p">
        Merge normalizes integration data from different third-party platforms - each with its own
        data schema - into a standardized data model format ("Common Model") for a unified
        integration experience.
      </Text>
      <Text as="p">
        While the Common Model covers most use cases, some third-parties may have uncommon fields.
      </Text>
      <Text as="p">
        In these cases, you can leverage Merge's Unified API and SDK to interact with original data
        from integrated third-parties.
      </Text>
      <Text as="p" className="mb-9">
        Explore our two advanced capabilities below:
      </Text>

      <div className="rounded-lg w-full block bg-blue-0 p-4 mb-6">
        <BaggageClaim size={16} className="text-blue-40 mb-3" />
        <DeprecatedH4>Remote Data</DeprecatedH4>
        <Text variant="sm" as="p">
          Pull user data from integrated third-parties in its <b>original format</b> (before
          normalization). Professional and Enterprise plans only.
        </Text>
        <Button
          size="sm"
          rightIcon={<ChevronRight size={12} />}
          onClick={() => window.open(DOCS_REMOTE_DATA_PATH, "_blank")}
        >
          View docs
        </Button>
      </div>
      <div className="rounded-lg w-full block bg-blue-0 p-4 mb-6">
        <Rotate3d size={16} className="text-blue-40 mb-3" />
        <DeprecatedH4>Passthrough Requests</DeprecatedH4>
        <Text variant="sm" as="p">
          Use our <strong>Passthrough Requests</strong> to make any supported request
          (pre-authenticated) to an integration's API.
        </Text>
        <Button
          size="sm"
          rightIcon={<ChevronRight size={12} />}
          onClick={() => window.open(DOCS_PASSTHROUGH_REQUESTS_PATH, "_blank")}
        >
          View docs
        </Button>
      </div>
    </Card>
  );
};

export default SupplementalData;
