import React from "react";
import { ArrowUpRight } from "lucide-react";
import {
  FieldMappingCreationAndEditDict,
  FieldMappingOptions,
} from "../../../../../../models/Entities";
import { createFieldMappingOptions } from "../../../../configuration/field-mappings/utils/FieldMappingUtils";
import { deleteLinkedAccountFieldMapping } from "../../../../../../api-client/APIClient";
import { ArrowLeft } from "lucide-react";
import {
  Typeahead,
  Button,
  ButtonVariant,
  Card,
  Text,
  TextFieldVariant,
} from "@merge-api/merge-javascript-shared";
import { LinkedAccount } from "../../../../../../models/Entities";
import { showSuccessToast } from "../../../../../shared/Toasts";
import { getFieldMappingSettingsPathForId } from "../../../../../../router/RouterUtils";
import EditJmesPathModal from "../modal/field-mappings/EditJmesPathModal";
import useUnmappedTargetCard from "./useUnmappedTargetCard";
import FieldMappingDropdownChild from "../../../../../shared/FieldMappingDropdownChild";
import FieldMappingsGridContainer from "../components/FieldMappingsGridContainer";
type FieldMappingUnmappedTargetCardProps = {
  linkedAccount: LinkedAccount;
  fieldKey: string;
  fieldDescription: string;
  fieldMappingOptions: FieldMappingOptions;
  commonModel: string;
  fieldMappingTargetId: string;
};
const FieldMappingUnmappedTargetCard = ({
  linkedAccount,
  fieldKey,
  fieldDescription,
  fieldMappingOptions,
  commonModel,
  fieldMappingTargetId,
}: FieldMappingUnmappedTargetCardProps) => {
  const createdfieldMappingOptions = createFieldMappingOptions(fieldMappingOptions, commonModel);
  const {
    createdFieldMappingInstanceId,
    originFieldForAdvancedMapping,
    showAdvancedMappingButton,
    showAdvancedFieldMappingModal,
    setCreatedFieldMappingInstanceId,
    setOriginFieldForAdvancedMapping,
    setShowAdvancedMappingButton,
    setShowAdvancedFieldMappingModal,
    saveLinkedAccountMapping,
    editCreatedFieldMapping,
    jmesPath,
    setJmesPath,
  } = useUnmappedTargetCard();

  return (
    <>
      {showAdvancedFieldMappingModal && originFieldForAdvancedMapping && (
        <EditJmesPathModal
          currentJmesPath={jmesPath}
          jsonData={originFieldForAdvancedMapping.value}
          remoteFieldDisplayName={originFieldForAdvancedMapping.display_name}
          organizationTargetField={fieldKey}
          onModalClose={() => {
            setShowAdvancedFieldMappingModal(false);
          }}
          onUpdate={(jmesPath: string) => {
            if (createdFieldMappingInstanceId) {
              editCreatedFieldMapping(originFieldForAdvancedMapping, jmesPath);
            } else {
              saveLinkedAccountMapping(
                originFieldForAdvancedMapping,
                linkedAccount,
                fieldKey,
                fieldDescription,
                commonModel,
                jmesPath,
              );
            }
            setJmesPath(jmesPath);
            setShowAdvancedMappingButton(false);
            setShowAdvancedFieldMappingModal(false);
          }}
        />
      )}
      <FieldMappingsGridContainer>
        <>
          <Card size="lg" variant="outline" className="py-4 px-5 flex flex-col">
            <div className="flex flex-grow-1 justify-between mb-2">
              <Text variant="lg-mono">{fieldKey}</Text>
              <Button
                variant={ButtonVariant.TextBlue}
                size="sm"
                onClick={() => getFieldMappingSettingsPathForId(fieldMappingTargetId)}
              >
                View
              </Button>
            </div>
            {fieldDescription && (
              <Text variant="sm" className="text-gray-60">
                {fieldDescription}
              </Text>
            )}
          </Card>
          <div className="flex justify-center pt-3">
            <ArrowLeft size="20" />
          </div>
          <div className="flex flex-col">
            <Typeahead
              className="w-100"
              borderVariant={TextFieldVariant.Shadowed}
              options={createdfieldMappingOptions}
              placeholder={
                createdfieldMappingOptions.length != 0
                  ? "Search Remote Fields..."
                  : "No Remote Fields currently available"
              }
              disabled={createdfieldMappingOptions.length === 0}
              multiple={false}
              getOptionLabel={(option: any) => {
                return option?.display_name ?? "";
              }}
              blurOnSelect
              renderOption={(fieldMapping: FieldMappingCreationAndEditDict) => {
                return <FieldMappingDropdownChild option={fieldMapping} />;
              }}
              onChange={(_, selectedRemoteField: any) => {
                const showAdvancedFieldMappingsForm =
                  selectedRemoteField &&
                  ((selectedRemoteField.type.includes("list") &&
                    selectedRemoteField.value?.length !== 0) ||
                    selectedRemoteField.type == "dict");
                setJmesPath(null);
                if (showAdvancedFieldMappingsForm) {
                  setOriginFieldForAdvancedMapping(selectedRemoteField);
                  setShowAdvancedMappingButton(true);
                  return;
                }
                if (selectedRemoteField) {
                  if (createdFieldMappingInstanceId == null) {
                    saveLinkedAccountMapping(
                      selectedRemoteField,
                      linkedAccount,
                      fieldKey,
                      fieldDescription,
                      commonModel,
                      null,
                    );
                  } else {
                    editCreatedFieldMapping(selectedRemoteField, null);
                  }
                  // create field mapping instance
                } else {
                  if (createdFieldMappingInstanceId) {
                    deleteLinkedAccountFieldMapping(createdFieldMappingInstanceId, () => {
                      showSuccessToast("Successfully deleted Field Mapping");
                      setCreatedFieldMappingInstanceId(null);
                    });
                  }
                  setShowAdvancedMappingButton(false);
                }
              }}
            />
            {showAdvancedMappingButton && (
              <Button
                size="sm"
                variant={ButtonVariant.SecondaryBlue}
                onClick={() => {
                  setShowAdvancedFieldMappingModal(true);
                }}
                className="mt-3 ml-auto"
                rightIcon={<ArrowUpRight size={12} />}
              >
                Advanced mapping
              </Button>
            )}
            {jmesPath && (
              <div className="flex items-center px-[10px] justify-between bg-blue-0 rounded-md text-sm mt-3 h-[30px]">
                <div className="truncate">{jmesPath}</div>
                <Button
                  size="sm"
                  variant={ButtonVariant.TextBlue}
                  rightIcon={<ArrowUpRight size={12} />}
                >
                  Advanced
                </Button>
              </div>
            )}
          </div>
        </>
      </FieldMappingsGridContainer>
    </>
  );
};
export default FieldMappingUnmappedTargetCard;
