import React from "react";
import { OrganizationAvatar, UserAvatarSize } from "../../../../../shared/MergeAvatars";
import FieldMappingsGridContainer from "./FieldMappingsGridContainer";
import { Globe } from "lucide-react";
import { Text } from "@merge-api/merge-javascript-shared";
import { Organization } from "../../../../../../models/Entities";
import MergeMark from "../../../../../../assets/img/mark-borderless.png";

type FieldMappingsMergeToIntegrationHeaderProps = {
  integrationName?: string;
  integrationSquareImage?: string | undefined;
  hasDropdown?: boolean;
  isSingular?: boolean;
  organization?: Organization;
  commonModel?: string;
  isCommonModelOverride?: boolean;
  isFMv3DashboardEnabled?: boolean;
};

const FieldMappingsMergeToIntegrationHeader = ({
  integrationName,
  integrationSquareImage,
  hasDropdown,
  isSingular,
  organization,
  commonModel,
  isCommonModelOverride = false,
  isFMv3DashboardEnabled = false,
}: FieldMappingsMergeToIntegrationHeaderProps) => {
  if (isFMv3DashboardEnabled) {
    return (
      <FieldMappingsGridContainer>
        <>
          <div className="flex items-center font-semibold">
            <img
              src={MergeMark}
              width={20}
              height={20}
              className="rounded-full flex items-center justify-center"
            />
            <div className="ml-2">
              {isCommonModelOverride
                ? `Merge ${commonModel || ""} field`
                : `New ${commonModel || ""} target field`}
            </div>
          </div>
          <div className="w-5"></div>
          <div className="flex items-center font-semibold">
            <OrganizationAvatar
              size={UserAvatarSize.xs}
              imageURL={integrationSquareImage}
              organizationName={integrationName}
              isCircle
              className="mr-3 d-flex"
            />
            {integrationName} Remote Field
          </div>
        </>
      </FieldMappingsGridContainer>
    );
  }

  return (
    <FieldMappingsGridContainer hasDropdown={hasDropdown}>
      <>
        <div className="flex items-center font-semibold">
          {organization ? (
            <>
              <Globe size={16} className="text-orange-60 mr-2" />
              <Text variant="h6">
                {organization.name}-wide target {isSingular ? "field" : "fields"}
              </Text>
            </>
          ) : (
            <>
              <img
                src={MergeMark}
                width={20}
                height={20}
                className="rounded-full flex items-center justify-center"
              />
              <div className="ml-2">Merge {isSingular ? "field" : "fields"}</div>
            </>
          )}
        </div>
        <div className="w-5"></div>
        <div className="flex items-center font-semibold">
          <OrganizationAvatar
            size={UserAvatarSize.xs}
            imageURL={integrationSquareImage}
            organizationName={integrationName}
            isCircle
            className="mr-3 d-flex"
          />
          {integrationName} remote {isSingular ? "field" : "fields"}
        </div>
        {hasDropdown && <div className="w-[32px] mx-1"></div>}
      </>
    </FieldMappingsGridContainer>
  );
};

export default FieldMappingsMergeToIntegrationHeader;
