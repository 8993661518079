import React from "react";
import { MendableInPlace } from "@mendable/search";
import { MENDABLE_ANON_KEY } from "../../constants";
import { Dialog, Modal } from "@merge-api/merge-javascript-shared";

type MergeMendableInPlaceProps = {
  showMendableModal: boolean;
  setShowMendableModal: any;
};

const MergeMendableInPlace = ({
  showMendableModal,
  setShowMendableModal,
}: MergeMendableInPlaceProps) => {
  return (
    <Modal
      open={showMendableModal}
      onClose={() => {
        setShowMendableModal(false);
      }}
      animate
    >
      <div className="w-[600px]">
        <MendableInPlace
          askAIText="Ask Merge AI"
          hintText="Find or ask anything..."
          anon_key={MENDABLE_ANON_KEY}
          style={{
            darkMode: false,
            accentColor: "#123456",
          }}
          showSimpleSearch
          messageSettings={{
            openInNewTabForOutsideSources: true,
            legacySourceDisplay: true,
          }}
        />
      </div>
    </Modal>
  );
};
export default MergeMendableInPlace;
