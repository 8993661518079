import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { APICategory, Button } from "@merge-api/merge-javascript-shared";
import uniq from "lodash/uniq";

import {
  fetchWithAuth,
  FormErrorData,
  MultipleFormErrorData,
} from "../../../../../api-client/APIClient";
import { showSuccessToast, showErrorToast } from "../../../../shared/Toasts";
import { navigateToWebhooksConfigurationPage } from "../../../../../router/RouterUtils";
import { SelectedWebhookType, PageMode, HookEvent } from "./enums";
import { AddEditWebhooksPageHeader, WebhookUrlInput } from "./components";
import NewWebhookTypeSelect from "./components/WebhookTypeSelect/WebhookTypeSelect";
import { useWebhookOptions } from "./hooks/useWebhookOptions";

type RouteParams = {
  webhookID: string;
};

const ConfigurationEditWebhooksPage = () => {
  // hooks
  const history = useHistory();
  const { webhookID } = useParams<RouteParams>();
  const { register, handleSubmit, setError, errors, trigger } = useForm();

  // state
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [displayTarget, setDisplayTarget] = useState<string>();
  const [selectedWebhookType, setSelectedWebhookType] = useState<Set<SelectedWebhookType>>(
    new Set(),
  );

  // common model select specific state
  const [selectedChangedDataCategories, setSelectedChangedDataCategories] = useState<
    Array<APICategory>
  >([]);
  const [selectedSyncCategories, setSelectedSyncCategories] = useState<Array<APICategory>>([]);
  const [selectedSyncCommonModels, setSelectedSyncCommonModels] = useState<Array<string>>([]);
  const [selectedSyncCommonModelEvents, setSelectedSyncCommonModelEvents] = useState<Array<string>>(
    [],
  );
  const [selectedChangedDataCommonModels, setSelectedChangedDataCommonModels] = useState<
    Array<string>
  >([]);
  const [selectedChangedDataCommonModelEvents, setSelectedChangedDataCommonModelEvents] = useState<
    Array<string>
  >([]);

  const [selectedChangedDataCommonModelsToFields, setSelectedChangedDataCommonModelsToFields] =
    useState<Record<string, string[]>>({});

  // derived state
  const mode = webhookID ? PageMode.EDIT : PageMode.ADD;

  // prop loading state

  const skeletonLoadingState = isPageLoading && mode === PageMode.EDIT;

  // event handlers
  const onSelectedWebhookTypeChange = (newSelectedWebhookType: SelectedWebhookType) =>
    setSelectedWebhookType((prev) => {
      if (newSelectedWebhookType === SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_ANY) {
        prev.delete(SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_SELECT);
      } else if (newSelectedWebhookType === SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_SELECT) {
        prev.delete(SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_ANY);
      } else if (newSelectedWebhookType === SelectedWebhookType.COMMON_MODEL_SYNC_ANY) {
        prev.delete(SelectedWebhookType.COMMON_MODEL_SYNC_SELECT);
      } else if (newSelectedWebhookType === SelectedWebhookType.COMMON_MODEL_SYNC_SELECT) {
        prev.delete(SelectedWebhookType.COMMON_MODEL_SYNC_ANY);
      }

      return new Set(prev.add(newSelectedWebhookType));
    });
  const onUnselectingWebhookType = (unSelectedWebhookType: SelectedWebhookType) =>
    setSelectedWebhookType((prev) => {
      prev.delete(unSelectedWebhookType);
      return new Set(prev);
    });
  const onSelectedSyncCategoriesChange = (selectedCategories: APICategory[]) =>
    setSelectedSyncCategories(selectedCategories);
  const onSelectedChangedDataCategoriesChange = (selectedCategories: APICategory[]) =>
    setSelectedChangedDataCategories(selectedCategories);
  const onTargetChange = (target: string) => setDisplayTarget(target);

  const onSelectedSyncCommonModelsChange = (selectedSyncCommonModels: string[]) =>
    setSelectedSyncCommonModels(selectedSyncCommonModels);
  const onSelectedSyncCommonModelEventsChange = (selectedSyncCommonModelEvents: string[]) =>
    setSelectedSyncCommonModelEvents(selectedSyncCommonModelEvents);
  const onSelectedChangedDataCommonModelsChange = (selectedChangedDataCommonModels: string[]) =>
    setSelectedChangedDataCommonModels(selectedChangedDataCommonModels);
  const onSelectedChangedDataCommonModelEventsChange = (
    selectedChangedDataCommonModelEvents: string[],
  ) => setSelectedChangedDataCommonModelEvents(selectedChangedDataCommonModelEvents);

  const target = "https://" + displayTarget;

  // setup state for EDIT mode
  const fetchWebhook = useCallback(() => {
    fetchWithAuth({
      path: `/integrations/webhooks/${webhookID}`,
      method: "GET",
      onResponse: (data) => {
        setDisplayTarget(data.target.replace("https://", ""));
        if (data.events.length === 0) {
          // Nothing selected.
          setSelectedWebhookType(new Set());
        } else {
          const fetchedSelectedWebhookTypes = new Set<SelectedWebhookType>();

          if (data.events.includes(HookEvent.ACCOUNT_SYNC_COMPLETED_HOOK)) {
            // Sync Notifications -> "Linked Account Sync" is selected.
            fetchedSelectedWebhookTypes.add(SelectedWebhookType.LINKED_ACCOUNT_SYNCED);
          }
          if (data.events.includes(HookEvent.ACCOUNT_LINKED_HOOK)) {
            // Account Linked webhook selected
            fetchedSelectedWebhookTypes.add(SelectedWebhookType.LINKED);
          }
          if (data.events.includes(HookEvent.ACCOUNT_DELETED_HOOK)) {
            // Account Deleted webhook selected
            fetchedSelectedWebhookTypes.add(SelectedWebhookType.LINKED_ACCOUNT_DELETED);
          }
          if (data.events.includes(HookEvent.ACCOUNT_SYNCED_INITIAL_HOOK)) {
            // Sync Notifications -> "First Sync" is selected.
            fetchedSelectedWebhookTypes.add(SelectedWebhookType.FIRST_SYNC);
          }
          if (data.events.includes(HookEvent.ACCOUNT_FULLY_SYNCED_INITIAL_HOOK)) {
            // Sync Notifications -> "First Sync" is selected.
            fetchedSelectedWebhookTypes.add(SelectedWebhookType.FIRST_SYNC);
          }
          if (data.events.includes(HookEvent.ACCOUNT_SYNCED_HOOK)) {
            // Sync Notifications -> "Any Sync" is selected.
            fetchedSelectedWebhookTypes.add(SelectedWebhookType.ANY_SYNC);
          }
          if (
            // Issues Create -> "Issues"  is selected.
            data.events.includes(HookEvent.ISSUES_CREATED) ||
            data.events.includes(HookEvent.ISSUES_RESOLVED)
          ) {
            fetchedSelectedWebhookTypes.add(SelectedWebhookType.ISSUES);
          }
          if (
            // Async Passthrough Resolved -> "Async Passthrough" is selected.
            data.events.includes(HookEvent.ASYNC_PASSTHROUGH_RESOLVED)
          ) {
            fetchedSelectedWebhookTypes.add(SelectedWebhookType.ASYNC_PASSTHROUGH);
          }
          if (data.events.includes(HookEvent.ALL_COMMON_MODEL_CHANGED_DATA_EVENTS)) {
            // Changed Data -> "Anything" is selected.
            fetchedSelectedWebhookTypes.add(SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_ANY);
          }
          if (data.events.includes(HookEvent.ALL_COMMON_MODEL_SYNC_EVENTS)) {
            // Common Model Sync -> "Anything" is selected.
            fetchedSelectedWebhookTypes.add(SelectedWebhookType.COMMON_MODEL_SYNC_ANY);
          }
          if (
            data.events.some(
              (event: string) =>
                event.endsWith(".added") ||
                event.endsWith(".changed") ||
                event.endsWith(".removed"),
            )
          ) {
            // Changed Data -> various data types are selected if any include .added, .removed, .changed
            const commonModelEvents = data.events.filter(
              (event: string) =>
                event.endsWith(".added") ||
                event.endsWith(".changed") ||
                event.endsWith(".removed"),
            );
            setSelectedChangedDataCommonModelEvents(commonModelEvents);
            setSelectedChangedDataCommonModels(
              uniq(commonModelEvents.map((e: string) => e.split(".")[0])),
            );
            fetchedSelectedWebhookTypes.add(SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_SELECT);
            setSelectedChangedDataCategories(data.changed_data_categories);
          }
          if (
            data.events.some(
              (event: string) => event.endsWith(".synced") && !event.startsWith("LinkedAccount"),
            )
          ) {
            // Common Model Sync -> various data types are selected if any include .synced
            const commonModelSyncedEvents = data.events.filter(
              (event: string) => event.endsWith(".synced") && !event.startsWith("LinkedAccount"),
            );
            setSelectedSyncCommonModelEvents(commonModelSyncedEvents);
            const selectedSyncCommonModels = uniq(
              commonModelSyncedEvents.map((e: string) => e.split(".")[0]),
            ) as string[];
            setSelectedSyncCommonModels(selectedSyncCommonModels);

            fetchedSelectedWebhookTypes.add(SelectedWebhookType.COMMON_MODEL_SYNC_SELECT);
            setSelectedSyncCategories(data.common_model_sync_categories);
          }

          if (data.models_to_fields_enabled) {
            setSelectedChangedDataCommonModelsToFields(data.models_to_fields_enabled);
          }

          setSelectedWebhookType(new Set(fetchedSelectedWebhookTypes));
        }

        setIsPageLoading(false);
      },
    });
  }, [webhookID, useWebhookOptions]);

  // state management for switching categories in edit/add mode

  const isSyncSelected =
    selectedWebhookType?.has(SelectedWebhookType.COMMON_MODEL_SYNC_SELECT) ||
    selectedWebhookType?.has(SelectedWebhookType.COMMON_MODEL_SYNC_ANY);

  const isChangedDataSelected =
    selectedWebhookType?.has(SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_SELECT) ||
    selectedWebhookType?.has(SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_ANY);

  useEffect(() => {
    setSelectedWebhookType((prev) => {
      const newSet = new Set(prev);

      if (isSyncSelected) {
        newSet.add(SelectedWebhookType.COMMON_MODEL_SYNC_SELECT);
        newSet.delete(SelectedWebhookType.COMMON_MODEL_SYNC_ANY);
      }

      if (isChangedDataSelected) {
        newSet.add(SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_SELECT);
        newSet.delete(SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_ANY);
      }

      return newSet;
    });
  }, [
    isSyncSelected,
    isChangedDataSelected,
    selectedChangedDataCategories,
    selectedSyncCategories,
  ]);

  // effects
  // inititalize
  useEffect(() => {
    if (webhookID) {
      fetchWebhook();
    } else {
      setIsPageLoading(false);
    }
  }, [fetchWebhook, webhookID]);

  const selectedEvents: string[] = useMemo(() => {
    let events: string[] = [];
    // linked account related webhooks
    if (selectedWebhookType.has(SelectedWebhookType.FIRST_SYNC)) {
      events.push(HookEvent.ACCOUNT_FULLY_SYNCED_INITIAL_HOOK);
    }

    if (selectedWebhookType.has(SelectedWebhookType.ANY_SYNC)) {
      events.push(HookEvent.ACCOUNT_SYNCED_HOOK);
    }

    if (selectedWebhookType.has(SelectedWebhookType.LINKED)) {
      events.push(HookEvent.ACCOUNT_LINKED_HOOK);
    }

    if (selectedWebhookType.has(SelectedWebhookType.LINKED_ACCOUNT_SYNCED)) {
      events.push(HookEvent.ACCOUNT_SYNC_COMPLETED_HOOK);
    }
    if (selectedWebhookType.has(SelectedWebhookType.LINKED_ACCOUNT_DELETED)) {
      events.push(HookEvent.ACCOUNT_DELETED_HOOK);
    }

    // async passthrough webhook
    if (selectedWebhookType.has(SelectedWebhookType.ASYNC_PASSTHROUGH)) {
      events.push(HookEvent.ASYNC_PASSTHROUGH_RESOLVED);
    }
    // issues webhook
    if (selectedWebhookType.has(SelectedWebhookType.ISSUES)) {
      events.push(HookEvent.ISSUES_CREATED);
      events.push(HookEvent.ISSUES_RESOLVED);
    }
    // common model changed data
    if (selectedWebhookType.has(SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_ANY)) {
      events.push(HookEvent.ALL_COMMON_MODEL_CHANGED_DATA_EVENTS);
    }

    if (selectedWebhookType.has(SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_SELECT)) {
      events = events.concat(selectedChangedDataCommonModelEvents);
    }

    // common model sync
    if (selectedWebhookType.has(SelectedWebhookType.COMMON_MODEL_SYNC_ANY)) {
      events.push(HookEvent.ALL_COMMON_MODEL_SYNC_EVENTS);
    }

    if (selectedWebhookType.has(SelectedWebhookType.COMMON_MODEL_SYNC_SELECT)) {
      events = events.concat(selectedSyncCommonModelEvents);
    }

    const setOfEvents = new Set(events);

    return [...setOfEvents];
  }, [selectedSyncCommonModelEvents, selectedChangedDataCommonModelEvents, selectedWebhookType]);

  const onSubmit = () => {
    if (selectedWebhookType.size === 0) {
      showErrorToast("Please select a webhook trigger.");
      return;
    }

    setIsLoading(true);

    //  filters out events between common model sync and changed data : this is an additional check to ensure we never couple these webhooks together
    const filteredEvents = [...selectedEvents];

    if (selectedWebhookType.has(SelectedWebhookType.COMMON_MODEL_SYNC_SELECT)) {
      const events_for_changed_data = filteredEvents.filter((event) => event.endsWith(".synced"));
      if (events_for_changed_data.length === 0) {
        setError("event", { message: "This field may not be null." });
        setIsLoading(false);
        return;
      }
    }

    if (selectedWebhookType.has(SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_SELECT)) {
      const unwantedEndings = [".added", ".changed", ".removed"];
      const events_for_common_model_sync_select = filteredEvents.filter((event) =>
        unwantedEndings.some((ending) => event.endsWith(ending)),
      );

      if (events_for_common_model_sync_select.length === 0) {
        setError("event", { message: "This field may not be null." });
        showErrorToast("Something went wrong, please check your selections and try again.");
        setIsLoading(false);
        return;
      }
    }

    const formData = {
      target,
      events: filteredEvents,
      models_to_fields_enabled: selectedChangedDataCommonModelsToFields,
    };

    const handleError = (err: Response | undefined) => {
      if (err) {
        err.json().then((data: MultipleFormErrorData | FormErrorData) => {
          const formErrorData: FormErrorData =
            (data as MultipleFormErrorData)[0] === undefined
              ? (data as FormErrorData)
              : (data as MultipleFormErrorData)[0];

          if (formErrorData["non_field_errors"]) {
            showErrorToast(formErrorData["non_field_errors"][0]);
          } else {
            showErrorToast("Something went wrong, please check your selections and try again.");
          }
          for (const field_name in formErrorData) {
            setError(field_name, { message: formErrorData[field_name][0] });
          }
        });
      } else {
        showErrorToast("Something went wrong, please check your selections and try again.");
      }
      setIsLoading(false);
    };

    const handleResponse = () => {
      showSuccessToast("Successfully saved webhook!");
      navigateToWebhooksConfigurationPage(history);
      setIsLoading(false);
    };

    if (mode === PageMode.EDIT) {
      fetchWithAuth({
        path: `/integrations/webhooks/${webhookID}`,
        method: "PATCH",
        body: formData,
        onResponse: handleResponse,
        onError: handleError,
      });
    } else {
      fetchWithAuth({
        path: "/integrations/webhooks",
        method: "POST",
        body: formData,
        onResponse: handleResponse,
        onError: handleError,
      });
    }
  };

  return (
    <>
      <AddEditWebhooksPageHeader webhookID={webhookID} mode={mode} />
      <div className="flex flex-col rounded-[10px] shadow-md px-6 py-5 mt-8 mb-64 bg-white">
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <WebhookUrlInput
            isLoading={skeletonLoadingState}
            register={register}
            errors={errors}
            onTargetChange={onTargetChange}
            displayTarget={displayTarget}
            target={target}
            trigger={trigger}
            selectedWebhookType={selectedWebhookType}
            selectedEvents={selectedEvents}
            setError={setError}
          />
          <NewWebhookTypeSelect
            isLoading={skeletonLoadingState}
            onSelectedSyncCategoriesChange={onSelectedSyncCategoriesChange}
            selectedSyncCategories={selectedSyncCategories}
            onSelectedChangedDataCategoriesChange={onSelectedChangedDataCategoriesChange}
            selectedChangedDataCategories={selectedChangedDataCategories}
            selectedWebhookType={selectedWebhookType}
            onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
            onUnselectingWebhookType={onUnselectingWebhookType}
            selectedSyncCommonModels={selectedSyncCommonModels}
            selectedSyncCommonModelEvents={selectedSyncCommonModelEvents}
            selectedChangedDataCommonModels={selectedChangedDataCommonModels}
            selectedChangedDataCommonModelEvents={selectedChangedDataCommonModelEvents}
            selectedChangedDataCommonModelsToFields={selectedChangedDataCommonModelsToFields}
            onSelectedSyncCommonModelsChange={onSelectedSyncCommonModelsChange}
            onSelectedSyncCommonModelEventsChange={onSelectedSyncCommonModelEventsChange}
            onSelectedChangedDataCommonModelsChange={onSelectedChangedDataCommonModelsChange}
            onSelectedChangedDataCommonModelEventsChange={
              onSelectedChangedDataCommonModelEventsChange
            }
            setSelectedChangedDataCommonModelsToFields={setSelectedChangedDataCommonModelsToFields}
          />
          <div className="border-b border-gray-10 -ml-6 -mr-6 mt-6 mb-5" />
          <div className="flex flex-row items-center flex-nowrap">
            {mode === PageMode.EDIT ? (
              <Button type="submit" loading={isLoading} disabled={!displayTarget}>
                Save
              </Button>
            ) : (
              <Button type="submit" disabled={!displayTarget} loading={isLoading}>
                Add webhook
              </Button>
            )}{" "}
            <div
              onClick={() => navigateToWebhooksConfigurationPage(history)}
              className="ml-4 font-semibold cursor-pointer"
            >
              Cancel
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ConfigurationEditWebhooksPage;
