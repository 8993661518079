import React, { useContext, useState } from "react";
import { AlertTriangle, ArrowLeft } from "lucide-react";
import CancelAndNextFooter from "../../../../../../common/CancelAndNextFooter";
import { Alert, Link, Typeahead } from "@merge-api/merge-javascript-shared";
import FieldMappingsGridContainer from "../../components/FieldMappingsGridContainer";
import FieldMappingModalTitle from "../../modal/FieldMappingModalTitle";
import FieldMappingModalContext from "../context/FieldMappingModalContext";
import LinkedAccountFieldMappingsContext from "../../context/LinkedAccountFieldMappingsContext";
import RemoteFieldCard from "../components/RemoteFieldCard";
import {
  CommonModelFieldDefinition,
  FieldMappingMetaResponse,
} from "../../../../../../../models/Entities";
import CommonModelFieldDropdown from "../../../../../../shared/CommonModelFieldDropdown";
import {
  createCommonModelOverride,
  getFieldMappingModalTitle,
  getOverrideOptions,
} from "../helpers";
import FieldMappingsMergeToIntegrationHeader from "../../components/FieldMappingsMergeToIntegrationHeader";
import { doesModelHaveRemoteDataEnabled } from "../../modal/field-mappings/CreateFieldMappingModelSelection";
import { CONFIGURATION_COMMON_MODELS_PATH } from "../../../../../../../router/RouterUtils";
import FieldMappingTargetBadge from "../../../../../../shared/FieldMappingTargetBadge";

const CommonModelOverrideMappingCreation = () => {
  const {
    linkedAccount,
    onModalClose,
    selectedRemoteField,
    jmesPath,
    linkedAccountMappingInfo,
    selectedFieldMappingPathway,
    commonModelsToMappingConfig,
  } = useContext(FieldMappingModalContext);
  const { setFieldMappingTargetAndInstances } = useContext(LinkedAccountFieldMappingsContext);

  const [isCreating, setIsCreating] = useState(false);
  const [fieldToOverride, setFieldToOverride] = useState<{
    name: string;
    type: string;
    description: string;
    format: string | null;
  } | null>(null);

  const commonModelSelected = selectedRemoteField?.commonModelName;
  const availableFieldsAndOverrides = (linkedAccountMappingInfo as FieldMappingMetaResponse)
    .available_model_and_field_overrides;

  return (
    <>
      <FieldMappingModalTitle
        title={getFieldMappingModalTitle(selectedFieldMappingPathway!)}
        onClose={onModalClose}
      />
      <div className="px-6 mt-5">
        <div className="text-base">
          <p className="mb-2">
            Remote Fields can only be mapped to their respective Common Model. This change will be
            reflected after the next sync which will be a full resync of data for{" "}
            {linkedAccount?.end_user.organization_name}.
          </p>
        </div>
        <div className="flex flex-col mt-6 gap-y-4">
          <FieldMappingsMergeToIntegrationHeader
            integrationName={linkedAccount?.integration.name!}
            integrationSquareImage={linkedAccount?.integration.square_image}
            isFMv3DashboardEnabled
            commonModel={commonModelSelected}
            isCommonModelOverride
          />

          <FieldMappingsGridContainer>
            <>
              {!doesModelHaveRemoteDataEnabled(
                selectedRemoteField!.commonModelName,
                commonModelsToMappingConfig,
              ) ? (
                <Alert color="yellow" icon={<AlertTriangle size={16} />}>
                  <span>
                    <Link
                      target="_blank"
                      href={`${CONFIGURATION_COMMON_MODELS_PATH}/${linkedAccount!.category}#${
                        selectedRemoteField!.commonModelName
                      }`}
                    >
                      Enable Remote Data
                    </Link>
                    {"  "} to create a new Common Model field for this model
                  </span>
                </Alert>
              ) : (
                <>
                  <Typeahead
                    placeholder={`Select ${commonModelSelected} fields...`}
                    options={getOverrideOptions(commonModelSelected, availableFieldsAndOverrides!)}
                    getOptionLabel={(option) => {
                      return option.field_name;
                    }}
                    renderOption={(option) => {
                      return (
                        <CommonModelFieldDropdown
                          fieldDescription={option.field_description}
                          fieldType={option.field_format || option.field_type}
                          fieldName={option.field_name}
                        />
                      );
                    }}
                    onChange={(_: any, selectedOption) => {
                      if (selectedOption) {
                        setFieldToOverride({
                          name: selectedOption.field_name as string,
                          type: selectedOption.field_type,
                          description: selectedOption.field_description,
                          format: selectedOption.field_format,
                        });
                      } else {
                        setFieldToOverride(null);
                      }
                    }}
                    filterOptions={(options: any, { inputValue }: any) => {
                      return options.filter((option: CommonModelFieldDefinition) => {
                        const inputValueToLowerCase = inputValue.toLowerCase();
                        return (
                          option.field_name.includes(inputValueToLowerCase) ||
                          option.field_description.includes(inputValueToLowerCase)
                        );
                      });
                    }}
                  />
                </>
              )}
              <div className="flex p-4 pt-3 flex-shrink-0">
                <ArrowLeft size={20} />
              </div>
              <RemoteFieldCard
                displayName={selectedRemoteField?.display_name || ""}
                apiEndpointPath={selectedRemoteField?.api_endpoint_path}
                type={selectedRemoteField?.type}
                jmesPath={jmesPath}
                badge={<FieldMappingTargetBadge type="common-model-override-field" />}
              />
            </>
          </FieldMappingsGridContainer>
        </div>
      </div>
      <div className="pr-6 !mt-6">
        <CancelAndNextFooter
          buttonText="Save mapping"
          onCancel={onModalClose}
          onNext={() => {
            createCommonModelOverride(
              selectedRemoteField,
              linkedAccount,
              commonModelSelected,
              jmesPath,
              fieldToOverride,
              setIsCreating,
              setFieldMappingTargetAndInstances,
              onModalClose,
            );
          }}
          isNextDisabled={fieldToOverride == null || fieldToOverride == undefined}
          isLoading={isCreating}
        />
      </div>
    </>
  );
};
export default CommonModelOverrideMappingCreation;
