import React, { useContext, useState } from "react";
import FieldMappingModalTitle from "../FieldMappingModalTitle";
import FieldMappingModalContext from "../context/FieldMappingModalContext";
import { Alert, TextField, Typeahead, Text, Link } from "@merge-api/merge-javascript-shared";
import { AlertTriangle } from "lucide-react";
import CancelAndNextFooter from "../../../../../../common/CancelAndNextFooter";
import { CONFIGURATION_COMMON_MODELS_PATH } from "../../../../../../../router/RouterUtils";
import { FieldMappingCommonModelConfiguration } from "../../../../../../../models/Entities";

export const doesModelHaveRemoteDataEnabled = (
  modelId: string,
  commonModelsToMappingConfig: {
    [common_model_id: string]: FieldMappingCommonModelConfiguration;
  },
) => {
  if (modelId in commonModelsToMappingConfig) {
    return commonModelsToMappingConfig[modelId]?.has_remote_data_enabled || false;
  }
  return false;
};
const CreateFieldMappingModelSelection = () => {
  const {
    linkedAccount,
    onModalClose,
    commonModelsForIntegration,
    commonModelsToMappingConfig,
    setFormStep,
    setFieldMappingTargetName,
    fieldMappingTargetName,
    setCommonModelSelected,
    fieldMappingTargetDescription,
    setFieldMappingTargetDescription,
  } = useContext(FieldMappingModalContext);

  const [selectedModelId, setSelectedModelId] = useState<string | null>(null);

  return (
    <>
      <FieldMappingModalTitle
        title={`Map a new ${linkedAccount?.end_user.organization_name}-specific target field`}
        onClose={onModalClose}
      />
      <div className="flex flex-col px-6 mt-5 gap-y-4">
        <div>
          Add a new Merge field on a Common Model, also known as a <strong>target field</strong> to
          map third-party data to
        </div>
        <div>
          <Text variant="h6">Common Model</Text>
          <div className="mt-2">
            <Typeahead
              value={selectedModelId}
              placeholder="Select Common Model..."
              onChange={(_: any, selectedOption: any) => {
                if (selectedOption == null) {
                  setSelectedModelId(selectedOption);
                } else {
                  // if switching from model with remote enabled to not skip timeout
                  if (
                    selectedModelId &&
                    doesModelHaveRemoteDataEnabled(selectedModelId, commonModelsToMappingConfig) &&
                    !doesModelHaveRemoteDataEnabled(selectedOption, commonModelsToMappingConfig)
                  ) {
                    setSelectedModelId(selectedOption);
                  } else {
                    setTimeout(() => {
                      setSelectedModelId(selectedOption);
                    }, 400);
                  }
                }
              }}
              options={commonModelsForIntegration}
            />
          </div>
        </div>
        <div>
          <div
            className={`transition-all duration-400 ease-in-out ${
              selectedModelId ? "h-fit" : "h-0 opacity-0"
            }`}
          >
            {!selectedModelId ? (
              <></>
            ) : !doesModelHaveRemoteDataEnabled(selectedModelId!, commonModelsToMappingConfig) ? (
              <Alert color="yellow" icon={<AlertTriangle size={16} />}>
                <span>
                  <Link
                    target="_blank"
                    href={`${CONFIGURATION_COMMON_MODELS_PATH}/${linkedAccount!.category}`}
                  >
                    Enable Remote Data
                  </Link>
                  {"  "} to create a new Common Model field for this model
                </span>
              </Alert>
            ) : (
              <>
                <TextField
                  label="Target field Name"
                  placeholder="Name"
                  value={fieldMappingTargetName!}
                  onChange={(e) => {
                    setFieldMappingTargetName(e.target.value);
                  }}
                />
                <TextField
                  label={
                    <div className="flex items-center justify-between">
                      <Text variant="h6">Target field description</Text>
                      <Text variant="sm" className=" text-gray-60">
                        Optional
                      </Text>
                    </div>
                  }
                  className="mt-4"
                  placeholder="Description"
                  value={fieldMappingTargetDescription ? fieldMappingTargetDescription : ""}
                  onChange={(e) => {
                    setFieldMappingTargetDescription(e.target.value);
                  }}
                />
              </>
            )}
          </div>
          <div className="mt-6">
            <CancelAndNextFooter
              isNextDisabled={
                selectedModelId == null ||
                !doesModelHaveRemoteDataEnabled(selectedModelId, commonModelsToMappingConfig) ||
                fieldMappingTargetName == null ||
                fieldMappingTargetName == ""
              }
              onCancel={onModalClose}
              onNext={() => {
                if (selectedModelId != null) {
                  setCommonModelSelected(selectedModelId);
                  setFormStep("map-field-to-target");
                  setFieldMappingTargetName(fieldMappingTargetName);
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateFieldMappingModelSelection;
