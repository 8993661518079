import React from "react";
import FieldMappingModalTitle from "../FieldMappingModalTitle";
import FieldMappingsMergeToIntegrationHeader from "../../components/FieldMappingsMergeToIntegrationHeader";
import { ArrowLeft } from "lucide-react";
import CancelAndNextFooter from "../../../../../../common/CancelAndNextFooter";
import { createFieldMappingOptions } from "../../../../../configuration/field-mappings/utils/FieldMappingUtils";
import { Typeahead } from "@merge-api/merge-javascript-shared";
import AdvancedFieldMappingForm from "./advanced-field-mappings/AdvancedFieldMappingsForm";
import useRemoteFieldsDropdown from "./hooks/useRemoteFieldsDropdown";
import { FieldMappingCreationAndEditDict } from "../../../../../../../models/Entities";
import FieldMappingDropdownChild from "../../../../../../shared/FieldMappingDropdownChild";
import FieldMappingsGridContainer from "../../components/FieldMappingsGridContainer";
import FieldMappingCard from "../../components/FieldMappingCard";
import FieldMappingTargetBadge from "../../../../../../shared/FieldMappingTargetBadge";

const MapFieldMappingTarget = () => {
  const {
    linkedAccount,
    commonModelSelected,
    onModalClose,
    fieldMappingTargetName,
    fieldMappingTargetDescription,
    selectedRemoteField,
    setSelectedRemoteField,
    availableFieldMappings,
    setJmesPath,
    setIsJmesPathValid,
    setShowAdvancedMappingOption,
    showAdvancedMappingOption,
    jmesPath,
    isCreating,
    isJmesPathValid,
    createFieldMapping,
  } = useRemoteFieldsDropdown();
  return (
    <>
      <FieldMappingModalTitle
        title={`Map a new ${linkedAccount?.end_user.organization_name}-specific target field`}
        onClose={onModalClose}
      />
      <div className="px-6 mt-5">
        <div className="text-base">
          <p className="mb-2">
            Select a third-party Remote Field to create a Field Mapping for this new{" "}
            {linkedAccount?.end_user.organization_name}-specific target field.
          </p>
          <p className="mb-0">
            This change will be reflected after the next sync which will be a full resync of data
            for {linkedAccount?.end_user.organization_name}.
          </p>
        </div>
        <div className="flex flex-col mt-6 gap-y-4">
          <FieldMappingsMergeToIntegrationHeader
            integrationName={linkedAccount?.integration.name!}
            integrationSquareImage={linkedAccount?.integration.square_image}
            isSingular
          />

          <FieldMappingsGridContainer>
            <>
              <FieldMappingCard
                displayName={fieldMappingTargetName || ""}
                description={fieldMappingTargetDescription}
                badge={<FieldMappingTargetBadge />}
              />
              <div className="flex p-4 pt-3">
                <ArrowLeft size={20} />
              </div>
              <Typeahead
                value={selectedRemoteField}
                options={
                  availableFieldMappings
                    ? createFieldMappingOptions(availableFieldMappings!, commonModelSelected)
                    : []
                }
                onChange={(_, selectedRemoteField: any) => {
                  if (selectedRemoteField) {
                    setSelectedRemoteField(selectedRemoteField);
                    // reset jmes path on any remote field selection switch
                    setJmesPath(null);
                    setIsJmesPathValid(false);
                    setShowAdvancedMappingOption(false);
                    if (
                      (selectedRemoteField?.type.includes("list") &&
                        selectedRemoteField.value?.length !== 0) ||
                      selectedRemoteField.type == "dict"
                    ) {
                      setTimeout(() => {
                        setShowAdvancedMappingOption(true);
                      }, 400);
                    }
                  } else {
                    setSelectedRemoteField(null);
                    // if advanced mapping tool is opened and closed and another remote field is selceted
                    setJmesPath(null);
                    setIsJmesPathValid(false);
                  }
                }}
                placeholder={
                  availableFieldMappings == undefined ? "Loading fields..." : "Search fields..."
                }
                getOptionLabel={(option: any) => {
                  return option?.display_name ?? "";
                }}
                renderOption={(fieldMapping: FieldMappingCreationAndEditDict) => {
                  return <FieldMappingDropdownChild option={fieldMapping} />;
                }}
                loadingText="Loading fields..."
                loading={availableFieldMappings == undefined}
              />
            </>
          </FieldMappingsGridContainer>
        </div>
      </div>
      <div
        className={`${
          showAdvancedMappingOption && selectedRemoteField ? `mt-4 px-6` : "h-0 opacity-0"
        } transition-all ease-in-out duration-400`}
      >
        {showAdvancedMappingOption && selectedRemoteField && (
          <AdvancedFieldMappingForm
            setIsJmesPathValid={setIsJmesPathValid}
            onJmesPathChange={(jmesPath: string | null) => {
              setJmesPath(jmesPath);
            }}
            jsonData={selectedRemoteField.value}
          />
        )}
      </div>
      <div className="pr-6 !mt-6">
        <CancelAndNextFooter
          buttonText="Save"
          onCancel={onModalClose}
          onNext={() => {
            createFieldMapping(selectedRemoteField);
          }}
          isNextDisabled={selectedRemoteField == null || (jmesPath !== null && !isJmesPathValid)}
          isLoading={isCreating}
        />
      </div>
    </>
  );
};
export default MapFieldMappingTarget;
