import React from "react";
import clsx from "clsx";
import { EndpointUsageDataResponse } from "../../types";
import { APICategory, Card } from "@merge-api/merge-javascript-shared";
import EndpointUsageTableColHeader from "./components/EndpointUsageTableColHeader";
import EndpointUsageTableRow from "./components/EndpointUsageTableRow";
import TableSkeletonLoader from "../../../../../shared/TableSkeletonLoader";
import MergeTable from "../../../../../../shared/MergeTable";

type EndpointUsageTableProps = {
  endpointUsageData: EndpointUsageDataResponse | undefined;
  loading: boolean;
  selectedCategory: APICategory;
};

export const COL_HEADER_HEIGHT = "max-h-[60px] h-[60px]";
export const ROW_HEADER_WIDTH = "min-w-[264px]";
export const CELL_WIDTH = "min-w-[220px]";

const EndpointUsageTable = ({
  endpointUsageData,
  loading,
  selectedCategory,
}: EndpointUsageTableProps) => {
  if (loading) {
    return (
      <MergeTable
        hover={false}
        hasMarginBottom={false}
        content={<TableSkeletonLoader cols={7} rows={8} />}
      />
    );
  }

  return (
    <Card className="overflow-x-auto">
      <table>
        <thead>
          {/* Col Headers */}
          <tr className="border-b-[1px] border-solid border-gray-10">
            <div className="sticky left-0">
              <th
                className={clsx(
                  ROW_HEADER_WIDTH,
                  COL_HEADER_HEIGHT,
                  "bg-white z-10 px-4 flex flex-row items-center justify-start border-r-[1px] border-solid border-gray-10 overflow-visible shadow-[4px_0_6px_-2px_rgba(0,0,0,0.12)]",
                )}
              >
                Endpoints
              </th>
            </div>
            {endpointUsageData?.integrations.map((integration) => (
              <EndpointUsageTableColHeader integration={integration} key={integration.id} />
            ))}
          </tr>
        </thead>

        <tbody>
          {/* Rows */}
          {endpointUsageData?.endpoints.map((endpoint, index) => (
            <EndpointUsageTableRow
              key={endpoint}
              index={index}
              endpointUsageData={endpointUsageData}
              endpoint={endpoint}
              selectedCategory={selectedCategory}
            />
          ))}
        </tbody>
      </table>
    </Card>
  );
};

export default EndpointUsageTable;
