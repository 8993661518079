import React from "react";
import clsx from "clsx";
import { Link, Text } from "@merge-api/merge-javascript-shared";
import { ArrowUpRight } from "lucide-react";
import AuthenticationInstructions from "./AuthenticationInstructions";

type MergeLinkDescriptionTableProps = {
  className?: string;
  title: string;
  description: string;
  helpGuideUrl?: string;
  integrationColor?: string;
};

const MergeLinkDescriptionTable = ({
  className,
  title,
  description,
  helpGuideUrl = "test",
  integrationColor,
}: MergeLinkDescriptionTableProps) => {
  return (
    <div className={clsx("w-full", className)}>
      <div className="flex flex-col rounded-lg border-gray-10 border-[1px] w-full">
        {/* Header */}
        <div className="flex flex-row justify-between items-center p-2">
          <Text variant="pre-title" className="text-gray-60">
            {title}
          </Text>
          {helpGuideUrl ? (
            <Link variant="sm" href={helpGuideUrl} target="_blank">
              Help Center <ArrowUpRight size={12} />
            </Link>
          ) : (
            // placeholder div for flex
            <div />
          )}
        </div>

        {/* Body */}
        <div className="border-gray-10 border-t-[1px]">
          <AuthenticationInstructions
            description={description}
            integrationColor={integrationColor}
          />
        </div>
      </div>
    </div>
  );
};

export default MergeLinkDescriptionTable;
