import { ArrowUpRight, MoreVertical } from "lucide-react";
import React, { useEffect, useState } from "react";
import get from "lodash/get";
import {
  FieldMappingCreationAndEditDict,
  FieldMappingInstance,
  FieldMappingOptionsResponse,
  FieldMappingTarget,
  LinkedAccount,
} from "../../../../../models/Entities";
import DeleteFieldMappingModal from "./DeleteFieldMappingModal";
import { createFieldMappingOptions } from "../../../configuration/field-mappings/utils/FieldMappingUtils";
import {
  EditFieldMappingInstanceProps,
  NewFieldMappingProps,
  createLinkedAccountFieldMapping,
  editFieldMappingInstance,
  fetchFieldMappingInstance,
} from "../../../../../api-client/APIClient";
import { FieldMappingSource } from "../../../../../constants";
import {
  Typeahead,
  Button,
  ButtonVariant,
  Menu,
  MenuItem,
} from "@merge-api/merge-javascript-shared";
import { ArrowLeft } from "lucide-react";
import EditJmesPathModal from "./modal/field-mappings/EditJmesPathModal";
import useLinkedAccountCustomMappingsContext from "./context/useLinkedAccountFieldMappingsContext";
import FieldMappingDropdownChild from "../../../../shared/FieldMappingDropdownChild";
import { showSuccessToast } from "../../../../shared/Toasts";
import FieldMappingsGridContainer from "./components/FieldMappingsGridContainer";
import FieldMappingCard from "./components/FieldMappingCard";
import FieldMappingTargetBadge from "../../../../shared/FieldMappingTargetBadge";
import { navigateToFieldMappingSettings } from "../../../../../router/RouterUtils";
import { useHistory } from "react-router-dom";
import FieldMappingCardRemoteFieldsSubtitle from "./components/FieldMappingCardRemoteFieldsSubtitle";
import FieldMappingRemoteFieldsBadge from "./components/FieldMappingRemoteFieldsBadge";

type Props = {
  fieldMapping: FieldMappingInstance;
  linkedAccount: LinkedAccount;
  fieldMappingMetaResponse: FieldMappingOptionsResponse | undefined;
  common_model: string;
  newMapping?: boolean;
  newMappingSaved?: (instance: FieldMappingInstance) => void;
  cancelNewMapping?: () => void;
  exampleValue?: string | null;
};

const FieldMappingInstanceRow = ({
  fieldMapping: fieldMapping,
  linkedAccount,
  fieldMappingMetaResponse,
  common_model,
  newMapping = false,
  newMappingSaved = () => {},
  exampleValue,
}: Props) => {
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [creatingNewMapping, setCreatingNewMapping] = useState<boolean>(newMapping);
  const { refreshFieldMappings: refreshCustomMappings } = useLinkedAccountCustomMappingsContext();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editingAdvancedMappingPath, setEditAdvancedMappingPath] = useState(false);

  const [fieldMappingOptions, setFieldMappingOptions] = useState<FieldMappingCreationAndEditDict[]>(
    [],
  );
  const [originFields, setOriginFields] = useState<FieldMappingCreationAndEditDict | null>(null);
  const [fieldMappingTarget, setFieldMappingTarget] = useState<FieldMappingTarget | null>(null);
  const [fieldMappingInstance, setFieldMappingInstance] =
    useState<FieldMappingInstance>(fieldMapping);
  const fieldMappingMetaOptions = fieldMappingMetaResponse?.remote_field_options;

  const fieldMappingTargetID =
    fieldMappingInstance.is_integration_wide && fieldMappingInstance.field_mapping_target !== null
      ? fieldMappingInstance.field_mapping_target.id
      : undefined;

  const [fieldMappingInstanceID, setFieldMappingInstanceID] = useState<string | undefined>(
    fieldMapping.is_integration_wide && fieldMapping.field_mapping_target !== null
      ? undefined
      : fieldMapping.id,
  );

  const jmesPath = get(fieldMappingInstance, "jmes_path");
  const remoteFieldType = get(originFields, "type", "");
  const isAdvancedMappingType = remoteFieldType.includes("list") || remoteFieldType == "dict";
  const hasOptionChanged =
    get(originFields, "traversal_path", "").split(".").toString() !==
    fieldMappingInstance.field_traversal_path.toString();

  const updateFieldMappingTarget = () => {
    if (fieldMappingTargetID && fieldMappingTarget == null) {
      setFieldMappingTarget(fieldMappingInstance.field_mapping_target ?? null);
    }
  };
  updateFieldMappingTarget();

  const fetchAndSetFieldMappingInstanceInfo = (onResponse: () => void) => {
    if (fieldMappingInstanceID && fieldMappingInstance == null) {
      fetchFieldMappingInstance(fieldMappingInstanceID, setFieldMappingInstance);
    }

    onResponse();
  };
  fetchAndSetFieldMappingInstanceInfo(() => {});

  useEffect(() => {
    if (creatingNewMapping == false && newMapping) {
      newMappingSaved(fieldMappingInstance);
    }
  }, [fieldMappingInstance]);

  const setandFetchFieldMappingInstance = (id: string) => {
    setFieldMappingInstanceID(id);
    setCreatingNewMapping(false);
    fetchFieldMappingInstance(id, setFieldMappingInstance);
  };

  useEffect(() => {
    if (fieldMappingMetaOptions !== undefined) {
      setFieldMappingOptions(createFieldMappingOptions(fieldMappingMetaOptions, common_model));
    }
  }, [fieldMappingMetaOptions]);

  const createFieldMapping = (
    passedOrigin: FieldMappingCreationAndEditDict | undefined = undefined,
    jmesPath: string | null = null,
  ) => {
    const origin = passedOrigin ? passedOrigin : originFields;
    if (origin) {
      const body: NewFieldMappingProps = {
        linked_account_id: linkedAccount.id,
        common_model_id: `${linkedAccount.category}.${common_model}`,
        field_key: fieldMappingTarget?.field_key ?? "",
        field_traversal_path: origin.traversal_path.split("."),
        create_for_organization: false,
        configured_by: FieldMappingSource.ORGANIZATION,
        ...(fieldMappingTarget?.field_description
          ? {
              field_description: fieldMappingTarget?.field_description,
            }
          : {}),
        ...(fieldMappingTargetID ? { field_mapping_target_id: fieldMappingTargetID } : {}),
        api_endpoint_id: origin.api_endpoint_id,
        display_name: origin.display_name,
        origin_type: origin.type,
        ...(jmesPath ? { jmes_path: jmesPath } : {}),
      };
      createLinkedAccountFieldMapping(body, (createdFieldMappingId: string) => {
        setandFetchFieldMappingInstance(createdFieldMappingId);
        showSuccessToast("Successfully created mapping!");
        setIsEditing(false);
      });
    }
  };

  const fetchAndUpdateFieldMappingInstance = () => {
    fetchFieldMappingInstance(fieldMappingInstanceID!, setFieldMappingInstance, () => {
      setIsEditing(false);
    });
  };

  const updateCustomMappingInstance = (originFields: any, jmes_path: string | null = null) => {
    if (fieldMappingInstanceID && originFields) {
      const body: EditFieldMappingInstanceProps = {
        field_traversal_path: originFields.traversal_path.split("."),
        field_mapping_instance_id: fieldMappingInstanceID,
        api_endpoint_id: originFields.api_endpoint_id,
        origin_type: originFields.type,
        display_name: originFields.display_name,
        field_description: fieldMappingInstance?.field_description,
        jmes_path: jmes_path ?? originFields.jmes_path,
      };
      editFieldMappingInstance(fieldMappingInstanceID!, body, () => {
        fetchAndUpdateFieldMappingInstance();
        setIsEditing(false);
        showSuccessToast("Successfully edited mapping!");
      });
    }
  };

  useEffect(() => {
    if (fieldMappingInstance && fieldMappingOptions) {
      const currentOption = fieldMappingOptions.find(
        (fieldMappingOption) =>
          fieldMappingOption.display_name === fieldMappingInstance.display_name,
      );
      if (currentOption) {
        setOriginFields(currentOption);
      }
    }
  }, [fieldMappingInstance, fieldMappingOptions, isEditing]);

  useEffect(() => {
    if (fieldMappingMetaOptions !== undefined) {
      setFieldMappingOptions(createFieldMappingOptions(fieldMappingMetaOptions, common_model));
    }
  }, [fieldMappingMetaOptions]);

  // The dropdown logic below was a little complicated but basically:
  // 1. Render dropdown only if the field mapping is not int-wide OR it has linked account level overrides, and
  // if it's not the case that it's integration wide and remote data/shallow runs arent enabled.
  // 2. Render edit only if remote data was enabled. The edit page will either route to a linked account edit
  // if the rendered instance is a non int wide mapping or an override if its an int wide mapping
  // 3. Only show delete if it's not an integration wide mapping. cant delete from this page

  const onDeleteSuccess = (data: any) => {
    const orgWideInstance = get(data, "org_wide_instance", null);
    if (orgWideInstance) {
      // deleted linked account override set field mapping to the org wide instance
      setFieldMappingInstance(orgWideInstance);
    } else {
      refreshCustomMappings();
    }
  };

  return (
    <>
      {editingAdvancedMappingPath && isAdvancedMappingType && originFields?.value && (
        <EditJmesPathModal
          currentJmesPath={hasOptionChanged ? null : jmesPath}
          jsonData={originFields.value}
          remoteFieldDisplayName={fieldMappingInstance.display_name}
          onModalClose={() => {
            setEditAdvancedMappingPath(false);
          }}
          onUpdate={(jmesPath: string) => {
            if (fieldMappingInstance.is_integration_wide) {
              createFieldMapping(originFields, jmesPath);
            } else {
              updateCustomMappingInstance(originFields, jmesPath);
            }
            setEditAdvancedMappingPath(false);
          }}
        />
      )}

      <DeleteFieldMappingModal
        fieldMappingID={fieldMappingInstance.id}
        showModal={showDeleteModal}
        linkedAccountID={linkedAccount.id}
        onHide={() => setShowDeleteModal(false)}
        onSuccess={onDeleteSuccess}
        skipFieldMappingRefresh={fieldMappingInstance?.field_mapping_target != null}
      />
      <FieldMappingsGridContainer hasDropdown>
        <>
          <FieldMappingCard
            displayName={fieldMappingInstance.field_key}
            description={fieldMappingInstance.field_description}
            badge={
              <FieldMappingTargetBadge
                type={
                  fieldMappingInstance?.field_mapping_target == null
                    ? "linked-account-specific-target-field"
                    : "org-wide-target-field"
                }
                linkedAccount={linkedAccount}
              />
            }
            onClick={
              fieldMappingInstance.is_integration_wide
                ? () =>
                    navigateToFieldMappingSettings(
                      history,
                      fieldMappingInstance?.field_mapping_target?.id,
                    )
                : undefined
            }
          />
          <div className="flex justify-center items-center h-full">
            <ArrowLeft size={20} />
          </div>

          {isEditing ? (
            <div className="flex flex-col w-100 gap-y-3">
              <Typeahead
                value={fieldMappingOptions.find(
                  (fieldMappingOption) =>
                    fieldMappingOption.display_name === fieldMappingInstance.display_name,
                )}
                options={fieldMappingOptions}
                onChange={(_, selectedRemoteField: any) => {
                  setOriginFields(selectedRemoteField);
                }}
                placeholder={
                  fieldMappingOptions.length > 0 ? "Search fields..." : "No fields found."
                }
                getOptionLabel={(option: FieldMappingCreationAndEditDict) => {
                  return option?.display_name ?? "";
                }}
                renderOption={(option: FieldMappingCreationAndEditDict) => {
                  return <FieldMappingDropdownChild option={option} />;
                }}
                loadingText="Loading fields..."
                loading={fieldMappingMetaOptions == undefined}
              />
              {isAdvancedMappingType && (
                <>
                  {hasOptionChanged ? (
                    <Button
                      size="sm"
                      variant={ButtonVariant.SecondaryBlue}
                      onClick={() => {
                        setEditAdvancedMappingPath(true);
                      }}
                      className="ml-auto"
                      rightIcon={<ArrowUpRight size={12} />}
                    >
                      Advanced mapping
                    </Button>
                  ) : (
                    <div className="flex items-center py-2 px-3  justify-between bg-blue-0 rounded-md text-sm">
                      <div className="text-wrap">{jmesPath}</div>
                      <Button
                        variant={ButtonVariant.TextBlue}
                        size="sm"
                        rightIcon={<ArrowUpRight size={12} />}
                        onClick={() => {
                          setEditAdvancedMappingPath(true);
                        }}
                      >
                        Advanced mapping
                      </Button>
                    </div>
                  )}
                </>
              )}
              <div className="flex flex-row items-center justify-content-end gap-x-3">
                <Button
                  size="sm"
                  variant={ButtonVariant.TextBlack}
                  onClick={() => {
                    setIsEditing(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  variant={ButtonVariant.TertiaryWhite}
                  disabled={originFields ? false : true}
                  onClick={() => {
                    if (originFields) {
                      // if we are creating a linked account override for an integration wide field mapping we should be creating a new linked account field mapping instance
                      if (fieldMappingInstance?.is_integration_wide) {
                        createFieldMapping(originFields);
                      } else {
                        updateCustomMappingInstance(originFields);
                      }
                    }
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          ) : (
            <FieldMappingCard
              displayName={fieldMappingInstance.display_name}
              description={
                <FieldMappingCardRemoteFieldsSubtitle
                  exampleValue={exampleValue}
                  jmesPath={jmesPath}
                  remoteEndpointMethod={fieldMappingInstance.remote_endpoint_method}
                  remoteEndpointPath={fieldMappingInstance.remote_endpoint_path}
                />
              }
              badge={
                <FieldMappingRemoteFieldsBadge
                  isOrganizationWide={fieldMappingInstance?.is_integration_wide}
                  linkedAccount={linkedAccount}
                />
              }
              type={fieldMappingInstance.origin_type}
            />
          )}

          <div className="flex items-center mx-1">
            <Menu
              ButtonProps={{
                children: <MoreVertical size={16} />,
                variant: ButtonVariant.IconShadowHidden,
                className: "p-0",
                size: "sm",
              }}
              menuPlacement="bottom-end"
            >
              <MenuItem onClick={() => setIsEditing(true)}>Edit mapping</MenuItem>
              {!fieldMappingInstance.is_integration_wide && (
                <MenuItem
                  onClick={() => setShowDeleteModal(true)}
                  className={fieldMappingInstance.is_integration_wide ? "text-gray-60" : ""}
                >
                  Delete mapping
                </MenuItem>
              )}
            </Menu>
          </div>
        </>
      </FieldMappingsGridContainer>
    </>
  );
};

export default FieldMappingInstanceRow;
