import React, { useContext, useState } from "react";
import FieldMappingModalTitle from "../FieldMappingModalTitle";
import FieldMappingModalContext from "../context/FieldMappingModalContext";
import { Alert, Typeahead, Text, Link } from "@merge-api/merge-javascript-shared";
import { doesModelHaveRemoteDataEnabled } from "../field-mappings/CreateFieldMappingModelSelection";
import CancelAndNextFooter from "../../../../../../common/CancelAndNextFooter";
import { AlertTriangle } from "lucide-react";
import { CONFIGURATION_COMMON_MODELS_PATH } from "../../../../../../../router/RouterUtils";
import CommonModelFieldDropdown from "../../../../../../shared/CommonModelFieldDropdown";
import {
  CommonModelFieldDefinition,
  FieldMappingMetaResponse,
} from "../../../../../../../models/Entities";

const CommonModelOverrideChoice = () => {
  const {
    linkedAccount,
    onModalClose,
    linkedAccountMappingInfo,
    commonModelsForIntegration,
    commonModelsToMappingConfig,
    setFormStep,
    setCommonModelSelected,
    fieldToOverride,
    setFieldToOverride,
  } = useContext(FieldMappingModalContext);

  const [selectedModelId, setSelectedModelId] = useState<string | null | undefined>(null);

  const availableFieldsAndOverrides = (linkedAccountMappingInfo as FieldMappingMetaResponse)
    .available_model_and_field_overrides;
  const availableFieldsToModelsMap = Object.values(availableFieldsAndOverrides!)?.reduce(
    (
      acc: {
        [common_model_id: string]: CommonModelFieldDefinition[];
      },
      modelOverrideInfo,
    ) => {
      acc[modelOverrideInfo.common_model_name] =
        modelOverrideInfo["allowed_overridden_fields_configs"];
      return acc;
    },
    {},
  );

  const getOverrideOptions = () => {
    if (!selectedModelId) {
      return [];
    }
    return availableFieldsToModelsMap[selectedModelId];
  };

  return (
    <>
      <FieldMappingModalTitle title="Override an existing Merge field" onClose={onModalClose} />
      <div className="px-6 mt-5">
        <div className="mb-4">
          Select an existing Merge field on a Common Model to override and map new third-party data
          to.
        </div>
        <Text variant="h6" className="mb-2">
          Common Model
        </Text>
        <Typeahead
          className="mb-4"
          value={selectedModelId}
          placeholder="Select Common Model..."
          onChange={(_: any, selectedOption: any) => {
            if (selectedOption == null) {
              setSelectedModelId(selectedOption);
            } else {
              // if switching from model with remote enabled to not skip timeout
              if (
                selectedModelId &&
                doesModelHaveRemoteDataEnabled(selectedModelId, commonModelsToMappingConfig) &&
                !doesModelHaveRemoteDataEnabled(selectedOption, commonModelsToMappingConfig)
              ) {
                setSelectedModelId(selectedOption);
              } else {
                setTimeout(() => {
                  setSelectedModelId(selectedOption);
                }, 400);
              }
            }
          }}
          options={commonModelsForIntegration}
        />
        <div
          className={`transition-all duration-400 ease-in-out ${
            selectedModelId ? "" : "h-0 opacity-0"
          }`}
        >
          {!selectedModelId ? (
            <></>
          ) : !doesModelHaveRemoteDataEnabled(selectedModelId!, commonModelsToMappingConfig) ? (
            <Alert color="yellow" icon={<AlertTriangle size={16} className="text-yellow-50" />}>
              <Link
                target="_blank"
                href={`${CONFIGURATION_COMMON_MODELS_PATH}/${linkedAccount!.category}`}
              >
                Enable Remote Data
              </Link>
              &nbsp;to create a new Common Model field for this Model
            </Alert>
          ) : (
            <>
              <Text variant="h6" className="mb-2">
                Common Model field
              </Text>
              <Typeahead
                placeholder="Select field..."
                options={getOverrideOptions()}
                getOptionLabel={(option) => {
                  return option.field_name;
                }}
                renderOption={(option) => {
                  return (
                    <CommonModelFieldDropdown
                      fieldDescription={option.field_description}
                      fieldType={option.field_format || option.field_type}
                      fieldName={option.field_name}
                    />
                  );
                }}
                onChange={(_: any, selectedOption) => {
                  if (selectedOption) {
                    setFieldToOverride({
                      name: selectedOption.field_name as string,
                      type: selectedOption.field_type,
                      description: selectedOption.field_description,
                      format: selectedOption.field_format,
                    });
                  } else {
                    setFieldToOverride(null);
                  }
                }}
                filterOptions={(options: any, { inputValue }: any) => {
                  return options.filter((option: CommonModelFieldDefinition) => {
                    const inputValueToLowerCase = inputValue.toLowerCase();
                    return (
                      option.field_name.includes(inputValueToLowerCase) ||
                      option.field_description.includes(inputValueToLowerCase)
                    );
                  });
                }}
              />
            </>
          )}
        </div>
        <div className="mt-6">
          <CancelAndNextFooter
            isNextDisabled={
              selectedModelId == null ||
              !doesModelHaveRemoteDataEnabled(selectedModelId, commonModelsToMappingConfig) ||
              fieldToOverride == null
            }
            onCancel={onModalClose}
            onNext={() => {
              if (selectedModelId != null && fieldToOverride != null) {
                setCommonModelSelected(selectedModelId);
                setFormStep("map-override-to-field");
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default CommonModelOverrideChoice;
