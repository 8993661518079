import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import TileStep from "../helpers/TileStep";
import { ReactComponent as LinkVector } from "../../src/link-over-ui.svg";
import { Info, Wand } from "lucide-react";
import { palette } from "../../../../../styles/theme";
import LeftSideBar from "../helpers/LeftSideBar";
import LeftSideBarSpacing, { LeftSideBarCol, PaddingLessCol } from "../helpers/LeftSideBarSpacing";
import LiveWaitingIndicator from "../LiveWaitingIndicator";
import {
  DOCS_LINKING_FLOW_GET_STARTED_PATH,
  DOCS_MAGIC_LINK_PATH,
} from "../../../../../router/RouterUtils";
import useDashboardOnboardingStatus from "../../utils/useDashboardOnboardingStatus";
import DeprecatedH3 from "../../../../../deprecated/DeprecatedH3";
import AddProductionLinkedAccountButton from "../../../linked-accounts/AddProductionLinkedAccountButton";

import Mountain from "../../src/mountain-image.png";
import { Tooltip } from "@merge-api/merge-javascript-shared";
import { Text } from "@merge-api/merge-javascript-shared";

const FillerDiv = styled.div`
  height: 32px;
  margin-bottom: 17px;
`;

const MagicLinkBox = styled.div`
  border-radius: 14px;
  padding: 20px;
  word-wrap: break-word;
  font-size: 12px;
  width: 240px;
  background: #f5f8ff;
  margin-top: 10px;
  gap: 16px;
  margin-left: 32px;
`;

const MagicLinkBoxResize = styled.div`
  border-radius: 14px;
  padding: 20px;
  word-wrap: break-word;
  font-size: 12px;
  width: 100%;
  background: #f5f8ff;
  margin-top: 32px;
  gap: 16px;
`;

const StyledWand = styled(Wand)`
  color: ${palette.indigo};
`;

const SideBoxH3 = styled(DeprecatedH3)`
  display: flex;
  align-items: center;
  margin: 0px 0px 16px;
  font-size: 16px;
`;

const MagicLinkBoxCol = styled(Col)`
  padding-left: 0px;
  padding-right: 0px;
  @media (max-width: 1329px) {
    display: none;
  }
`;

const MagicLinkBoxResizeCol = styled.div`
  display: none;
  @media (max-width: 1329px) {
    display: flex;
    width: 100%;
    margin-bottom: 32px;
  }
`;
const StyledP = styled.p`
  margin-bottom: 9px;
`;

const RelativeContainer = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  top: 5px;
`;

const AbsoluteDiv = styled.div`
  position: absolute;
  bottom: 20px;
`;

const MountainHolder = styled.div`
  position: absolute;
`;

const SpacingDiv = styled.div`
  height: 32px;
`;

/*
 ** The 1330px is selected based on the same media queries used 'DashboardOnboardingWrapper' to transition from the full sized
 *  Navigation bars to the dropdown. We use this same media query to transition the rest of the page to be more mobile responsive
 */
const ContainerCol = styled(Col)`
  @media (min-width: 1330px) {
    flex: 0 0 75%;
    max-width: 75%;
  }
`;

const AddLinkToAppSection = () => {
  const dashboardOnboardingStatus = useDashboardOnboardingStatus();

  return (
    <div className="flex flex-row items-start">
      <div className="flex flex-col">
        <Text variant="h3" className="mb-6">
          Add Merge Link to your product
        </Text>
        <Row>
          <LeftSideBar stepNumber="1" isNormalStep />
          <TileStep
            img={<LinkVector />}
            title={
              <>
                <div className="flex items-center">
                  Installation
                  <Tooltip
                    title="In order to successfully complete this step, you must pass your
                            production access key in as a header to get a link token."
                  >
                    <span className="flex align-middle">
                      <Info size={18} className="ml-2 text-gray-60" />
                    </span>
                  </Tooltip>
                </div>
              </>
            }
            subtitle={
              <>
                Add Merge Link to your product using{" "}
                <a target="_blank" href={DOCS_LINKING_FLOW_GET_STARTED_PATH} rel="noreferrer">
                  this guide
                </a>{" "}
                in our docs.
              </>
            }
            stepNumber={1}
          />
        </Row>
        <Row>
          <LeftSideBarSpacing />
          <PaddingLessCol>
            <SpacingDiv />
          </PaddingLessCol>
        </Row>
        <Row>
          <LeftSideBar stepNumber="2" isNormalStep={false} />
          <TileStep
            img={
              <RelativeContainer>
                <MountainHolder>
                  <img src={Mountain} height={100}></img>
                </MountainHolder>
                <AbsoluteDiv>
                  {dashboardOnboardingStatus?.has_opened_production_link ? (
                    <LiveWaitingIndicator color="green" />
                  ) : (
                    <LiveWaitingIndicator color="red" />
                  )}
                </AbsoluteDiv>
              </RelativeContainer>
            }
            title="Test run"
            subtitle={
              <>
                <p>Once you've added Merge Link to your product, visit your domain and open it.</p>
                Merge Link will give us the 👍 and we'll check this one off your list!
              </>
            }
            stepNumber={2}
          />
        </Row>
        <Row>
          <LeftSideBarCol className="col-2 align-items-center"></LeftSideBarCol>
          <PaddingLessCol>
            <MagicLinkBoxResizeCol>
              <MagicLinkBoxResize>
                <SideBoxH3>
                  <StyledWand className="mr-3" />
                  Magic Link
                </SideBoxH3>
                <span>
                  <StyledP>Want to start using Merge Link without writing any code?</StyledP>
                  View our{" "}
                  <a target="_blank" href={DOCS_MAGIC_LINK_PATH} rel="noreferrer">
                    Magic Link
                  </a>{" "}
                  guide to send end users a URL to authorize their integrations!
                </span>
                <AddProductionLinkedAccountButton isGetStarted />
              </MagicLinkBoxResize>
            </MagicLinkBoxResizeCol>
          </PaddingLessCol>
        </Row>
      </div>
      <MagicLinkBoxCol className="col-3 offset-12">
        <Row>
          <FillerDiv />
        </Row>
        <MagicLinkBox>
          <SideBoxH3>
            <StyledWand className="mr-3" />
            Magic Link
          </SideBoxH3>
          <span>
            <StyledP>Want to start using Merge Link without writing any code?</StyledP>
            View our{" "}
            <a target="_blank" href={DOCS_MAGIC_LINK_PATH} rel="noreferrer">
              Magic Link
            </a>{" "}
            guide to send end users a URL to authorize their integrations, instead of authenticating
            via Link in your product!
          </span>
          <AddProductionLinkedAccountButton isGetStarted />
        </MagicLinkBox>
      </MagicLinkBoxCol>
    </div>
  );
};

export default AddLinkToAppSection;
