import React from "react";
import { Key } from "lucide-react";

type Props = Pick<React.ComponentProps<"div">, "className"> & {
  textLength: number;
};

const IconGrayTextCard = ({ textLength }: Props) => {
  return (
    <div className="flex flex-row flex-nowrap bg-gray-0 text-gray-60 items-center rounded px-2 py-[2px] ml-4 mt-0 w-48">
      <div className="mr-1">
        <Key size={12} />
      </div>
      <div className="flex-grow">
        <div className="px-1 whitespace-nowrap overflow-hidden">{"•".repeat(textLength)}</div>
      </div>
    </div>
  );
};

export default IconGrayTextCard;
