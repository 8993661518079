import React from "react";
import clsx from "clsx";

interface EnabledDisabledDivProps {
  isEnabled: boolean;
  className?: string;
}

const EnabledDisabledDiv: React.FC<EnabledDisabledDivProps> = ({
  isEnabled,
  className,
  children,
}) => {
  return (
    <div
      className={clsx(
        "font-medium text-base flex items-center flex-nowrap",
        isEnabled ? "text-teal-60" : "text-gray-60",
        className,
      )}
    >
      {children}
    </div>
  );
};

export default EnabledDisabledDiv;
