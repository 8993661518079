import React, { useEffect, useState } from "react";
import { SelectedWebhookType } from "../../enums";
import LinkedAccountIssuesWebhook from "./components/LinkedAccountIssuesWebhook";
import FirstSyncWebhook from "./components/FirstSyncWebhook";
import AsyncPassthroughWebhook from "./components/AsyncPassthroughWebhook";
import ChangedDataWebhook from "./components/ChangedDataWebhook";
import { useWebhookOptions } from "../../hooks/useWebhookOptions";
import WebhookSelectLoading from "./components/WebhookSelectLoading";
import CommonModelSyncWebhook from "./components/CommonModelSyncWebhook";
import LinkedAccountLinkedWebhook from "./components/LinkedAccountLinkedWebhook";
import uniq from "lodash-es/uniq";
import { APICategory } from "@merge-api/merge-javascript-shared";
import LinkedAccountSyncedWebhook from "./components/LinkedAccountSyncedWebhook";
import LinkedAccountDeletedWebhook from "./components/LinkedAccountDeletedWebhook";
import AnySyncWebhook from "./components/AnySyncWebhook";

interface WebhookTypeSelectProps {
  onSelectedWebhookTypeChange: (selectedWebhookType: SelectedWebhookType) => void;
  onUnselectingWebhookType: (selectedWebhookType: SelectedWebhookType) => void;
  selectedWebhookType: Set<SelectedWebhookType>;
  isLoading: boolean;

  // common model select props

  onSelectedSyncCategoriesChange: (selectedCategories: APICategory[]) => void;
  selectedSyncCategories: APICategory[];
  onSelectedChangedDataCategoriesChange: (selectedCategories: APICategory[]) => void;
  selectedChangedDataCategories: APICategory[];

  selectedSyncCommonModels: string[];
  selectedSyncCommonModelEvents: string[];

  selectedChangedDataCommonModels: string[];
  selectedChangedDataCommonModelEvents: string[];
  selectedChangedDataCommonModelsToFields: Record<string, string[]>;

  onSelectedChangedDataCommonModelsChange: (selectedChangedDataCommonModels: string[]) => void;
  onSelectedChangedDataCommonModelEventsChange: (
    selectedChangedDataCommonModelEvents: string[],
  ) => void;

  onSelectedSyncCommonModelsChange: (selectedSyncCommonModels: string[]) => void;
  onSelectedSyncCommonModelEventsChange: (selectedSyncCommonModelEvents: string[]) => void;
  setSelectedChangedDataCommonModelsToFields: (
    selectedChangedDataCommonModelsToFields: Record<string, string[]>,
  ) => void;
}

function WebhookTypeSelect(props: WebhookTypeSelectProps) {
  const {
    onSelectedSyncCategoriesChange,
    selectedSyncCategories,
    onSelectedChangedDataCategoriesChange,
    selectedChangedDataCategories,
    isLoading,
    onSelectedWebhookTypeChange,
    onUnselectingWebhookType,
    selectedWebhookType,
    selectedSyncCommonModels,
    selectedSyncCommonModelEvents,
    selectedChangedDataCommonModels,
    selectedChangedDataCommonModelEvents,
    selectedChangedDataCommonModelsToFields,
    onSelectedSyncCommonModelsChange,
    onSelectedSyncCommonModelEventsChange,
    onSelectedChangedDataCommonModelsChange,
    onSelectedChangedDataCommonModelEventsChange,
    setSelectedChangedDataCommonModelsToFields,
  } = props;

  const {
    isIssuesWebhookEnabled,
    modelToCategoryMap,
    modelsToFieldsEnabled,
    isWebhookOptionsLoading,
  } = useWebhookOptions();

  // handling deprecated webhooks

  const [hasFirstSyncBeenSelected, setHasFirstSyncBeenSelected] = useState<boolean>(false);
  const [hasAnySyncBeenSelected, setHasAnySyncBeenSelected] = useState<boolean>(false);

  const changedDataCommonModelsFromEvents = uniq(
    selectedChangedDataCommonModels.map((event) => event.split(".")[0]),
  );

  const syncCommonModelsFromEvents = uniq(
    selectedSyncCommonModelEvents.map((event) => event.split(".")[0]),
  );

  // additional state for category selection, and logic for processing those commonModels based on category selected

  useEffect(() => {
    if (selectedWebhookType?.has(SelectedWebhookType.FIRST_SYNC)) {
      setHasFirstSyncBeenSelected(true);
    }

    if (selectedWebhookType.has(SelectedWebhookType.ANY_SYNC)) {
      setHasAnySyncBeenSelected(true);
    }
  }, [selectedWebhookType]);

  return (
    <div className="mt-5">
      <h5>Emit webhook for...</h5>
      {isLoading || isWebhookOptionsLoading ? (
        <WebhookSelectLoading />
      ) : (
        <div className="mt-4">
          <LinkedAccountLinkedWebhook
            selectedWebhookType={selectedWebhookType}
            onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
            onUnselectingWebhookType={onUnselectingWebhookType}
          />
          <LinkedAccountSyncedWebhook
            selectedWebhookType={selectedWebhookType}
            onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
            onUnselectingWebhookType={onUnselectingWebhookType}
          />
          <LinkedAccountDeletedWebhook
            selectedWebhookType={selectedWebhookType}
            onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
            onUnselectingWebhookType={onUnselectingWebhookType}
          />
          {isIssuesWebhookEnabled && (
            <LinkedAccountIssuesWebhook
              selectedWebhookType={selectedWebhookType}
              onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
              onUnselectingWebhookType={onUnselectingWebhookType}
            />
          )}
          {hasFirstSyncBeenSelected && (
            <FirstSyncWebhook
              selectedWebhookType={selectedWebhookType}
              onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
              onUnselectingWebhookType={onUnselectingWebhookType}
            />
          )}
          {hasAnySyncBeenSelected && (
            <AnySyncWebhook
              selectedWebhookType={selectedWebhookType}
              onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
              onUnselectingWebhookType={onUnselectingWebhookType}
            />
          )}
          <CommonModelSyncWebhook
            onSelectedSyncCategoriesChange={onSelectedSyncCategoriesChange}
            selectedSyncCategories={selectedSyncCategories}
            changedDataCommonModelsFromEvents={changedDataCommonModelsFromEvents}
            syncCommonModelsFromEvents={syncCommonModelsFromEvents}
            modelToCategoryMap={modelToCategoryMap}
            selectedWebhookType={selectedWebhookType}
            onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
            onUnselectingWebhookType={onUnselectingWebhookType}
            selectedCommonModels={selectedSyncCommonModels}
            selectedCommonModelEvents={selectedSyncCommonModelEvents}
            onSelectedCommonModelsChange={onSelectedSyncCommonModelsChange}
            onSelectedCommonModelEventsChange={onSelectedSyncCommonModelEventsChange}
          />
          <ChangedDataWebhook
            onSelectedChangedDataCategoriesChange={onSelectedChangedDataCategoriesChange}
            selectedChangedDataCategories={selectedChangedDataCategories}
            modelsToFieldsEnabledMap={modelsToFieldsEnabled}
            selectedChangedDataCommonModelsToFields={selectedChangedDataCommonModelsToFields}
            changedDataCommonModelsFromEvents={changedDataCommonModelsFromEvents}
            modelToCategoryMap={modelToCategoryMap}
            selectedWebhookType={selectedWebhookType}
            onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
            onUnselectingWebhookType={onUnselectingWebhookType}
            selectedCommonModels={selectedChangedDataCommonModels}
            selectedCommonModelEvents={selectedChangedDataCommonModelEvents}
            onSelectedCommonModelsChange={onSelectedChangedDataCommonModelsChange}
            onSelectedCommonModelEventsChange={onSelectedChangedDataCommonModelEventsChange}
            setSelectedChangedDataCommonModelsToFields={setSelectedChangedDataCommonModelsToFields}
          />
          <AsyncPassthroughWebhook
            selectedWebhookType={selectedWebhookType}
            onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
            onUnselectingWebhookType={onUnselectingWebhookType}
          />
        </div>
      )}
    </div>
  );
}

export default WebhookTypeSelect;
