import React, { useState, useEffect, useCallback } from "react";
import { Card, Button, Text, ButtonVariant, Toggle } from "@merge-api/merge-javascript-shared";
import useAppContext from "../../../../context/useAppContext";
import { showErrorToast, showSuccessToast } from "../../../../shared/Toasts";
import { fetchCurrentUser, fetchWithAuth } from "../../../../../api-client/APIClient";
import { DisableTwoFactorAuthRequireModal } from "../modals/DisableTwoFactorAuthRequireModal";
import EditOrganizationNameModal from "../modals/EditOrganizationNameModal";
import { HTTPMethod } from "../../../../../models/Entities";

const OrganizationSettingsCard = () => {
  // STATE
  const { user, setUser, isUserPrivileged } = useAppContext();
  const [organizationName, setOrganizationName] = useState("");
  const [isMfaRequired, setMfaRequired] = useState(false);
  const [showConfirmMsg, setShowConfirmMsg] = useState(false);
  const [showEditNameModal, setShowEditNameModal] = useState(false);

  // HANDLERS
  const handleNameChange = (name: string) => {
    fetchWithAuth({
      path: "/organizations/me",
      method: HTTPMethod.PATCH,
      body: { name },
      onResponse: () => {
        showSuccessToast("Organization name updated!");
        setOrganizationName(name);
      },
      onError: () => showErrorToast("Failed to update organization name."),
    });
    setShowEditNameModal(false);
  };

  const disableTwoFactorAuthentication = useCallback(() => {
    fetchWithAuth({
      path: "/organizations/me",
      method: "PATCH",
      body: { is_mfa_required: false },
      onResponse: () => {
        setMfaRequired(false);
        showSuccessToast("Successfully changed two-factor authentication for your organization.");
        fetchCurrentUser(setUser);
      },
      onError: () => {
        showErrorToast("Failed to disable two-factor authentication for your organization.");
        setMfaRequired(true);
      },
    });
    setShowConfirmMsg(false);
  }, [setShowConfirmMsg, setMfaRequired]);

  const handleConfirmMsg = useCallback(() => {
    if (isMfaRequired) {
      setShowConfirmMsg(true);
    } else {
      fetchWithAuth({
        path: "/organizations/me",
        method: "PATCH",
        body: { is_mfa_required: true },
        onResponse: () => {
          setMfaRequired(true);
          showSuccessToast("Successfully changed two-factor authentication for your organization.");
          fetchCurrentUser(setUser);
        },
        onError: () => {
          showErrorToast("Failed to enable two-factor authentication for your organization.");
          setMfaRequired(false);
        },
      });
    }
  }, [setShowConfirmMsg, setMfaRequired, isMfaRequired]);

  // HOOKS
  useEffect(() => {
    setMfaRequired(user.organization?.is_mfa_required);
  }, [user]);

  useEffect(() => {
    setOrganizationName(user.organization.name);
  }, []);

  return (
    <>
      <Card variant="shadow" className="mb-6">
        <div className="w-full flex flex-col">
          {/* header */}
          <div className="px-6 py-5 border-b-solid border-b-gray-10 border-b-[1px]">
            <Text variant="h5">Organization settings</Text>
          </div>

          {/* body */}
          <div className="flex flex-col px-6 py-5 justify-between gap-y-5">
            <div className="flex flex-row w-full justify-between items-center">
              <div className="flex flex-col">
                <Text variant="h6">Organization name</Text>
                <Text>{organizationName}</Text>
              </div>
              <Button
                variant={ButtonVariant.SecondaryBlue}
                size="sm"
                disabled={!isUserPrivileged}
                onClick={() => setShowEditNameModal(true)}
              >
                Edit name
              </Button>
              {showEditNameModal && (
                <EditOrganizationNameModal
                  onHandleNameChange={handleNameChange}
                  isShown={showEditNameModal}
                  onHide={() => setShowEditNameModal(false)}
                />
              )}
            </div>
            <div className="flex flex-row w-full justify-between items-center">
              <div className="flex flex-col">
                <Text variant="h6">Two-factor authentication</Text>
                <Text>Enforce 2FA for all organization members</Text>
              </div>
              <Toggle
                label="Enabled"
                checked={isMfaRequired}
                disabled={!isUserPrivileged}
                onChange={() => handleConfirmMsg()}
              />
              {showConfirmMsg && (
                <DisableTwoFactorAuthRequireModal
                  onClickMethod={disableTwoFactorAuthentication}
                  isShown={showConfirmMsg}
                  onHide={() => setShowConfirmMsg(false)}
                />
              )}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default OrganizationSettingsCard;
