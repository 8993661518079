import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { spectrum } from "../../../../styles/theme";
import { useHistory } from "react-router-dom";
import { ButtonVariant, Card, Link, Text } from "@merge-api/merge-javascript-shared";
import { UserType } from "../../../../models/Entities";
import useAppContext from "../../../context/useAppContext";
import EmptyStateWrapper from "../../../shared/EmptyStateWrapper";
import { showSuccessToast } from "../../../shared/Toasts";
import { getPaymentMethods, updateDefaultPaymentMethod } from "./BillingAPIClient";
import { BankAccount, BillingPlanTier, CreditCard, PaymentMethod } from "./BillingModels";
import { LinkedAccountMetrics } from "../../../../models/Entities";
import { fetchWithAuth } from "../../../../api-client/APIClient";
import { useLocation } from "react-router-dom";
import FreeToLaunchConfirmModal from "./components/FreeToLaunchConfirmModal";
import { formatSyncFrequncyPlans, getBillingPlanTitle } from "./BillingUtils";
import PaymentMethodRow from "./components/BillingPaymentMethodRow";
import AddBankAccountModal from "./components/AddBankAccountModal";
import DeletePaymentMethodModal from "./components/DeletePaymentMethodModal";
import OldBillingPage from "./OldBillingPage";
import { Plus } from "lucide-react";
import { Button, Tooltip } from "@merge-api/merge-javascript-shared";
import SkeletonLoader from "../../../shared/SkeletonLoader";
import clsx from "clsx";
import useLoadOrganizationBillingPlan from "./hooks/useLoadOrganizationBillingPlan";
import { navigateToAddPaymentPage } from "../../../../router/RouterUtils";
import LinkedAccountsCountsCard from "./components/LinkedAccountsCountsCard";

const PlanNameText = styled.div`
  background: linear-gradient(85.42deg, #0c57da 9.77%, #669bf9 89.78%), ${spectrum.gray90};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

function BillingPage() {
  // state
  const [showingAddPaymentMethodModal, setShowingAddPaymentMethodModal] = useState(false);
  const [paymentMethodDeletionTarget, setPaymentMethodDeletionTarget] = useState<
    PaymentMethod | undefined
  >();
  const [paymentMethods, setPaymentMethods] = useState<null | PaymentMethod[]>(null);
  const [linkedAccountMetrics, setLinkedAccountMetrics] = useState<LinkedAccountMetrics>();
  const [showFreeToLaunchModal, setShowFreeToLaunchModal] = useState(false);
  const [defaultPaymentMethodBeingSet, setDefaultPaymentMethodBeingSet] =
    useState<null | PaymentMethod>(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const refetch = queryParams.get("refetch");
  const upgraded = queryParams.get("upgraded");

  // hooks
  const { user } = useAppContext();
  const { organizationBillingPlan, setOrganizationBillingPlan, isLoadingOrganizationBillingPlan } =
    useLoadOrganizationBillingPlan();

  const freeAccountGated = user?.is_free_account_gating_enabled ?? false;

  enum QueryParamOption {
    True = "true",
  }

  const history = useHistory();

  const organizePaymentMethods = (
    bankAccounts: BankAccount[],
    creditCards: CreditCard[],
  ): PaymentMethod[] => {
    const arr: PaymentMethod[] = [...bankAccounts, ...creditCards]
      .sort((a, b) => a.created_at.localeCompare(b.created_at))
      .sort((a, _) => (a.is_default_method ? -1 : 1));
    return arr;
  };

  const fetchPaymentMethods = (forceRefreshFromStripe: boolean) => {
    getPaymentMethods({
      forceRefreshStripe: forceRefreshFromStripe,
      onSuccess: (data: { bank_accounts: BankAccount[]; credit_cards: CreditCard[] }) => {
        const paymentMethods = organizePaymentMethods(data.bank_accounts, data.credit_cards);
        setPaymentMethods(paymentMethods);
      },
    });
  };

  const setDefaultPaymentMethod = (paymentMethod: PaymentMethod) => {
    setDefaultPaymentMethodBeingSet(paymentMethod);
    updateDefaultPaymentMethod({
      paymentMethod: paymentMethod,
      onSuccess: (data: { bank_accounts: BankAccount[]; credit_cards: CreditCard[] }) => {
        const paymentMethods = organizePaymentMethods(data.bank_accounts, data.credit_cards);
        setPaymentMethods(paymentMethods);
        setDefaultPaymentMethodBeingSet(null);
        showSuccessToast("Successfully updated your default payment method!");
      },
    });
  };

  const reformatLinkedAccountMetrics = (data: LinkedAccountMetrics) => {
    return {
      completed_linked_accounts_count: data.completed_linked_accounts_count,
      idle_accounts_count: data.idle_accounts_count,
      incomplete_linked_accounts_count: data.incomplete_linked_accounts_count,
      relink_needed_accounts_count: data.relink_needed_accounts_count,
      plan_limit_reached_accounts_count: data.plan_limit_reached_accounts_count,
      rippling_oauth_accounts_count: data.rippling_oauth_accounts_count,
    };
  };

  // load payment methods
  useEffect(() => {
    if (user.type === UserType.admin_with_billing) {
      //on new payment methods being added to stripe, refetch is set to true to get those new payment methods from stripe. otherwise we don't actively fetch new payment methods
      if (refetch && refetch === QueryParamOption.True) {
        fetchPaymentMethods(true);
      }
      if (upgraded && upgraded === QueryParamOption.True) {
        setShowFreeToLaunchModal(true);
      }
      fetchPaymentMethods(false);
    }
  }, [location, freeAccountGated]);

  useEffect(() => {
    fetchWithAuth({
      path: "/integrations/linked-accounts/detailed-counts",
      method: "GET",
      onResponse: (data: LinkedAccountMetrics) => {
        const reformattedData = reformatLinkedAccountMetrics(data);
        setLinkedAccountMetrics(reformattedData);
      },
    });
  }, []);

  const isFreePlan =
    user.organization.organization_billing_plan?.billing_plan.plan_tier ===
    BillingPlanTier.BILLING_PLAN_TIER_FREE;
  const isLaunchPlan =
    user.organization.organization_billing_plan?.billing_plan.plan_tier ===
    BillingPlanTier.BILLING_PLAN_TIER_LAUNCH;

  const totalPurchasedLinkedAccounts = user.organization.purchased_prod_linked_accounts;
  const syncPlanMap = user.organization.sync_frequency_plans || {};
  const defaultSyncPlan = syncPlanMap["DEFAULT"];

  const isOldBillingPlan =
    user.organization.organization_billing_plan?.billing_plan.plan_tier ===
      BillingPlanTier.BILLING_PLAN_TIER_GROW ||
    user.organization.organization_billing_plan?.billing_plan.plan_tier ===
      BillingPlanTier.BILLING_PLAN_TIER_EXPAND;

  if (isOldBillingPlan || isFreePlan || (isLaunchPlan && !freeAccountGated)) {
    return <OldBillingPage />;
  } else {
    return (
      <>
        {freeAccountGated && (
          <FreeToLaunchConfirmModal
            open={showFreeToLaunchModal}
            onHide={() => setShowFreeToLaunchModal(false)}
          />
        )}
        <AddBankAccountModal
          show={showingAddPaymentMethodModal}
          freeAccountGated={freeAccountGated}
          onHide={() => {
            setShowingAddPaymentMethodModal(false);
            fetchPaymentMethods(true);
          }}
        />
        <DeletePaymentMethodModal
          paymentMethod={paymentMethodDeletionTarget}
          onHide={() => {
            setPaymentMethodDeletionTarget(undefined);
            fetchPaymentMethods(true);
          }}
        />
        <div className="grid grid-cols-4 gap-6 w-full">
          <div className="flex flex-col col-span-4 xl:col-span-1 gap-6">
            <Card className="px-5 py-4 flex flex-col">
              <Text variant="h5" className="mb-2">
                Current plan
              </Text>
              <PlanNameText className="font-semibold text-[24px] leading-[32px]">
                {!isLoadingOrganizationBillingPlan ? (
                  getBillingPlanTitle(organizationBillingPlan)
                ) : (
                  <SkeletonLoader width={169} height={24} borderRadius={4} />
                )}
              </PlanNameText>
              <Text>
                <span className="font-semibold">{formatSyncFrequncyPlans(defaultSyncPlan)}</span>{" "}
                sync frequency
              </Text>
            </Card>
            <LinkedAccountsCountsCard
              linkedAccountMetrics={linkedAccountMetrics}
              totalPurchasedLinkedAccounts={totalPurchasedLinkedAccounts}
            />
          </div>
          <div className="col-span-4 xl:col-span-3">
            <Card className="flex flex-col">
              <div className="flex flex-row justify-between items-center py-5 px-6 border-b-[1px] border-gray-10">
                <Text variant="h4">Payment methods</Text>
                {user.type === UserType.admin_with_billing ? (
                  <Button
                    leftIcon={<Plus size={12} />}
                    size="sm"
                    onClick={() => {
                      if (freeAccountGated) {
                        navigateToAddPaymentPage(history, { fromUpgradeButton: false });
                      } else {
                        setShowingAddPaymentMethodModal(true);
                      }
                    }}
                  >
                    Payment method
                  </Button>
                ) : (
                  <Tooltip title="You must be an admin to add payment methods">
                    <Button
                      variant={ButtonVariant.TertiaryWhite}
                      size="sm"
                      leftIcon={<Plus size={12} />}
                    >
                      Payment method
                    </Button>
                  </Tooltip>
                )}
              </div>
              <div className="list-group list-group-flush px-6">
                {!paymentMethods && user.type === UserType.admin_with_billing ? (
                  <div>
                    {Array(5)
                      .fill(null)
                      .map((_, index) => (
                        <div
                          key={index}
                          className={clsx("flex flex-row justify-between items-center py-5", {
                            "border-b border-gray-10": index !== 4,
                          })}
                        >
                          <SkeletonLoader width={238} height={12} borderRadius={2} />
                          <SkeletonLoader width={182} height={12} borderRadius={2} />
                        </div>
                      ))}
                  </div>
                ) : user.type !== UserType.admin_with_billing ? (
                  <EmptyStateWrapper title="You must be an admin to view payment methods" />
                ) : paymentMethods && paymentMethods.length > 0 ? (
                  paymentMethods.map((paymentMethod) => (
                    <PaymentMethodRow
                      paymentMethod={paymentMethod}
                      billingPlan={organizationBillingPlan}
                      updateBillingPlan={setOrganizationBillingPlan}
                      onClickSetDefault={() => setDefaultPaymentMethod(paymentMethod)}
                      settingDefault={defaultPaymentMethodBeingSet === paymentMethod}
                      setPaymentMethodDeletionTarget={setPaymentMethodDeletionTarget}
                    />
                  ))
                ) : (
                  <EmptyStateWrapper title="No payment methods" />
                )}
              </div>
            </Card>
          </div>
        </div>

        <div className="flex justify-center my-9">
          <Text className="text-center text-gray-60">
            Tired of painless integrations?{" "}
            <Link href="mailto:hello@merge.dev" className="text-red-60">
              Contact us to cancel your plan
            </Link>
          </Text>
        </div>
      </>
    );
  }
}

export default BillingPage;
