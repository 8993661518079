import React from "react";
import styled from "styled-components";
import { ChevronRight, Codesandbox } from "lucide-react";
import { DOCS_METADATA_PATH } from "../../../../../router/RouterUtils";
import AppMarketplaceImg from "../../../../../assets/img/app-marketplace.png";
import DeprecatedH4 from "../../../../../deprecated/DeprecatedH4";
import { Button, Card, Text } from "@merge-api/merge-javascript-shared";

const ShadedArea = styled.div`
  border-radius: 10px;
  width: 100%;
  display: block;
  background: #eff6ff;
  padding: 16px 20px;
`;

const Metadata = () => {
  return (
    <Card className="p-6 mx-auto w-3/4">
      <Text variant="h3" className="mb-1">
        Integration Metadata
      </Text>
      <Text as="p" className="mb-6 text-gray-60">
        Use Integration Metadata to programmatically fetch information about the integrations Merge
        offers
      </Text>

      <p>
        Use the Merge API's Integration Metadata endpoint to fetch integration names, identifiers,
        images, brand colors, and more.
      </p>
      <p>
        You can use this data to create a custom integrations menu or applications marketplace for
        your product.
      </p>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "64px 0",
        }}
      >
        <img src={AppMarketplaceImg} width="400px" />
      </div>

      <ShadedArea className="mb-2">
        <Codesandbox size={16} color="var(--blue40)" style={{ marginBottom: 12 }} />
        <DeprecatedH4>Integration Metadata</DeprecatedH4>
        <p className="small">Programmatically fetch information about Merge integrations</p>
        <Button
          size="sm"
          rightIcon={<ChevronRight size={12} />}
          onClick={() => window.open(DOCS_METADATA_PATH, "_blank")}
        >
          View docs
        </Button>
      </ShadedArea>
    </Card>
  );
};

export default Metadata;
