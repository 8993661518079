import React from "react";
import { LOG_DIRECTIONS } from "../../../../../../constants";

import Divider from "../../../../../shared/Divider";
import {
  isScraperLogEntry,
  isWebConnectorLogEntry,
  isWebhookLogEntry,
  LogType,
  ScraperLogEntry,
} from "../../../../IntegrationsManagementEntities";
import { LogSidePanelBodySection } from "./LogSidePanelBodySection";
import { LogSidePanelBodySectionHeader } from "./LogSidePanelBodySectionHeader";
import { CollapsableSection } from "./LogSidePanelBody";
import { ScraperLogSidePanelRequestSection } from "../../ScraperLogEntrySidePanel";
import { Card } from "@merge-api/merge-javascript-shared";
import MergeMarkAvatar from "./MergeMarkAvatar";

type Props = {
  logEntry: LogType;
  organizationBodyImage: JSX.Element;
  integrationImage: JSX.Element;
  isRedacted?: boolean;
};

export const LogSidePanelRequestSection = ({
  logEntry,
  organizationBodyImage,
  integrationImage,
  isRedacted,
}: Props) => {
  const isOutbound = logEntry.direction === LOG_DIRECTIONS.LOG_OUTBOUND;
  const isWebhookLog = isWebhookLogEntry(logEntry);
  const isWebConnectorLog = isWebConnectorLogEntry(logEntry);
  const isScraperLog = isScraperLogEntry(logEntry);

  const requestSubtitle = isOutbound ? "Merge" : logEntry.linked_account.end_user.organization_name;
  const requestImage = isOutbound ? (
    <MergeMarkAvatar />
  ) : isWebhookLog || isWebConnectorLog ? (
    integrationImage
  ) : (
    organizationBodyImage
  );

  const shouldShowRequestHeaders =
    !isScraperLog && logEntry.request_headers && Object.keys(logEntry.request_headers).length > 0;
  const shouldShowRequestQueryParams =
    !isScraperLog &&
    logEntry.request_query_params &&
    Object.keys(logEntry.request_query_params).length > 0;
  let shouldShowRequestBody =
    !isScraperLog &&
    logEntry.request_body &&
    logEntry.request_body != "{}" &&
    Object.keys(logEntry.request_body).length > 0;

  if (!isScraperLog && isRedacted) {
    shouldShowRequestBody = true;
  }

  return (
    <Card size="lg" className="flex flex-col w-full h-fit">
      <LogSidePanelBodySectionHeader
        title="Request"
        subtitle={requestSubtitle}
        image={requestImage}
        request_method={logEntry.method}
      />

      {isScraperLog ? (
        <CollapsableSection
          title="Request parameters"
          numberOfElements={undefined}
          isRoundedBottom
          children={<ScraperLogSidePanelRequestSection logEntry={logEntry as ScraperLogEntry} />}
        />
      ) : (
        <>
          {shouldShowRequestHeaders && (
            <LogSidePanelBodySection
              title="Headers"
              data={logEntry.request_headers}
              isCollapsedByDefault
              isRoundedBottom={!shouldShowRequestQueryParams && !shouldShowRequestBody}
              isRequest
            />
          )}
          {shouldShowRequestHeaders && (shouldShowRequestQueryParams || shouldShowRequestBody) && (
            <Divider noMargin />
          )}
          {shouldShowRequestQueryParams && (
            <LogSidePanelBodySection
              title="Query parameters"
              data={logEntry.request_query_params}
              isRoundedBottom={!shouldShowRequestBody}
              isRequest
            />
          )}
          {shouldShowRequestQueryParams && shouldShowRequestBody && <Divider noMargin />}
          {shouldShowRequestBody && (
            <LogSidePanelBodySection
              title="Body"
              data={logEntry.request_body}
              isRedacted={isRedacted}
              isBody
              isRoundedBottom
              isRequest
            />
          )}
        </>
      )}
    </Card>
  );
};
