import clsx from "clsx";
import copy from "copy-to-clipboard";
import React, { useMemo, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import bash from "react-syntax-highlighter/dist/cjs/languages/hljs/bash";
import elixir from "react-syntax-highlighter/dist/cjs/languages/hljs/elixir";
import go from "react-syntax-highlighter/dist/cjs/languages/hljs/go";
import json from "react-syntax-highlighter/dist/cjs/languages/hljs/json";
import python from "react-syntax-highlighter/dist/cjs/languages/hljs/python";
import ruby from "react-syntax-highlighter/dist/cjs/languages/hljs/ruby";
import darkModeStyle from "react-syntax-highlighter/dist/cjs/styles/hljs/night-owl";
import lightModeStyle from "react-syntax-highlighter/dist/cjs/styles/hljs/github-gist";
import isUndefined from "lodash/isUndefined";
import { Button, ButtonVariant, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { Copy } from "lucide-react";

const languages = {
  json,
  python,
  ruby,
  bash,
  elixir,
  go,
};
for (const [languageName, languageValue] of Object.entries(languages)) {
  SyntaxHighlighter.registerLanguage(languageName, languageValue);
}

export interface CodeBlockFileDownloadInfo {
  previewMessage: string;
  downloadLink: string;
}

type LanguageName = keyof typeof languages;

interface Props {
  children?: string | null;
  language?: LanguageName | null;
  showLineNumbers?: boolean | null;
  customStyle?: any;
  codeBlockName?: string;
  textToCopy?: string;
  downloadInfo?: CodeBlockFileDownloadInfo;
  colorMode?: "light" | "dark";
}

const MergeCodeBlock = ({
  className,
  textToCopy,
  codeBlockName,
  children,
  language,
  showLineNumbers,
  customStyle,
  downloadInfo,
  colorMode = "dark",
}: Props & Pick<React.ComponentProps<"div">, "className">) => {
  const [hasCopiedToClipboard, setHasCopiedToClipboard] = useState(false);

  const additionalStyling = useMemo(
    () =>
      downloadInfo
        ? {
            padding: "10px 20px",
            overflow: "clip",
            marginBottom: "0px",
          }
        : { padding: "10px 20px" },
    [downloadInfo],
  );
  const memoizedSyntaxHighlighter = useMemo(() => {
    return (
      <SyntaxHighlighter
        className="code-block block"
        language={language || "json"}
        showLineNumbers={!!showLineNumbers}
        style={colorMode == "dark" ? darkModeStyle : lightModeStyle}
        customStyle={Object.assign(additionalStyling, customStyle)}
      >
        {children}
      </SyntaxHighlighter>
    );
  }, [children, language, additionalStyling, showLineNumbers, colorMode, customStyle]);

  return (
    <div
      className={clsx(
        "rounded-lg overflow-hidden [&>pre.code-block]:p-2.5",
        colorMode === "dark"
          ? "[&>pre]:bg-[#00102F] text-[#60819c] [&>]:bg-[#00102F] bg-[#00102F]"
          : "[&>pre]:bg-[#FBFCFD] text-[#3E3E44] bg-[#FBFCFD]",
        className,
      )}
    >
      <div
        className={clsx(
          "flex flex-row items-center justify-between py-2 px-3",
          colorMode === "dark" ? "bg-navy-50 text-gray-20" : "bg-[#F1F3F4] text-[#3E3E44]",
        )}
      >
        <Text className="m" variant="h6">
          {codeBlockName ?? ""}
        </Text>
        <div className="flex flex-row items-center">
          {language && (
            <Text variant="h6" className="mr-6">
              {language?.toUpperCase()}
            </Text>
          )}
          {isUndefined(downloadInfo) && (
            <div className="flex items-center">
              <Tooltip title={hasCopiedToClipboard ? "Copied!" : "Copy"}>
                <CopyToClipboard
                  text=""
                  onCopy={() => {
                    copy(textToCopy ?? "");
                    setHasCopiedToClipboard(true);
                  }}
                >
                  <Copy className="cursor-pointer" size={16} />
                </CopyToClipboard>
              </Tooltip>
            </div>
          )}
        </div>
      </div>

      {/* Syntax Highligher */}
      {memoizedSyntaxHighlighter}

      {/* download button */}
      {downloadInfo && (
        <a className="flex justify-end p-2 w-full" href={downloadInfo?.downloadLink} download>
          <Button
            variant={
              colorMode === "light" ? ButtonVariant.SecondaryGray : ButtonVariant.TertiaryWhite
            }
            className="w-full"
          >
            {downloadInfo?.previewMessage}
          </Button>
        </a>
      )}
    </div>
  );
};

export default React.memo(MergeCodeBlock);
