import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PaginationFooter from "../../shared/PaginationFooter";
import {
  IntegrationIssue,
  IntegrationIssueActionLogEntry,
  IntegrationIssueActionLogEntryAction,
  IntegrationIssueActor,
  IntegrationIssueStatus,
} from "../IntegrationsManagementEntities";
import {
  getFormattedEventDistanceFromNow,
  getIssueUpdatedAt,
  isIssueMuted,
} from "../utils/IntegrationsManagementUtils";
import EmptyStateWrapper from "../../shared/EmptyStateWrapper";
import { fetchWithAuth } from "../../../api-client/APIClient";
import { getCursorPath } from "../../../services";
import IntegrationsManagementFilter from "../shared/IntegrationsManagementFilter";
import isEmpty from "lodash/isEmpty";
import { UserAvatar, UserAvatarSize } from "../../shared/MergeAvatars";
import { navigateToIndividualIssuePage } from "../../../router/RouterUtils";
import { ChevronRight, VolumeX } from "lucide-react";
import ContentLoader from "react-content-loader";
import { spectrum } from "../../../styles/theme";
import IntegrationNameAndLogo from "../configuration/integrations/IntegrationNameAndLogo";
import MergeTable from "../../shared/MergeTable";
import RotatingChevronDown from "../../shared/RotatingChevronDown";
import { MessageSquare } from "lucide-react";
import PortalPageHeaderWrapper from "../../portal/PortalPageHeaderWrapper";
import { Tooltip, Text } from "@merge-api/merge-javascript-shared";
import clsx from "clsx";
import IssueStatusBadge from "../../shared/IssueStatusBadge";
import MergeMark from "../../../assets/img/mark-borderless.png";

export const getResolvedBy = (integrationIssue: IntegrationIssue) => {
  if (integrationIssue?.metadata) {
    for (let i = integrationIssue.metadata.length - 1; i >= 0; i--) {
      const action = integrationIssue.metadata[i];
      if (action.action == IntegrationIssueActionLogEntryAction.RESOLVE) {
        switch (action.actor_type) {
          case IntegrationIssueActor.AUTO_ACTOR:
            return "Auto-resolved";
          case IntegrationIssueActor.USER_ACTOR:
            return `Resolved by ${action.user_name || action.user_email}`;
          default:
            return "Resolved";
        }
      }
    }
  }
  return "Resolved";
};

type OrderByField = "CREATED" | "UPDATED";
type OrderByOrder = "ASC" | "DESC";

export const getLastActionMessage = (
  lastAction: undefined | IntegrationIssueActionLogEntry,
  isMuted: boolean | undefined,
) => {
  if (!lastAction || isEmpty(lastAction)) {
    return <Text className="text-gray-60">–</Text>;
  }
  const { user, action_type, created_at } = lastAction;

  let text;

  switch (action_type) {
    case IntegrationIssueActionLogEntryAction.RESOLVE:
      text = user ? "Resolved" : "Resolved (auto)";
      break;
    case IntegrationIssueActionLogEntryAction.REOPEN:
      text = user ? "Reopened" : "Reopened (auto)";
      break;
    case IntegrationIssueActionLogEntryAction.NOTIFY:
      text = "Notified end-user";
      break;
    default:
      text = "-";
      break;
  }

  return (
    <div
      className={clsx(
        "flex flex-row items-center whitespace-nowrap gap-x-2",
        isMuted && "text-gray-60",
      )}
    >
      {user ? (
        <Tooltip title={user.name}>
          <UserAvatar
            user={user}
            size={UserAvatarSize.xs}
            className={clsx(isMuted && "opacity-50")}
          />
        </Tooltip>
      ) : (
        <Tooltip title="Automatically updated" className="flex items-center">
          <img src={MergeMark} width="20" height="20" className="rounded-full" />
        </Tooltip>
      )}
      <Text>{text}</Text>
      <Text className="text-gray-60">{getFormattedEventDistanceFromNow(created_at)}</Text>
    </div>
  );
};

const IntegrationsManagementIssuesPage = () => {
  const history = useHistory();
  const [integrationIssues, setIntegrationIssues] = useState<null | IntegrationIssue[]>();
  const [previousPageURL, setPreviousPageURL] = useState<string>();
  const [nextPageURL, setNextPageURL] = useState<string>();
  const [paramsPath, setParamsPath] = useState<string | undefined>();
  const [orderBy, setOrderBy] = useState<string>("CREATED_DESC");
  const [updatedOrder, setUpdatedOrder] = useState<OrderByOrder>("DESC");
  const [createdOrder, setCreatedOrder] = useState<OrderByOrder>("DESC");
  const hasPagination = nextPageURL || previousPageURL;
  //@ts-ignore
  useEffect(() => {
    let cancelled = false;
    setIntegrationIssues(null);

    const searchParams = new URLSearchParams(paramsPath);
    searchParams.set("order_by", orderBy);
    const orderedParamsPath = orderBy !== undefined ? searchParams.toString() : "";

    fetchWithAuth({
      path: "/integrations/issues?" + orderedParamsPath,
      method: "GET",
      onResponse: (data) => {
        if (!cancelled) {
          setNextPageURL(data.next);
          setPreviousPageURL(data.previous);
          setIntegrationIssues(data.results);
        }
      },
    });

    return () => (cancelled = true);
  }, [orderBy, paramsPath]);

  function fetchIssuesWithCursor(cursorPath: string | undefined) {
    setIntegrationIssues(null);

    fetchWithAuth({
      path:
        "/integrations/issues?" +
        (cursorPath ? getCursorPath(cursorPath) : paramsPath ? paramsPath : ""),
      method: "GET",
      onResponse: (data) => {
        setNextPageURL(data.next);
        setPreviousPageURL(data.previous);
        setIntegrationIssues(data.results);
      },
    });
  }

  const updateOrderBy = (field: OrderByField, order: OrderByOrder) => {
    const flippedOrder = order === "ASC" ? "DESC" : "ASC";
    if (field === "UPDATED") {
      setOrderBy(`UPDATED_${flippedOrder}`);
      setUpdatedOrder(flippedOrder);
    } else if (field === "CREATED") {
      setOrderBy(`CREATED_${flippedOrder}`);
      setCreatedOrder(flippedOrder);
    }
  };

  const header = (
    <>
      <th scope="col">Issue</th>
      <th scope="col">Status</th>
      <th scope="col">Organization</th>
      <th scope="col">Integration</th>
      <th scope="col">Recent activity</th>
      <th
        scope="col"
        className="whitespace-nowrap hover:cursor-pointer"
        onClick={() => {
          updateOrderBy("UPDATED", updatedOrder);
        }}
      >
        <div className="flex flex-row items-center flex-nowrap">
          Updated
          <RotatingChevronDown isRotatedUp={updatedOrder === "ASC"} />
        </div>
      </th>
      <th
        scope="col"
        className="whitespace-nowrap hover:cursor-pointer"
        onClick={() => {
          updateOrderBy("CREATED", createdOrder);
        }}
      >
        <div className="flex flex-row items-center flex-nowrap">
          Created
          <RotatingChevronDown isRotatedUp={createdOrder === "ASC"} />
        </div>
      </th>
      <th scope="col" />
    </>
  );

  const content = (
    <>
      {integrationIssues ? (
        integrationIssues.length > 0 ? (
          integrationIssues.map((issue) => {
            const isMuted = isIssueMuted(issue);
            return (
              <tr
                className="table-link"
                key={issue.id}
                onClick={() => navigateToIndividualIssuePage(history, issue.id)}
              >
                <td>
                  <div className="flex flex-row items-center whitespace-nowrap">
                    <Text variant="h6" className={isMuted ? "text-gray-60" : ""}>
                      {issue.error_description}
                    </Text>

                    {isMuted && <VolumeX className="ml-2 text-gray-60" size={16} />}
                  </div>
                </td>
                <td>
                  {issue.status === IntegrationIssueStatus.RESOLVED ? (
                    <Tooltip title={getResolvedBy(issue)}>
                      <IssueStatusBadge issue={issue} />
                    </Tooltip>
                  ) : (
                    <IssueStatusBadge issue={issue} />
                  )}
                </td>

                <td>
                  <Text variant="h6" className={isMuted ? "text-gray-60" : ""}>
                    {issue.linked_account.end_user.organization_name}
                  </Text>
                </td>
                <td>
                  <IntegrationNameAndLogo
                    isMuted={isMuted}
                    integration={issue.linked_account.integration}
                  />
                </td>

                <td>{getLastActionMessage(issue.last_action, isMuted)}</td>
                <td>
                  <Text className="text-gray-60">
                    {getFormattedEventDistanceFromNow(getIssueUpdatedAt(issue))}
                  </Text>
                </td>
                <td>
                  <Text className="text-gray-60">
                    {getFormattedEventDistanceFromNow(issue.first_incident_time)}
                  </Text>
                </td>
                <td>
                  <div className="flex flex-row items-center justify-end gap-2 text-gray-60">
                    <MessageSquare size={12} /> <Text>{issue.comment_count}</Text>
                    <Tooltip title="Review issue details" className="ml-2">
                      <ChevronRight size={16} />
                    </Tooltip>
                  </div>
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={8} className="p-0">
              <EmptyStateWrapper isTable title="No issues" />
            </td>
          </tr>
        )
      ) : (
        Array.from({ length: 25 }).map((_, i) => (
          <tr key={`issue-skel-row-${i}`}>
            <td>
              <ContentLoader
                speed={1.4}
                width={40}
                height={20}
                viewBox="0 0 40 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="40" height="14" />
              </ContentLoader>
            </td>
            <td>
              <ContentLoader
                speed={1.4}
                width={64}
                height={20}
                viewBox="0 0 64 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="64" height="14" />
              </ContentLoader>
            </td>
            <td className="text-gray-60">
              <ContentLoader
                speed={1.4}
                width={64}
                height={20}
                viewBox="0 0 64 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="64" height="14" />
              </ContentLoader>
            </td>

            <td className="text-gray-60">
              <div className="d-flex align-items-center">
                <i className="fe fe-link-2 mr-3" />
                <ContentLoader
                  speed={1.4}
                  width={80}
                  height={20}
                  viewBox="0 0 80 20"
                  backgroundColor={spectrum.gray0}
                  foregroundColor={spectrum.gray10}
                >
                  <rect x="0" y="3" rx="3" ry="3" width="80" height="14" />
                </ContentLoader>
              </div>
            </td>
            <td>
              <ContentLoader
                speed={1.4}
                width={64}
                height={20}
                viewBox="0 0 64 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="64" height="14" />
              </ContentLoader>
            </td>
            <td>
              <ContentLoader
                speed={1.4}
                width={64}
                height={20}
                viewBox="0 0 64 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="64" height="14" />
              </ContentLoader>
            </td>
            <td>
              <ContentLoader
                speed={1.4}
                width={64}
                height={20}
                viewBox="0 0 64 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="64" height="14" />
              </ContentLoader>
            </td>
            <td />
          </tr>
        ))
      )}
    </>
  );

  return (
    <PortalPageHeaderWrapper title="Issues" isMaxWidthEnabled>
      <>
        <IntegrationsManagementFilter isIntegrationIssues setParamsPath={setParamsPath} />
        <MergeTable header={header} content={content} hasMorePaddingOnFirstElement />
        {hasPagination && (
          <PaginationFooter
            hasPrevious={!!previousPageURL}
            hasNext={!!nextPageURL}
            onPreviousClick={() => fetchIssuesWithCursor(previousPageURL)}
            onNextClick={() => fetchIssuesWithCursor(nextPageURL)}
          />
        )}
      </>
    </PortalPageHeaderWrapper>
  );
};

export default IntegrationsManagementIssuesPage;
