import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import SelectionRow from "../../../../../../common/SelectionRow";
import { Box, Globe, User } from "lucide-react";
import FieldMappingModalContext from "../context/FieldMappingModalContext";
import FieldMappingModalTitle from "../FieldMappingModalTitle";
import { navigateToLinkedAccountUnmappedTargetsPage } from "../../../../../../../router/RouterUtils";
import { CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH } from "../../../../../../../router/RouterUtils";

const AddFieldMappingChoice = () => {
  const { linkedAccount, setFormStep, onModalClose, fieldMappingTargets, fieldMappingInstances } =
    useContext(FieldMappingModalContext);
  const history = useHistory();
  if (!linkedAccount) {
    return <></>;
  }
  const doesHaveOrgTargetsDefined = fieldMappingTargets.length > 0;
  const doesOrgHaveInstances = fieldMappingInstances.length > 0;
  return (
    <>
      <FieldMappingModalTitle title="Add Mapping" onClose={onModalClose} />
      <div className="flex px-6 mt-6 flex-column gap-4">
        <SelectionRow
          key="common-model-selection"
          icon={<User size={16} className="text-yellow-50" />}
          title={`Map a new ${linkedAccount.end_user.organization_name}-specific target field`}
          subtitle={`Create a new target field for this Linked Account and connect it to a new ${linkedAccount.integration.name} field`}
          onClick={() => {
            setFormStep("common-model-selection");
          }}
        />
        <SelectionRow
          key="map-existing-target"
          disable={!doesHaveOrgTargetsDefined}
          icon={<Globe size={16} className="text-orange-60" />}
          title={`Map existing ${linkedAccount.organization.name}-wide target fields`}
          subtitle={
            doesHaveOrgTargetsDefined ? (
              `Connect predefined target fields to new ${linkedAccount.integration.name} fields`
            ) : doesOrgHaveInstances ? (
              <>
                All your organization-wide target fields have been mapped. See{" "}
                <a href={CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH}>Advanced Configuration</a>{" "}
                to add fields.
              </>
            ) : (
              <>
                No organization-wide target fields have been defined. See{" "}
                <a href={CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH}>Advanced Configuration</a>{" "}
                to add fields.
              </>
            )
          }
          onClick={() => {
            navigateToLinkedAccountUnmappedTargetsPage(history, linkedAccount.id);
            setFormStep("map-existing-target");
          }}
        />
        <SelectionRow
          key="common-model-override-choice"
          icon={<Box size={16} className="text-blue-50" />}
          title="Override Merge Common Model field"
          subtitle={`Map an existing Merge Common Model field to a ${linkedAccount.integration.name} field`}
          onClick={() => {
            setFormStep("common-model-override-choice");
          }}
        />
      </div>
    </>
  );
};

export default AddFieldMappingChoice;
