import React, { useEffect, useRef } from "react";
import { Search, Info, Users } from "lucide-react";
import first from "lodash/first";
import size from "lodash/size";
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import {
  AuthType,
  LinkedAccount,
  LinkedAccountWebhookReceivers,
} from "../../../../models/Entities";
import {
  getLinkedAccountDetailFieldMappingsPathForId,
  getLinkedAccountIssuesPathForId,
  getLinkedAccountDetailOverviewPathForId,
  getLinkedAccountDetailSettingsPathForId,
  getLinkedAccountDetailWebhooksPathForId,
  navigateToProductionLinkedAccountsTablePage,
  getLinkedAccountDetailLogsSettingsPathForId,
  getLinkedAccountSelectiveSyncPathForId,
} from "../../../../router/RouterUtils";
import useAppContext from "../../../context/useAppContext";
import PortalPageHeaderWrapper from "../../../portal/PortalPageHeaderWrapper";
import {
  Alert,
  Button,
  ButtonVariant,
  HTTPMethod,
  UnreleasedAPICategory,
  Badge,
} from "@merge-api/merge-javascript-shared";
import { IntegrationIssue } from "../../IntegrationsManagementEntities";
import LinkedAccountPretitle from "./overview/portal/LinkedAccountPretitle";
import LinkedAccountTitleContainer from "./overview/portal/LinkedAccountTitleContainer";
import LinkedAccountDetailPageRouter from "./LinkedAccountDetailPageRouter";
import compact from "lodash/compact";
import { useRequest } from "../../../shared/hooks/useRequest";
import { usePaginatedRequest } from "../../../shared/hooks/usePaginatedRequest";

/**
 * Shows the detail page for one singular linked account. Has a header
 * plus tabs below, showing overview, webhooks, and settings for one
 * particular linked account.
 */
const LinkedAccountDetailPage = () => {
  // hooks
  const { isUserPrivileged } = useAppContext();
  const location = useLocation();
  const { id: linkedAccountId } = useParams<Pick<LinkedAccount, "id">>();
  const history = useHistory();

  // fetch linked account
  const {
    data: linkedAccount,
    error: linkedAccountError,
    refetch: refetchLinkedAccount,
  } = useRequest<LinkedAccount>({
    path: `/integrations/linked-accounts/${linkedAccountId}`,
    skip: !linkedAccountId,
    method: HTTPMethod.GET,
  });

  // fetch integration issues for linked account
  const { results: ongoingIntegrationIssues, refetch: refetchIntegrationIssues } =
    usePaginatedRequest<IntegrationIssue>({
      rootPath: "/integrations/issues",
      paramsPath: `status=ONGOING&order_by=CREATED_DESC&linked_account_id=${linkedAccountId}`,
      errorText: "Error fetching linked account issues",
    });

  // fetch webhook receivers
  const { data: webhookReceivers, refetch: refetchWebhookReceivers } =
    useRequest<LinkedAccountWebhookReceivers>({
      path: `/integrations/webhook-receivers/${linkedAccountId}`,
      method: HTTPMethod.GET,
    });

  // derived state
  const isTestAccount = !!linkedAccount?.is_test_account;
  const isSftpAccount = linkedAccount?.auth_type === AuthType.SFTP;
  const isQBDAccount = linkedAccount?.auth_type === AuthType.WEB_CONNECTOR;
  const isDataWarehouseAccount = linkedAccount?.category === UnreleasedAPICategory.datawarehouse;
  const isOnOverviewPage = useRouteMatch("/linked-accounts/account/:id/overview/")?.isExact;
  // QBD accounts (even test) are always syncing
  const showTestAccountBanner = isOnOverviewPage && isTestAccount && !isQBDAccount;
  const isOnIssuesPage = location.pathname.includes("/issues");
  const ongoingIntegrationIssue = first(ongoingIntegrationIssues);
  const numberIntegrationIssueTypes = new Set(
    ongoingIntegrationIssues?.map((issue) => issue.error_description) ?? [],
  ).size;
  const shouldShowLogsTab = !isDataWarehouseAccount;
  const shouldShowIssuesTab = !isDataWarehouseAccount;
  const shouldShowWebhooksTab =
    !!size(webhookReceivers?.webhook_receivers) && !isSftpAccount && !isDataWarehouseAccount;
  const shouldShowSelectiveSyncTab =
    !!linkedAccount && !isSftpAccount && !isDataWarehouseAccount && !isQBDAccount;
  const shouldShowFieldMappingsTab = isUserPrivileged && !!linkedAccount && !isDataWarehouseAccount;

  // refetch issues when on Overview or Issues page
  useEffect(() => {
    if (isOnOverviewPage || isOnIssuesPage) {
      refetchIntegrationIssues();
    }
  }, [isOnIssuesPage, isOnOverviewPage, refetchIntegrationIssues]);

  // refetch webhook receivers count when on overview
  useEffect(() => {
    if (isOnOverviewPage) {
      refetchWebhookReceivers();
    }
  }, [isOnOverviewPage, refetchWebhookReceivers]);

  return (
    <PortalPageHeaderWrapper
      isMaxWidthEnabled
      isLinkedAccountPortalView
      preTitleContent={
        <LinkedAccountPretitle
          linkedAccount={linkedAccount ?? null}
          isTestAccount={isTestAccount}
        />
      }
      title={
        <LinkedAccountTitleContainer
          linkedAccount={linkedAccount ?? null}
          isDataWarehouseAccount={isDataWarehouseAccount}
        />
      }
      subtabs={compact([
        {
          label: "Overview",
          destination: getLinkedAccountDetailOverviewPathForId(linkedAccountId),
        },
        shouldShowLogsTab && {
          label: "Logs",
          destination: getLinkedAccountDetailLogsSettingsPathForId(linkedAccountId),
        },
        shouldShowIssuesTab && {
          label: "Issues",
          destination: getLinkedAccountIssuesPathForId(linkedAccountId),
          ...(numberIntegrationIssueTypes > 0
            ? {
                rightBadge: (
                  <Badge color="gray" className="ml-1.5">
                    {numberIntegrationIssueTypes}
                  </Badge>
                ),
              }
            : null),
        },
        // field mappings require admin or dev
        shouldShowFieldMappingsTab && {
          label: "Field Mapping",
          destination: getLinkedAccountDetailFieldMappingsPathForId(linkedAccountId),
        },
        // only show webhooks if there are receivers
        shouldShowWebhooksTab && {
          label: "Webhooks",
          destination: getLinkedAccountDetailWebhooksPathForId(linkedAccountId),
        },
        shouldShowSelectiveSyncTab && {
          label: "Selective Sync",
          destination: getLinkedAccountSelectiveSyncPathForId(linkedAccountId),
        },
        {
          label: "Settings",
          destination: getLinkedAccountDetailSettingsPathForId(linkedAccountId),
        },
      ])}
    >
      <>
        {showTestAccountBanner && (
          <Alert className="mt-2 mb-6" icon={<Info size={16} />} color="gray">
            Test Linked Accounts must be manually synced through this dashboard to capture updates
            from the third-party platform
          </Alert>
        )}

        {linkedAccount?.is_duplicate && isOnOverviewPage && (
          <Alert className="mt-2 mb-6" icon={<Users size={16} className=" text-yellow-70" />}>
            <div className="flex flex-row w-full items-center justify-between">
              <div>We&apos;ve detected multiple Linked Accounts with the same credentials</div>
              <Button
                size="sm"
                variant={ButtonVariant.TertiaryWhite}
                leftIcon={<Search size={12} />}
                onClick={() => {
                  navigateToProductionLinkedAccountsTablePage(history, {
                    complete_production_duplicates_of: linkedAccount?.id,
                  });
                }}
              >
                Review duplicates
              </Button>
            </div>
          </Alert>
        )}

        <LinkedAccountDetailPageRouter
          linkedAccount={linkedAccount ?? null}
          shouldShowWebhooksTab={shouldShowWebhooksTab}
          shouldShowSelectiveSyncTab={shouldShowSelectiveSyncTab}
          shouldShowFieldMappingsTab={shouldShowFieldMappingsTab}
          isDataWarehouseAccount={isDataWarehouseAccount}
          updateLinkedAccount={refetchLinkedAccount}
          ongoingIntegrationIssue={ongoingIntegrationIssue ?? null}
          refetchIntegrationIssues={refetchIntegrationIssues}
        />
      </>
    </PortalPageHeaderWrapper>
  );
};

export default LinkedAccountDetailPage;
