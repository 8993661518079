import React, { useEffect, useState } from "react";
import useAppContext from "../../../../context/useAppContext";

import {
  FieldMappingInstance,
  FieldMappingTarget,
  LinkedAccount,
  FieldMappingOptionsResponse,
} from "../../../../../models/Entities";
import FieldMappingInstanceRow from "./FieldMappingInstanceRow";
import SkeletonLoader from "../../../../shared/SkeletonLoader";
import FieldMappingsMergeToIntegrationHeader from "./components/FieldMappingsMergeToIntegrationHeader";
import FieldMappingOverrideRow from "./FieldMappingOverrideRow";
import { Card, Text } from "@merge-api/merge-javascript-shared";
import {
  OverriddenCommonModelInstance,
  OverriddenCommonModelTarget,
} from "../../../../../models/Entities";
import get from "lodash/get";
import PreviewMappingButton from "./PreviewMappingButton";

type Props = {
  commonModelName: string;
  linkedAccount: LinkedAccount;
  fieldMappings: Array<FieldMappingInstance | FieldMappingTarget>;
  overriddenCommonModelTargets?: Array<OverriddenCommonModelTarget>;
  overriddenCommonModelInstances: Array<OverriddenCommonModelInstance>;
  linkedAccountFieldMappingOptions: FieldMappingOptionsResponse | undefined;
  hasFieldMappingsLoaded: boolean;
};

const isLinkedAccountInstance = (
  customMapping: FieldMappingInstance | FieldMappingTarget,
): customMapping is FieldMappingInstance => {
  // TODO: Better way of detemrmining this
  return !("is_mappable_in_link" in customMapping);
};

const FieldMappingCommonModels = ({
  commonModelName,
  linkedAccount,
  fieldMappings,
  hasFieldMappingsLoaded,
  linkedAccountFieldMappingOptions,
  overriddenCommonModelInstances,
}: Props) => {
  const { user } = useAppContext();
  const [fieldMappingValues, setFieldMappingValues] = useState({});

  const newFieldMappingIsSaved = (newInstance: FieldMappingInstance) => {
    if (fieldMappingInstances.length > 0) {
      setFieldMappingInstances((prevState) => [
        ...prevState.splice(0, prevState.length - 1),
        newInstance,
      ]);
    }
  };

  const cancelNewFieldMapping = () => {
    setFieldMappingInstances((prevState) => [...prevState.slice(0, -1)]);
  };

  const [fieldMappingInstances, setFieldMappingInstances] =
    useState<Array<FieldMappingInstance | FieldMappingTarget>>(fieldMappings);

  useEffect(() => {
    setFieldMappingInstances(fieldMappings);
  }, [fieldMappings]);

  return (
    <Card size="lg">
      <div
        id={`${commonModelName}-mapping-info`}
        className="flex border-b border-gray-10 py-5 px-6 items-center justify-between"
      >
        {hasFieldMappingsLoaded ? (
          <>
            <Text variant="h4">{commonModelName}</Text>
            <PreviewMappingButton
              commonModelName={commonModelName}
              setMappingValues={setFieldMappingValues}
              linkedAccountID={linkedAccount.id}
            />
          </>
        ) : (
          <SkeletonLoader height={29} borderRadius={4} />
        )}
      </div>
      {hasFieldMappingsLoaded ? (
        <div className="flex flex-col gap-y-4 py-5 pb-6 pl-6">
          <FieldMappingsMergeToIntegrationHeader
            integrationName={linkedAccount.integration.name}
            integrationSquareImage={linkedAccount.integration.square_image}
            hasDropdown
          />
          <div className="flex flex-col gap-6">
            {overriddenCommonModelInstances.map(
              (modelOverrideInstance: OverriddenCommonModelInstance) => {
                const exampleValue = get(
                  fieldMappingValues,
                  `override_previews.${modelOverrideInstance.id}`,
                  undefined,
                );

                return (
                  <FieldMappingOverrideRow
                    key={modelOverrideInstance.id}
                    linkedAccount={linkedAccount}
                    commonModelOverrideInstance={modelOverrideInstance}
                    fieldMappingOptions={linkedAccountFieldMappingOptions}
                    exampleValue={exampleValue}
                  />
                );
              },
            )}
            {fieldMappingInstances.map((customMapping) => {
              const exampleValue = get(
                fieldMappingValues,
                `field_mapping_previews.${customMapping.id}`,
                undefined,
              );

              return isLinkedAccountInstance(customMapping) && customMapping.field_key == "" ? (
                <FieldMappingInstanceRow
                  fieldMappingMetaResponse={linkedAccountFieldMappingOptions}
                  common_model={commonModelName}
                  key={customMapping.id}
                  fieldMapping={customMapping}
                  linkedAccount={linkedAccount}
                  newMapping
                  cancelNewMapping={cancelNewFieldMapping}
                  newMappingSaved={newFieldMappingIsSaved}
                  exampleValue={exampleValue}
                />
              ) : (
                isLinkedAccountInstance(customMapping) && (
                  <FieldMappingInstanceRow
                    fieldMappingMetaResponse={linkedAccountFieldMappingOptions}
                    common_model={commonModelName}
                    key={customMapping.id}
                    fieldMapping={customMapping}
                    linkedAccount={linkedAccount}
                    cancelNewMapping={cancelNewFieldMapping}
                    newMappingSaved={newFieldMappingIsSaved}
                    exampleValue={exampleValue}
                  />
                )
              );
            })}
          </div>
        </div>
      ) : (
        <div className="flex flex-row gap-x-[68px] py-5 px-6">
          <SkeletonLoader fullWidth height={24} borderRadius={4} />{" "}
          <SkeletonLoader fullWidth height={24} borderRadius={4} />{" "}
        </div>
      )}
    </Card>
  );
};

export default FieldMappingCommonModels;
