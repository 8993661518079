import React from "react";
import { X } from "lucide-react";
import { Button, ButtonVariant, Text, Card } from "@merge-api/merge-javascript-shared";
import SkeletonLoader from "../../../../shared/SkeletonLoader";
import Divider from "../../../../shared/Divider";

type Props = {
  title: string;
  onClickExit: () => void;
};

export const LoadingBodySection = () => {
  return (
    <Card size="lg" className="flex flex-col w-full h-fit">
      <div className="flex flex-row items-center px-6 py-5 gap-6 border-b-[1px] border-gray-10">
        <div className="rounded-full bg-gray-10 w-10 h-10" />
        <div className="flex flex-grow flex-col">
          <SkeletonLoader width={143} height={24} borderRadius={4} />
        </div>
        <SkeletonLoader width={64} height={20} borderRadius={4} />
      </div>
      <div className="px-6 py-5">
        <SkeletonLoader width={143} height={20} borderRadius={4} />
      </div>
      <Divider noMargin />
      <div className="px-6 py-5">
        <SkeletonLoader width={143} height={20} borderRadius={4} />
      </div>
    </Card>
  );
};

const LogSidePanelLoading = ({ title, onClickExit }: Props) => {
  return (
    <div className="flex flex-col log-side-panel col-md-9 h-full">
      {/* banner */}
      <div className="p-6 flex justify-between w-full items-center gap-y-5">
        <div className="flex flex-row justify-start items-center gap-x-5 w-full">
          <Button size="sm" variant={ButtonVariant.IconShadowHidden} onClick={() => onClickExit()}>
            <X size={20} />
          </Button>
          <div className="flex flex-row items-center gap-x-3 w-full lg:w-fit">
            <Text variant="h3">{title}</Text>
            <SkeletonLoader width={64} height={20} borderRadius={4} />
          </div>
        </div>
        <SkeletonLoader width={240} height={20} borderRadius={4} />
      </div>

      <Divider noMargin />

      {/* body */}
      <div className="flex flex-row w-full justify-between p-6 gap-6">
        <LoadingBodySection />
        <LoadingBodySection />
      </div>
    </div>
  );
};

export default LogSidePanelLoading;
