import React, { useState } from "react";
import { OrganizationAvatar } from "../../../../../shared/MergeAvatars";
import { CodeBlockFileDownloadInfo } from "../../../../../shared/MergeCodeBlock";
import { LogSidePanelRequestSection } from "./LogSidePanelRequestSection";
import { LogSidePanelResponseSection } from "./LogSidePanelResponseSection";
import { LogType } from "../../../../IntegrationsManagementEntities";
import { Accordion, Alert, Badge, Text, Tooltip } from "@merge-api/merge-javascript-shared";

interface CollapsableSectionProps {
  title: React.ReactNode;
  numberOfElements: number | undefined;
  children: JSX.Element | JSX.Element[];
  isCollapsedByDefault?: boolean;
  isRoundedBottom?: boolean;
}

export const CollapsableSection = ({
  title,
  numberOfElements,
  children,
  isCollapsedByDefault,
  isRoundedBottom,
}: CollapsableSectionProps) => {
  const [isClosed, setIsClosed] = useState(isCollapsedByDefault);

  return (
    <Accordion
      onChange={() => setIsClosed(!isClosed)}
      className={`bg-transparent ${isRoundedBottom ? "rounded-b-lg" : ""}`}
      title={
        <div className="flex flex-row items-center gap-x-2 py-5">
          <Text variant="h4">{title}</Text>
          {numberOfElements !== undefined && <Badge>{numberOfElements}</Badge>}
        </div>
      }
      titleClassName="hover:bg-gray-0 px-6"
      defaultExpanded={!isClosed}
      variant="none"
    >
      <div className="flex flex-col p-6">{children}</div>
    </Accordion>
  );
};

type Props = {
  logEntry: LogType;
  hasDynamicResponseBody: boolean;
  responseBody: string | undefined;
  downloadInfo?: CodeBlockFileDownloadInfo;
  isRedacted?: boolean;
};

export const LogSidePanelBody = ({
  logEntry,
  hasDynamicResponseBody,
  responseBody,
  downloadInfo,
  isRedacted,
}: Props) => {
  const orgName = logEntry.linked_account.organization.name || "None";

  const OrganizationBodyImage = () => <OrganizationAvatar organizationName={orgName} />;

  const IntegrationImage = () => (
    <OrganizationAvatar
      imageURL={logEntry.linked_account.integration.square_image}
      organizationName={logEntry.linked_account.integration.name}
    />
  );

  return (
    <div className="overflow-auto">
      {isRedacted && (
        <div className="pt-6 px-6">
          <Alert showWarningIcon color="yellow">
            <div className="flex w-full flex-row items-center justify-between">
              <span>
                At the request of {logEntry?.linked_account.integration?.name}, personally
                identifiable information is redacted from our logs' request and response bodies
              </span>
            </div>
          </Alert>
        </div>
      )}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 p-6 h-fit">
        <LogSidePanelRequestSection
          logEntry={logEntry}
          organizationBodyImage={<OrganizationBodyImage />}
          integrationImage={<IntegrationImage />}
          isRedacted={isRedacted}
        />
        <LogSidePanelResponseSection
          logEntry={logEntry}
          organizationBodyImage={<OrganizationBodyImage />}
          integrationImage={<IntegrationImage />}
          hasDynamicResponseBody={hasDynamicResponseBody}
          isRedacted={isRedacted}
          responseBody={responseBody}
          downloadInfo={downloadInfo}
        />
      </div>
    </div>
  );
};
