import { useContext, useState } from "react";
import FieldMappingModalContext from "../../context/FieldMappingModalContext";
import LinkedAccountFieldMappingsContext from "../../../context/LinkedAccountFieldMappingsContext";
import { FieldMappingCreationAndEditDict } from "../../../../../../../../models/Entities";
import { FieldMappingSource } from "../../../../../../../../constants";
import {
  createLinkedAccountFieldMapping,
  fetchLinkedAccountFieldMappingInstances,
} from "../../../../../../../../api-client/APIClient";
import { createOverriddenCommonModelInstance } from "../../../../../../configuration/field-mappings/utils/FieldMappingUtils";
import { showSuccessToast, showErrorToast } from "../../../../../../../shared/Toasts";

const useRemoteFieldsDropdown = () => {
  // dependent context
  const {
    linkedAccount,
    onModalClose,
    fieldMappingTargetName,
    linkedAccountFieldMappingOptions,
    commonModelSelected,
    fieldMappingTargetDescription,
    fieldToOverride,
  } = useContext(FieldMappingModalContext);

  // state
  const { setFieldMappingTargetAndInstances } = useContext(LinkedAccountFieldMappingsContext);
  const [isCreating, setIsCreating] = useState(false);
  const [selectedRemoteField, setSelectedRemoteField] =
    useState<FieldMappingCreationAndEditDict | null>(null);
  const [showAdvancedMappingOption, setShowAdvancedMappingOption] = useState(false);
  const [jmesPath, setJmesPath] = useState<string | null>(null);
  const [isJmesPathValid, setIsJmesPathValid] = useState(false);

  // derived state
  const availableFieldMappings = linkedAccountFieldMappingOptions?.remote_field_options;

  // event handlers
  const createFieldMapping = (selectedRemoteField: any) => {
    setIsCreating(true);
    const body = {
      linked_account_id: linkedAccount!.id,
      common_model_id: `${linkedAccount!.category}.${commonModelSelected}`,
      field_key: fieldMappingTargetName!,
      field_traversal_path: selectedRemoteField.traversal_path.split("."),
      create_for_organization: false,
      configured_by: FieldMappingSource.ORGANIZATION,
      ...(fieldMappingTargetDescription
        ? {
            field_description: fieldMappingTargetDescription,
          }
        : {}),
      api_endpoint_id: selectedRemoteField.api_endpoint_id,
      display_name: selectedRemoteField.display_name,
      origin_type: selectedRemoteField.type,
      ...(jmesPath !== null ? { jmes_path: jmesPath } : {}),
    };
    createLinkedAccountFieldMapping(body, () => {
      fetchLinkedAccountFieldMappingInstances(
        linkedAccount!.id,
        setFieldMappingTargetAndInstances,
        () => {
          onModalClose();
          setIsCreating(false);
          showSuccessToast("Successfully created a linked account mapping!");
          document.getElementById(`${commonModelSelected}-mapping-info`)?.scrollIntoView({
            behavior: "smooth",
          });
        },
      );
    });
  };

  const createCommonModelOverride = () => {
    setIsCreating(true);
    if (selectedRemoteField) {
      const data = {
        linked_account_id: linkedAccount?.id,
        common_model_id: `${linkedAccount?.category}.${commonModelSelected}`,
        overridden_field: fieldToOverride?.name,
        field_traversal_path: selectedRemoteField.traversal_path.split("."),
        api_endpoint_id: selectedRemoteField?.api_endpoint_id,
        display_name: selectedRemoteField?.display_name,
        origin_type: selectedRemoteField.type,
        ...(jmesPath !== null ? { jmes_path: jmesPath } : {}),
      };

      const onSuccess = () => {
        fetchLinkedAccountFieldMappingInstances(
          linkedAccount!.id,
          setFieldMappingTargetAndInstances,
          () => {
            onModalClose();
            setIsCreating(false);
            showSuccessToast("Successfully created a linked account mapping!");
            document.getElementById(`${commonModelSelected}-mapping-info`)?.scrollIntoView({
              behavior: "smooth",
            });
          },
        );
      };

      createOverriddenCommonModelInstance(data, onSuccess, () => {
        showErrorToast("Unable to create Common Model override");
        onModalClose();
      });
    }
  };
  return {
    linkedAccount,
    commonModelSelected,
    onModalClose,
    fieldMappingTargetName,
    fieldMappingTargetDescription,
    selectedRemoteField,
    setSelectedRemoteField,
    availableFieldMappings,
    setJmesPath,
    setIsJmesPathValid,
    setShowAdvancedMappingOption,
    showAdvancedMappingOption,
    jmesPath,
    isJmesPathValid,
    isCreating,
    createFieldMapping,
    fieldToOverride,
    createCommonModelOverride,
  };
};

export default useRemoteFieldsDropdown;
