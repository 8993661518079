import React, { useState } from "react";
import { APICategory, UserType } from "../../../../models/Entities";
import INTEGRATION_CATEGORY_LIST, {
  displayNameForAPICategory,
  lucideIconForAPICategory,
} from "../../../../models/Helpers";
import {
  CONFIGURATION_FIELD_MAPPINGS_PATH,
  commonModelsConfigurationPathForIndividualCategory,
} from "../../../../router/RouterUtils";
import SubLeftNavigationContainer from "../../../portal/SubLeftNavigationContainer";
import { MergeFlagFeature, useMergeFlag } from "../../../shared/hooks/useMergeFlag";
import useAppContext from "../../../context/useAppContext";
import { ProductRestrictions } from "../../settings/billing/BillingModels";
import useProductRestrictions from "../../../shared/hooks/useProductRestrictions";
import RedactUnmappedDataModal from "./RedactUnmappedDataModal";
import PortalPageHeaderWrapper from "../../../portal/PortalPageHeaderWrapper";
import { Link, Text } from "@merge-api/merge-javascript-shared";

type ConfigurationCommonModelsPageWrapperProps = {
  showUpsellModal: boolean;
};

const _maybeRenderRedactionCard = (
  organizationId: string,
  isAdmin: boolean,
  onToggleClick: (enabled: boolean) => void,
  productRestrictions?: ProductRestrictions,
  isRedactionEnabledForOrganization?: boolean,
) => {
  const redactionCardDetails = (
    <Text variant="sm" className="text-gray-60">
      Redact all fields in API logs and Remote Data that are not mapped to Merge Common Model fields
      or used in
      <Link href={CONFIGURATION_FIELD_MAPPINGS_PATH} target="_blank">
        {" "}
        Field Mapping.
      </Link>
    </Text>
  );

  // Whether the frontend flag showing the redaction cards is enabled
  const { enabled: isRedactionFrontendEnabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_ENABLE_REDACT_UNMAPPED_DATA_FRONTEND,
    organizationId: organizationId,
  });

  // Whether redaction is turned on for this organization
  const isRedactionEnabled = isRedactionEnabledForOrganization;

  // Whether the entire redaction feature is allowed for this organization
  const isRedactionFeatureEnabled = productRestrictions?.redact_unmapped_data_enabled ?? false;

  return isRedactionFrontendEnabled && isRedactionFeatureEnabled && isAdmin
    ? {
        cardTitle: "Redact unmapped data",
        cardDetails: redactionCardDetails,
        toggleId: "toggle-redact-unmapped-data",
        isToggleChecked: isRedactionEnabled,
        onToggleClick: onToggleClick,
      }
    : null;
};

/**
 * Creates a wrapper for the Common Models configuration page, with links to each category
 */
const ConfigurationCommonModelsPageWrapper = ({
  showUpsellModal,
  children,
}: ConfigurationCommonModelsPageWrapperProps & Pick<React.ComponentProps<"div">, "children">) => {
  const { user } = useAppContext();
  const { productRestrictions } = useProductRestrictions();
  const [showModal, setShowModal] = useState(false);

  const isAdmin = user.type == UserType.admin_with_billing;
  const _onToggleClick = (enabled: boolean) => {
    setShowModal(true);
  };

  const isRedactionEnabledForOrganization = user.organization.redact_unmapped_data_enabled;
  const organizationId = user.organization.id;

  const maybeRedactionCard = _maybeRenderRedactionCard(
    organizationId,
    isAdmin,
    _onToggleClick,
    productRestrictions,
    isRedactionEnabledForOrganization,
  );
  const cards = maybeRedactionCard ? [maybeRedactionCard] : [];

  return (
    <>
      {isRedactionEnabledForOrganization !== undefined && (
        <RedactUnmappedDataModal
          isRedactionCurrentlyEnabledForOrg={isRedactionEnabledForOrganization}
          organizationId={organizationId}
          isModalOpen={showModal}
          onHide={() => setShowModal(false)}
          onConfirmResponse={() => setShowModal(false)}
        />
      )}
      <PortalPageHeaderWrapper
        title="Common Model Scopes"
        subtitle="Manage the data that you interact with via Merge's Unified API"
      >
        <div className={showUpsellModal ? "opacity-50" : "opacity-100"}>
          <SubLeftNavigationContainer
            subtabs={[
              ...INTEGRATION_CATEGORY_LIST.map((category: APICategory) => ({
                label: displayNameForAPICategory(category),
                destination: commonModelsConfigurationPathForIndividualCategory(category),
                icon: lucideIconForAPICategory(category, "md", "mr-3"),
              })),
            ]}
            cards={cards}
          >
            {children}
          </SubLeftNavigationContainer>
        </div>
      </PortalPageHeaderWrapper>
    </>
  );
};

export default ConfigurationCommonModelsPageWrapper;
