import React, { useEffect, useState } from "react";
import TileStep from "../helpers/TileStep";
import { ReactComponent as LinkVector } from "../../src/link-over-ui.svg";
import { ReactComponent as Sandbox } from "../../src/sandbox.svg";
import { ReactComponent as CircularArrow } from "../../src/CircularArrow.svg";
import LeftSideBar from "../helpers/LeftSideBar";
import LeftSideBarSpacing, { LeftSideBarCol, PaddingLessCol } from "../helpers/LeftSideBarSpacing";
import SandboxTile from "../helpers/SandboxTile";
import { ReactComponent as BambooHR } from "../../src/BambooHR.svg";
import { ReactComponent as Workable } from "../../src/Workable.svg";
import { ReactComponent as Jira } from "../../src/Jira.svg";
import { ReactComponent as HubSpot } from "../../src/HubSpot.svg";
import { ReactComponent as QuickBooks } from "../../src/QuickBooks.svg";
import styled from "styled-components";
import CustomTileStep from "../helpers/CustomTileStep";
import CustomLeftSideBar from "../helpers/CustomLeftSideBar";
import LiveWaitingIndicator from "../LiveWaitingIndicator";
import SyncOverview from "./SyncOverview";
import { LinkedAccount, LinkedAccountStatus } from "../../../../../models/Entities";
import { fetchWithAuth } from "../../../../../api-client/APIClient";
import useDashboardOnboardingStatus from "../../utils/useDashboardOnboardingStatus";
import TileOption from "../helpers/TileOption";
import { ReactComponent as LinkedAccountSingle } from "../../src/LinkedAccountsSingle.svg";
import { ReactComponent as SelectOneSingleLine } from "../../src/longline.svg";
import { Rocket, Wrench } from "lucide-react";
import { palette } from "../../../../../styles/theme";
import { ReactComponent as LinkedAccountsSVG } from "../../src/linked-accounts-svg.svg";
import { Text } from "@merge-api/merge-javascript-shared";

const StyledLinkedAccounts = styled(LinkedAccountsSVG)`
  position: relative;
  margin-top: 8px;
  margin-bottom: -10px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const StyledCol = styled(PaddingLessCol)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledLinkedAccountsSingle = styled(LinkedAccountSingle)`
  grid-area: linkedaccounts;
  display: none;
  @media (max-width: 1000px) {
    display: grid;
    margin-bottom: 10px;
  }
`;

const StyledSelectOneSingleLine = styled(SelectOneSingleLine)`
  grid-area: selectoneline;
  display: none;
  @media (max-width: 1000px) {
    position: relative;
    display: grid;
    left: 40px;
  }
`;

const SelectOneContainer = styled.div`
  display: grid;
  grid-area: container;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 22px;
  justify-content: space-evenly;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

const GridWrapper = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  margin-bottom: 92.5px;
  @media (max-width: 1195px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 1000px) {
    grid-template-columns: minmax(1px, 1fr);
  }
`;

const SelectOneGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "container";
  @media (max-width: 1000px) {
    grid-template-columns: 1fr minmax(1px, 4fr);
    grid-template-areas:
      "linkedaccounts ..."
      "selectoneline container";
  }
`;

const StyledTool = styled(Wrench)`
  color: ${palette.indigo};
`;

const StyledRocket = styled(Rocket)`
  color: ${palette.indigo};
`;

const RelativeContainer = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const AbsoluteDiv = styled.div`
  position: absolute;
`;

const StyledCircularArrow = styled(CircularArrow)`
  position: absolute;
`;

const StyledP = styled.p`
  margin-bottom: 9px;
`;

const SpacingDiv = styled.div`
  margin-bottom: 82px;
`;

const ExtraBold = styled.span`
  font-weight: 700;
`;

const LinkedAccountSection = () => {
  const dashboardOnboardingStatus = useDashboardOnboardingStatus();
  const [linkedAccounts, setLinkedAccounts] = useState<LinkedAccount[]>([]);
  const [linkedAccount, setLinkedAccount] = useState<LinkedAccount | null>(null);
  const [numberOfAccounts, setNumberOfAccounts] = useState<number | undefined>(undefined);

  /*
   *  Pulling all available test Linked Accounts, not including any demo data accounts when it is turned on
   * Sets the value of total Linked Accounts to check if there's a max number of Linked Accounts
   */

  useEffect(() => {
    setLinkedAccounts([]);
    fetchWithAuth({
      path: `/integrations/linked-accounts?status=${LinkedAccountStatus.COMPLETE}&is_test_account=true&ignore_demo_data=true&page_size=100`,
      method: "GET",
      onResponse: (data) => {
        setLinkedAccounts((linkedAccounts) => [...data.results, ...linkedAccounts]);
        if (linkedAccounts) {
          const firstLinkedAccount = data.results[0];
          setLinkedAccount(firstLinkedAccount);
          setNumberOfAccounts(data.results.length);
        }
      },
    });
  }, []);

  return (
    <div className="flex flex-col mx-auto w-11/12">
      <Text variant="h3" className="mb-6">
        Sync sandbox data
      </Text>
      <div className="flex flex-row">
        <LeftSideBar stepNumber="1" isNormalStep />
        <TileStep
          img={<Sandbox />}
          title="Create sandbox"
          subtitle={
            <>
              <StyledP>
                Visit one of our recommended third-party platforms below and create a free account.
              </StyledP>
              This account will have test data for you to play with. This is your{" "}
              <strong>sandbox</strong>.
            </>
          }
          stepNumber={1}
        />
      </div>
      <div className="flex flex-row">
        <LeftSideBarSpacing />
        <PaddingLessCol>
          <GridWrapper>
            <SandboxTile
              integrationImage={<BambooHR />}
              sandboxName="BambooHR"
              categoryName="HRIS"
              sandboxURL="https://www.bamboohr.com/signup/"
            />
            <SandboxTile
              integrationImage={<Workable height={48} width={48} />}
              sandboxName="Workable"
              categoryName="ATS"
              sandboxURL="https://ps.workable.com/merge"
            />
            <SandboxTile
              integrationImage={<Jira />}
              sandboxName="Jira"
              categoryName="Ticketing"
              sandboxURL="https://www.atlassian.com/software/jira/free"
            />
            <SandboxTile
              integrationImage={<HubSpot />}
              sandboxName="HubSpot"
              categoryName="CRM"
              sandboxURL="https://app.hubspot.com/signup-hubspot"
            />
            <SandboxTile
              integrationImage={<QuickBooks />}
              sandboxName="QuickBooks"
              categoryName="Accounting"
              sandboxURL="https://quickbooks.intuit.com/online/advanced/free-trial/"
            />
          </GridWrapper>
        </PaddingLessCol>
      </div>
      <div className="flex flex-row">
        <LeftSideBarSpacing />
        <PaddingLessCol>
          <div />
        </PaddingLessCol>
      </div>
      <div className="flex flex-row">
        <CustomLeftSideBar stepNumber="2" isNormalStep />

        <CustomTileStep
          img={<LinkVector />}
          title="Connect sandbox"
          subtitle={
            <>
              <StyledP>
                Open <strong>Merge Link</strong> below to connect your sandbox to Merge.
              </StyledP>
              <StyledP>
                You'll need to enter <strong>credentials</strong> from your sandbox.
              </StyledP>
              This connection will be called your <strong>Linked Account</strong>.
            </>
          }
          stepNumber={2}
          numberOfAccounts={numberOfAccounts}
          setNumberOfAccounts={setNumberOfAccounts}
          setLinkedAccounts={setLinkedAccounts}
          setLinkedAccount={setLinkedAccount}
          linkedAccounts={linkedAccounts}
        />
      </div>
      <div className="flex flex-row">
        <LeftSideBarSpacing />
        <StyledCol>
          <StyledLinkedAccounts />
          &nbsp;
        </StyledCol>
      </div>
      <div className="flex flex-row">
        <LeftSideBarSpacing />
        <PaddingLessCol>
          <SelectOneGridWrapper>
            <StyledLinkedAccountsSingle />
            <StyledSelectOneSingleLine />
            <SelectOneContainer>
              <TileOption
                img={<StyledTool size={16} />}
                title="Test Linked Accounts"
                subtitle1={
                  <>
                    <StyledP>
                      <strong>Best used for development of different integrations.</strong>
                    </StyledP>
                  </>
                }
                subtitle2={
                  <>
                    <StyledP>
                      You can <ExtraBold>sync data manually</ExtraBold> via Merge Dashboard to see
                      our normalized response. This is a great way to test an integration before
                      offering it to your customers.
                    </StyledP>
                  </>
                }
                subtitle3={
                  <>
                    <StyledP>
                      You get <strong>3 free</strong> test Linked Accounts.
                    </StyledP>
                  </>
                }
              />
              <TileOption
                img={<StyledRocket size={16} />}
                title="Production Linked Accounts"
                subtitle1={
                  <>
                    <StyledP>
                      <strong>Best used as you release your integrations to real users.</strong>
                    </StyledP>
                  </>
                }
                subtitle2={
                  <>
                    <StyledP>
                      Data <strong>syncs automatically</strong>, representing a live connection to
                      your customers' systems!
                    </StyledP>
                  </>
                }
                subtitle3={
                  <>
                    <StyledP>
                      You get <strong>3 free</strong> production Linked Accounts.
                    </StyledP>
                  </>
                }
              />
            </SelectOneContainer>
          </SelectOneGridWrapper>
        </PaddingLessCol>
      </div>
      <div className="flex flex-row">
        <LeftSideBarSpacing />
        <PaddingLessCol>
          <SpacingDiv />
        </PaddingLessCol>
      </div>
      <div className="flex flex-row">
        <LeftSideBar stepNumber="3" isNormalStep={false} />
        <TileStep
          img={
            <RelativeContainer>
              <StyledCircularArrow />
              <AbsoluteDiv>
                {dashboardOnboardingStatus?.has_successfully_linked_account ? (
                  <LiveWaitingIndicator color="green" />
                ) : (
                  <LiveWaitingIndicator color="red" />
                )}
              </AbsoluteDiv>
            </RelativeContainer>
          }
          title="Sync sandbox data"
          subtitle={
            <>
              <StyledP>
                Once you've finished the Merge Link workflow, Merge will automatically start syncing
                data.
              </StyledP>
              <StyledP>When Merge successfully syncs all data, this step will be finished!</StyledP>
              <StyledP>
                If you want, update your Sandbox and then resync all to see the changes reflected in
                Merge.
              </StyledP>
            </>
          }
          stepNumber={3}
        />
      </div>
      <div className="flex flex-row">
        <LeftSideBarCol className="col-2 align-items-center" />
        <SyncOverview
          linkedAccount={linkedAccount}
          linkedAccounts={linkedAccounts}
          setLinkedAccount={setLinkedAccount}
        />
      </div>
    </div>
  );
};

export default LinkedAccountSection;
