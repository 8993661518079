import React from "react";
import { useState, useEffect } from "react";
import { X } from "lucide-react";
import {
  APICategory,
  Badge,
  Button,
  ButtonVariant,
  Text,
  Typeahead,
} from "@merge-api/merge-javascript-shared";
import {
  lucideIconForAPICategory,
  displayNameForAPICategory,
} from "../../../../../../../../models/Helpers";
import { stringRemoveSpaces } from "../../../../../../../../services";
import { uniq } from "lodash";
import { conditionallyPluralize } from "../../../../../../../../utils/general";

interface Props {
  category: APICategory;
  onRemoveCategory?: () => void;
  commonModelOptions: string[];
  selectedCommonModelsForCategory: string[];
  selectedCommonModels: string[];
  selectedCommonModelEvents: string[];
  onSelectedCommonModelsChange: (selectedCommonModels: string[]) => void;
  onSelectedCommonModelEventsChange: (selectedCommonModelEvents: string[]) => void;
}
const SyncCommonModelCard = ({
  category,
  onRemoveCategory,
  commonModelOptions,
  selectedCommonModels,
  selectedCommonModelsForCategory,
  selectedCommonModelEvents,
  onSelectedCommonModelsChange,
  onSelectedCommonModelEventsChange,
}: Props) => {
  // state
  const [selectedCommonModelsCount, setSelectedCommonModelsCount] = useState<number>(
    selectedCommonModelsForCategory.length,
  );

  // use effect that updates the common model counts for each category
  useEffect(() => {
    setSelectedCommonModelsCount(selectedCommonModelsForCategory.length);
  }, [selectedCommonModelsForCategory]);

  /**
   * Update the arrays of selectedCommonModels and selectedCommonModelEvents.
   * `commonModels` is the new list of selectedCommonModels (just their names) for the category.
   * Removes the old common models that were selected for the category and then adds all the selected
   * common models in.
   */
  const updateSelectedCommonModels = (event: any, commonModels: string[] | null) => {
    if (commonModels) {
      // Remove all the common models for the current category
      const commonModelsForCategory = commonModelOptions.filter((commonModel) =>
        selectedCommonModels.includes(commonModel),
      );
      const updatedCommonModels = selectedCommonModels.filter(
        (m) => !commonModelsForCategory.includes(m),
      );
      // Remove events for Common Models for the current category
      const updatedSelectedEvents = selectedCommonModelEvents.filter(
        (e) => !commonModelsForCategory.map((m) => stringRemoveSpaces(m)).includes(e.split(".")[0]),
      );

      // Compose array of events to add for each newly added Common Model.
      const addedEvents: string[] = [];
      commonModels.forEach((commonModel) =>
        addedEvents.push(`${stringRemoveSpaces(commonModel)}.synced`),
      );

      // For newly added Common Models, add the default event types for that model and add to list of all common models (for all categories).
      updatedSelectedEvents.push(...addedEvents);
      updatedCommonModels.push(...commonModels);

      onSelectedCommonModelsChange(updatedCommonModels);
      onSelectedCommonModelEventsChange(uniq(updatedSelectedEvents));
      setSelectedCommonModelsCount(selectedCommonModelsForCategory.length);
    }
  };

  return (
    <div className="border border-gray-10 rounded-lg w-full bg-white mb-2">
      <div className="border-b border-gray-10 px-4 py-3 flex lg:flex-row cursor-default flex-col lg:items-center items-start justify-between">
        <div className="flex flex-row justify-center items-center">
          {lucideIconForAPICategory(category as APICategory, "md", "mr-2")}
          <Text variant="h6">{displayNameForAPICategory(category as APICategory)}</Text>
        </div>
        <div className="flex flex-row justify-center items-center lg:mt-0 mt-3">
          <Badge size="md" color="blue">
            {conditionallyPluralize("model", selectedCommonModelsCount)}
          </Badge>
          <Button
            size="sm"
            className="ml-3"
            variant={ButtonVariant.IconShadowHidden}
            onClick={onRemoveCategory}
          >
            <X size={16} />
          </Button>
        </div>
      </div>
      <div className="px-4 pt-3 pb-4 flex flex-col gap-1">
        <Text variant="h6">Select common models</Text>
        <Typeahead
          multiple
          showSearchIcon
          placeholder="Search..."
          options={commonModelOptions}
          onChange={updateSelectedCommonModels}
          value={selectedCommonModelsForCategory}
          disableClearable
        />
      </div>
    </div>
  );
};

export default SyncCommonModelCard;
