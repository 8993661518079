import { capitalizeFirstLetter, Tooltip } from "@merge-api/merge-javascript-shared";
import { ToggleLeft, Type, Brackets, CalendarDays, Braces, Hash, HelpCircle } from "lucide-react";
import React from "react";

interface Props {
  type: string;
  size: number;
  className?: string;
}

const IconForFieldType = ({ type, size, className }: Props) => {
  let icon;

  switch (type) {
    case "string":
      icon = <Type size={size} className={className} />;
      break;
    case "boolean":
      icon = <ToggleLeft size={size} className={className} />;
      break;
    case "dict":
      icon = <Braces size={size} className={className} />;
      break;
    case "list[any]":
    case "list[object]":
      icon = <Brackets size={size} className={className} />;
      break;
    case "array":
      icon = <Brackets size={size} className={className} />;
      break;
    case "date-time":
      icon = <CalendarDays size={size} className={className} />;
      break;
    case "date":
      icon = <CalendarDays size={size} className={className} />;
      break;
    case "number":
      icon = <Hash size={size} className={className} />;
      break;
    default:
      icon = <HelpCircle size={size} className={className} />;
      break;
  }

  return <Tooltip title={capitalizeFirstLetter(type)}>{icon}</Tooltip>;
};

export default IconForFieldType;
